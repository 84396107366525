/* eslint-disable */
// NOTE: useWorker와 사용하기 때문에 webpack을 사용하지 말 것.
const excelJS = require('exceljs')

export const excelCreateNewPopulationFileFromExcelData = new Function(
  'templateFileObject', 'excelData',
  `
  const toLetters = (num) => {
    const mod = num % 26
    let pow = (num / 26) | 0
    const out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z')
    return pow ? toLetters(pow) + out : out
  }

  // 모집단 템플릿 파일에 새로운 데이터를 쓴다
  const workBook = new ExcelJS.Workbook()

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader()
    fileReader.onloadend = async (evt) => {
      const {
        target: { result: targetExcelArrayBuffer }
      } = evt

      await workBook.xlsx.load(targetExcelArrayBuffer)
      const workSheet = workBook.getWorksheet('모집단')

      const excelRowDataList = [excelData.headerRow]
      excelData.rowObjectList.forEach((row) => {
        excelRowDataList.push(Object.values(row))
      })

      let columnIndex = 2
      let rowIndexInt = 9
      let rowIndexString = String(rowIndexInt) // "9"
      excelRowDataList.forEach((rowData) => {
        rowData.forEach((data) => {
          const cellIndex = toLetters(columnIndex) + rowIndexString
          workSheet.getCell(cellIndex).value = data
          columnIndex += 1
        })
        columnIndex = 2
        rowIndexInt += 1
        rowIndexString = String(rowIndexInt)
      })
      const resultExcelBuffer = await workBook.xlsx.writeBuffer()
      const resultExcelFile = new File([resultExcelBuffer], 'newPopulationFile.xlsx')

      resolve(resultExcelFile)
    }

    fileReader.onerror = reject
    fileReader.readAsArrayBuffer(templateFileObject)
  })
`)
