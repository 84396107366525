/* eslint-disable camelcase */
export const parsingReadMap_OE = {
  Overview: {
    C3: {
      name: 'number',
      korName: '통제번호'
    },
    C4: {
      name: 'name',
      korName: '통제활동명'
    },
    C6: {
      name: 'executor',
      korName: '통제 평가자'
    },
    C7: {
      name: 'createDate',
      korName: '작성일자'
    },
    C9: {
      name: 'populationCount',
      korName: '모집단 항목수'
    },
    C10: {
      name: 'samplingCount',
      korName: '샘플링 항목수'
    },
    C11: {
      name: 'validCount',
      korName: '유효 항목수'
    },
    C12: {
      name: 'invalidCount',
      korName: '비유효 항목수'
    },
    C13: {
      name: 'testResult',
      korName: '운영 테스트결과'
    }
  },
  '모집단 및 샘플링': {
    C4: {
      name: 'populationDescription',
      korName: '모집단 파일 및 설명'
    },
    C6: {
      name: 'populationCompleteness',
      korName: '모집단의 완전성 확인'
    },
    C11: {
      name: 'samplingMethod',
      korName: '샘플링 방법'
    },
    C13: {
      name: 'populationTableColumnCount',
      korName: '항목당 속성수(열)'
    }
  },
  '운영평가 수행': {
    C4: {
      name: 'samplingAttributeCount',
      korName: '테스트 속성수'
    }
  }
}

/*
"": {
      name: "",
      korName: "",
    },
*/

export const parsingWriteMap_OE = {
  id: -1,
  name: -1,
  goal: -1,
  narrative: -1,
  Yes: -1, // keyControl
  일별수시: -1, // period
  수동통제: -1, // autoManual
  TOC_Population: -1,
  TOC_Completeness: -1,
  TOC_TestProperties: -1,
  TOC_Exception: -1,
  TOC_Evidence: -1,
  TOC_Procedure: -1
}
