import { makeStyles, Theme } from '@material-ui/core'
import Button from 'antd/lib/button'
import Tabs from 'antd/lib/tabs'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import Animate from 'rc-animate'
import React from 'react'
import { useCookies } from 'react-cookie'

import { EvaluationFile, ServerFile } from '../../../base/data/BaseFile'
import {
  EvaluationType,
  getKoEvaluation,
  Evaluation,
  OperationEvaluationData,
  DesignEvaluationData
} from '../../../base/data/Evaluation'
import { DesignDefect } from '../../../base/data/EvaluationDefect'
import { LoadStaffs, Staff } from '../../../base/data/Staff'
import {
  evaluationUpdateState,
  evaluationUpdateWeakStateData
} from '../../../dataLoader/Evaluation/evaluation'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CardTabs from '../../common/CardTabs'
import CircleBackdrop from '../../common/CircleBackdrop'
import { BASE_ITEM } from '../../common/DividerSelect'
import LoadingButton from '../../common/LoadingButton'
import ControlView from '../../rcm/common/ControlView'
import {
  EvaluationSelectAttribute,
  evaluationStateToString,
  getShortEvalType,
  loadEvaluationDetails,
  loadEvaluationNames,
  SaveEvaluationDetail
} from '../common/EvaluationUtils'
import DesignEvaluationReportEditor from '../view/DesignEvaluationReportEditor'
import EvaluationSelectAccordion from '../view/EvaluationSelectAccordion'
import OperationEvaluationReportEditor from '../view/OperationEvaluationReportEditor'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    minHeight: 0
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  subcontent: {
    padding: 0
  },
  editableTab: {
    padding: theme.spacing(1),
    flexDirection: 'column',
    '& .ant-tabs-tabpane': {
      padding: 0
    }
  },
  designEvaluationEditor: {
    padding: theme.spacing(2)
  },
  saveButtonContainer: {
    paddingBottom: theme.spacing(2),
    textAlign: 'end'
  },
  performedButton: {
    marginRight: theme.spacing(1)
  }
}))

export interface EvaluationReportEditorHandler {
  getNewEvaluation: () => {
    evaluationData: OperationEvaluationData | DesignEvaluationData
    deletedFiles: ServerFile[]
    newFiles: EvaluationFile[]
    weakStateData?: DesignDefect
  }
}

function getReportView(
  classes: any,
  type: EvaluationType,
  staffs: Staff[] | undefined,
  evaluation: Evaluation | undefined,
  unperformEvaluation: () => Promise<void>,
  onSaveEvaluation: (
    evaluationData: OperationEvaluationData | DesignEvaluationData,
    deletedFiles: ServerFile[],
    newFiles: EvaluationFile[],
    progressState: '2' | '3',
    weakStateData?: DesignDefect
  ) => void
): React.ReactElement {
  const editorRef = React.useRef<EvaluationReportEditorHandler>(null)
  if (!evaluation || !staffs || _.isEmpty(evaluation)) {
    return <div />
  }

  const saveButton = (
    <div>
      {evaluation.data.base.state === '1' && (
        <>
          <Typography.Text
            style={{
              marginRight: 4,
              visibility: _.includes(['3', '5'], evaluation?.data.state.state)
                ? 'visible'
                : 'hidden'
            }}
            type="danger"
          >
            {evaluationStateToString(evaluation.data.state.state)} 된 보고서는 저장할 수 없습니다.
          </Typography.Text>
          <LoadingButton
            className={classes.performedButton}
            disabled={_.includes(['5'], evaluation?.data.state.state)}
            onClick={() => {
              if (evaluation.data.state.state === '3') {
                // 수행 완료 취소
                return unperformEvaluation()
              }

              if (editorRef?.current) {
                const { evaluationData, deletedFiles, newFiles, weakStateData } =
                  editorRef.current.getNewEvaluation()
                onSaveEvaluation(evaluationData, deletedFiles, newFiles, '3', weakStateData)
              }
              return Promise.resolve()
            }}
            danger={evaluation?.data.state.state === '3'}
          >
            {evaluation?.data.state.state === '3' ? '수행완료 취소' : '수행완료'}
          </LoadingButton>
          <Button
            // 수행완료, 승인완료일 경우 저장 불가
            disabled={_.includes(['3', '5'], evaluation?.data.state.state)}
            type="primary"
            onClick={() => {
              if (editorRef?.current) {
                const { evaluationData, deletedFiles, newFiles, weakStateData } =
                  editorRef.current.getNewEvaluation()
                onSaveEvaluation(evaluationData, deletedFiles, newFiles, '2', weakStateData)
              }
            }}
          >
            저장
          </Button>
        </>
      )}
      {evaluation.data.base.state === '2' && (
        <Typography.Text style={{ marginRight: 4 }} type="danger">
          마감 된 {getKoEvaluation(type)}는 수정 할 수 없습니다.
        </Typography.Text>
      )}
    </div>
  )

  if (type === 'operation') {
    return (
      <CardTabs
        defaultActiveKey="1"
        overflow
        className={classes.editableTab}
        tabBarExtraContent={saveButton}
      >
        <Tabs.TabPane key="1" tab={getKoEvaluation('operation')}>
          <div className={classes.subcontent}>
            <OperationEvaluationReportEditor
              evaluation={evaluation}
              ref={editorRef}
              staffs={staffs || []}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="통제활동">
          <div className={classes.subcontent}>
            {evaluation?.data.content.controlData && (
              <ControlView control={evaluation.data.content.controlData} staffs={staffs || []} />
            )}
          </div>
        </Tabs.TabPane>
      </CardTabs>
    )
  }

  return (
    <div className={classes.designEvaluationEditor}>
      <div className={classes.saveButtonContainer}>{saveButton}</div>
      <DesignEvaluationReportEditor ref={editorRef} evaluation={evaluation} staffs={staffs || []} />
    </div>
  )
}

async function saveEvaluation(
  type: EvaluationType,
  evaluationName: string,
  evaluations: Evaluation[],
  controlId: string,
  evaluationData: OperationEvaluationData | DesignEvaluationData,
  deletedFile: ServerFile[],
  newFiles: EvaluationFile[],
  progressState: '2' | '3',
  weakStateData?: DesignDefect
) {
  try {
    await SaveEvaluationDetail(
      type,
      evaluationName,
      evaluations,
      controlId,
      evaluationData,
      deletedFile,
      newFiles,
      progressState
    )
  } catch (error: any) {
    if (error.message !== 'not changed' || type === 'operation' || _.isEmpty(weakStateData)) {
      throw Error(error)
    }
  }

  if (type === 'design' && weakStateData) {
    await evaluationUpdateWeakStateData(
      getShortEvalType(type),
      evaluationName,
      controlId,
      weakStateData
    )
  }
}
interface Props {
  type: EvaluationType
}

const EvaluationEditor: React.FC<Props> = ({ type }) => {
  const classes = useStyles()
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: BASE_ITEM,
    names: [],
    summaries: [],
    evaluationDetails: []
  })
  const [states, setStates] = React.useState({
    selectedDetailId: '',
    saveLoading: false
  })
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const [cookies, setCookie] = useCookies([`${type}EvaluationName`])

  React.useEffect(() => {
    LoadStaffs().then((newStaffs) => {
      setStaffs(newStaffs)
    })
  }, [])

  React.useEffect(() => {
    loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
    setStates({ ...states, selectedDetailId: '' })
  }, [type])

  const onSaveEvaluationDetail = React.useCallback(
    (
      evaluationData: OperationEvaluationData | DesignEvaluationData,
      deletedFile: ServerFile[],
      newFiles: EvaluationFile[],
      progressState: '2' | '3',
      weakStateData?: DesignDefect
    ): void => {
      setStates({
        ...states,
        saveLoading: true
      })

      saveEvaluation(
        type,
        select.selectedName,
        select.evaluationDetails || [],
        states.selectedDetailId,
        evaluationData,
        deletedFile,
        newFiles,
        progressState,
        weakStateData
      )
        .then(() => {
          loadEvaluationDetails(type, select.selectedName, select, setSelect)
          alertRef.current?.showAlert('success', '저장 성공')
        })
        .catch((e) => {
          console.log('Failed to update ', e)
          if (e.message === 'not changed') {
            alertRef.current?.showAlert('warning', '변경 사항이 없음')
          } else if (e.message === 'empty header') {
            alertRef.current?.showAlert('warning', '샘플링 결과의 헤더에 빈칸을 채워주세요.')
          } else {
            alertRef.current?.showAlert('error', '저장 실패')
          }
        })
        .finally(() => {
          setStates({
            ...states,
            saveLoading: false
          })
        })
    },
    [states, select, alertRef]
  )

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setStates({ ...states, selectedDetailId: '' })
      setCookie(`${type}EvaluationName`, evaluationName)
    },
    [states, select]
  )

  const selectedEvaluation = React.useMemo(
    () => _.find(select.evaluationDetails, { controlId: states.selectedDetailId }),
    [select, states]
  )

  return (
    <div className={classes.root}>
      <EvaluationSelectAccordion
        type={type}
        staffs={staffs}
        select={select}
        selectedDetailId={states.selectedDetailId}
        onEvaluationSelect={onEvaluationSelect}
        onEvaluationDetailClick={(evaluationDetail: Evaluation) => {
          setStates({
            ...states,
            selectedDetailId: evaluationDetail.controlId
          })
        }}
      />
      <Animate transitionAppear className={classes.content} transitionName="fade">
        {getReportView(
          classes,
          type,
          staffs,
          selectedEvaluation,
          () =>
            evaluationUpdateState(
              getShortEvalType(type),
              select.selectedName,
              [states.selectedDetailId],
              '2'
            )
              .then(() => {
                alertRef.current?.showAlert('success', '수행 완료가 취소되었습니다.')
                loadEvaluationDetails(type, select.selectedName, select, setSelect)
              })
              .catch(() => alertRef.current?.showAlert('error', '상태 변경이 실패했습니다.')),
          onSaveEvaluationDetail
        )}
      </Animate>
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={states.saveLoading} />
    </div>
  )
}

export default EvaluationEditor
