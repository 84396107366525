import _ from 'lodash'

import { PRCType } from '../../base/data/PRC'
import { evaluationUpdateTemplate } from '../Evaluation/evaluation'
import { adminGetOrCreateUser } from '../Utils/admin'
import { backupSetFlag } from '../Utils/backup'
import { updateObjectForm } from '../Utils/updateObjectForm'
import { dbService } from '../fbase'
import { controlGetWithId, controlListUpdatePathArray } from './control'
import { cycleGetAll, cycleListUpdatePathArray } from './cycle'
import { processListUpdatePathArray } from './process'

export const riskInsertDataListForInit = async (dataObjectList) => {
  /*
  Init 을 위해서 Excel 에서 넘어온 Risk Data List 및 추가 정보들을 Firestore 에 집어넣는다

  @param: dataObjectList: Object[]: 데이터 리스트
  */
  const riskInsertDataForInit = async (dataObject) => {
    // 단일 Data 를 집어넣는다.
    const riskId = dataObject.riskNumber

    const targetCollectionName = 'RISK'
    const targetDocId = riskId
    const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

    return await docRef.set({
      id: riskId,
      uid: riskId,
      name: dataObject.riskName,
      RoMM: dataObject.RoMM,
      inherentRiskLevel: dataObject.inherentRiskLevel,
      selfPath: 'RISK/' + riskId,
      cyclePathArray: [
        'CYCLE/' +
          dataObject.processCycleNumber +
          '-' +
          dataObject.processCategoryNumber +
          '-' +
          dataObject.processSubCategoryNumber
      ],
      processPathArray: dataObject.processIdList.map((processId) => 'PROCESS/' + processId),
      controlPathArray: []
    })
  }

  // risk 같은 경우는 중복되는 게 많다.
  const uniqueDataObjectList = []
  const uniqueDataObjectMap = {}
  dataObjectList.forEach((dataObject) => {
    const riskId = dataObject.riskNumber
    const processIdList = dataObject.processMappingCode.trim().split('\r\n')
    if (riskId in uniqueDataObjectMap) {
      processIdList.forEach((processId) => uniqueDataObjectMap[riskId].push(processId.trim()))
    } else {
      uniqueDataObjectMap[riskId] = processIdList
      uniqueDataObjectList.push(dataObject)
    }
  })

  uniqueDataObjectList.forEach((dataObject) => {
    const riskId = dataObject.riskNumber
    const processIdList = _.uniq(uniqueDataObjectMap[riskId])
    dataObject.processIdList = processIdList
  })

  const result = uniqueDataObjectList.map(async (dataObject) => {
    return await riskInsertDataForInit(dataObject)
  })

  console.log('Insert Risk Data For Init Done')
  return Promise.all(result)
}

export const riskGetWithId = async (riskId) => {
  // riskId 에 해당하는 항목만 가져온다.
  const targetCollectionName = 'RISK'
  const docData = await dbService.collection(targetCollectionName).doc(riskId).get()

  return docData.data()
}

export const riskGetAll = async () => {
  // 모든 RISK 데이터를 가져온다.
  const targetCollectionName = 'RISK'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.data())
}

const riskUpdatePathArray = async (riskId, updateObject) => {
  /*
  RISK 의 riskId 에 대해서
  updateObject 의 내용들을 수정

  @param: riskId: String - RISK id
  @param: updateObject{
    "cyclePathArray": {
      "add": [cycleId, ...],
      "del": [cycleId, ...]
    },
    "processPathArray": {
      "add": [processId, ...],
      "del": [processId, ...]
    },
    "riskPathArray": {
      "add": [riskId, ...],
      "del": [riskId, ...]
    },
    "controlPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    }
  }
  */

  const finalUpdateObject = {}

  const riskObject = await riskGetWithId(riskId)
  const iterKeyList = ['cyclePathArray', 'processPathArray', 'controlPathArray']
  iterKeyList.forEach((targetKey) => {
    let resultIdList = riskObject[targetKey].map((path) => path.split('/')[1])
    const addList = updateObject[targetKey].add
    addList.forEach((addId) => {
      resultIdList.push(addId)
    })

    const delList = updateObject[targetKey].del
    delList.forEach((delId) => {
      const idx = resultIdList.indexOf(delId)
      if (idx > -1) {
        resultIdList.splice(idx, 1)
      }
    })

    resultIdList = [...new Set(resultIdList)]
    const resultPathArray = resultIdList.map(
      (id) => targetKey.split('PathArray')[0].toUpperCase() + '/' + id
    )
    finalUpdateObject[targetKey] = resultPathArray
  })

  return await dbService.collection('RISK').doc(riskId)
.update(finalUpdateObject)
}

export const riskListUpdatePathArray = async (updateObject) => {
  /*
  RISK 의 riskIdList 에 속하는 riskId 들에 대해서
  riskUpdatePathArray 를 호출

  @param: riskId: String - RISK id
  @param: updateObject{
    "riskId": {},
    ...
  }
  */
  const result = Object.keys(updateObject).map(async (riskId) => {
    return await riskUpdatePathArray(riskId, updateObject[riskId])
  })

  return Promise.all(result)
}

export const riskGetAllContainCycle = async (controls) => {
  /*
  RISK의 정보전체에 분류(CYCLE) 정보를 추가해서 가져온다.
  추가되는 CYCLE 의 내용은 대분류(cycleNumber, cycleName), 중분류(categoryNumber, categoryName), 소분류(subCategoryNumber, subCategoryName) 이 필요하다.

  RISK 는 생성될 때 CYCLE 에 무조건 연결되면서 생성된다.
  그러므로 cyclePathArray 가 비어있을 수 없다.

  UserLevel이 NORMAL, UPLOADER일 경우, 해당되는 Control이 속한 Risk만 반환한다.

  @return: Object[]
  [
    {
      id: String - "ELC-01-01-01-R01",
      uid: String - "ELC-01-01-01-R01",
      name: String - "경영진의 윤리적 의지가 조직에 효과적으로 전달되지 못할 위험",
      RoMM: String - "N/A",
      inherentRiskLevel: String - "L",
      selfPath: String - "RISK/ELC-01-01-01-R01",
      cyclePathArray: String[] - ["CYCLE/ELC-01-01"],
      processPathArray: String[] - ["PROCESS/ELC-01-01-01"],
      controlPathArray: String[] - ["CONTROL/ELC-01-01-01-R01-C01"],

      cycleName: String - "전사수준통제",
      cycleNumber: String - "ELC",
      categoryName: String - "원칙 1. 도덕성과 윤리적 가치에 대한 책임",
      categoryNumber: String - "01",
      subCategoryName: String - "경영진과 이사회의 의지",
      subCategoryNumber: String - "01"
    }
  ]
  */

  const cycleDataAll = await cycleGetAll()
  const riskDataAll = await riskGetAll()
  const user = await adminGetOrCreateUser()

  return _(riskDataAll)
    .map((riskObject) => {
      const riskId = riskObject.id
      const cycleId = riskObject.cyclePathArray[0].split('/')[1]
      const relateCycleObject = _.find(cycleDataAll, { id: cycleId })
      const controlIds = _.map(riskObject.controlPathArray, (path) => _.last(_.split(path, '/')))

      if (
        (user.level === 'UPLOADER' || user.level === 'NORMAL') &&
        !_.some(controlIds, (controlId) => _.find(controls, { id: controlId }))
      ) {
        return undefined
      }

      return {
        type: PRCType.risk,
        id: riskId,
        uid: riskId,
        name: riskObject.name,

        RoMM: riskObject.RoMM,
        inherentRiskLevel: riskObject.inherentRiskLevel,

        selfPath: riskObject.selfPath,
        cyclePathArray: riskObject.cyclePathArray,
        processPathArray: riskObject.processPathArray,
        controlPathArray: riskObject.controlPathArray,

        cycleNumber: relateCycleObject.cycleNumber,
        cycleName: relateCycleObject.cycleName,
        categoryNumber: relateCycleObject.categoryNumber,
        categoryName: relateCycleObject.categoryName,
        subCategoryNumber: relateCycleObject.subCategoryNumber,
        subCategoryName: relateCycleObject.subCategoryName,

        processIds: _.map(riskObject.processPathArray, (path) => _.last(_.split(path, '/'))),
        controlIds
      }
    })
    .compact()
    .value()
}

export const riskUpdate = async (riskObject) => {
  /*
  RISK 를 업데이트 한다
  name, RoMM, inherentRiskLevel 만 가능하다

  TODO: 설계평가에 영향

  @param: Object{
    id: String - "R.AP.01",
    name: String - "중복, 미승인 또는 오류의 대금지급이 비용과 부채로 잘못 기록되거나 잘못 배분될 위험",
    RoMM: String - "매출 과계대상",
    inherentRiskLevel: String - "H" ("L", "M", "H"),
  }
  */

  const riskId = riskObject.id
  const targetCollectionName = 'RISK'
  const targetDocId = riskObject.id
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef
    .update({
      name: riskObject.name,
      RoMM: riskObject.RoMM,
      inherentRiskLevel: riskObject.inherentRiskLevel
    })
    .then(async () => {
      // RISK 가 업데이트 되면 연결된 CONTROL 에서도 바뀌어야 한다.(riskName)
      // 그리고 설계평가 보고서 Template 에서도 바뀌어야 한다(riskName)
      const riskData = await riskGetWithId(riskId)
      const controlIdList = riskData.controlPathArray.map(
        (controlPath) => controlPath.split('/')[1]
      )
      const result = controlIdList.map(async (controlId) => {
        // 변경할 DE 에 들어갈 내용을 교체
        const controlData = await controlGetWithId(controlId)
        controlData.riskName = riskObject.name
        controlData.riskInherentRiskLevel = riskObject.inherentRiskLevel
        // CONTROL 업데이트
        return dbService
          .collection('CONTROL')
          .doc(controlId)
          .update({
            riskName: riskObject.name,
            riskInherentRiskLevel: riskObject.inherentRiskLevel
          })
          .then(() => evaluationUpdateTemplate('DE', controlId, controlData))
      })
      await Promise.all(result)
    })
    .then(() => backupSetFlag())
}

export const riskFindNewNumberToCreate = async (processId) => {
  /*
  새로운 RISK 를 만들기 위해서 필요한 새로운 number 를 리턴.

  아이디의 체계가 바뀌어서 필요한 정보가 바뀜
  기존에는 cycleName 을 기준으로 RISK 를 생성했지만
  이제는 processId 를 기준으로 RISK 를 생성하게 바뀜
  PROCESS id 가 필요하다 - ELC-01-01-01-R01
  리턴하는 값도 예전에는 "01" 이런식이었지만
  이제는 "R01" 이런식으로 바뀜

  @param: processId: String - "ELC-01-01-01", etc...
  @return: String: "R01" - new RISK number
  */

  const riskDataAll = await riskGetAll()

  // 같은 cycleName 에 속하는 RISK 목록
  const riskTargetList = _.filter(riskDataAll, (riskObject) => {
    const riskId = riskObject.id
    // TODO: Id 체계에 따라 수정할 부분
    const splitRiskId = riskId.split('-')
    const processIdFromRisk = _.join(_.slice(splitRiskId, 0, 4), '-')

    return processIdFromRisk === processId
  })

  let maxNumber = 0
  riskTargetList.forEach((riskObject) => {
    // TODO: Id 체계에 따라 수정할 부분
    const riskId = riskObject.id
    const splitRiskId = riskId.split('-')
    const riskPartFromRiskId = _.last(splitRiskId)
    const stringNumber = riskPartFromRiskId.slice(1)
    const intNumber = Number(stringNumber)
    if (intNumber > maxNumber) {
      maxNumber = intNumber
    }
  })
  const newIntNumber = maxNumber + 1
  const newStringNumber = newIntNumber < 10 ? '0' + String(newIntNumber) : String(newIntNumber)
  const newRiskPart = 'R' + newStringNumber

  return newRiskPart
}

const riskFindChangePathFromCreate = async (riskId, cycleId, processId) => {
  /*
  RISK 를 생성할 때 생기는 연결된 path 들의 변경사항을 모두 찾는다.
  CYCLE
  PROCESS

  @param: riskId: String - RISK id to be created
  @param: cycleId: String - CYCLE id
  @param: processId: String - PROCESS id
  @return: Object{
    cycleUpdateObject, processUpdateObject, controlUpdateObject
    생긴건 updateObjectForm 참조
  }
  */

  // CYCLE 업데이트 객체
  const cycleUpdateObject = {}
  cycleUpdateObject[cycleId] = _.cloneDeep(updateObjectForm)
  cycleUpdateObject[cycleId].riskPathArray.add = [riskId]

  // PROCESS 업데이트 객체
  const processUpdateObject = {}
  processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
  processUpdateObject[processId].riskPathArray.add = [riskId]

  return { cycleUpdateObject, processUpdateObject }
}

export const riskCreate = async (riskObject) => {
  /*
  새로운 RISK 를 만든다.

  기존에 RISK 를 생성할 때 RISK 는 CYCLE 에 연결되어져서 만들어졌다.
  하지만 이제는 생성되는 RISK 는 PROCESS 에 귀속되기 때문에
  CYCLE-PROCESS 까지 연결되어져서 만들어진다.

  속하는 CYCLE 의 riskPathArray 에 RISK 를 추가한다.
  속하는 PROCESS 의 riskPathARray 에 RISK 를 추가한다

  @param: Object{
    processId: String - "ELC-01-01-01": PROCESS id
    riskNumber: String - "R02",

    riskName: String - "NEW RISK name",
    RoMM: String - "NEW RISK RoMM",
    inherentRiskLevel: String - "NEW RISK inherentRiskLevel",

    cycleNumber: String - "7",
    cycleName: String - "AP",
    categoryNumber: String - "1",
    categoryName: String - "Accounts Payable 관리",
    subCategoryNumber: String - "4",
    subCategoryName: String - "Accruals",
  }
  */
  const processId = riskObject.processId
  // TODO: Id 체계에 따라 수정할 부분
  const splitProcessId = processId.split('-')
  const riskNumber = riskObject.riskNumber
  const riskId = processId + '-' + riskNumber
  const cycleId = _.join(_.slice(splitProcessId, 0, 3), '-')

  const targetCollectionName = 'RISK'
  const targetDocId = riskId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const updateResult = await riskFindChangePathFromCreate(riskId, cycleId, processId)

  return await docRef
    .set({
      id: riskId,
      uid: riskId,
      name: riskObject.name,
      RoMM: riskObject.RoMM,
      inherentRiskLevel: riskObject.inherentRiskLevel,

      selfPath: 'RISK/' + riskId,
      cyclePathArray: ['CYCLE/' + cycleId],
      processPathArray: ['PROCESS/' + processId],
      controlPathArray: []
    })
    .then(() => {
      cycleListUpdatePathArray(updateResult.cycleUpdateObject)
      processListUpdatePathArray(updateResult.processUpdateObject)
    })
    .then(() => backupSetFlag())
}

export const riskCheckBeforeDelete = async (riskId) => {
  /*
  RISK 에 연결된 CONTROL 이 있는지 체크해서 해당 리스트를 반환
  연결된 CONTROL 이 없다면 빈 리스트 [] 가 반환 될 것이다.

  @param: riskId: String - RISK id
  @return: String[] - 연결된 CONTROL id list
  */

  const riskData = await riskGetWithId(riskId)
  const controlPathArray = riskData.controlPathArray
  const controlIdList = controlPathArray.map((controlPath) => controlPath.split('/')[1])

  return controlIdList
}

const riskFindChangePathFromDelete = async (riskId) => {
  /*
  RISK 를 삭제할 때 생기는 연결된 path 들의 변경사항을 모두 찾는다.
  CYCLE, PROCESS, CONTROL

  @param: riskId: String - RISK id to be deleted
  @return: Object{
    cycleUpdateObject, processUpdateObject, controlUpdateObject
    생긴건 updateObjectForm 참조
  }
  */

  const riskData = await riskGetWithId(riskId)
  const cycleId = riskData.cyclePathArray[0].split('/')[1] // 연결된 cycle 은 무조건 한개다
  const processIdList = riskData.processPathArray.map((processPath) => processPath.split('/')[1])
  const controlIdList = riskData.controlPathArray.map((controlPath) => controlPath.split('/')[1])

  // CYCLE 업데이트 객체
  const cycleUpdateObject = {}
  cycleUpdateObject[cycleId] = _.cloneDeep(updateObjectForm)
  cycleUpdateObject[cycleId].riskPathArray.del = [riskId]

  // PROCESS 업데이트 객체
  const processUpdateObject = {}
  processIdList.forEach((processId) => {
    processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
    processUpdateObject[processId].riskPathArray.del = [riskId]
  })

  // CONTROL 업데이트 객체
  const controlUpdateObject = {}
  controlIdList.forEach((controlId) => {
    controlUpdateObject[controlId] = _.cloneDeep(updateObjectForm)
    controlUpdateObject[controlId].riskPathArray.del = [riskId]

    // RISK 에 연결된 CONTROL 들은 RISK 와 연결된 PROCESS 의 controlPatharray 에서도 지워야 한다.
    processIdList.forEach((processId) => {
      processUpdateObject[processId].controlPathArray.del.push(controlId)
    })
  })

  return { cycleUpdateObject, processUpdateObject, controlUpdateObject }
}

export const riskDelete = async (riskId) => {
  /*
  ID 의 체계가 바뀌어서
  RISK 밑에는 CONTROL 들이 귀속되어 있다.
  그래서 RISK 를 마구 지울 수 없다.
  지우기 전에 연결된(귀속된) CONTROL 이 있는지 체크가 필요하다
  => riskCheckBeforeDelete 를 사전에 호출해서 UI 에서 막아야한다(존재하면 여기서도 Error 를 띄우지만)
  만약에 존재한다면 CONTROL 탭으로 가서 직접 삭제하고 난 뒤에 RISK 를 지울 수 있게 해줘야할듯

  RISK 를 삭제한다
  연결된 CYCLE 의 riskPathArray 에서 삭제한다
  연결된 PROCESS 의 riskPathArray 에서 삭제한다
  연결된 CONTROL 의 riskPathArray 에서 삭제한다

  @param: riskId: String - "ELC-01-01-01-R02"
  */

  const controlIdList = await riskCheckBeforeDelete(riskId)
  if (controlIdList.length > 0) {
    // 연결된 CONTROL 이 존재. 삭제 불가
    return Promise.reject(new Error('삭제 불가. 연결된 CONTROL 이 존재합니다.', controlIdList))
  }

  const targetCollectionName = 'RISK'
  const targetDocId = riskId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const updateResult = await riskFindChangePathFromDelete(riskId)
  return await docRef
    .delete()
    .then(() => {
      cycleListUpdatePathArray(updateResult.cycleUpdateObject)
      processListUpdatePathArray(updateResult.processUpdateObject)
      controlListUpdatePathArray(updateResult.controlUpdateObject)
    })
    .then(() => backupSetFlag())
}
