import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { EvaluationDefect, OperationDefect } from '../../../base/data/EvaluationDefect'
import DefectCase1 from './assets/@3x_case_1(780_830).png'
import DefectCase2 from './assets/@3x_case_2(780_830).png'
import DefectCase3 from './assets/@3x_case_3(780_830).png'
import DefectCase4 from './assets/@3x_case_4(780_830).png'
import DefectCase5 from './assets/@3x_case_5(780_830).png'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2)
  },
  text: {
    wordBreak: 'keep-all'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  }
}))

const defectContents: string[] = [
  '미비점으로 인한 재무제표 왜곡표시 발생가능성이 낮지 않은 경우인가?',
  '잠재적인 왜곡표시의 규모가 재무제표에 미치는 영향이 중요한가?',
  '잠재적인 왜곡표시의 규모를 중요한 수준 미만으로 줄일 수 있는 보완통제가 있는가?',
  '회계와 내부회계관리제도에 충분한 전문지식을 갖춘 관리자는 이 미비점을 중요한 취약점이라고 결론 내릴것으로 판단되는가?',
  '미비점의 수준이 재무보고 관리감독기구(감사위원회 등)가 주목할 만큼 중요한가?'
]

const defectCaseImages: any[] = [DefectCase1, DefectCase2, DefectCase3, DefectCase4, DefectCase5]

const defectOrders: { Yes: number | string; No: number | string }[] = [
  { Yes: 1, No: 3 },
  { Yes: 2, No: 3 },
  { Yes: 3, No: 'MaterialWeakness' },
  { Yes: 'MaterialWeakness', No: 4 },
  { Yes: 'SignificantDeficiency', No: 'Deficiency' }
]

const koDefects: { [key: string]: string } = {
  MaterialWeakness: '중요한 취약점',
  SignificantDeficiency: '유의한 미비점',
  Deficiency: '단순한 미비점'
}

interface Props {
  onSaveEvaluationDefect: (evaluationDefect: EvaluationDefect) => Promise<void>
}

const DefectEvaluationProcessView: React.FC<Props> = ({ onSaveEvaluationDefect }) => {
  const classes = useStyles()

  const [answer, setAnswer] = React.useState('')
  const [reason, setReason] = React.useState('')
  const [level, setLevel] = React.useState(0)
  const [history, setHistory] = React.useState([
    {
      level: 0,
      answer: '',
      reason: ''
    }
  ])

  const onChangeLevel = React.useCallback(
    (next: number | string): void => {
      if (typeof next === 'number') {
        const newHistory = _.cloneDeep(history)
        const last = _.last(newHistory)
        if (last) {
          last.answer = answer
          last.reason = reason
        }
        newHistory.push({ level: next, answer: '', reason: '' })
        setHistory(newHistory)
        setLevel(next)
        setAnswer('')
        setReason('')
      } else {
        const newHistory = _.cloneDeep(history)
        const last = _.last(newHistory)
        if (last) {
          last.answer = answer
          last.reason = reason
        }

        const newEvaluationDefect: OperationDefect = {
          weakState: next === 'Deficiency' ? '3' : next === 'SignificantDeficiency' ? '4' : '5',
          weakStateData: {
            1: { Question: defectContents[0], Answer: '', Reason: '' },
            2: { Question: defectContents[1], Answer: '', Reason: '' },
            3: { Question: defectContents[2], Answer: '', Reason: '' },
            4: { Question: defectContents[3], Answer: '', Reason: '' },
            5: { Question: defectContents[4], Answer: '', Reason: '' }
          }
        }
        _.forEach(newHistory, (element) => {
          const levelStr = (element.level + 1).toString()
          if (
            levelStr === '1' ||
            levelStr === '2' ||
            levelStr === '3' ||
            levelStr === '4' ||
            levelStr === '5'
          ) {
            newEvaluationDefect.weakStateData[levelStr].Answer = element.answer
            newEvaluationDefect.weakStateData[levelStr].Reason = element.reason
          }
        })

        onSaveEvaluationDefect(newEvaluationDefect).then(() => {
          setHistory([{ level: 0, answer: '', reason: '' }])
          setLevel(0)
          setAnswer('')
          setReason('')
        })
      }
    },
    [onSaveEvaluationDefect, answer, reason, level, history]
  )

  const onBackLevel = React.useCallback((): void => {
    const droppedHistory = _.dropRight(_.cloneDeep(history))
    const lastLevel = _.last(droppedHistory)
    if (lastLevel && typeof lastLevel.level === 'number') {
      setLevel(lastLevel.level)
      setAnswer(lastLevel.answer)
      setReason(lastLevel.reason)
      setHistory(droppedHistory)
    }
  }, [history])

  const isLast = React.useCallback((): boolean => {
    if (answer !== 'Yes' && answer !== 'No') return false
    const nextLevel = _.get(defectOrders, `${level}.${answer}`)
    return typeof nextLevel === 'string'
  }, [answer, defectOrders])

  return (
    <div className={classes.root}>
      <img alt="미비점의 평가절차" src={defectCaseImages[level]} width={700} />
      <div className={classes.content}>
        <Typography.Title className={classes.text} level={4}>
          {defectContents[level]}
        </Typography.Title>
        <Radio.Group
          options={['Yes', 'No']}
          value={answer}
          onChange={(e) => {
            setAnswer(e.target.value)
          }}
        />
        <Typography.Title className={classes.text} level={5} style={{ marginTop: 12 }}>
          근거
        </Typography.Title>
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 5 }}
          value={reason}
          onChange={(e) => {
            setReason(e.target.value)
          }}
        />
        <div className={classes.buttonContainer}>
          <Button
            disabled={history.length <= 1}
            onClick={() => {
              onBackLevel()
            }}
          >
            이전
          </Button>
          <Button
            disabled={(answer !== 'Yes' && answer !== 'No') || _.isEmpty(reason)}
            type={isLast() ? 'primary' : 'default'}
            onClick={() => {
              onChangeLevel(_.get(defectOrders, `${level}.${answer}`))
              setAnswer('')
              setReason('')
            }}
          >
            {isLast() ? _.get(koDefects, _.get(defectOrders, `${level}.${answer}`)) : '다음'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DefectEvaluationProcessView
