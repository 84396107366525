import { blue } from '@ant-design/colors'

export const lightGrey = {
  backgroundOpacity: '#00000011',
  background: '#fafafa',
  border: '1px solid #f0f0f0',
  borderColor: '#f0f0f0'
}

export const grey = {
  background: '#f5f5f5',
  border: '1px solid lightgray',
  borderColor: 'lightgray'
}

export const lightBlue = {
  color: blue.primary,
  background: '#e6f7ff',
  border: '1px solid #64b5f6',
  borderColor: '#64b5f6'
}

export const lightGreen = {
  background: '#eefdec'
}

export function getEditableColor(): string {
  return 'rgb(255, 248, 240, 0.6)'
}
