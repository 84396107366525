import _ from 'lodash'
import moment from 'moment'

import { authService, dbService } from '../fbase'
import { adminCheckLevel } from './admin'
import { storageUploadFile, storageDeleteFile, storageDownloadFromFilePath } from './storage'

const fileBoxDBGetWithId = async (fileBoxDocId) => {
  // FILEBOX 의 docId 에 해당하는 정보를 반환
  const targetCollectionName = 'FILEBOX'
  const targetDocRef = dbService.collection(targetCollectionName).doc(fileBoxDocId)
  const targetDoc = await targetDocRef.get()

  return targetDoc.data()
}

const fileBoxDBGetAll = async () => {
  // 모든 FILEBOX 의 데이터를 반환
  const targetCollectionName = 'FILEBOX'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.data())
}

const fileBoxDBDelete = async (fileBoxDocId) => {
  // 해당 파일 정보 삭제
  const targetCollectionName = 'FILEBOX'
  const targetDocRef = dbService.collection(targetCollectionName).doc(fileBoxDocId)

  return await targetDocRef.delete()
}

const fileBoxDBUpdate = async (fileObject) => {
  // 업로드 및 덮어쓰기 시 파일에 대한 정보를 쓴다
  // @param: fileObject 이건 fileBoxFileInfo 의 return 값 그대로

  // 작성자
  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(new Error('파일 보관함 업데이트 - 로그인한 유저를 찾을 수 없습니다.'))
  }
  // 파일명
  const fileName = fileObject.name
  // 크기
  const fileSize = fileObject.size
  // 시각
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  // update Object
  const updateObject = {
    name: fileName,
    size: fileSize,
    author: currentUserId,
    updateTime: currentTime
  }

  // duplication
  const duplicationFlag = fileObject.duplication
  // doc id
  const targetCollectionName = 'FILEBOX'
  const targetCollectionRef = dbService.collection(targetCollectionName)
  const docId = fileObject.docId
  if (docId !== '' && duplicationFlag) {
    // 이미 존재하는 파일을 덮어씌우는 것
    const docRef = targetCollectionRef.doc(docId)
    return await docRef.update(updateObject)
  } else if (docId === '' && !duplicationFlag) {
    // 새로운 파일을 업로드하는 것
    return targetCollectionRef.add(updateObject).then((result) => {
      const newDocId = result.id
      if (_.isNil(newDocId)) {
        return Promise.reject(new Error('파일보관함 - 파일을 업로드하는데 실패했습니다.'))
      }
      return targetCollectionRef.doc(newDocId).update({
        docId: newDocId
      })
    })
  }
}

export const fileBoxParsingFileInfo = async (fileList) => {
  /*
  Drag&Drop 으로 File Input 에 들어온 파일들의 정보를 반환한다
  기존 Storage 에 동일한 이름의 파일이 있는지 체크한 정보까지 같이 반환한다

  @param: FileList(from 웹 브라우저의 Drag&Drop)
  */

  const fileBoxDataAll = await fileBoxDBGetAll()
  const fileBoxNameToIdMap = {}
  fileBoxDataAll.forEach((fileBoxData) => {
    const fileName = fileBoxData.name
    const docId = fileBoxData.docId
    fileBoxNameToIdMap[fileName] = docId
  })

  const result = _.map(fileList, (file) => {
    let duplicationFlag = false
    let docId = fileBoxNameToIdMap[file.name]
    if (docId !== undefined) {
      duplicationFlag = true
    } else {
      docId = ''
    }

    return {
      data: file,
      name: file.name,
      size: file.size,
      duplication: duplicationFlag,
      docId
    }
  })

  return result
}

export const fileBoxUpload = async (fileList) => {
  /*
  파일 리스트를 정해진 위치(Cloud Storage 의 FileBox 로 업로드한다)

  @param: fileBoxParsingFileInfo 의 결과 List
  */
  // 로그인 및 권한 확인
  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(new Error('파일 보관함 - 로그인한 유저를 찾을 수 없습니다.'))
  }

  const isAdmin = await adminCheckLevel(currentUserId)
  if (!isAdmin) {
    return Promise.reject(new Error('파일 보관함 - 관리자 권한이 아닙니다.'))
  }

  // 빈 리스트일 경우
  if (fileList.length < 1) {
    return Promise.reject(new Error('업로드 할 파일 목록이 없습니다.'))
  }

  const fileBasePath = 'FileBox/'

  const uploadResult = fileList.map(async (fileObject) => {
    const fileData = fileObject.data
    const fileName = fileObject.name
    const filePath = fileBasePath + fileName

    return await fileBoxDBUpdate(fileObject).then(() => storageUploadFile(filePath, fileData))
  })

  return Promise.all(uploadResult)
}

export const fileBoxList = async () => {
  /*
  파일 리스트 전체를 보여준다
  */

  // 로그인 및 권한 확인
  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(new Error('파일 보관함 - 로그인한 유저를 찾을 수 없습니다.'))
  }

  const isAdmin = await adminCheckLevel(currentUserId)
  if (!isAdmin) {
    return Promise.reject(new Error('파일 보관함 - 관리자 권한이 아닙니다.'))
  }

  const result = await fileBoxDBGetAll()
  return result
}

export const fileBoxDownload = async (fileBoxDocId) => {
  /*
  해당 파일을 다운로드

  해당 파일의 DocId 는 fileBoxList 의 결과에 들어있다.

  @param: String fileBoxDocId - 해당 파일의 DB 에 있는 Document Id
  */
  // 로그인 및 권한 확인
  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(new Error('파일 보관함 - 로그인한 유저를 찾을 수 없습니다.'))
  }

  const isAdmin = await adminCheckLevel(currentUserId)
  if (!isAdmin) {
    return Promise.reject(new Error('파일 보관함 - 관리자 권한이 아닙니다.'))
  }

  const fileData = await fileBoxDBGetWithId(fileBoxDocId)
  const fileName = fileData.name

  const fileBasePath = 'FileBox/'
  const filePath = fileBasePath + fileName

  return await storageDownloadFromFilePath(filePath, fileName)
}

export const fileBoxDelete = async (fileBoxDocId) => {
  /*
  해당 파일을 삭제

  해당 파일의 DocId 는 fileBoxList 의 결과에 들어있다.

  @param: String fileBoxDocId - 해당 파일의 DB 에 있는 Document Id
  */

  // 로그인 및 권한 확인
  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(new Error('파일 보관함 - 로그인한 유저를 찾을 수 없습니다.'))
  }

  const isAdmin = await adminCheckLevel(currentUserId)
  if (!isAdmin) {
    return Promise.reject(new Error('파일 보관함 - 관리자 권한이 아닙니다.'))
  }

  const fileData = await fileBoxDBGetWithId(fileBoxDocId)
  const fileName = fileData.name

  const fileBasePath = 'FileBox/'
  const filePath = fileBasePath + fileName

  return await fileBoxDBDelete(fileBoxDocId).then(() => storageDeleteFile(filePath))
}
