import { ColDef } from '@ag-grid-enterprise/all-modules'
import _ from 'lodash'

import { getEditableColor } from '../../../base/color'
import { Process } from '../../../base/data/PRC'
import { processUpdate, processDelete } from '../../../dataLoader/RCM/process'
import { deleteCellRenderer } from './PRCEditorUtils'

export function GetProcessColumnDefs(deleteItem: (item: any) => void): ColDef[] {
  return [
    { headerName: '대분류', field: 'cycleName', sortable: true },
    { headerName: '중분류', field: 'categoryName', sortable: true },
    { headerName: '소분류', field: 'subCategoryName', sortable: true },
    { headerName: '코드', field: 'id', sortable: true },
    {
      headerName: '설명',
      field: 'narrative',
      flex: 1,
      editable: true,
      cellStyle: { backgroundColor: getEditableColor() },
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: '1000',
        column: '800',
        rows: '10'
      }
    },
    {
      headerName: '삭제',
      field: 'action',
      cellRenderer: deleteCellRenderer,
      cellRendererParams: {
        deleteItem
      }
    }
  ]
}

export async function EditProcess(process: Process, editedItem: any): Promise<any> {
  await processUpdate(editedItem)
  return editedItem
}

export async function DeleteProcess(item: any): Promise<void> {
  return processDelete(item.id)
}
