import Button from '@material-ui/core/Button'
import React from 'react'
import { withRouter } from 'react-router-dom'

const LogoutButton: React.FC<any> = ({ logout, history }) => {
  const handleClick = (): void => {
    logout()
    history.push('/login')
  }
  return (
    <Button style={{ color: '#ffffff' }} onClick={handleClick}>
      Logout
    </Button>
  )
}

export default withRouter(LogoutButton)
