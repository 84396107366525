import Typography from '@material-ui/core/Typography'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import React from 'react'

import { PRCType } from '../../../../base/data/PRC'

interface Props {
  value: PRCType
  onChange: (event: PRCType) => void
}

const TypeSelector: React.FC<Props> = ({ value, onChange }) => {
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  }
  return (
    <div>
      <Typography noWrap variant="subtitle1">
        조회대상 선택
      </Typography>
      <Radio.Group
        value={value}
        onChange={(e: RadioChangeEvent) => {
          onChange(e.target.value)
        }}
      >
        <Radio style={radioStyle} value={PRCType.process}>
          Process
        </Radio>
        <Radio style={radioStyle} value={PRCType.risk}>
          Risk
        </Radio>
        <Radio style={radioStyle} value={PRCType.control}>
          Control
        </Radio>
      </Radio.Group>
    </div>
  )
}

export default TypeSelector
