/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable brace-style */
import _ from 'lodash'

import { controlListMappingWithAdmin, staffMappingWithDepartment } from '../RCM/mapping'
import { staffCreate, staffGetWithId, staffGetAll } from '../RCM/staff'
import firebase, { dbService } from '../fbase'
import { excelParsingForStaffRegister } from './excel'
import { mailAboutGuestUpgrade } from './mail'
import { storageDownloadBlobFromUrl, storageGetDownloadUrl, storageSaveFromBlob } from './storage'

export const guestGetAll = async () => {
  // 모든 GUEST 데이터를 가져온다.
  const targetCollectionName = 'GUEST'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.data())
}

export const guestGetWithId = async (guestId) => {
  // guestId 에 해당하는 항목만 가져온다.
  const targetCollectionName = 'GUEST'
  const docData = await dbService.collection(targetCollectionName).doc(guestId).get()

  return docData.data()
}

export const guestGetAllIdList = async () => {
  // 모든 GUEST 의 Id List 반환
  const targetCollectionName = 'GUEST'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.id)
}

const guestCreate = async (registerObj) => {
  /*
  새로운 유저가 회원가입을 하면 DB에 GUEST 로 만들어준다
  */

  return await dbService.collection('GUEST').doc(registerObj.id)
.set({
    id: registerObj.id,
    name: registerObj.name,
    department: registerObj.department,
    level: 'GUEST',
    uid: '',
    upgrade: false
  })
}

export const guestDelete = async (guestId) => {
  /*
  해당 아이디(이메일) 의 GUEST 유저를 삭제한다
  DB 의 GUEST Collection 에서 삭제되는거임
  실제 Firebase Auth 목록에서 삭제되는건 Cloud Function 으로 처리
  그러므로 즉각적으로는 안됨

  @param: guestId: String - 유저 아이디(이메일)
  */

  const targetCollectionName = 'GUEST'
  const targetDocId = guestId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef.delete()
}

const guestGetUid = async (guestId) => {
  // GUEST 의 uid 를 가져옴
  const targetCollectionName = 'GUEST'
  const docData = await dbService.collection(targetCollectionName).doc(guestId).get()

  return docData.data().uid
}

const guestDeleteUid = async (guestId) => {
  // GUEST 를 STAFF 로 넣을 때
  // GUEST 의 uid 를 삭제해서 cloud function에 의해 auth에서 완전 삭제되는걸 방지

  const targetCollectionName = 'GUEST'
  const targetDocId = guestId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef.update({
    uid: ''
  })
}

const guestUpdateForCF = async (guestId) => {
  // Cloud Function 을 위한 업데이트
  const targetCollectionName = 'GUEST'
  const targetDocId = guestId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef.update({
    upgrade: true
  })
}

export const guestUpgrade = async (guestObject) => {
  /*
  GUEST 를 NORMAL 로 업그레이드
  GUEST 에서 STAFF 로 합류
  최종적으로 STAFF 에 집어넣을때 넣을 정보들
  (GUEST 로 가입할 떄 쓴 정보를 그대로 써도 되고 아니면 수정해서 줘도 되고
    혹시 부서 같은거 기입 잘못 했을까봐)

  속하게 될 DEPARTMENT 의 staffPathArray 변경

  @param: Object{}
  {
    id: String - 아이디(이메일)
    name: String - 이름
    department: String - 부서
  }
  */

  const { id, name, department } = guestObject

  const uid = await guestGetUid(id)
  guestDeleteUid(id)

  guestObject.uid = uid

  await staffCreate(guestObject)
  await guestUpdateForCF(id)
  await mailAboutGuestUpgrade([id])
  return Promise.resolve()
}

export const adminGetOrCreateUser = async () => {
  const authService = firebase.auth()
  if (!authService?.currentUser) {
    return Promise.reject(new Error('Not login'))
  }

  const staffData = await staffGetWithId(authService.currentUser.email)
  if (!staffData) {
    let guestData = await guestGetWithId(authService.currentUser.email)
    if (!guestData) {
      await guestCreate({
        id: authService.currentUser.email,
        uid: authService.currentUser.uid,
        name: authService.currentUser?.displayName || '',
        department: ''
      })
      guestData = await guestGetWithId(authService.currentUser.email)
    }
    return guestData
  }
  return staffData
}

export const adminUpdateStaff = async (staffObject) => {
  /*
  유저의 정보들을 수정한다.
  이름, 부서, 레벨

  @param: Object{}
  {
    id: String - 유저아이디(이메일), NORMAL 인 유저
    name: String - 이름
    department: String - 부서
    level: String - 바꾸고 싶은 최종 레벨 (NORMAL, BLOCKED, ADMIN)
  }
  */

  const userId = staffObject.id
  const newName = staffObject.name
  const newLevel = staffObject.level
  const newDepartmentId = staffObject.department

  // 레벨의 String 에 문제가 없는지 체크
  if (
    newLevel !== 'UPLOADER' &&
    newLevel !== 'NORMAL' &&
    newLevel !== 'BLOCKED' &&
    newLevel !== 'WATCHER' &&
    newLevel !== 'ADMIN'
  ) {
    const errorMsg = 'Error. level 에 적합한 문자열이 아닙니다.'
    return Promise.reject(new Error(errorMsg))
  }

  const staffData = await staffGetWithId(userId)
  const staffPath = 'STAFF/' + userId
  const prevName = staffData.name
  const prevLevel = staffData.level
  const prevDepartmentId = staffData.departmentName

  const targetCollectionName = 'STAFF'
  const targetDocId = userId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  // 이전과 달라진 것만 변경하고 싶은 것이다. 체크
  // 이름을 변경
  if (newName !== prevName) {
    return await docRef.update({
      name: newName
    })
  }
  // 권한을 변경
  else if (newLevel !== prevLevel) {
    // 변하고 싶은 레벨에 따른 각각의 경우가 다 다름

    if (newLevel === 'BLOCKED') {
      if (prevLevel === 'ADMIN') {
        // 또 다른 ADMIN 한테 CONTROL 다 넘기기 - 이 경우 해당 ADMIN 아이디를 반환
        const staffData = await staffGetAll()
        const adminData = _.filter(staffData, (staffObject) => {
          const level = staffObject.level
          const id = staffObject.id
          return userId !== id && level === 'ADMIN'
        })
        if (adminData.length < 1) {
          // 만약 또 다른 ADMIN 인 애가 없으면  - Error
          const errorMsg = 'Error. CONTROL 을 이관할 또 다른 ADMIN 이 존재하지 않습니다.'
          return Promise.reject(new Error(errorMsg))
        }

        const adminId = _.head(adminData).id

        docRef.update({
          level: newLevel
        })
        controlListMappingWithAdmin(userId, adminId)

        return await Promise.resolve({
          adminId
        })
      } else if (prevLevel === 'NORMAL' || prevLevel === 'UPLOADER' || prevLevel === 'WATCHER') {
        // 어떤 ADMIN 한테 CONTROL 다 넘기기 - 이 경우 해당 ADMIN 아이디를 반환
        const staffData = await staffGetAll()
        const adminData = _.filter(staffData, { level: 'ADMIN' })
        if (adminData.length < 1) {
          // 만약 ADMIN 인 애가 없으면  - Error
          const errorMsg = 'Error. CONTROL 을 이관할 ADMIN 이 존재하지 않습니다.'
          return Promise.reject(new Error(errorMsg))
        }
        const adminId = _.head(adminData).id

        docRef.update({
          level: newLevel
        })
        controlListMappingWithAdmin(userId, adminId)

        return await Promise.resolve({
          adminId
        })
      }
    } else {
      return await docRef.update({
        level: newLevel
      })
    }
  }
  // 부서를 변경
  else if (newDepartmentId !== prevDepartmentId) {
    // 부서를 변경하면 연결된 다양한 내용들이 바뀌어야한다
    // 해당 유저가 들고 있는 컨트롤들을 이전부서의 controlPathArray에서 삭제
    // 해당 유저가 들고 있는 컨트롤들을 새로운 부서의 controlPathArray 에 추가
    // 각 컨트롤에서 controlDepartment, departmentPathArray 변경
    // 설계평가 보고서 업데이트

    return await staffMappingWithDepartment(userId, newDepartmentId).then(() => {
      docRef.update({
        departmentName: newDepartmentId,
        departmentPathArray: ['DEPARTMENT/' + newDepartmentId]
      })
    })
  }
}

export const adminDownloadStaffRegisterTemplateExcel = async () => {
  const fileName = '직원 업로드 Template.xlsx'
  const fullFilePath = 'Template/직원 업로드 Template.xlsx'

  const downloadUrl = await storageGetDownloadUrl(fullFilePath)
  const blobData = await storageDownloadBlobFromUrl(downloadUrl)
  storageSaveFromBlob(blobData, fileName)
}

export const adminRegisterStaffAsGuestFromExcel = async (fileObject) => {
  /*
  Admin 페이지에서 다수의 STAFF 정보를 Excel 파일로부터 입력 받아서 GEUST 로 등록한다.

  GUEST 로 등록된 정보는 CloudFunction(registerNewUserToFirebase) 에 의해서  Firebase Auth 에 자동으로 등록

  @param: 입력받은 엑셀파일의 오브젝트를 넘겨주면 된다
  */

  // 파싱
  const excelParsingResult = await excelParsingForStaffRegister(fileObject)

  // 결과에 에러가 있는지부터 파악
  const errorList = []
  excelParsingResult.forEach((staffObject) => {
    if (staffObject.error) {
      errorList.push(staffObject)
    }
  })

  if (errorList.length > 0) {
    // 에러가 있는것이 존재
    // 이경우에 어떻게 할것인가
    // 일단은 에러가 있는경우 등록을 모두 안하고 에러를 띄우는 것으로
    return Promise.reject(new Error(errorList[0].errorMsg))
  }

  // 에러가 없다면 등록
  // 이제는 바뀌어서 실제 Firebase Auth에 등록하지 않고 GUEST 에 값을 집어넣는 것만 여기서 처리

  const result = excelParsingResult.map(async (staffObject) => {
    const temp = {
      id: staffObject.Email,
      name: staffObject.Name,
      department: staffObject.Department
    }
    return await guestCreate(temp)
  })

  return Promise.all(result)
}

export const adminCheckLevel = async (staffId) => {
  /*
  해당 아이디의 직원의 권한(level)이 "ADMIN" 인지 체크

  @param String staffId - 직원의 아이디
  */

  let result = false

  const staffData = await staffGetWithId(staffId)
  const level = staffData.level

  if (level === 'ADMIN') {
    result = true
  }

  return result
}
