import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { ArrowRight } from '@material-ui/icons'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import useResizeAware from 'react-resize-aware'

import { grey } from '../../base/color'
import { getStaffNameAndDepartment, Staff } from '../../base/data/Staff'
import { parseTimeStr } from '../../base/utils/TimeUtils'
import { adminGetOrCreateUser } from '../../dataLoader/Utils/admin'
import { Notice, noticeCreate, noticeDelete, noticeGetAll } from '../../dataLoader/Utils/notice'
import AlertMessage, { AlertMessageHandler } from '../common/AlertMessage'
import CircleBackdrop from '../common/CircleBackdrop'

const useStyles = makeStyles((theme: Theme) => ({
  notice: {
    flex: 2.5,
    padding: theme.spacing(1),
    border: grey.border,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1)
  },
  headerText: {
    padding: theme.spacing(1)
  },
  list: {
    border: grey.border,
    overflow: 'auto',
    flex: 1
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    margin: theme.spacing(1, 2)
  },
  button: {
    padding: theme.spacing(1, 2),
    width: '100%'
  },
  contents: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  mainText: {
    flex: 1
  },
  listSubItem: {
    minWidth: theme.spacing(22)
  },
  dateText: {
    padding: theme.spacing(0, 1),
    fontSize: '0.8rem',
    fontWeight: 400
  },
  writerText: {
    padding: theme.spacing(0, 1),
    fontSize: '0.8rem',
    fontWeight: 400
  }
}))

interface Props {
  staffs: Staff[]
}

const NoticeView: React.FC<Props> = ({ staffs }) => {
  const classes = useStyles()
  const [notices, setNotices] = React.useState<Notice[]>([])
  const [userLevel, setUserLevel] = React.useState('NORMAL')
  const [states, setStates] = React.useState<{
    loading: boolean
    creationVisible: boolean
    visible: boolean
    notice?: Notice
  }>({
    loading: false,
    creationVisible: false,
    visible: false,
    notice: undefined
  })
  const [form] = Form.useForm()
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const [resizeListener, sizes] = useResizeAware()

  React.useEffect(() => {
    adminGetOrCreateUser().then((user) => {
      setUserLevel(_.get(user, 'level'))
    })

    noticeGetAll()
      .then((result) => {
        setNotices(_.reverse(_.sortBy(result, 'createTime')))
      })
      .catch((e) => {
        console.log('Failed to load notices ', e)
      })
  }, [])

  const onNoticeCreationClick = React.useCallback(() => {
    setStates({
      ...states,
      loading: true
    })
    const data = form.getFieldsValue()
    noticeCreate(data.title, data.content)
      .then(noticeGetAll)
      .then((result) => {
        setNotices(_.reverse(_.sortBy(result, 'createTime')))
        setStates({
          ...states,
          loading: false,
          creationVisible: false
        })
        alertRef.current?.showAlert('success', '작성 완료')
      })
      .catch((e) => {
        // TODO(sangmuk): 생성은 성공하고 리스트 가져오는 것에서 실패할 경우
        console.log('Failed to create notice', e)
        alertRef.current?.showAlert('error', '작성 실패')
        setStates({
          ...states,
          loading: false
        })
      })
  }, [form, states])

  const onNoticeDeletionClick = React.useCallback(() => {
    if (!states.notice?.id) {
      return
    }

    setStates({
      ...states,
      loading: true
    })

    noticeDelete(states.notice.id)
      .then(noticeGetAll)
      .then((result) => {
        setNotices(_.sortBy(result, ['createTime']))
        setStates({
          ...states,
          loading: false,
          visible: false
        })
        alertRef.current?.showAlert('success', '삭제 완료')
      })
      .catch((e) => {
        // TODO(sangmuk): 삭제는 성공하고 리스트 가져오는 것에서 실패할 경우
        console.log('Failed to delete notice', e)
        alertRef.current?.showAlert('error', 'ㅓ삭제 실패')
        setStates({
          ...states,
          loading: false
        })
      })
  }, [states])

  return (
    <div className={classes.notice}>
      {resizeListener}
      <div className={classes.header}>
        <Typography.Title className={classes.headerText} level={4}>
          공지사항
        </Typography.Title>
        <Button
          style={{ visibility: userLevel === 'ADMIN' ? 'visible' : 'hidden' }}
          onClick={() => {
            setStates({ ...states, creationVisible: true })
          }}
        >
          공지사항 작성
        </Button>
      </div>
      <List className={classes.list}>
        {_.map(notices, (notice) => (
          <>
            <ListItem
              button
              style={{ display: !notice.isVisible ? 'none' : undefined }}
              onClick={() => {
                setStates({
                  ...states,
                  visible: true,
                  notice
                })
              }}
            >
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <div className={classes.contents}>
                <Typography.Text className={classes.mainText}>{notice.title}</Typography.Text>
                <Divider orientation="vertical" flexItem />
                <Typography.Text className={clsx(classes.listSubItem, classes.writerText)}>
                  {getStaffNameAndDepartment(staffs, notice.writer)}
                </Typography.Text>
                <Divider orientation="vertical" flexItem />
                <Typography.Text className={clsx(classes.listSubItem, classes.dateText)}>
                  {parseTimeStr(notice.createTime)}
                </Typography.Text>
              </div>
            </ListItem>
            <Divider style={{ display: !notice.isVisible ? 'none' : undefined }} />
          </>
        ))}
      </List>
      <Modal
        width={sizes.width ? sizes.width * 0.8 : 1000}
        footer={[
          <Button
            type="primary"
            style={{ visibility: userLevel === 'ADMIN' ? 'visible' : 'hidden' }}
            onClick={onNoticeCreationClick}
          >
            생성
          </Button>,
          <Button key="back" onClick={() => setStates({ ...states, creationVisible: false })}>
            닫기
          </Button>
        ]}
        onCancel={() => setStates({ ...states, creationVisible: false })}
        visible={states.creationVisible}
        title={<Typography.Title level={4}>공지사항 작성</Typography.Title>}
      >
        <Form form={form}>
          <Form.Item
            label="제목"
            name="title"
            rules={[{ required: true, message: '제목을 입력해주세요.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="내용"
            name="content"
            rules={[{ required: true, message: '내용을 입력해주세요.' }]}
          >
            <Input.TextArea rows={20} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={sizes.width ? sizes.width * 0.8 : 1000}
        footer={[
          <Button
            type="primary"
            style={{ visibility: userLevel === 'ADMIN' ? 'visible' : 'hidden' }}
            danger
            onClick={onNoticeDeletionClick}
          >
            삭제
          </Button>,
          <Button key="back" onClick={() => setStates({ ...states, visible: false })}>
            닫기
          </Button>
        ]}
        title={
          <div>
            <Typography.Title level={4}>{states.notice?.title}</Typography.Title>
            {states.notice && (
              <div className={classes.contents}>
                <Typography.Text className={classes.writerText}>
                  작성자: {getStaffNameAndDepartment(staffs, states.notice.writer)}
                </Typography.Text>
                <Divider orientation="vertical" flexItem />
                <Typography.Text className={classes.dateText}>
                  날짜: {parseTimeStr(states.notice.createTime)}
                </Typography.Text>
              </div>
            )}
          </div>
        }
        onCancel={() => setStates({ ...states, visible: false })}
        visible={states.visible}
      >
        <Typography.Paragraph style={{ whiteSpace: 'pre' }}>
          {states.notice?.content}
        </Typography.Paragraph>
      </Modal>
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={states.loading} />
    </div>
  )
}

export default NoticeView
