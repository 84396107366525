import { makeStyles, Theme } from '@material-ui/core'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Space from 'antd/lib/space'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import { grey } from '../../../base/color'
import { EvaluationType, getKoEvaluation, StateType } from '../../../base/data/Evaluation'
import { Staff } from '../../../base/data/Staff'
import { evaluationDelete, evaluationUpdateState } from '../../../dataLoader/Evaluation/evaluation'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import DividerSelect, { BASE_ITEM } from '../../common/DividerSelect'
import { LoadStaff } from '../../rcm/editor/RCMEditorUtils'
import {
  EvaluationSelectAttribute,
  getEvaluationNamesWithDate,
  getShortEvalType,
  isAlreadyClose,
  loadEvaluationDetails,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import EvaluationStateView from '../view/EvaluationStateView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  warningText: {
    marginRight: theme.spacing(2)
  },
  deleteButton: {
    marginBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    margin: theme.spacing(1),
    backgroundColor: 'white',
    background: '#ffffff',
    verticalAlign: 'middle',
    justifyContent: 'center',
    padding: theme.spacing(2),
    flexGrow: 1,
    border: grey.border
  }
}))

interface Props {
  type: EvaluationType
}

const EvaluationManagementView: React.FC<Props> = ({ type }) => {
  const classes = useStyles()
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: BASE_ITEM,
    names: [],
    summaries: [],
    evaluationDetails: []
  })
  const [deletingEvaluation, setDeletingEvaluation] = React.useState({
    visible: false,
    loading: false,
    name: ''
  })
  const [staffs, setStaffs] = React.useState([] as Staff[])
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const [cookies, setCookie, removeCookie] = useCookies([`${type}EvaluationName`])

  React.useEffect(() => {
    LoadStaff().then(({ data }) => {
      setStaffs(data)
    })
  }, [])

  React.useEffect(() => {
    loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
  }, [type])

  const deletingEvaluationCancel = React.useCallback((): void => {
    setDeletingEvaluation({
      ...deletingEvaluation,
      visible: false,
      name: ''
    })
  }, [deletingEvaluation])

  const deletingEvaluationOk = React.useCallback((): void => {
    setDeletingEvaluation({
      ...deletingEvaluation,
      loading: true
    })

    evaluationDelete(getShortEvalType(type), deletingEvaluation.name)
      .then(() => {
        removeCookie(`${type}EvaluationName`)
        loadEvaluationNames(type, undefined, select, setSelect)
      })
      .catch((e: any) => {
        // eslint-disable-next-line no-console
        console.log('Failed to delete operation eval', e)
        alertRef.current?.showAlert('error', '삭제 실패')
      })
      .finally(() => {
        setDeletingEvaluation({
          ...deletingEvaluation,
          loading: false,
          visible: false,
          name: ''
        })
      })
  }, [deletingEvaluation, select, type])

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setCookie(`${type}EvaluationName`, evaluationName)
    },
    [type, select]
  )

  const updateEvaluationState = React.useCallback(
    (stateType: StateType | 'request', controlIds: string[]): Promise<void> => {
      if (stateType === 'request') {
        // TODO(sangmuk): Send email to staff.
        return Promise.resolve(undefined)
      } else if (stateType === 'unperformed' || stateType === 'performing') {
        // Skip.
        return Promise.resolve(undefined)
      }

      const state = {
        performed: '3',
        rejected: '4',
        approved: '5'
      }

      if (
        stateType === 'performed' &&
        _(select.evaluationDetails)
          .filter((row) => _.includes(controlIds, row.controlId))
          .some((row) => row.data.state.resultState === '1')
      ) {
        alertRef.current?.showAlert('error', '평가 결론이 없는 보고서가 있습니다.')
        return Promise.resolve(undefined)
      }

      return evaluationUpdateState(
        getShortEvalType(type),
        select.selectedName,
        controlIds,
        state[stateType]
      )
        .then(() => {
          loadEvaluationDetails(type, select.selectedName, select, setSelect)
        })
        .catch((e: any) => {
          // eslint-disable-next-line no-console
          console.log('Failed to update state', e)
        })
    },
    [select]
  )

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.select}>
          <DividerSelect
            defaultName={`${getKoEvaluation(type)} 선택`}
            defaultValue={BASE_ITEM}
            loading={select.loading}
            names={getEvaluationNamesWithDate(select.summaries)}
            value={select.selectedName}
            values={select.names}
            onChange={(value) => onEvaluationSelect(value as string)}
          />
          <div>
            <Typography.Text
              className={classes.warningText}
              style={{
                visibility: isAlreadyClose(select.evaluationDetails) ? 'visible' : 'hidden'
              }}
              strong
              type="danger"
            >
              마감된 {getKoEvaluation(type)}입니다.
            </Typography.Text>
            <Button
              danger
              className={classes.deleteButton}
              disabled={
                _.isEqual(BASE_ITEM, select.selectedName) ||
                isAlreadyClose(select.evaluationDetails)
              }
              type="primary"
              onClick={() => setDeletingEvaluation({ ...deletingEvaluation, visible: true })}
            >
              {getKoEvaluation(type)} 삭제
            </Button>
          </div>
          <Modal
            centered
            footer={[
              <Button key="back" onClick={deletingEvaluationCancel}>
                취소
              </Button>,
              <Button
                danger
                disabled={!_.isEqual(deletingEvaluation.name, select.selectedName)}
                key="submit"
                loading={deletingEvaluation.loading}
                type="primary"
                onClick={deletingEvaluationOk}
              >
                삭제
              </Button>
            ]}
            title={`${getKoEvaluation(type)} 삭제`}
            visible={deletingEvaluation.visible}
            onCancel={deletingEvaluationCancel}
            onOk={deletingEvaluationOk}
          >
            <Space direction="vertical">
              <Typography.Text>
                선택된 {getKoEvaluation(type)}의 모든 자료가 삭제됩니다. (보고서
                {type === 'operation' && '/모집단/증빙'}/기타 파일 삭제)
              </Typography.Text>
              <Typography.Text>
                삭제를 원하실 경우 아래에 {getKoEvaluation(type)} 이름을 입력해주세요.
              </Typography.Text>
              <Input
                value={deletingEvaluation.name}
                onChange={(event) => {
                  setDeletingEvaluation({
                    ...deletingEvaluation,
                    name: event.target.value as string
                  })
                }}
              />
            </Space>
          </Modal>
        </div>
        <EvaluationStateView
          evaluationDetails={select.evaluationDetails || []}
          evaluationName={select.selectedName}
          loading={select.loading}
          staffs={staffs}
          type={type}
          updateEvaluationState={updateEvaluationState}
        />
      </div>
      <AlertMessage ref={alertRef} />
    </div>
  )
}

export default EvaluationManagementView
