/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
import _ from 'lodash'
import moment from 'moment'

import { adminCheckLevel } from '../Utils/admin'
import { excelCreateRCM } from '../Utils/excel'
import { dbService, authService } from '../fbase'
import { controlGetAll } from './control'
import { cycleGetAll } from './cycle'
import { processGetAll } from './process'
import { riskGetAll } from './risk'

const createRCM_Data = async () => {
  // 현재의 RCM 데이터 전체를 가져온다(예전에 backup.js 에 있던 것)

  const rcmGetDocumentObjectFromCollection = async (collectionName) => {
    // Do Not Use!!
    const collectionRef = await dbService.collection(collectionName).get()
    const documentObjectList = collectionRef.docs.map((doc) => {
      const obj = {}
      obj[doc.id] = doc.data()
      return obj
    })

    return documentObjectList
  }
  const rcmGetDocumentObjectFromCollectionList = async (collectionList) => {
    // Do Not Use!!
    const result = collectionList.map(async (collection) => {
      const documentObjectList = await rcmGetDocumentObjectFromCollection(collection)

      const obj = {}
      obj[collection] = documentObjectList
      return obj
    })

    return Promise.all(result)
  }

  const targetCollectionList = [
    'CYCLE',
    'PROCESS',
    'RISK',
    'CONTROL',
    'STAFF',
    'DEPARTMENT',
    'NOTICE'
  ]

  const backupDataObject = {}
  const documentObjectFromAllCollection = await rcmGetDocumentObjectFromCollectionList(
    targetCollectionList
  )
  documentObjectFromAllCollection.forEach((collectionObj) => {
    const collectionName = Object.keys(collectionObj)[0]
    backupDataObject[collectionName] = {}

    const targetCollectionObjectList = collectionObj[collectionName]
    targetCollectionObjectList.forEach((documentObject) => {
      const documentId = Object.keys(documentObject)[0]
      const documentData = documentObject[documentId]
      backupDataObject[collectionName][documentId] = documentData
    })
  })

  return backupDataObject
}

const insertRCM_Data = async (rcmVersionId, rcmData) => {
  const rcmInsertEachCollection = async (rcmVersionId, collectionName, collectionData) => {
    // Do Not Use!!
    const baseDocRef = dbService.collection('RCM').doc(rcmVersionId)
    const result = Object.keys(collectionData).map(async (documentId) => {
      const docRef = baseDocRef.collection(collectionName).doc(documentId)
      return docRef.set(collectionData[documentId])
    })
  }

  const result = Object.keys(rcmData).map(async (collectionName) => {
    const eachCollectionResult = await rcmInsertEachCollection(
      rcmVersionId,
      collectionName,
      rcmData[collectionName]
    )
    return eachCollectionResult
  })

  return Promise.all(result)
}

export const getRCM_VersionList = async () => {
  /*
  버전 목록 전체를 가져온다.
  버전은 다음과 같은 형태 20210826-01
  */

  const targetCollectionName = 'RCM'
  const targetCollectionRef = dbService.collection(targetCollectionName)
  const targetCollectionData = await targetCollectionRef.get()

  return targetCollectionData.docs.map((docData) => docData.id)
}

const createRCM_newVersionId = async () => {
  /*
  새로 만들 RCM 의 버전명을 만든다
  */

  const versionIdList = await getRCM_VersionList()
  const lastVersionId = _.last(versionIdList)

  const versionIdSplit = _.split(lastVersionId, '-')
  const versionIdDate = versionIdSplit[0]
  const versionIdNumberString = versionIdSplit[1]
  const versionIdNumberInt = Number(versionIdNumberString)
  const todayDate = moment().format('YYYYMMDD')

  let newVersionIdDate = ''
  let newVersionIdNumberString = ''
  let newVersionIdNumberInt = 0
  if (todayDate !== versionIdDate) {
    newVersionIdDate = todayDate
    newVersionIdNumberString = '01'
  } else {
    newVersionIdDate = versionIdDate
    newVersionIdNumberInt = versionIdNumberInt + 1
    newVersionIdNumberString =
      newVersionIdNumberInt < 10
        ? '0' + String(newVersionIdNumberInt)
        : String(newVersionIdNumberInt)
  }

  const newVersionId = _.join([newVersionIdDate, newVersionIdNumberString], '-')

  return newVersionId
}

export const createRCM = async () => {
  /*
  새로 만들 버전명을 정해야 한다

  RCM 내용 전체 가져오기

  DB 에 insert
    Data
    updateTime
    updater
  */

  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(new Error('RCM 버전 생성 - 로그인한 유저를 찾을 수 없습니다.'))
  }
  const isAdmin = await adminCheckLevel(currentUserId)
  if (!isAdmin) {
    return Promise.reject(new Error('RCM 버전 생성 - 관리자 권한이 아닙니다.'))
  }

  // 새로운 버전 아이디
  const newVersionId = await createRCM_newVersionId()
  console.log(newVersionId)

  const rcmData = await createRCM_Data()

  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  return await insertRCM_Data(newVersionId, rcmData).then(() => {
    dbService.collection('RCM').doc(newVersionId).set({
      updateTime: currentTime,
      updater: currentUserId
    })
  })
}

const getRCM_Data = async (versionId) => {
  // 해당 Id 의 RCM Data 를 가져온다.

  const rcmGetDocumentObjectFromCollection = async (versionId, collectionName) => {
    // Collection 에 속한 Document 의 ID 리스트
    const collectionRef = await dbService
      .collection('RCM')
      .doc(versionId)
      .collection(collectionName)
      .get()
    const documentObjectList = collectionRef.docs.map((doc) => {
      const obj = {}
      obj[doc.id] = doc.data()
      return obj
    })

    return documentObjectList
  }
  const rcmGetDocumentObjectFromCollectionList = async (versionId, collectionList) => {
    const result = collectionList.map(async (collection) => {
      const documentObjectList = await rcmGetDocumentObjectFromCollection(versionId, collection)

      const obj = {}
      obj[collection] = documentObjectList
      return obj
    })

    return Promise.all(result)
  }

  // Excel 을 만들 때 필요한 Collection 들만
  const collectionList = ['CYCLE', 'PROCESS', 'RISK', 'CONTROL']

  const rcmDataObject = {}
  const documentObjectForBackup = await rcmGetDocumentObjectFromCollectionList(
    versionId,
    collectionList
  )
  documentObjectForBackup.forEach((collectionObj) => {
    const collectionName = Object.keys(collectionObj)[0]
    rcmDataObject[collectionName] = {}

    const targetCollectionObjectList = collectionObj[collectionName]
    targetCollectionObjectList.forEach((documentObject) => {
      const documentId = Object.keys(documentObject)[0]
      const documentData = documentObject[documentId]
      rcmDataObject[collectionName][documentId] = documentData
    })
  })

  return rcmDataObject
}

export const downloadRCM_Excel = async (versionId) => {
  /*
  선택한 Id의 RCM 을 엑셀파일로 다운로드한다

  versionId === "NOW" 라면 현재의 데이터로 합시다

  @param String versionId - 해당 RCM 데이터의 버전이름 (ex: 20210826-01)
  */

  let cycleDataAll
  let processDataAll
  let riskDataAll
  let controlDataAll

  if (versionId === 'NOW') {
    cycleDataAll = await cycleGetAll()
    processDataAll = await processGetAll()
    riskDataAll = await riskGetAll()
    controlDataAll = await controlGetAll()
  } else {
    const { CYCLE, PROCESS, RISK, CONTROL } = await getRCM_Data(versionId)
    cycleDataAll = Object.values(CYCLE)
    processDataAll = Object.values(PROCESS)
    riskDataAll = Object.values(RISK)
    controlDataAll = Object.values(CONTROL)
  }

  // 원하는 엑셀의 Row 모양대로 만들기
  // PROCESS.xlsx(Collection: CYCLE + PROCESS)
  const processExcelResult = []
  processDataAll.forEach((processData) => {
    // 아 이번 프로젝트는 그냥 이렇게 쓸게요
    const { number, code, name, narrative, cyclePathArray } = processData
    const cycleId = _.head(cyclePathArray).split('/')[1]
    const cycleData = _.find(cycleDataAll, { id: cycleId })
    const {
      cycleNumber,
      cycleName,
      categoryNumber,
      categoryName,
      subCategoryNumber,
      subCategoryName
    } = cycleData

    const result = {
      '대분류 번호': cycleNumber,
      '대분류 이름': cycleName,
      '중분류 번호': categoryNumber,
      '중분류 이름': categoryName,
      '소분류 번호': subCategoryNumber,
      '소분류 이름': subCategoryName,
      '프로세스 번호': number,
      '프로세스 코드': code,
      '프로세스 이름': name,
      '프로세스 설명': narrative
    }
    processExcelResult.push(result)
  })

  // RCM.xlsx(Collection: CONTROL)
  const orderingMap = {
    processCycleName: '대분류 이름',
    processCycleNumber: '대분류 번호',
    processCategoryName: '중분류 이름',
    processCategoryNumber: '중분류 번호',
    processSubCategoryName: '소분류 이름',
    processSubCategoryNumber: '소분류 번호',
    processMappingCode: '프로세스 코드',

    riskNumber: '위험 코드',
    riskName: '위험명',
    RoMM: '재무제표 왜곡표시 위험',
    inherentRiskLevel: '고유위험',

    number: '통제번호',
    name: '통제활동명',
    narrative: '통제활동 설명',
    policy: '관련규정집',
    ITDependencySystem: 'Related System',
    MRC_YN: 'MRC 해당 여부',
    MRC_Number: 'MRC 번호',
    MRC_IPE_Name: 'MRC 이름',
    IPE_YN: 'IPE 통제 해당 여부',
    IPE_Number: 'IPE 번호',
    IPE_Name: 'IPE 이름',
    department: '담당부서',
    owner: '통제 담당 현업',
    teamLeader: '통제책임자',
    teamMember: '통제담당자',
    keyControl: 'Key Control',
    goal: '통제목적',
    goal_Operation: '통제목적(운영의 효율/효과)',
    goal_Trust: '통제목적(재무보고의 신뢰성)',
    goal_Asset: '통제목적(자산의 보호)',
    goal_Override: '통제목적(부정방지)',
    goal_Law: '통제목적(법규 및 정책준수)',
    accountCode: '계정코드',
    accountName: '계정과목명',
    reportFootnotes: '관련주석',
    assertion_EO: '경영진의주장(실재성/발생사실)',
    assertion_C: '경영진의주장(완전성)',
    assertion_RO: '경영진의주장(권리와 의무)',
    assertion_PD: '경영진의주장(표시와 공시)',
    assertion_Occur: '경영진의주장(발생사실)',
    assertion_AssessMent: '경영진의주장(평가)',
    assertion_Measurement: '경영진의주장(측정)',
    period: '통제주기',
    preventDetective: '예방/적발 통제',
    autoManual: '수기/자동 통제',
    residualRiskLevel: '통제의 잔여위험',
    controlRiskLevel: '통제 위험',
    TOC_Procedure: '테스트 절차',
    TOC_Evidence: '테스트 증빙',
    TOC_Population: '모집단 정의',
    TOC_PopulationCount: '모집단 샘플수',
    TOC_TestProperties: '평가대상 항목',
    TOC_Exception: '예외사항',
    TOC_TestMethod_InquiryInspection: '질문&관찰',
    TOC_TestMethod_Observation: '문서',
    TOC_TestMethod_Reperformance: '재수행'
  }
  const rcmExcelResult = []
  controlDataAll.forEach((controlData) => {
    const excludeKeyList = [
      'cyclePathArray',
      'departmentPathArray',
      'id',
      'processPathArray',
      'riskInherentRiskLevel',
      'riskPathArray',
      'selfPath',
      'staffPathArray',
      'uid'
    ]
    const result = _.omit(controlData, excludeKeyList)
    result.processMappingCode = _.head(result.processMappingCode)
    const riskId = result.riskNumber
    const riskData = _.find(riskDataAll, { id: riskId })
    result.inherentRiskLevel = riskData.inherentRiskLevel
    result.RoMM = riskData.RoMM

    // key 이름과 순서를 알맞게 수정해준다
    const convertedResult = {}
    Object.keys(orderingMap).forEach((orderMapKey) => {
      const newKey = orderingMap[orderMapKey]
      const newValue = result[orderMapKey]
      convertedResult[newKey] = newValue
    })
    rcmExcelResult.push(convertedResult)
  })

  // 엑셀파일 만들기(자동으로 다운로드됨)
  return await excelCreateRCM('PROCESS.xlsx', versionId, processExcelResult).then(() =>
    excelCreateRCM('RCM.xlsx', versionId, rcmExcelResult)
  )
}
