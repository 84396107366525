import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import _ from 'lodash'

export async function writeExcel(
  filename: string,
  data: Record<string, (string | number)[][]>
): Promise<void> {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'CCKSolution'
  _.forEach(data, (sheet, name) => {
    const worksheet = workbook.addWorksheet(name)
    _.forEach(sheet, (row) => {
      worksheet.addRow(row)
    })

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      })
    })
    worksheet.getColumn(1).width = 20
    worksheet.getColumn(2).width = 30
  })

  const buffer = await workbook.xlsx.writeBuffer()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  // eslint-disable-next-line no-undef
  const blob = new Blob([buffer], { type: fileType })

  saveAs(blob, `${filename}.xlsx`)
}
