import _ from 'lodash'

import { evaluationUpdateTemplate } from '../Evaluation/evaluation'
import { backupSetFlag } from '../Utils/backup'
import { dbService } from '../fbase'
import { controlGetWithId } from './control'

export const cycleInsertDataListForInit = async (dataObjectList) => {
  /*
  Init 을 위해서 Excel 에서 넘어온 Cycle Data List 및 추가 정보들을 Firestore 에 집어넣는다

  @param: dataObjectList: Object[]: 데이터 리스트
  */
  const cycleInsertDataForInit = async (dataObject) => {
    // 단일 Data 를 집어넣는다.
    const cycleId = [
      dataObject.cycleNumber,
      dataObject.categoryNumber,
      dataObject.subCategoryNumber
    ].join('-')

    const targetCollectionName = 'CYCLE'
    const targetDocId = cycleId
    const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

    return await docRef.set({
      id: cycleId,
      uid: cycleId,
      cycleNumber: dataObject.cycleNumber,
      cycleName: dataObject.cycleName,
      categoryNumber: dataObject.categoryNumber,
      categoryName: dataObject.categoryName,
      subCategoryNumber: dataObject.subCategoryNumber,
      subCategoryName: dataObject.subCategoryName,
      selfPath: 'CYCLE/' + cycleId,
      processPathArray: [],
      riskPathArray: [],
      controlPathArray: []
    })
  }

  // cycle 같은 경우는 중복되는 게 많다.
  const uniqueDataObjectList = []
  const uniqueDataObjectMap = {}
  dataObjectList.forEach((dataObject) => {
    // TODO: Id 체계에 따라 수정할 부분
    const cycleId = [
      dataObject.cycleNumber,
      dataObject.categoryNumber,
      dataObject.subCategoryNumber
    ].join('-')
    if (!(cycleId in uniqueDataObjectMap)) {
      uniqueDataObjectMap[cycleId] = true
      uniqueDataObjectList.push(dataObject)
    }
  })

  const result = uniqueDataObjectList.map(async (dataObject) => {
    return await cycleInsertDataForInit(dataObject)
  })

  console.log('Insert Cycle Data For Init Done')
  return Promise.all(result)
}

export const cycleGetWithId = async (cycleId) => {
  // cycleId 에 해당하는 항목만 가져온다.
  const targetCollectionName = 'CYCLE'
  const docData = await dbService.collection(targetCollectionName).doc(cycleId)
.get()

  return docData.data()
}

export const cycleGetAll = async () => {
  // 모든 CYCLE 데이터를 가져온다.
  const targetCollectionName = 'CYCLE'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.data())
}

export const cycleGetAllTree = async () => {
  /*
  "CYCLE" 컬렉션의 정보를 모두 가져온다
  가져온 정보를 cycle(대분류) - category(중분류) - subcategory(소분류) 순으로 정리해서 리턴
  @param X
  @return Obejct{} - 각 cycle, category, subcategory 를 Key로 트리구조
  {
    "1": {
      id: "1",  // cycleNumber
      name: "Entry Level",  // cycleName
      children: {
        "1": {
          id: "1", // categoryNumber
          name: "전사통제", // categoryName
          children: {
            "1": {
              id: "1",  // subCategoryNumber
              name: "조직구조", // subCategoryName
            },
          }
        }
      }
    },
    "2": {
      ...
    },
    ...
  }
  */

  const cycleDataAll = await cycleGetAll()

  // 필요한 형태로 만든다
  const cycleObject = {}
  cycleDataAll.forEach((cycle) => {
    // cycle(대분류)
    const cycleNumber = cycle.cycleNumber
    const cycleName = cycle.cycleName
    if (!(cycleNumber in cycleObject)) {
      cycleObject[cycleNumber] = {
        id: cycleNumber,
        name: cycleName,
        children: {}
      }
    }

    // category(중분류)
    const categoryNumber = cycle.categoryNumber
    const categoryName = cycle.categoryName
    if (!(categoryNumber in cycleObject[cycleNumber].children)) {
      cycleObject[cycleNumber].children[categoryNumber] = {
        id: categoryNumber,
        name: categoryName,
        children: {}
      }
    }

    // subcategory(소분류)
    const subCategoryNumber = cycle.subCategoryNumber
    const subCategoryName = cycle.subCategoryName
    if (!(subCategoryNumber in cycleObject[cycleNumber].children[categoryNumber].children)) {
      cycleObject[cycleNumber].children[categoryNumber].children[subCategoryNumber] = {
        id: subCategoryNumber,
        name: subCategoryName
      }
    }
  })

  return cycleObject
}

const cycleUpdatePathArray = async (cycleId, updateObject) => {
  /*
  CYCLE 의 cycleId 에 대해서
  updateObject 의 내용들을 수정

  @param: cycleId: String - CYCLE id
  @param: updateObject{
    "cyclePathArray": {
      "add": [cycleId, ...],
      "del": [cycleId, ...]
    },
    "processPathArray": {
      "add": [processId, ...],
      "del": [processId, ...]
    },
    "riskPathArray": {
      "add": [riskId, ...],
      "del": [riskId, ...]
    },
    "controlPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    }
  }
  */
  const finalUpdateObject = {}

  const cycleObject = await cycleGetWithId(cycleId)
  const iterKeyList = ['processPathArray', 'riskPathArray', 'controlPathArray']
  iterKeyList.forEach((targetKey) => {
    let resultIdList = cycleObject[targetKey].map((path) => path.split('/')[1])
    const addList = updateObject[targetKey].add
    addList.forEach((addId) => {
      resultIdList.push(addId)
    })

    const delList = updateObject[targetKey].del
    delList.forEach((delId) => {
      const idx = resultIdList.indexOf(delId)
      if (idx > -1) {
        resultIdList.splice(idx, 1)
      }
    })

    resultIdList = [...new Set(resultIdList)]
    const resultPathArray = resultIdList.map(
      (id) => targetKey.split('PathArray')[0].toUpperCase() + '/' + id
    )
    finalUpdateObject[targetKey] = resultPathArray
  })
  return await dbService.collection('CYCLE').doc(cycleId).update(finalUpdateObject)
}

export const cycleListUpdatePathArray = async (updateObject) => {
  /*
  CYCLE 의 cycleIdList 에 속하는 cycleId 들에 대해서
  cycleUpdatePathArray 를 호출

  @param: cycleId: String - CYCLE id
  @param: updateObject{
    "cycleId": {},
    ...
  }
  */

  const result = Object.keys(updateObject).map(async (cycleId) => {
    return await cycleUpdatePathArray(cycleId, updateObject[cycleId])
  })

  return Promise.all(result)
}

export const cycleUpdate = async (cycleObject) => {
  /*
  CYCLE 에 새로운 항목을 만들거나
  기존 항목의 이름을 수정하거나(이름만 수정 가능. cycleName, categoryName, subCategoryName)

  TODO: 설계평가에 영향

  @param Object{
    isNew: Boolean, //true: 신규생성, false: 수정
    cycleNumber: String,  // 필수
    cycleName: String, // (변경대상이 아니면 원래값 담아주세요)
    categoryNumber: String, // 필수
    categoryName: String, // (변경대상이 아니면 원래값 담아주세요)
    subCategoryNumber: String,  // 필수
    subCategoryName: String,  // (변경대상이 아니면 원래값 담아주세요)
  }
  */

  const {
    isNew,
    cycleNumber,
    cycleName,
    categoryNumber,
    categoryName,
    subCategoryNumber,
    subCategoryName
  } = cycleObject

  const targetCollectionName = 'CYCLE'
  const cycleId = [cycleNumber, categoryNumber, subCategoryNumber].join('-')
  const targetDocId = cycleId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  if (isNew) {
    // 예외사항에 대한 체크
    const cycleDataAll = await cycleGetAll()
    // cycleId 가 이미 존재하는지 체크
    const duplicateCheckForCycleId = _.find(cycleDataAll, { id: cycleId })
    if (!_.isUndefined(duplicateCheckForCycleId)) {
      return Promise.reject(new Error('이미 존재하는 대분류-중분류-소분류 아이디 입니다.'))
    }
    // 기존의 cycleId 와 cycleName 중에 하나만 일치하는 경우
    const duplicateCheckForCycleNumber = _.find(cycleDataAll, { cycleNumber })
    if (!_.isUndefined(duplicateCheckForCycleNumber)) {
      if (duplicateCheckForCycleNumber.cycleName !== cycleName) {
        // cycleNumber 가 일치하는 게 있고 cycleName 은 다른 경우
        return Promise.reject(new Error('같은 대분류 아이디에 대해서 다른 대분류 이름은 안됩니다.'))
      }
    }
    const duplicateCheckForCycleName = _.find(cycleDataAll, { cycleName })
    if (
      !_.isUndefined(duplicateCheckForCycleName) &&
      duplicateCheckForCycleName.cycleNumber !== cycleNumber
    ) {
      // 이미 cycleNumber 가 일치하는 건 없는 상황에서(위에서 체크했기 때문에) cycleName 만 같은 경우도 막는다
      return Promise.reject(new Error('다른 대분류 아이디에 대해서 같은 대분류 이름은 안됩니다.'))
    }

    // 새로운 CYCLE 항목 생성
    return await docRef
      .set({
        id: cycleId,
        uid: cycleId,

        cycleNumber,
        cycleName,
        categoryNumber,
        categoryName,
        subCategoryNumber,
        subCategoryName,

        selfPath: 'CYCLE/' + cycleId,
        processPathArray: [],
        riskPathArray: [],
        controlPathArray: []
      })
      .then(() => backupSetFlag())
  } else {
    // 기존의 CYCLE 항목 수정
    return await docRef
      .update({
        cycleName,
        categoryName,
        subCategoryName
      })
      .then(async () => {
        // 연결된 CONTROL 들의 cycleName, categoryName, subCategoryName 수정해줘야함
        const cycleData = await cycleGetWithId(cycleId)
        const controlIdList = cycleData.controlPathArray.map(
          (controlPath) => controlPath.split('/')[1]
        )
        const result = controlIdList.map(async (controlId) => {
          const controlData = await controlGetWithId(controlId)
          controlData.processCycleName = cycleName
          controlData.processCategoryName = categoryName
          controlData.processSubCategoryName = subCategoryName
          return (
            dbService
              .collection('CONTROL')
              .doc(controlId)
              .update({
                processCycleName: cycleName,
                processCategoryName: categoryName,
                processSubCategoryName: subCategoryName
              })
              // cycle 에서 processCategoryName, processSubCategoryName 이 정보가 바뀌면 연결된 CONTROL 에서도 이 정보가 바뀐다
              // 그리고 이 정보는 설계평가의 보고서 Template 에서도 변경되어야 한다.
              .then(() => evaluationUpdateTemplate('DE', controlId, controlData))
          )
        })
        await Promise.all(result)
      })
      .then(() => backupSetFlag())
  }
}
