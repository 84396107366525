import { ColDef, ColumnApi, GridApi } from '@ag-grid-enterprise/all-modules'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Radio from 'antd/lib/radio'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import { grey, lightBlue } from '../../base/color'
import { StaffCellRenderer } from '../../base/data/Staff'
import CommonAgGrid from '../common/CommonAgGrid'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  whiteBox: {
    backgroundColor: 'white',
    border: grey.border,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(50)
  },
  doughnutContainer: {
    margin: theme.spacing(1)
  },
  title: {
    padding: theme.spacing(1.5, 3, 1.5, 3)
  },
  doughnut: {
    width: theme.spacing(50),
    height: theme.spacing(50),
    padding: theme.spacing(2)
  },
  tableContainer: {
    display: 'flex',
    flex: 2.5,
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: theme.spacing(1)
  }
}))

type GridProps = {
  gridApi?: GridApi
  columnApi?: ColumnApi
}

export type StatisticsType = 'departmentName' | 'controlOwner' | 'controlPerformer'
export type StatisticsRow = {
  key: string
  totalCount: number
  state: Record<string, number>
  weakState: Record<string, number>
  resultState: Record<string, number>
}

function totalCellStyle(params: any): any {
  if (params.data.key === '합계') {
    return { backgroundColor: lightBlue.background }
  }
}

interface Props {
  doughnutTitle: string
  doughnutData: Record<string, number>
  columnMap: {
    departmentName: ColDef[]
    controlOwner: ColDef[]
    controlPerformer: ColDef[]
  }
  tableData: {
    departmentName: StatisticsRow[]
    controlOwner: StatisticsRow[]
    controlPerformer: StatisticsRow[]
  }
}

const StatusView: React.FC<Props> = ({ doughnutTitle, doughnutData, columnMap, tableData }) => {
  const classes = useStyles()
  const [selectionType, setSelectionType] = React.useState<StatisticsType>('departmentName')

  return (
    <div className={classes.root}>
      <div className={clsx(classes.doughnutContainer, classes.whiteBox)}>
        <Typography.Text strong className={classes.title}>
          {doughnutTitle}
        </Typography.Text>
        <div className={classes.doughnut}>
          <Doughnut
            data={{
              labels: _.keys(doughnutData),
              datasets: [
                {
                  data: _.values(doughnutData),
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                  ],
                  borderWidth: 1
                }
              ]
            }}
            options={{
              cutoutPercentage: 66,
              spanGaps: false,
              legend: {
                display: true,
                position: 'bottom',
                labels: {
                  padding: 16,
                  usePointStyle: true
                }
              },
              maintainAspectRatio: false
            }}
          />
        </div>
      </div>
      <div className={clsx(classes.tableContainer, classes.whiteBox)}>
        <Radio.Group
          style={{ margin: '10px' }}
          onChange={({ target: { value } }) => {
            setSelectionType(value)
          }}
          value={selectionType}
        >
          <Radio value="departmentName">부서</Radio>
          <Radio value="controlOwner">통제 담당자</Radio>
          <Radio value="controlPerformer">통제 평가자</Radio>
        </Radio.Group>
        <Divider />
        <CommonAgGrid
          noBorder
          columnDefs={columnMap[selectionType]}
          defaultColDef={{
            wrapText: true,
            autoHeight: true,
            resizable: true,
            lockPosition: true,
            filter: 'agSetColumnFilter',
            suppressSizeToFit: true,
            filterParams: {
              excelMode: 'windows' // can be 'windows' or 'mac'
            },
            cellStyle: totalCellStyle
          }}
          frameworkComponents={{
            StaffCellRenderer
          }}
          rowData={tableData[selectionType]}
        />
      </div>
    </div>
  )
}

export default StatusView
