// NOTE: https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
/* eslint-disable no-undef */
import packageJson from 'frontend/package.json'
import React from 'react'

global.appVersion = packageJson.version

const checkVersionMismatch = (latestVersionStr, currentVersionStr) => {
  const latestVersion = latestVersionStr.split(/\./g)
  const currentVersion = currentVersionStr.split(/\./g)

  while (latestVersion.length || currentVersion.length) {
    const a = Number(latestVersion.shift())
    const b = Number(currentVersion.shift()) // eslint-disable-next-line no-continue

    if (a === b) continue // eslint-disable-next-line no-restricted-globals

    return a > b || isNaN(b)
  }

  return false
}

class CacheBuster extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isLatestVersion: false,

      clearCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (const name of names) caches.delete(name)
          })
        }
        window.location.reload(true)
      }
    }
  }

  componentDidMount() {
    fetch('/meta.json', { cache: 'no-cache' })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version
        const currentVersion = global.appVersion
        const shouldForceRefresh = checkVersionMismatch(latestVersion, currentVersion)

        this.setState({ isLoading: false, isLatestVersion: !shouldForceRefresh })
      })
  }

  render() {
    const { isLoading, isLatestVersion, clearCacheAndReload } = this.state

    return this.props.children({ isLoading, isLatestVersion, clearCacheAndReload })
  }
}

export default CacheBuster
