/* eslint-disable camelcase */
export const parsingReadMap_DE = {
  설계평가: {
    I6: {
      name: 'executor',
      korName: '통제 평가자'
    },
    I7: {
      name: 'createDate',
      korName: '작성일자'
    },
    G25: {
      name: 'considerationOneContent',
      korName: '이해내용1'
    },
    L24: {
      name: 'considerationOneResult',
      korName: '이해결론1'
    },
    G27: {
      name: 'considerationTwoContent',
      korName: '이해내용2'
    },
    L26: {
      name: 'considerationTwoResult',
      korName: '이해결론2'
    },
    G29: {
      name: 'considerationThreeContent',
      korName: '이해내용3'
    },
    L28: {
      name: 'considerationThreeResult',
      korName: '이해내용3'
    },
    F33: {
      name: 'baselineReference',
      korName: '설계일자(Baseline) Reference'
    },
    D35: {
      name: 'result',
      korName: '평가 결론'
    }
  }
}

export const parsingWriteMap_DE = {
  processCycleName: -1,
  processCategoryName: -1,
  processSubCategoryName: -1,
  processMappingCode: -1,
  department: -1,
  owner: -1,
  riskNumber: -1,
  riskName: -1,
  id: -1,
  narrative: -1,
  Yes: -1, // keyControl
  수동통제: -1, // autoManual(Nature)
  예방: -1, // preventDetective(Approach)
  일별수시: -1, // period(통제주기)
  H: -1, // riskInherentRiskLevel(고유위험 - Risk 에서 온것임)
  M: -1, // residualRiskLevel(잔여위험)
  L: -1, // controlRiskLevel(통제위험)
  TOC_TestMethod_InquiryInspection: -1, // 질문&관찰
  TOC_TestMethod_Observation: -1, // 문서검사
  TOC_TestMethod_Reperformance: -1, // 재수행
  TOC_Procedure: -1, // 테스트 절차
  TOC_Evidence: -1, // 테스트 증빙
  MRC_Number: -1, // MRC 번호
  IPE_Number: -1 // IPE 번호
}