import { makeStyles, Theme } from '@material-ui/core'
import { Button, Modal, Typography } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'
import useResizeAware from 'react-resize-aware'

import {
  ERROR_STATES,
  EvaluationFile,
  State,
  stateToColor,
  stateToString
} from '../../../base/data/BaseFile'
import {
  EvaluationType,
  getKoEvaluation,
  Evaluation,
  OperationEvaluationData,
  DesignEvaluationData
} from '../../../base/data/Evaluation'
import { LoadStaffs, Staff } from '../../../base/data/Staff'
import { UserLevel } from '../../../base/data/User'
import {
  evaluationUpdateFromExcel,
  evaluationUpdateState
} from '../../../dataLoader/Evaluation/evaluation'
import { adminGetOrCreateUser } from '../../../dataLoader/Utils/admin'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import DividerSelect, { BASE_ITEM } from '../../common/DividerSelect'
import DropBox from '../common/DropBox'
import {
  EvaluationSelectAttribute,
  getEvaluationNamesWithDate,
  getShortEvalType,
  loadEvaluationDetails,
  loadEvaluationDetailsInner,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import DesignEvaluationReportView from '../view/DesignEvaluationReportView'
import EvaluationTable from '../view/EvaluationTable'
import OperationEvaluationReportViewer from '../view/OperationEvaluationReportViewer'
import FileNameNoticeView from './FileNameNoticeView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 600,
    padding: theme.spacing(1)
  },
  uploadList: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 0
  },
  content: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1)
  }
}))

function updateFileState(
  userLevel: UserLevel,
  type: EvaluationType,
  files: EvaluationFile[],
  evalName: string,
  documents?: Evaluation[]
): EvaluationFile[] {
  const newFiles = files.slice()
  _.forEach(newFiles, (file) => {
    if (!_.includes(ERROR_STATES, file.state)) {
      if (file.state !== State.OK) {
        return
      }

      if (file.evaluationName === evalName) {
        const matched = _.find(documents, { controlId: file.controlId })
        if (!matched) {
          file.state = State.UNRELATED_FILE
        } else {
          if (matched.data.state.state === '5') {
            file.state = State.NOT_EDITABLE
          } else if (
            file.fileType === '보고서' &&
            matched.data.content.files?.recordFile?.fileName
          ) {
            file.state = State.ALREADY_EXISTING
          } else if (type === 'operation') {
            if (
              file.fileType === '모집단' &&
              matched.data.content.files?.populationFile?.fileName
            ) {
              if (userLevel === UserLevel.ADMIN || userLevel === UserLevel.NORMAL) {
                file.state = State.ALREADY_EXISTING
              } else {
                file.state = State.NOT_ALLOWED
              }
            } else if (
              file.fileType === '증빙' &&
              _.find(matched.data.content.files?.evidenceFile, {
                fileName: `${file.fileName}.${file.fileExtension}`
              })
            ) {
              file.state = State.ALREADY_EXISTING
            } else if (
              file.fileType === '기타' &&
              _.find(matched.data.content.files?.extraFile, {
                fileName: `${file.fileName}.${file.fileExtension}`
              })
            ) {
              file.state = State.ALREADY_EXISTING
            }
          }

          // NOTE: ADMIN, NORMAL 외의 경우, 조서를 업로드 할 수 없음
          if (
            file.fileType === '보고서' &&
            !_.includes([UserLevel.ADMIN, UserLevel.NORMAL], userLevel)
          ) {
            file.state = State.NO_PERMISSION
          }
        }
      } else {
        file.state = State.UNRELATED_OPERATION_EVAL
      }
    }
  })
  return newFiles
}

function getUploadableFiles(uploadFiles: EvaluationFile[]): EvaluationFile[] {
  return _.filter(uploadFiles, (file) => {
    return file.state === State.OK || file.state === State.ALREADY_EXISTING
  })
}

function isEnableUpload(selectedDoc: string, uploadFiles: EvaluationFile[]): boolean {
  return (
    !_.isEmpty(selectedDoc) &&
    selectedDoc !== '__select__' &&
    !_.isEmpty(getUploadableFiles(uploadFiles))
  )
}

function showUploadFiles(
  type: EvaluationType,
  operationEvaluationName: string,
  files: EvaluationFile[],
  afterUploadFiles: (uploadedFiles: EvaluationFile[]) => void,
  alertRef: React.RefObject<AlertMessageHandler>
): void {
  const uploadableFiles = getUploadableFiles(files)
  const modalProps = {
    centered: true,
    okCancel: true,
    width: 600,
    onOk() {
      return evaluationUpdateFromExcel(
        getShortEvalType(type),
        operationEvaluationName,
        uploadableFiles
      )
        .then(() => {
          const changeStates = _.compact(
            _.map(uploadableFiles, (uploadableFile) => {
              return evaluationUpdateState(
                getShortEvalType(type),
                uploadableFile.evaluationName,
                [uploadableFile.controlId],
                '2'
              )
            })
          )

          Promise.all(changeStates).then(() => {
            afterUploadFiles(uploadableFiles)
            alertRef.current?.showAlert('success', '업로드 완료')
          })
        })
        .catch((e: any) => {
          // eslint-disable-next-line no-console
          console.log('Failed to upload file', e)
          alertRef.current?.showAlert('error', '업로드 실패')
        })
    }
  }

  if (_.every(uploadableFiles, { state: State.OK })) {
    Modal.success({
      title: (
        <div>
          <p>업로드 하시겠습니까?</p>
          <p>
            (파일이 업로드된 통제항목은 수행 중으로 전환됩니다. {getKoEvaluation(type)} 보고서 작성
            화면에서 수행완료 처리 하여야 합니다.)
          </p>
        </div>
      ),
      content: _.map(uploadableFiles, (file) => {
        const fileName = `${file.fileName}.${file.fileExtension}`
        return (
          <p key={fileName} style={{ margin: 0 }}>
            {fileName}
          </p>
        )
      }),
      ...modalProps
    })
  } else {
    Modal.confirm({
      title: (
        <div>
          <p>이미 업로드된 파일입니다. 업로드 하시겠습니까?</p>
          <p>
            (파일이 업로드된 통제항목은 수행 중으로 전환됩니다. {getKoEvaluation(type)} 보고서 작성
            화면에서 수행완료 처리 하여야 합니다.)
          </p>
        </div>
      ),
      content: _.map(uploadableFiles, (file) => {
        const fileName = `${file.fileName}.${file.fileExtension}`
        return (
          <p key={fileName} style={{ margin: 0, color: stateToColor(file.state) }}>
            {fileName} - {stateToString(file.state)}
          </p>
        )
      }),
      ...modalProps
    })
  }
}

interface Props {
  active: boolean
  type: EvaluationType
}

const FileManagement: React.FC<Props> = ({ active, type }) => {
  const classes = useStyles()
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: BASE_ITEM,
    names: [],
    summaries: [],
    evaluationDetails: []
  })
  const [files, setFiles] = React.useState(new Array<EvaluationFile>(0))
  const [report, setReport] = React.useState<{ visible: boolean; data: Evaluation | undefined }>({
    visible: false,
    data: undefined
  })
  const [evaluationMap, setEvaluationMap] = React.useState<Record<string, Evaluation[]>>({})
  const [backdrop, setBackdrop] = React.useState(false)
  const [resizeListener, sizes] = useResizeAware()
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const [cookies, setCookie, removeCookie] = useCookies([`${type}EvaluationName`])
  const [userLevel, setUserLevel] = React.useState(UserLevel.UPLOADER)
  const alertRef = React.useRef<AlertMessageHandler>(null)

  React.useEffect(() => {
    LoadStaffs().then((newStaffs) => {
      setStaffs(newStaffs)
    })
    adminGetOrCreateUser().then((user) => {
      const level = _.get(user, 'level')
      setUserLevel(level)
    })
  }, [])

  React.useEffect(() => {
    if (active) {
      loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
      setEvaluationMap({})
    }
  }, [active, type])

  React.useEffect(() => {
    setFiles(updateFileState(userLevel, type, files, select.selectedName, select.evaluationDetails))
  }, [select.evaluationDetails])

  const afterUploadFiles = React.useCallback(
    (uploadedFiles: EvaluationFile[]): void => {
      const notUploadedFiles = _.filter(files, (file) => {
        return !_.includes(uploadedFiles, file)
      })

      setFiles(notUploadedFiles)
      loadEvaluationDetails(type, select.selectedName, select, setSelect)
    },
    [files, select]
  )

  const afterDeleteFile = React.useCallback(
    (newData: Evaluation[]): void => {
      setSelect({
        ...select,
        evaluationDetails: newData
      })
      setFiles(updateFileState(userLevel, type, files, select.selectedName, newData))
    },
    [select, files]
  )

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setCookie(`${type}EvaluationName`, evaluationName)
    },
    [select]
  )

  const setEvaluationData = React.useCallback(
    (
      controlId: string,
      evaluationData: OperationEvaluationData | DesignEvaluationData,
      evaluations: Evaluation[]
    ): void => {
      const evaluation = _.find(evaluations, { controlId })
      if (!evaluation) {
        alertRef.current?.showAlert(
          'error',
          `해당 ${getKoEvaluation(type)}에 존재하지 않는 통제입니다.`
        )
        return
      }
      const newEvaluation = _.cloneDeep(evaluation)
      newEvaluation.data.content.evalData = evaluationData
      setReport({
        ...report,
        visible: true,
        data: newEvaluation
      })
    },
    [report]
  )

  const setReportFromDropBox = React.useCallback(
    (
      evaluationName: string,
      controlId: string,
      evaluationData: OperationEvaluationData | DesignEvaluationData
    ) => {
      if (_.has(evaluationMap, evaluationName)) {
        setEvaluationData(controlId, evaluationData, _.get(evaluationMap, evaluationName))
      } else {
        setBackdrop(true)
        loadEvaluationDetailsInner(type, evaluationName)
          .then((evaluations) => {
            setEvaluationMap({
              ...evaluationMap,
              [evaluationName]: evaluations
            })
            setEvaluationData(controlId, evaluationData, evaluations)
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log('Failed to get controls', evaluationName)
          })
          .finally(() => {
            setBackdrop(false)
          })
      }
    },
    [setEvaluationData, evaluationMap]
  )

  const setFilesFromDropBox = React.useCallback(
    (newFiles) => {
      setFiles(
        updateFileState(userLevel, type, newFiles, select.selectedName, select.evaluationDetails)
      )
    },
    [select]
  )

  return (
    <div className={classes.root}>
      {resizeListener}
      <DividerSelect
        defaultName={`${getKoEvaluation(type)} 선택`}
        defaultValue={BASE_ITEM}
        loading={select.loading}
        names={getEvaluationNamesWithDate(select.summaries)}
        value={select.selectedName}
        values={select.names}
        onChange={(value) => onEvaluationSelect(value as string)}
      />
      <div className={classes.uploadList}>
        <div className={classes.subheader}>
          <Typography.Title level={5}>현재 업로드 내역</Typography.Title>
        </div>
        <EvaluationTable
          afterDeleteFile={afterDeleteFile}
          evaluationDetails={select.evaluationDetails || []}
          evaluationType={type}
          loading={select.loading}
          staffs={staffs}
          type="upload"
        />
      </div>
      <div style={{ marginTop: 4, marginBottom: 4 }} />
      <div className={classes.content}>
        <div className={classes.subheader}>
          <Typography.Title level={5} style={{ flexGrow: 1 }}>
            업로드 파일
          </Typography.Title>
          <Button
            disabled={!isEnableUpload(select.selectedName, files)}
            onClick={() => {
              showUploadFiles(type, select.selectedName, files, afterUploadFiles, alertRef)
            }}
          >
            업로드
          </Button>
        </div>
        <FileNameNoticeView
          evaluationType={type}
          evaluationName={select.selectedName !== BASE_ITEM ? select.selectedName : undefined}
        />
        <DropBox
          files={files}
          setFiles={setFilesFromDropBox}
          setReport={setReportFromDropBox}
          type={type}
        />
      </div>
      <Modal
        bodyStyle={{ padding: 0 }}
        footer={[
          <Button
            key="back"
            onClick={() => setReport({ ...report, data: undefined, visible: false })}
          >
            닫기
          </Button>
        ]}
        title="보고서 상세"
        visible={report.visible}
        width={sizes.width ? sizes.width * 0.8 : 1000}
        onCancel={() => setReport({ ...report, data: undefined, visible: false })}
        onOk={() => setReport({ ...report, data: undefined, visible: false })}
      >
        {type === 'operation' && report.data && (
          <OperationEvaluationReportViewer
            isPopup
            noFiles
            evaluation={report.data}
            staffs={staffs}
          />
        )}
        {type === 'design' && report.data && (
          <DesignEvaluationReportView noFiles staffs={staffs} evaluation={report.data} />
        )}
      </Modal>
      <AlertMessage ref={alertRef} />
      <CircleBackdrop open={backdrop} />
    </div>
  )
}

export default FileManagement
