import { Theme, useTheme } from '@material-ui/core/styles'
import Select from 'antd/lib/select'
import _ from 'lodash'
import React from 'react'

function getStyles(value: string, values: string[], theme: Theme) {
  return {
    fontWeight:
      _.indexOf(values, value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

const DELIMITER = '\n'

const agMultiSelectCellEditor = React.forwardRef((props: any, ref) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [selectedValues, setSelectedValues] = React.useState<string[]>([])
  const refInput = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    // focus on the input
    setTimeout(() => {
      refInput.current?.focus()
      setOpen(true)
    })
  }, [])

  React.useEffect(() => {
    setSelectedValues(
      _(props.value)
        .split(DELIMITER)
        .map((value) => {
          if (_.includes(props.values, value)) {
            return value
          }
          return undefined
        })
        .compact()
        .value()
    )
  }, [props.value])

  /* Component Editor Lifecycle methods */
  React.useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        // this simple editor doubles any value entered into the input
        return _.join(selectedValues, DELIMITER)
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any value greater than 1000
        // return value > 1000
        return false
      }
    }
  })

  return (
    <Select
      mode="multiple"
      maxTagCount="responsive"
      style={{ width: '100%' }}
      open={open}
      onDropdownVisibleChange={(event) => {
        setOpen(event)
        if (!event) {
          props.api.clearFocusedCell()
        }
      }}
      onChange={(values) => {
        setSelectedValues(values as string[])
      }}
      value={selectedValues}
    >
      {_.map(props.values, (v) => (
        <Select.Option value={v} style={getStyles(v, selectedValues, theme)}>
          {v}
        </Select.Option>
      ))}
    </Select>
  )
})

export default agMultiSelectCellEditor
