export const updateObjectForm = {
  cyclePathArray: {
    add: [],
    del: []
  },
  processPathArray: {
    add: [],
    del: []
  },
  riskPathArray: {
    add: [],
    del: []
  },
  controlPathArray: {
    add: [],
    del: []
  },
  staffPathArray: {
    add: [],
    del: []
  },
  departmentPathArray: {
    add: [],
    del: []
  }
}
