import _ from 'lodash'
import moment from 'moment'

import { authService, dbService } from '../fbase'

interface Staff {
  id: string
  name: string
  level: string
  departmentName: string
  email: string
}

export interface Notice {
  id: string
  writer: string
  createTime: string
  isVisible: boolean
  title: string
  content: string
}

/**
 * @param {string} staffId staff id to get
 * @returns {Promise<Staff>} Promise that contains Staff
 */
export async function staffGetWithId(staffId: string): Promise<Staff> {
  const collectionName = 'STAFF'
  const docRef = dbService.collection(collectionName).doc(staffId)
  const documentObject = await docRef.get()
  if (!documentObject.exists) {
    return Promise.reject(new Error('해당 직원의 아이디가 존재하지 않습니다.'))
  }

  const data = documentObject.data()
  if (!data || _.isEmpty(data)) {
    return Promise.reject(new Error('해당 직원의 데이터가 비어있습니다.'))
  }

  return {
    id: data.id,
    name: data.name,
    level: data.level,
    departmentName: data.departmentName,
    email: data.email
  }
}

/**
 * @returns {Promise<void>} Promise that contains Notice[]
 */
export async function noticeGetAll(): Promise<Notice[]> {
  const collectionName = 'NOTICE'
  const collectionObject = await dbService.collection(collectionName).get()
  const documentObjectList = collectionObject.docs
  const noticeObjectList = _.map(documentObjectList, (noticeObject): Notice => {
    const noticeData = noticeObject.data()
    if (_.isEmpty(noticeData)) {
      throw new Error('해당 공지사항의 데이터가 비어있습니다.')
    }
    return {
      id: noticeData.id,
      writer: noticeData.writer,
      createTime: noticeData.createTime,
      isVisible: noticeData.isVisible,
      title: noticeData.title,
      content: noticeData.content
    }
  })

  return noticeObjectList || []
}

/**
 *
 * @param {Notice} newNotice 새로 생성할 Notice
 * @returns {Promise<void>}
 */
export async function noticeCreate(title: string, content: string): Promise<void> {
  if (_.isEmpty(title)) {
    return Promise.reject(new Error('공지사항 작성 - 제목을 입력해주세요'))
  }

  const currentUserId = authService?.currentUser?.email
  if (!currentUserId) {
    return Promise.reject(new Error('공지사항 작성 - 로그인한 유저를 찾을 수 없습니다.'))
  }
  const staff = await staffGetWithId(currentUserId)
  if (staff?.level !== 'ADMIN') {
    return Promise.reject(new Error('공지사항 작성 - 권한이 없습니다.'))
  }

  // 글의 생성시각
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  const collectionName = 'NOTICE'
  const collectionRef = dbService.collection(collectionName)

  const notice: Notice = {
    title,
    content,
    id: '',
    createTime: currentTime,
    writer: currentUserId,
    isVisible: true
  }

  return collectionRef.add(notice).then((result) => {
    const docId = result.id
    if (!(docId || false)) {
      return Promise.reject(new Error('공지사항 작성에 실패했습니다.'))
    }
    return collectionRef.doc(docId).update({
      id: docId
    })
  })
}

/**
 *
 * @param noticeId
 * @param visibleState
 * @returns {Promise<void>}
 */
export async function noticeDelete(noticeId: string): Promise<void> {
  const collectionName = 'NOTICE'
  const docRef = dbService.collection(collectionName).doc(noticeId)
  const documentObject = await docRef.get()
  if (!documentObject.exists) {
    return Promise.reject(new Error('해당 아이디의 공지사항 글이 존재하지 않습니다.'))
  }

  return docRef.update({
    isVisible: false
  })
}
