import { ColDef, ColGroupDef } from '@ag-grid-enterprise/all-modules'
import {
  FileOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileZipOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import React from 'react'

import { getShortEvalType } from '../../component/evaluation/common/EvaluationUtils'
import { evaluationDownloadFile } from '../../dataLoader/Evaluation/evaluation'
import { EvalFile, ServerFile, State } from '../data/BaseFile'
import { Evaluation, EvaluationData, EvaluationFiles, EvaluationType } from '../data/Evaluation'
import { Staff, StaffValueFormatter } from '../data/Staff'
import { getKoName } from '../data/Translation'
import { parseTimeStr } from './TimeUtils'

function getFileIcon(fileName: string): React.ReactElement {
  let icon = <FileOutlined />
  if (_.endsWith(fileName, 'xlsm') || _.endsWith(fileName, 'xlsx')) {
    icon = <FileExcelOutlined />
  } else if (_.endsWith(fileName, 'pptx') || _.endsWith(fileName, 'ppt')) {
    icon = <FilePptOutlined />
  } else if (_.endsWith(fileName, 'zip')) {
    icon = <FileZipOutlined />
  }
  return icon
}

export const MAX_POPULATION_SIZE = 5

export function getFileSizeByMB(file: File): number {
  return file.size / 1024 / 1024
}

function fileNameCellRenderer(params: any): HTMLElement {
  const aElement = document.createElement('a')
  aElement.innerHTML = params.value
  aElement.onclick = () =>
    evaluationDownloadFile(
      getShortEvalType(params.data.evaluationType),
      params.data.evaluationName,
      params.data.controlId,
      params.value
    )
  const div = document.createElement('div')
  div.appendChild(aElement)

  if (!_.isEmpty(params.data.description)) {
    const span = document.createElement('span')
    span.style.color = 'red'
    span.innerHTML = ' ' + params.data.description
    div.appendChild(span)
  }
  return div
}

export function dateCellRenderer(params: any): HTMLElement {
  const span = document.createElement('span')
  span.innerHTML = params.value ? parseTimeStr(params.value) : ''
  return span
}

export function getFileViewColumns(staffs: Staff[]): (ColDef | ColGroupDef)[] {
  return [
    {
      headerName: getKoName('common', 'fileName'),
      field: 'fileName',
      flex: 3,
      cellRenderer: fileNameCellRenderer,
      sortable: true
    },
    { headerName: getKoName('common', 'type'), field: 'type', sortable: true },
    {
      headerName: getKoName('common', 'updater'),
      field: 'updater',
      cellRenderer: 'StaffCellRenderer',
      flex: 1,
      valueFormatter: StaffValueFormatter(staffs),
      filterParams: {
        valueFormatter: StaffValueFormatter(staffs)
      },
      sortable: true
    },
    {
      headerName: getKoName('common', 'updateTime'),
      field: 'updateTime',
      cellRenderer: dateCellRenderer,
      width: 220,
      sortable: true
    }
  ]
}

export function FileCellRenderer(params: any): React.ReactElement {
  const field = params.colDef.field
  let icon
  if (
    _.isEqual(field, 'template') ||
    (_.includes(['data.content.files.evidenceFile', 'data.content.files.extraFile'], field) &&
      !_.isEmpty(params.value))
  ) {
    icon = getFileIcon('')
  } else if (
    _.includes(['data.content.files.recordFile', 'data.content.files.populationFile'], field) &&
    !_.isEmpty(params.value?.fileName)
  ) {
    icon = getFileIcon(params.value.fileName)
  }
  return <div style={{ textAlign: 'center', fontSize: 20 }}>{icon}</div>
}

export function FileNameCellRenderer(params: any): React.ReactElement {
  const filename = _.head(_.split(params.value, ', '))
  let icon
  if (filename) {
    icon = getFileIcon(filename)
  }
  return <div style={{ textAlign: 'center', fontSize: 20 }}>{icon}</div>
}

export function getFiles(type: EvaluationType, evaluation: Evaluation): ServerFile[] {
  const originalFiles: EvaluationFiles = _.cloneDeep(evaluation.data.content.files)
  const evalData: EvaluationData = evaluation.data.content.evalData
  const newFiles: ServerFile[] = []
  if (!_.isEmpty(originalFiles?.recordFile) && !_.isEmpty(originalFiles.recordFile.fileName)) {
    originalFiles.recordFile.type = '보고서'
    if (
      !_.isEmpty(evalData.updateTime) &&
      !_.isEqual(evalData.updateTime, originalFiles.recordFile.updateDate)
    ) {
      originalFiles.recordFile.description = '(현재 보고서 내용과 다름)'
    }

    newFiles.push(originalFiles.recordFile)
  }

  if (type === 'operation') {
    if (
      originalFiles.populationFile &&
      !_.isEmpty(originalFiles?.populationFile) &&
      !_.isEmpty(originalFiles.populationFile.fileName)
    ) {
      originalFiles.populationFile.type = '모집단'
      newFiles.push(originalFiles.populationFile)
    }
    _.forEach(originalFiles?.evidenceFile, (file) => {
      file.type = '증빙'
      newFiles.push(file)
    })
  }
  _.forEach(originalFiles?.extraFile, (file) => {
    file.type = '기타'
    newFiles.push(file)
  })

  _.forEach(newFiles, (file) => {
    file.evaluationType = type
    file.controlId = evaluation.controlId
    file.evaluationName = evaluation.data.base.name
  })
  return newFiles
}

export function getFilesForSaving(
  type: EvaluationType,
  evaluation: Evaluation,
  files: EvalFile[]
): { deletedFiles: any[]; newFiles: any[] } {
  const deletedFiles: any[] = []
  _.forEach(getFiles(type, evaluation), (file: ServerFile) => {
    if (!_.find(files, { fileName: file.fileName })) {
      deletedFiles.push({
        evaluationName: file.evaluationName,
        controlId: file.controlId,
        type: file.type,
        fileName: file.fileName
      })
    }
  })
  const newFiles: any[] = []
  _.forEach(files, (file: EvalFile) => {
    if (file.state !== State.OK && file.state !== State.ALREADY_EXISTING) return

    const dotIndex = file.fileName.lastIndexOf('.')
    const fileName = file.fileName.substr(0, dotIndex)
    const fileExtension = file.fileName.substr(dotIndex + 1)
    newFiles.push({
      ...file,
      // controlId: file.controlId,
      fileName,
      fileExtension,
      // state: file.state,
      fileType: file.type
      // excelData: file.excelData
    })
  })
  return {
    deletedFiles,
    newFiles
  }
}
