import _ from 'lodash'

import { UserLevel } from './User'

const CONTROL_KO_ATTRIBUTES: Record<string, string> = {
  id: '통제번호',
  goal: '통제목적',
  goal_Trust: '재무보고의 신뢰성',
  goal_Override: '부정방지',
  goal_Operation: '운영의 효율/효과',
  goal_Law: '법규 및 정책준수',
  goal_Asset: '자산의 보호',
  name: '통제활동 이름',
  narrative: '통제활동 설명',
  keyControl: 'Key Control',
  assertion: '경영진의 주장',
  assertion_EO: '실재성/발생사실',
  assertion_C: '완전성',
  assertion_RO: '권리와 의무',
  assertion_PD: '표시와 공시',
  assertion_Occur: '발생사실',
  assertion_Assessment: '평가',
  assertion_Measurement: '측정',

  accountName: '계정과목명',
  accountCode: '계정코드',
  riskName: '위험명',

  controlRiskLevel: '통제 위험',
  residualRiskLevel: '통제의 잔여위험',
  policy: '관련규정집',
  teamLeader: '통제 책임자',
  teamMember: '통제 담당자',

  reportFootnotes: '관련주석',
  reviewControl: 'Review Control',
  period: '통제주기',
  preventDetective: '예방/적발 통제',
  autoManual: '수기/자동 통제',
  ITDependency: 'IT dependency',
  ITDependencySystem: 'Related System',
  autoProperty: 'Auto Control 속성',
  autoProperty_interface: 'Interface',
  autoProperty_calculation: 'Calculation',
  autoProperty_autocontrol: 'Auto Control',
  autoProperty_restrictedaccess: 'Restricted Access',
  autoProperty_sgi: 'SGI',
  MRC_YN: 'MRC 해당 여부',
  MRC_Number: 'MRC 번호',
  MRC_IPE_Number: 'MRC 관련 IPE 번호',
  MRC_IPE_Name: 'MRC 이름',
  IPE_YN: 'IPE 통제 해당 여부',
  IPE_Number: 'IPE 번호',
  IPE_Name: 'IPE 이름',
  EUC: 'EUC',
  department: '담당부서',
  incharge: '현재 통제 책임자',
  owner: '현재 통제 담당자',
  TOC_TestMethod: '운영평가 속성',
  TOC_TestMethod_InquiryInspection: '질문&관찰',
  TOC_TestMethod_Observation: '문서',
  TOC_TestMethod_Reperformance: '재수행',
  TOC_Population: '모집단 정의',
  TOC_Period: '모집단 대상기간',
  TOC_Completeness: '모집단 완전성 검토',
  TOC_PopulationCount: '모집단 샘플수',
  TOC_SamplingCount: '샘플링 개수',
  TOC_TestProperties: '평가대상 항목',
  TOC_Exception: '예외사항',
  TOC_Evidence: '테스트 증빙',
  TOC_EvidenceSize: '증거자료크기',
  TOC_Procedure: '테스트 절차',
  TOC_Owner: '테스트 수행자',
  TOC_Department: '테스트 검토자',
  evaluationControlOwner: '통제 담당자',
  inchargeInRCM: 'RCM 상 통제 책임자',
  ownerInRCM: 'RCM 상 통제 담당자'
}

const OE_KO_ATTRIBUTES: Record<string, string> = {
  number: '통제번호',
  period: '대상기간',
  executor: '통제 평가자',
  performer: '통제 평가자',
  createDate: '작성일자',
  updateTime: '수정일자',
  populationCount: '모집단 항목수',
  validCount: '유효 항목수',
  invalidCount: '비유효 항목수',
  testResult: '운영 테스트결과',
  populationDescription: '모집단 파일 및 설명',
  populationCompleteness: '모집단의 완전성 확인',
  samplingMethod: '샘플링 방법',
  samplingCount: '샘플링 항목수',
  populationTableColumnCount: '항목당 속성수',
  samplingAttributeCount: '테스트 속성수',
  controlException: '식별된 예외사항 기재',
  // Evaluation state
  request: '수행요청',
  unperformed: '미수행',
  performing: '수행중',
  performed: '수행완료',
  rejected: '재작성필요',
  approved: '승인완료',
  exported: '엑셀 다운로드'
}

const DE_KO_ATTRIBUTES: Record<string, string> = {}

function getControlKoName(key: string): string {
  let name = _.get(CONTROL_KO_ATTRIBUTES, key)
  if (name) {
    return name
  }
  name = _.get(OE_KO_ATTRIBUTES, key)
  if (name) {
    return name
  }

  name = _.get(DE_KO_ATTRIBUTES, key)
  return name || ''
}

function getCommonKoName(key: string): string {
  const COMMON_KO_ATTRIBUTES = {
    cycleName: '상위프로세스',
    categoryName: '중위프로세스',
    subCategoryName: '하위프로세스',
    processCode: 'No.하위프로세스',
    fileName: '파일명',
    type: '종류',
    updater: '작성자',
    updateTime: '작성일자'
  }
  return _.get(COMMON_KO_ATTRIBUTES, key)
}

function getRiskKoName(key: string): string {
  const RISK_KO_ATTRIBUTES: Record<string, string> = {
    id: '위험 코드',
    name: '위험명',
    RoMM: '재무제표 왜곡표시 위험',
    inherentRiskLevel: '고유위험'
  }
  return _.get(RISK_KO_ATTRIBUTES, key)
}

export function getKoName(superKey: 'common' | 'risk' | 'control', key: string): string {
  let name
  if (superKey === 'risk') {
    name = getRiskKoName(key)
  } else if (superKey === 'control') {
    name = getControlKoName(key)
  }
  return name || getCommonKoName(key)
}

export function getLevelName(key: UserLevel): string {
  if (key === UserLevel.ADMIN) {
    return '관리자'
  }
  if (key === UserLevel.NORMAL) {
    return '통제 평가자'
  }
  if (key === UserLevel.UPLOADER) {
    return '통제 담당자'
  }
  if (key === UserLevel.WATCHER) {
    return '감사인'
  }
  return key
}
