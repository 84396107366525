import { Backdrop, CircularProgress, makeStyles, Theme } from '@material-ui/core'
import Modal from 'antd/lib/modal'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import { EvaluationType, getKoEvaluation } from '../../../base/data/Evaluation'
import { Control } from '../../../base/data/PRC'
import { sliceTreeId } from '../../../base/utils/TreeUtils'
import { getControls } from '../../../dataLoader/DataLoader'
import { evaluationCreate } from '../../../dataLoader/Evaluation/evaluation'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import { BASE_ITEM } from '../../common/DividerSelect'
import {
  EvaluationSelectAttribute,
  getShortEvalType,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import CreationContent from './CreationContent'
import CreationHeader from './CreationHeader'

const SPECIAL_CHARACTERS_REG = /[.]/

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: 0
  }
}))

function InitNewDocument(document: any, setDocument: any): void {
  const date = new Date(Date.now())
  setDocument({
    ...document,
    name: '',
    startDate: `${date.getFullYear()}-1-1`,
    endDate: `${date.getFullYear()}-12-31`,
    loadedControls: [] as string[],
    selectedControls: [] as string[]
  })
}

interface Props {
  type: EvaluationType
}

const EvaluationCreationView: React.FC<Props> = ({ type }) => {
  const classes = useStyles()
  const [document, setDocument] = React.useState({
    name: '',
    startDate: '',
    endDate: '',
    controlIds: [] as string[],
    selectedControls: [] as string[]
  })
  const [controls, setControls] = React.useState({} as Record<string, Control>)
  const [loading, setLoading] = React.useState(false)
  const [disabledMessage, setDisabledMessage] = React.useState(
    `생성할 ${getKoEvaluation(type)}명을 입력해주세요.`
  )
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: BASE_ITEM,
    names: [],
    summaries: [],
    evaluationDetails: []
  })
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const [cookies, setCookie] = useCookies([`${type}EvaluationName`])

  React.useEffect(() => {
    getControls().then((newControls) => {
      const controlDict: Record<string, Control> = {}
      _.forEach(newControls, (newControl) => {
        controlDict[newControl.id] = newControl
      })
      setControls(controlDict)
    })
  }, [])

  React.useEffect(() => {
    InitNewDocument(document, setDocument)
    loadEvaluationNames(type, undefined, select, setSelect)
  }, [type])

  // Update disabled message
  React.useEffect(() => {
    let message = ''
    if (_.isEmpty(document.name)) {
      message = `생성할 ${getKoEvaluation(type)}명을 입력해주세요.`
    } else if (SPECIAL_CHARACTERS_REG.test(document.name)) {
      message = `${getKoEvaluation(type)}명에 .은 사용하실 수 없습니다.`
    } else if (_.includes(select.names, document.name)) {
      message =
        `${getKoEvaluation(type)}명을 변경하여 신규로 만들 용도로만 사용가능합니다. ` +
        `기존 ${getKoEvaluation(type)}의 항목에 대한 수정 삭제는 불가능 합니다.`
    } else if (_.isEmpty(document.selectedControls)) {
      message = 'Control을 선택해주세요.'
    } else {
      const selectedIds = _.map(document.selectedControls, (treeId) => {
        return sliceTreeId(Object.keys(controls), treeId)
      })
      if (!_.every(selectedIds, Boolean)) {
        message = '존재하지 않는 Control이 포함되어 있습니다.'
      }
    }

    setDisabledMessage(message)
  }, [document.name, document.selectedControls])

  const createDocument = React.useCallback((): Promise<any> | undefined => {
    setLoading(true)

    const selectedIds = _.map(document.selectedControls, (treeId) => {
      return sliceTreeId(Object.keys(controls), treeId)
    })
    if (!_.every(selectedIds)) {
      alertRef.current?.showAlert('error', `${getKoEvaluation(type)} 생성에 실패했습니다.`)
      setLoading(false)
      return
    }

    const newDocument = {
      evalType: getShortEvalType(type),
      evalName: document.name,
      startDate: document.startDate,
      endDate: document.endDate,
      controlIdList: selectedIds
    }
    return evaluationCreate(newDocument)
      .then(() => {
        InitNewDocument(document, setDocument)
        setCookie(`${type}EvaluationName`, document.name)
        loadEvaluationNames(type, undefined, select, setSelect)
        alertRef.current?.showAlert('success', `${getKoEvaluation(type)}가 생성되었습니다.`)
      })
      .catch((e) => {
        console.log('Failed to create operation evaluations', e)
        alertRef.current?.showAlert('error', `${getKoEvaluation(type)} 생성에 실패했습니다.`)
      })
      .finally(() => setLoading(false))
  }, [document])

  const onCreateDocument = (): void => {
    Modal.confirm({
      title: `${getKoEvaluation(type)} 생성`,
      content: (
        <Typography.Text>
          한번 개설된 {getKoEvaluation(type)}의 항목은 추가, 수정, 삭제가 불가능합니다. 확인후
          진행해주세요.
        </Typography.Text>
      ),
      okText: '생성',
      cancelText: '취소',
      onOk: () => createDocument()
    })
  }

  const onEvaluationSelect = React.useCallback(
    (name: string): void => {
      setSelect({ ...select, selectedName: name })
      if (_.isEqual(name, BASE_ITEM)) {
        const date = new Date(Date.now())
        setDocument({
          ...document,
          name: '',
          startDate: `${date.getFullYear()}-1-1`,
          endDate: `${date.getFullYear()}-12-31`,
          controlIds: [] as string[],
          selectedControls: [] as string[]
        })
      } else {
        const selectedDocument = _.find(select.summaries, { evalName: name })
        if (selectedDocument) {
          setDocument({
            ...document,
            name,
            startDate: selectedDocument.startDate,
            endDate: selectedDocument.endDate,
            controlIds: selectedDocument.controlIdList,
            selectedControls: [] as string[]
          })
        }
      }
    },
    [select, document]
  )

  const onChangeCalendar = React.useCallback(
    (startDate: string, endDate: string): void => {
      if (startDate === '' || endDate === '') {
        const date = new Date(Date.now())
        setDocument({
          ...document,
          startDate: `${date.getFullYear()}-1-1`,
          endDate: `${date.getFullYear()}-12-31`
        })
      } else {
        setDocument({ ...document, startDate, endDate })
      }
    },
    [document]
  )

  const changeDocumentName = React.useCallback(
    (value: string): void => {
      setDocument({ ...document, name: value })
    },
    [document]
  )

  const onChangeTreeKeys = React.useCallback(
    (keys: string[]): void => {
      setDocument({ ...document, selectedControls: keys })
    },
    [document]
  )

  return (
    <div className={classes.root}>
      <CreationHeader
        changeDocumentName={changeDocumentName}
        createLoading={loading}
        disabledMessage={disabledMessage}
        documentName={document.name}
        endDate={document.endDate}
        evaluationSelect={{ ...select, onSelect: onEvaluationSelect }}
        startDate={document.startDate}
        type={type}
        onChangeCalendar={onChangeCalendar}
        onCreateDocument={onCreateDocument}
      />
      <CreationContent
        targetControls={document.controlIds}
        treeKeys={document.selectedControls}
        onChangeTreeKeys={onChangeTreeKeys}
      />
      <CircleBackdrop open={loading} />
      <AlertMessage ref={alertRef} />
    </div>
  )
}

export default EvaluationCreationView
