import { makeStyles, Theme, Typography } from '@material-ui/core'
import { Button, Popover } from 'antd'
import Select from 'antd/lib/select'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import { lightGrey } from '../../../base/color'
import { EvaluationType, getKoEvaluation, Evaluation } from '../../../base/data/Evaluation'
import { PRCType } from '../../../base/data/PRC'
import { Staff } from '../../../base/data/Staff'
import { permutations } from '../../../base/utils/Permutations'
import { convertToDataNodes, getExplorerItem, PRCDataNode } from '../../../base/utils/TreeUtils'
import { GetCycles, GetDepartments, GetStaffs } from '../../../dataLoader/DataLoader'
import { evaluationDownloadTemplateFiles } from '../../../dataLoader/Evaluation/evaluation'
import { TreeItem } from '../../../dataLoader/TreeDataLoader'
import DividerSelect, { BASE_ITEM } from '../../common/DividerSelect'
import TableItem from '../../common/TableItem'
import RCMDirectory from '../../rcm/common/RCMDirectory'
import {
  EvaluationSelectAttribute,
  getEvaluationNamesWithDate,
  getShortEvalType,
  loadEvaluationDetails,
  loadEvaluationNames
} from '../common/EvaluationUtils'
import EvaluationTable from '../view/EvaluationTable'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0
  },
  dividerSelect: {
    margin: theme.spacing(1)
  },
  setting: {
    border: lightGrey.border,
    margin: theme.spacing(1)
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexBasis: 0,
    minHeight: 0,
    height: 400
  },
  explorer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 310,
    width: 310
  },
  tableHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    background: '#f8f8f8',
    border: lightGrey.border,
    borderBottom: '1px solid #babfc7'
  },
  tableContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0,
    height: 0,
    border: lightGrey.border,
    borderTop: '0px'
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 3,
    flexBasis: 0,
    minWidth: 1100,
    width: 900,
    margin: theme.spacing(1)
  },
  select: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
}))

const DOWNLOAD_WARNING = '항목 탐색기와 동일한 폴더 구조로 다운됩니다. 폴더 구조 선택 유의.'

enum Folder {
  cycle = 'cycle',
  owner = 'owner',
  performer = 'performer'
}

const KO_FOLDER: Record<Folder, string> = {
  cycle: '대분류',
  owner: '통제 담당자',
  performer: '통제 평가자'
}

const FOLDER_STRUCTURES = [
  ...permutations([Folder.cycle, Folder.owner, Folder.performer], 1),
  ...permutations([Folder.cycle, Folder.owner, Folder.performer], 2),
  ...permutations([Folder.cycle, Folder.owner, Folder.performer], 3)
]

const DATA_STRING_TO_TYPE: { [id: string]: string } = {
  template: '1',
  uploaded: '2',
  both: '3'
}

function filterExplorerItems(items: TreeItem[], evaluations: Evaluation[]): TreeItem[] {
  const newItems: TreeItem[] = []
  _.forEach(items, (item) => {
    if (item.children) {
      const children = filterExplorerItems(item.children, evaluations)
      if (!_.isEmpty(children)) {
        newItems.push({
          ...item,
          children
        })
      }
    } else if (item.type === PRCType.control && _.find(evaluations, { controlId: item.id })) {
      newItems.push(item)
    }
  })
  return newItems
}

function convertFilterDataNode(
  explorerItems: TreeItem[],
  evaluationDetails: Evaluation[] | undefined
): PRCDataNode[] {
  if (!evaluationDetails || _.isEmpty(evaluationDetails)) {
    return []
  }
  return convertToDataNodes(
    filterExplorerItems(explorerItems, evaluationDetails),
    'control',
    'both'
  )
}

function hasUploadedData(evaluations: Evaluation[]): boolean {
  return (
    !_.isEmpty(evaluations) &&
    _.some(evaluations, (evaluation) => {
      if (evaluation.type === 'operation') {
        return (
          !_.isEmpty(evaluation.data.content.files?.recordFile?.fileName) ||
          !_.isEmpty(evaluation.data.content.files?.populationFile?.fileName) ||
          !_.isEmpty(evaluation.data.content.files?.evidenceFile) ||
          !_.isEmpty(evaluation.data.content.files?.extraFile) ||
          evaluation.data.state.state === '5' // 승인완료시 보고서 readonly 다운로드
        )
      }
      return (
        !_.isEmpty(evaluation.data.content.files?.recordFile?.fileName) ||
        !_.isEmpty(evaluation.data.content.files?.extraFile)
      )
    })
  )
}

interface Props {
  type: EvaluationType
  active: boolean
}

const TemplateDownload: React.FC<Props> = ({ type, active }) => {
  const classes = useStyles()
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: BASE_ITEM,
    names: [],
    summaries: [],
    evaluationDetails: []
  })
  const [states, setStates] = React.useState({
    loading: false,
    folderStructure: [Folder.cycle],
    originalExplorerItems: [] as TreeItem[],
    explorerItems: [] as PRCDataNode[],
    checkedData: [] as Evaluation[]
  })
  const [downloadLoading, setDownloadLoading] = React.useState({
    template: false,
    uploaded: false,
    both: false
  })
  const [data, setData] = React.useState({
    cycles: [] as any,
    departments: [] as any,
    staffs: [] as Staff[]
  })
  const [cookies, setCookie, removeCookie] = useCookies([`${type}EvaluationName`])

  React.useEffect(() => {
    Promise.all([GetCycles(), GetDepartments(), GetStaffs()]).then((values) => {
      const [cycles, departments, staffs] = values
      setData({
        cycles,
        departments,
        staffs
      })
    })
  }, [])

  React.useEffect(() => {
    if (active) {
      loadEvaluationNames(type, cookies[`${type}EvaluationName`], select, setSelect)
      setStates({
        ...states,
        folderStructure: [Folder.cycle],
        originalExplorerItems: [],
        explorerItems: [],
        checkedData: []
      })
    }
  }, [active, type])

  const getExplorerItemWithSelectedCategories = React.useCallback(
    (
      selectedCategories: Folder[]
    ): { originalExplorerItems: TreeItem[]; explorerItems: PRCDataNode[] } => {
      const items = getExplorerItem(
        _.map(selectedCategories, (key) => {
          if (key === Folder.cycle) return 'cycle-대분류'
          return `worker-${_.get(KO_FOLDER, key)}`
        }),
        _.map(select.evaluationDetails, (detail) => {
          // NOTE: Control 데이터에 performer 추가
          return {
            ...detail.data.content.controlData,
            performer: detail.data.state.controlPerformer
          }
        }),
        data.cycles,
        data.departments,
        data.staffs
      )
      return {
        originalExplorerItems: items,
        explorerItems: convertFilterDataNode(items, select.evaluationDetails)
      }
    },
    [data, select]
  )

  React.useEffect(() => {
    setStates({
      ...states,
      loading: false,
      checkedData: [] as Evaluation[],
      ...getExplorerItemWithSelectedCategories(states.folderStructure)
    })
  }, [select.evaluationDetails])

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      setStates({
        ...states,
        explorerItems: [],
        checkedData: []
      })
      loadEvaluationDetails(type, evaluationName, select, setSelect)
      setCookie(`${type}EvaluationName`, evaluationName)
    },
    [states, select]
  )

  const onFolderStructureSelect = React.useCallback(
    (folderStructure: string): void => {
      const folders = _.split(folderStructure, '-') as Folder[]
      setStates({
        ...states,
        folderStructure: folders,
        loading: false,
        checkedData: [] as Evaluation[],
        ...getExplorerItemWithSelectedCategories(folders)
      })
    },
    [states]
  )

  const itemOnChecked = React.useCallback(
    (checkedIds: string[]): void => {
      const checkedData: Evaluation[] = []
      _.forEach(checkedIds, (controlTreeId) => {
        const controlIds = controlTreeId.split('/')
        const checked = _.find(select.evaluationDetails, { controlId: _.last(controlIds) })
        if (checked) {
          checkedData.push(checked)
        }
      })
      setStates({ ...states, checkedData })
    },
    [states, select]
  )

  const downloadEvaluations = React.useCallback(
    (downloadType: string): void => {
      setDownloadLoading({
        ...downloadLoading,
        [downloadType]: true
      })
      evaluationDownloadTemplateFiles(
        getShortEvalType(type),
        select.selectedName,
        states.originalExplorerItems,
        DATA_STRING_TO_TYPE[downloadType],
        _.map(states.checkedData, (item) => item.controlId)
      )
        .then(() => {
          setDownloadLoading({
            ...downloadLoading,
            [downloadType]: false
          })
        })
        .catch((e) => {
          console.log('Failed to download ', e)
          setDownloadLoading({
            ...downloadLoading,
            [downloadType]: false
          })
        })
    },
    [downloadLoading, select, states]
  )

  return (
    <div className={classes.root}>
      <div className={classes.dividerSelect}>
        <DividerSelect
          defaultName={`${getKoEvaluation(type)} 선택`}
          defaultValue={BASE_ITEM}
          loading={select.loading}
          names={getEvaluationNamesWithDate(select.summaries)}
          value={select.selectedName}
          values={select.names}
          onChange={(value) => onEvaluationSelect(value as string)}
        />
      </div>
      <div className={classes.setting}>
        <TableItem noDivider minHeight={48} name="폴더구조 선택">
          <Select
            className={classes.select}
            defaultValue={Folder.cycle}
            disabled={select.selectedName === BASE_ITEM}
            style={{ width: 300 }}
            onChange={(value) => onFolderStructureSelect(value)}
          >
            {_.map(FOLDER_STRUCTURES, (folders) => {
              const folderStr = _(folders)
                .map((value) => _.get(KO_FOLDER, value))
                .join('-')
              const key = _.join(folders, '-')
              return (
                <Select.Option key={key} value={key}>
                  {folderStr}
                </Select.Option>
              )
            })}
          </Select>
        </TableItem>
      </div>
      <div className={classes.content}>
        <div className={classes.explorer}>
          <RCMDirectory
            RCMItems={states.explorerItems}
            isReady={select.loading || states.loading}
            itemOnChecked={itemOnChecked}
          />
        </div>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <Typography style={{ fontWeight: 'bold', marginLeft: 8 }} variant="subtitle1">
              선택된 항목
            </Typography>
            {type === 'operation' && (
              <Typography style={{ marginLeft: 8 }}>
                (승인완료된 통제의 경우, 작성된 보고서가 포함되어 다운로드 됩니다.)
              </Typography>
            )}
            <div style={{ flexGrow: 1 }} />
            {type === 'operation' && (
              <Popover content={DOWNLOAD_WARNING} title="주의">
                <Button
                  className={classes.button}
                  disabled={_.isEmpty(states.checkedData) || _.isEmpty(select.selectedName)}
                  loading={downloadLoading.template}
                  onClick={() => downloadEvaluations('template')}
                >
                  템플릿 다운로드
                </Button>
              </Popover>
            )}
            <Button
              className={classes.button}
              disabled={!hasUploadedData(states.checkedData) || _.isEmpty(select.selectedName)}
              loading={downloadLoading.uploaded}
              onClick={() => downloadEvaluations('uploaded')}
            >
              업로드 자료 다운로드
            </Button>
            {type === 'operation' && (
              <Popover content={DOWNLOAD_WARNING} title="주의">
                <Button
                  className={classes.button}
                  disabled={!hasUploadedData(states.checkedData) || _.isEmpty(select.selectedName)}
                  loading={downloadLoading.both}
                  onClick={() => downloadEvaluations('both')}
                >
                  모두 다운로드
                </Button>
              </Popover>
            )}
          </div>
          <div className={classes.tableContent}>
            <EvaluationTable
              noBorder
              evaluationDetails={states.checkedData}
              evaluationType={type}
              loading={select.loading || states.loading}
              staffs={data.staffs}
              type="template"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateDownload
