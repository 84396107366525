import { makeStyles, Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Transfer, Tree } from 'antd'
import { TransferItem } from 'antd/lib/transfer'
import { DataNode } from 'antd/lib/tree'
import _ from 'lodash'
import React from 'react'

import { getCheckedLeaf } from '../../base/utils/TreeUtils'
import { BlueLinearProgress } from './BlueProgress'

const useStyles = makeStyles((theme: Theme) => ({
  transfer: {
    '& .ant-transfer-list-header-dropdown': {
      // visibility: 'hidden'
    },
    '& .ant-transfer-list': {
      flexGrow: 1,
      flexBasis: 0
    },
    '& .ant-transfer-list-content-item': {
      alignItems: 'baseline'
    },
    '& .ant-transfer-list-content-item-text': {
      whiteSpace: 'normal'
    }
  }
}))

const generateTree = (treeNodes: DataNode[] = [], checkedKeys: string[] = []): any =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: checkedKeys.includes(props.key as string),
    children: generateTree(children, checkedKeys)
  }))

interface Props {
  dataSource: DataNode[]
  allSelectedKeys: string[]
  targetKeys: string[]
  expandedKeys: (string | number)[]
  height: number
  onSelect: (selectedKeys: string[]) => void
  onChange: (key: string[]) => void
  treeOnExpand: (keys: (string | number)[]) => void
}

const TreeTransfer: React.FC<Props> = ({
  dataSource,
  allSelectedKeys,
  targetKeys,
  expandedKeys,
  height,
  onChange,
  onSelect,
  treeOnExpand
}) => {
  const classes = useStyles()
  const transferDataSource: TransferItem[] = []
  function flatten(list: DataNode[] = []): void {
    list.forEach((item: DataNode): void => {
      transferDataSource.push(item as TransferItem)
      flatten(item.children)
    })
  }
  flatten(dataSource)

  return (
    <Transfer
      className={classes.transfer}
      dataSource={transferDataSource}
      render={(item: TransferItem): any => item.title}
      selectedKeys={allSelectedKeys}
      style={{ height }}
      targetKeys={targetKeys}
      onChange={onChange}
      onSelectChange={(source, target) => onSelect([...source, ...target])}
    >
      {({ direction, onItemSelectAll, selectedKeys }) => {
        if (direction === 'left') {
          const treeData = generateTree(dataSource, targetKeys)
          const checkedKeys = [...selectedKeys, ...targetKeys]
          return (
            <>
              {dataSource.length ? (
                <Tree
                  blockNode
                  checkable
                  showIcon
                  checkedKeys={checkedKeys}
                  expandedKeys={expandedKeys}
                  height={height - 42}
                  selectable={false}
                  treeData={treeData}
                  onCheck={(checked, node) => {
                    const checkedLeafKeys = getCheckedLeaf(treeData, checked)
                    const unSelectKeys = _.difference(
                      _.difference(selectedKeys, checkedLeafKeys),
                      targetKeys
                    )
                    const selectKeys = _.difference(
                      _.difference(checkedLeafKeys, selectedKeys),
                      targetKeys
                    )
                    if (!_.isEmpty(selectKeys) && !_.isEmpty(unSelectKeys)) {
                      // eslint-disable-next-line no-console
                      console.log('This cannot happen')
                    }
                    if (unSelectKeys) {
                      onItemSelectAll(unSelectKeys, false)
                    }
                    if (selectKeys) {
                      onItemSelectAll(selectKeys, true)
                    }
                  }}
                  onExpand={treeOnExpand}
                />
              ) : (
                <BlueLinearProgress />
              )}
            </>
          )
        }
      }}
    </Transfer>
  )
}

export default TreeTransfer
