import 'firebaseui/dist/firebaseui.css'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import React from 'react'

import { FirebaseLogin } from './FirebaseLogin'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    background: `linear-gradient(to left, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    maxWidth: 800,
    overflow: 'hidden'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 500
  },
  cardContent: {
    width: '100%',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 480
  },
  login: {
    width: '100%',
    maxWidth: 200,
    textTransform: 'none',
    fontWeight: 600
  },
  header: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(10),
    alignItems: 'center',
    justifyContent: 'center'
  },
  firebase: {
    width: '100%',
    height: '100%',
    '& .firebaseui-container': {
      boxShadow: 'none'
    }
  },
  meereLogo: {
    display: 'block',
    width: 190,
    height: 20
  },
  tmonCrop: {
    overflow: 'hidden',
    maxWidth: 120,
    maxHeight: 50
  },
  tmonLogo: {
    display: 'block',
    maxWidth: 'initial',
    maxHeight: 'initial',
    width: 120,
    height: 120,
    marginTop: '-40%',
    marginLeft: '-10%'
  }
}))

const Login: React.FC = () => {
  const classes = useStyles()
  const logoImgContainer = React.useMemo(() => {
    if (process.env.REACT_APP_IMAGE_DIR === 'meere') {
      return (
        <img
          className={classes.meereLogo}
          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo_white.svg`}
          alt="logo"
        />
      )
    } else if (process.env.REACT_APP_IMAGE_DIR === 'tmon') {
      return (
        <div className={classes.tmonCrop}>
          <img
            className={classes.tmonLogo}
            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo.png`}
            alt="logo"
          />
        </div>
      )
    }
    return undefined
  }, [])

  React.useEffect(() => {
    FirebaseLogin('#firebaseui')
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.header}>
          <div style={{ width: '100% ' }}>
            <Typography color="inherit" style={{ lineHeight: 1.25, fontWeight: 800 }} variant="h3">
              ICFR
            </Typography>

            {!logoImgContainer && (
              <Typography className="mt-32" color="inherit" variant="subtitle1">
                CCK Solution
              </Typography>
            )}
            {logoImgContainer}
          </div>
        </div>

        <Card square className={classes.main} elevation={0}>
          <CardContent className={classes.cardContent}>
            <div className={classes.firebase} id="firebaseui" />
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default Login
