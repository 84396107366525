import {
  FolderOutlined,
  FolderOpenOutlined,
  FilePptOutlined,
  WarningOutlined,
  CheckOutlined
} from '@ant-design/icons'
import { DataNode } from 'antd/lib/tree'
import _ from 'lodash'
import React from 'react'

import { getDataTree, TreeItem, TreeKeyType } from '../../dataLoader/TreeDataLoader'
import { BasePRC, PRCType } from '../data/PRC'
import { Staff } from '../data/Staff'

declare type Key = string | number

export interface PRCDataNode extends Omit<DataNode, 'children'> {
  type: string
  id?: string
  children?: PRCDataNode[]
}

const checkControlOption = (item: TreeItem, controlOption: string): boolean => {
  if (item.type !== 'control') {
    return true
  }
  let keyControl = false
  if (item.keyControl) {
    keyControl = item.keyControl
  }
  return (
    controlOption === 'both' ||
    (controlOption === 'key control' && keyControl) ||
    (controlOption === 'except key control' && !keyControl)
  )
}

function getExpandLevels(data: PRCDataNode[], expandedKeys: Key[]): number {
  const level = _.max(
    _.map(data, (item: PRCDataNode): number => {
      if (_.includes(expandedKeys, item.key) && item.children) {
        return getExpandLevels(item.children, expandedKeys) + 1
      }
      return 0
    })
  )
  return level || 0
}

function getKeysUntilLevel(data: PRCDataNode[], level: number): string[] {
  if (level <= 0) {
    return []
  }

  const keys = Array<string>(0)
  _.forEach(data, (item: PRCDataNode) => {
    keys.push(item.key as string)
    if (item.children) {
      keys.push(...getKeysUntilLevel(item.children, level - 1))
    }
  })
  return keys
}

export function expandTree(data: PRCDataNode[], expandedKeys: Key[]): string[] {
  const level = getExpandLevels(data, expandedKeys)
  return getKeysUntilLevel(data, level + 1)
}

export function collapseTree(data: PRCDataNode[], expandedKeys: Key[]): string[] {
  const level = getExpandLevels(data, expandedKeys)
  return getKeysUntilLevel(data, level - 1)
}

export const getTreeIcon = (
  expanded: boolean | undefined,
  type: string | undefined
): React.ReactElement => {
  if (type === 'process') {
    return <FilePptOutlined />
  } else if (type === 'risk') {
    return <WarningOutlined />
  } else if (type === 'control') {
    return <CheckOutlined />
  }
  return expanded ? <FolderOpenOutlined /> : <FolderOutlined />
}

export function convertToDataNodes(
  items: TreeItem[],
  leafType: string,
  controlOption: string
): PRCDataNode[] {
  const convertToDataNode = (item: TreeItem, prefixId: string): PRCDataNode => {
    const node: PRCDataNode = {
      title: item.name ? item.name : item.id,
      key: prefixId + '/' + item.id,
      icon: ({ expanded }) => getTreeIcon(expanded, ''),
      type: item.type
    }

    if (item.children) {
      for (let i = 0; i < item.children?.length; ++i) {
        if (!node.children) {
          node.children = []
        }
        if (checkControlOption(item.children[i], controlOption)) {
          node.children.push(convertToDataNode(item.children[i], prefixId + '-' + i))
        }
      }
    }

    if (_.includes(['process', 'risk', 'control'], item.type)) {
      node.id = item.id
      if (item.type === 'control' && item.keyControl) {
        node.title = `${item.id} (핵심)`
      } else {
        node.title = item.id
      }
      node.icon = getTreeIcon(false, item.type)
    }
    return node
  }

  const converted: PRCDataNode[] = []
  for (let i = 0; i < items.length; ++i) {
    converted.push(convertToDataNode(items[i], i.toString()))
  }
  return [
    {
      key: 'root0',
      title: leafType,
      icon: ({ expanded }) => getTreeIcon(expanded, ''),
      children: converted,
      type: 'root'
    }
  ]
}

export function getExplorerItem(
  keys: string[],
  data: BasePRC[],
  cycles: any[],
  departments: any[],
  staffs: Staff[]
): TreeItem[] {
  if (_.isEmpty(data)) return []

  const keyList: TreeKeyType[] = _.compact(
    keys.map((key) => {
      if (key === 'cycle-대분류') {
        return 'cycle'
      } else if (key === 'cycle-중분류') {
        return 'category'
      } else if (key === 'cycle-소분류') {
        return 'subCategory'
      } else if (key === 'worker-부서') {
        return 'department'
      } else if (key === 'worker-통제 담당자') {
        return 'owner'
      } else if (key === 'worker-통제 평가자') {
        return 'performer'
      }
      return undefined
    })
  )
  return getDataTree(keyList, data, cycles, departments, staffs)
}

function sortExplorerItem(items: TreeItem[]): void {
  items.sort((a, b) => {
    if (!_.isNaN(_.toNumber(a.id)) && !_.isNaN(_.toNumber(b.id))) {
      return _.toNumber(a.id) - _.toNumber(b.id)
    }
    const aKeys = a.id.split('-')
    const bKeys = b.id.split('-')

    for (let i = 0; i < aKeys.length && i < bKeys.length; ++i) {
      const diff = _.toNumber(aKeys[i]) - _.toNumber(bKeys[i])
      if (!_.isNaN(diff) && diff !== 0) {
        return diff
      }
    }
    return 0
  })
}

export function loadRCMTree(
  type: PRCType,
  keys: string[],
  controlOption: string,
  data: BasePRC[],
  cycles: any[],
  departments: any[],
  staffs: Staff[]
): PRCDataNode[] {
  const items = getExplorerItem(keys, data, cycles, departments, staffs)
  sortExplorerItem(items)
  return convertToDataNodes(items, type, controlOption)
}

export function getCheckedLeaf(
  dataNodes: PRCDataNode[],
  checked: { checked: (string | number)[]; halfChecked: (string | number)[] } | (string | number)[]
): string[] {
  const getCheckedLeafInner = (items: PRCDataNode[], checkedIds: string[]): string[] => {
    const ids: string[] = []
    _.forEach(items, (item: PRCDataNode) => {
      if (item.children && !_.isEmpty(item.children)) {
        ids.push(...getCheckedLeafInner(item.children, checkedIds))
      } else if (_.includes(checkedIds, item.key)) {
        ids.push(item.key as string)
      }
    })
    return ids
  }

  const ids: string[] = []
  _.forEach(checked, (item) => {
    if (typeof item !== 'string') {
      // eslint-disable-next-line no-console
      console.log('Wrong id type')
      return
    }
    ids.push(item as string)
  })

  return getCheckedLeafInner(dataNodes, ids)
}

export function findLeafNodes(data: PRCDataNode[], ids: string[]): PRCDataNode[] {
  const newData: PRCDataNode[] = []
  _.forEach(data, (item) => {
    if (_.includes(ids, item.id)) {
      newData.push(item)
    } else if (item.children) {
      newData.push(...findLeafNodes(item.children, ids))
    }
  })
  return newData
}

export function getRCMLeafKeys(data: PRCDataNode[]): string[] {
  const keys: string[] = []
  _.forEach(data, (item) => {
    if (item.id) {
      keys.push(item.key as string)
    } else if (item.children) {
      keys.push(...getRCMLeafKeys(item.children))
    }
  })
  return keys
}

export function sortKeysByTreeOrder(treeData: PRCDataNode[], keys: string[]): string[] {
  const newKeys: string[] = []
  _.forEach(treeData, (item) => {
    if (item.id && _.includes(keys, item.key)) {
      newKeys.push(item.key as string)
    } else if (item.children) {
      newKeys.push(...sortKeysByTreeOrder(item.children, keys))
    }
  })
  return newKeys
}

export function sortLeafKeys(keys: string[]): void {
  keys.sort((a, b): number => {
    const indexA = a.indexOf('/')
    const indexB = b.indexOf('/')
    if (indexA === -1 && indexB === -1) {
      return a < b ? -1 : a > b ? 1 : 0
    } else if (indexA === -1) {
      return -1
    } else if (indexB === -1) {
      return 1
    }
    const treeKeyA = a.slice(0, indexA)
    const treeKeyB = b.slice(0, indexB)
    if (treeKeyA !== treeKeyB) {
      const levelsA = treeKeyA.split('-')
      const levelsB = treeKeyB.split('-')
      let i = 0
      do {
        if (levelsA[i] !== levelsB[i]) {
          return parseInt(levelsA[i]) - parseInt(levelsB[i])
        }
        i += 1
        if (i === levelsA.length) {
          return -1
        } else if (i === levelsB.length) {
          return 1
        }
      } while (i < levelsA.length && i < levelsB.length)
    }

    const idA = a.slice(indexA + 1)
    const idB = b.slice(indexB + 1)
    return idA < idB ? -1 : idA > idB ? 1 : 0
  })
}

export function sliceTreeId(allIds: string[], treeId: string): string | undefined {
  const index = treeId.indexOf('/')
  if (index !== -1) {
    const id = treeId.slice(index + 1)
    if (_.includes(allIds, id)) {
      return id
    }
  }
  return undefined
}
