import _ from 'lodash'

import { getCurrentTime } from '../utils/TimeUtils'
import { EvaluationType } from './Evaluation'
import { getLevelName } from './Translation'
import { UserLevel } from './User'

export enum State {
  OK = 1,
  // Error
  INVALID_NAME = 2,
  PARSING_ERROR = 3,
  INVALID_DOT = 21,
  INVALID_STRUCTURE = 22,
  INVALID_POPULATION_FILE_EXTENSION = 23,
  NO_PERMISSION = 24,
  // Warning
  UNRELATED_OPERATION_EVAL = 100,
  UNRELATED_FILE = 101,
  ALREADY_EXISTING = 102,
  DIFFERENT_CONTROL_ID = 103,
  // Saved file
  SAVED_FILE = 200,
  // Skip record update for editing record.
  SKIP_RECORD_UPDATE = 201,
  // 승인완료 된 보고서
  NOT_EDITABLE = 300,
  NOT_ALLOWED = 301
}

export const ERROR_STATES: State[] = [
  State.INVALID_NAME,
  State.PARSING_ERROR,
  State.NOT_EDITABLE,
  State.NOT_ALLOWED
]

export const stateToString = (state: State): string => {
  let message: string
  switch (state) {
    case State.OK:
      message = '신규'
      break
    case State.INVALID_NAME:
      message = '잘못된 이름의 파일'
      break
    case State.INVALID_DOT:
      message = '확장자 표시 이외 "." 사용금지'
      break
    case State.INVALID_STRUCTURE:
      message =
        '1. 파일구조 : (업로드대상평가명)(업로드대상통제CODE)(보고서or증빙or모집단or기타 중 하나)파일명.확장자\n2. 괄호 등 파일 이름 규칙 준수 필수'
      break
    case State.INVALID_POPULATION_FILE_EXTENSION:
      message = '모집단 파일은 제공하는 Template 만 업로드 가능'
      break
    case State.NO_PERMISSION:
      message = '권한 없음'
      break
    case State.PARSING_ERROR:
      message = '보고서 파싱 실패'
      break
    case State.UNRELATED_OPERATION_EVAL:
      message = '운영평가 이름이 다름'
      break
    case State.UNRELATED_FILE:
      message = '관련 통제 번호가 운영평가에 없음'
      break
    case State.ALREADY_EXISTING:
      message = '기존 항목 대체'
      break
    case State.DIFFERENT_CONTROL_ID:
      message = '통제 번호가 다름'
      break
    case State.SAVED_FILE:
      message = '저장된 파일'
      break
    case State.NOT_EDITABLE:
      message = '이미 승인된 보고서'
      break
    case State.NOT_ALLOWED:
      message = '수정 권한 없음'
      break
    default:
      message = '에러'
      break
  }
  return message
}

export const stateToColor = (state: State): string => {
  let color = '#616161' // grey
  if (_.includes(ERROR_STATES, state)) {
    color = '#dd2c00' // red
  } else if (state === State.ALREADY_EXISTING) {
    color = '#ff6d00' // orange
  } else if (state === State.OK) {
    color = '#4caf50' // green
  } else if (state === State.SAVED_FILE) {
    color = '#7D3C98' // violet
  }
  return color
}

export interface EvaluationFile {
  key: number
  fileName: string
  fileExtension: string
  evaluationName: string
  controlId: string
  fileType: string
  fileContent: string
  state: State
  file: File
  checked: boolean
}

export interface ServerFile {
  type?: '보고서' | '모집단' | '증빙' | '기타'
  fileName: string
  updateDate: string
  updater: string
  // For evaluation file view
  description?: string
  evaluationType?: EvaluationType
  evaluationName?: string
  controlId?: string
  state?: State
}

// TODO(sangmuk): Use eval file instead of EvaluationFile and ServerFile
export interface EvalFile {
  type?: '보고서' | '모집단' | '증빙' | '기타'
  evaluationType?: 'operation' | 'design'
  fileName: string
  evaluationName: string
  controlId: string
  state: State
  file?: File
  updateDate?: string
  updater?: string
  excelData?: any
  excelException?: any
}

export function convertToServerFile(file: EvalFile): ServerFile {
  // TODO(sangmuk): add updater.
  return {
    type: file.type,
    fileName: file.fileName,
    updateDate: getCurrentTime(),
    updater: '',
    evaluationName: file.evaluationName,
    controlId: file.controlId
  }
}

export function formatBytes(bytes: number, decimals: number): string {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
