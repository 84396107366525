import _ from 'lodash'

import { BasePRC, Control, Risk } from '../data/PRC'

export function CompareProcess(a: BasePRC, b: BasePRC): number {
  if (a.cycleNumber !== b.cycleNumber) {
    return parseInt(a.cycleNumber) - parseInt(b.cycleNumber)
  } else if (a.categoryNumber !== b.categoryNumber) {
    return parseInt(a.categoryNumber) - parseInt(b.categoryNumber)
  }
  return parseInt(a.subCategoryNumber) - parseInt(b.subCategoryNumber)
}

export function CompareRisks(a: Risk, b: Risk): number {
  const isEmptyIds = (item: Risk): boolean => {
    return _.isEmpty(item.processIds) || _.isEmpty(item.controlIds)
  }

  if (isEmptyIds(a) && !isEmptyIds(b)) return -1
  else if (!isEmptyIds(a) && isEmptyIds(b)) return 1
  return CompareProcess(a, b)
}

export function CompareControls(a: Control, b: Control): number {
  const isEmptyIds = (item: Control): boolean => {
    return _.isEmpty(item.riskIds) || _.isEmpty(item.owner)
  }

  if (isEmptyIds(a) && !isEmptyIds(b)) return -1
  else if (!isEmptyIds(a) && isEmptyIds(b)) return 1
  return CompareProcess(a, b)
}

export function compareProcessIds(a: { id: string }, b: { id: string }): number {
  const processRegex = /^([0-9]+)-([0-9]+)-([0-9]+)\.([0-9]+)$/g
  const matchedA = processRegex.exec(a.id)
  const matchedB = processRegex.exec(b.id)
  if (!matchedA || !matchedB) return 0

  for (let i = 1; i < matchedA.length; ++i) {
    if (matchedA[i] !== matchedB[i]) return parseInt(matchedA[i]) - parseInt(matchedB[i])
  }
  return 0
}

export function compareIds(a: { id: string }, b: { id: string }): number {
  const numberA = parseInt(_.last(_.split(a.id, '.')) as string)
  const numberB = parseInt(_.last(_.split(b.id, '.')) as string)
  return numberA - numberB
}
