import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    flex: '1 1 0%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.2rem'
  },
  text: {
    fontSize: '2rem',
    marginBottom: '1.6rem',
    color: 'grey'
  },
  progress: {
    width: '32rem',
    maxWidth: '100%'
  },
  colorPrimary: {
    backgroundColor: '#33c2f8'
  },
  barColorPrimary: {
    backgroundColor: '#d0f4fe'
  }
}))

const LoadingPage: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography className={classes.text}>Loading...</Typography>
      <LinearProgress
        className={classes.progress}
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary
        }}
      />
    </div>
  )
}

export default LoadingPage
