import { makeStyles, Theme } from '@material-ui/core'
import { Row, Col } from 'antd'
import _ from 'lodash'
import React, { useEffect } from 'react'

import { Control, PRCType, Process, Risk } from '../../../base/data/PRC'
import { Staff } from '../../../base/data/Staff'
import { loadRCMTree, PRCDataNode } from '../../../base/utils/TreeUtils'
import {
  getProcesses,
  getRisks,
  getControls,
  GetStaffs,
  GetCycles,
  GetDepartments
} from '../../../dataLoader/DataLoader'
import RCMDirectory from '../common/RCMDirectory'
import RCMDetail from './detail/RCMDetail'
import ExplorerSetting, { updateCategories } from './setting/ExplorerSetting'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  setting: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap'
  },
  sider: {
    display: 'flex',
    width: 400
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    width: 600,
    minWidth: 0
  }
}))

const RCMViewer: React.FC = () => {
  const classes = useStyles()
  const [settings, setSettings] = React.useState({
    cycle: false,
    worker: false,
    categories: [] as string[],
    treeData: [] as PRCDataNode[],
    treeKeys: [] as string[],
    selectedCategories: [] as string[],
    type: PRCType.process,
    inquireProcessView: false,
    inquireRiskView: false,
    inquireControlView: false,
    controlOption: 'both'
  })
  const [data, setData] = React.useState({
    processes: [] as Process[],
    risks: [] as Risk[],
    controls: [] as Control[],
    cycles: [] as any,
    departments: [] as any,
    staffs: [] as Staff[]
  })

  const [RCMs, setRCMs] = React.useState({
    RCMItems: [] as PRCDataNode[],
    checkedItems: [] as string[],
    isReady: false
  })

  const [mainItems, setMainItems] = React.useState({
    processes: {} as Record<string, Process>,
    risks: {} as Record<string, Risk>,
    controls: {} as Record<string, Control>
  })

  useEffect(() => {
    setRCMs({
      ...RCMs,
      RCMItems: [] as PRCDataNode[],
      checkedItems: [] as string[],
      isReady: !_.isEmpty(settings.categories)
    })
    if (_.isEmpty(settings.categories)) {
      return
    }

    setRCMs({
      ...RCMs,
      checkedItems: [] as string[],
      RCMItems: loadRCMTree(
        settings.type,
        settings.selectedCategories,
        settings.controlOption,
        settings.type === 'process'
          ? data.processes
          : settings.type === 'risk'
          ? data.risks
          : data.controls,
        data.cycles,
        data.departments,
        data.staffs
      )
    })
  }, [settings.categories, settings.selectedCategories, settings.type, settings.controlOption])

  useEffect(() => {
    getControls().then((controls) => {
      Promise.all([
        getProcesses(controls),
        getRisks(controls),
        GetCycles(),
        GetDepartments(),
        GetStaffs()
      ]).then((values) => {
        const [processes, risks, cycles, departments, staffs] = values
        setData({
          processes,
          risks,
          controls,
          cycles,
          departments,
          staffs
        })

        setMainItems({
          ...mainItems,
          processes: _.keyBy(processes, 'id'),
          risks: _.keyBy(risks, 'id'),
          controls: _.keyBy(controls, 'id')
        })
      })
    })
  }, [])

  // RCMSetting
  const typeOnChange = (value: PRCType): void => {
    let updatedState = {}
    if (value !== PRCType.control && settings.worker === true) {
      updatedState = updateCategories(
        settings.cycle,
        settings.worker,
        settings.categories,
        settings.treeData,
        settings.selectedCategories,
        'worker',
        false,
        value
      )
    }

    setSettings({ ...settings, ...updatedState, type: value })
  }

  const controlOptionOnChange = React.useCallback(
    (value: string): void => {
      setSettings({ ...settings, controlOption: value })
    },
    [settings]
  )
  const inquireViewOnChange = React.useCallback(
    (target: string, checked: boolean): void => {
      setSettings({ ...settings, [target]: checked })
    },
    [settings]
  )

  const categoryOnChange = React.useCallback(
    (name: string, checked: boolean): void => {
      const updatedState = updateCategories(
        settings.cycle,
        settings.worker,
        settings.categories,
        settings.treeData,
        settings.selectedCategories,
        name,
        checked
      )
      setSettings({ ...settings, ...updatedState })
    },
    [settings]
  )
  const categoryOnSelect = React.useCallback(
    (selectedCategories: string[]): void => {
      setSettings({ ...settings, selectedCategories })
    },
    [settings]
  )

  // RCMDirectory
  const itemOnChecked = React.useCallback(
    (checkedIds: string[]): void => {
      const selectedItems: string[] = []
      _.forEach(checkedIds, (idStr) => {
        const splitIds = _.split(idStr, '/')
        if (splitIds.length !== 2) {
          // eslint-disable-next-line no-console
          console.log('Wrong tree id', idStr)
          return
        }
        const id = splitIds[1]
        if (settings.type === PRCType.process && _.has(mainItems.processes, id)) {
          selectedItems.push(id)
        } else if (settings.type === PRCType.risk && _.has(mainItems.risks, id)) {
          selectedItems.push(id)
        } else if (settings.type === PRCType.control && _.has(mainItems.controls, id)) {
          selectedItems.push(id)
        }
      })
      setRCMs({
        ...RCMs,
        checkedItems: selectedItems
      })
    },
    [RCMs, settings, mainItems]
  )

  return (
    <div className={classes.root}>
      <Row className={classes.setting}>
        <ExplorerSetting
          categoryOnChange={categoryOnChange}
          categoryOnSelect={categoryOnSelect}
          controlOption={settings.controlOption}
          controlOptionOnChange={controlOptionOnChange}
          cycle={settings.cycle}
          inquireControlView={settings.inquireControlView}
          inquireProcessView={settings.inquireProcessView}
          inquireRiskView={settings.inquireRiskView}
          inquireViewOnChange={inquireViewOnChange}
          selectedCategories={settings.selectedCategories}
          treeData={settings.treeData}
          treeKeys={settings.treeKeys}
          type={settings.type}
          typeOnChange={typeOnChange}
          worker={settings.worker}
        />
      </Row>
      <Row className={classes.content}>
        <Col className={classes.sider}>
          <RCMDirectory
            RCMItems={RCMs.RCMItems}
            isReady={RCMs.isReady}
            itemOnChecked={itemOnChecked}
          />
        </Col>
        <Col className={classes.main}>
          <RCMDetail
            checkedItems={RCMs.checkedItems}
            controlOption={settings.controlOption}
            controls={mainItems.controls}
            inquireControlView={settings.inquireControlView}
            inquireProcessView={settings.inquireProcessView}
            inquireRiskView={settings.inquireRiskView}
            processes={mainItems.processes}
            risks={mainItems.risks}
            staffs={data.staffs}
            type={settings.type}
          />
        </Col>
      </Row>
    </div>
  )
}

export default RCMViewer
