import Snackbar from '@material-ui/core/Snackbar'
import Alert, { Color as AlertColor } from '@material-ui/lab/Alert'
import React from 'react'

type AlertType = AlertColor

export interface AlertMessageHandler {
  showAlert: (alertType: AlertType, alertMessage: string) => void
}

interface Props {
  top?: number
}

const AlertMessage = React.forwardRef<AlertMessageHandler, Props>(({ top }, alertRef) => {
  const [alert, setAlert] = React.useState({
    snackbarOpen: false,
    alertType: 'success' as AlertType,
    alertMessage: ''
  })

  const onSnackbarClose = (event?: React.SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }
    setAlert({ ...alert, snackbarOpen: false })
  }

  React.useImperativeHandle(alertRef, () => ({
    showAlert(alertType: AlertType, alertMessage: string) {
      setAlert({
        ...alert,
        snackbarOpen: true,
        alertType,
        alertMessage
      })
    }
  }))

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      open={alert.snackbarOpen}
      style={{ top }}
      onClose={onSnackbarClose}
    >
      <Alert severity={alert.alertType} onClose={onSnackbarClose}>
        {alert.alertMessage}
      </Alert>
    </Snackbar>
  )
})

export default AlertMessage
