import { blue } from '@ant-design/colors'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import { EvalFile, State } from '../../../base/data/BaseFile'
import { Staff, StaffCellRenderer } from '../../../base/data/Staff'
import { dateCellRenderer } from '../../../base/utils/FileUtils'
import { evaluationParsingInfoUploadFileList } from '../../../dataLoader/Evaluation/evaluation'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CommonAgGrid from '../../common/CommonAgGrid'
import NoticeTextView from '../../common/NoticeTextView'
import UploadIconAndText from '../common/UploadIconAndText'
import { getFileUploaderColumns } from '../view/EvaluationFileUploader'

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: 'WhiteSmoke',
    border: '1px dashed lightgray',
    marginBottom: theme.spacing(1)
  },
  dragActive: {
    borderColor: blue.primary
  }
}))

interface Props {
  evaluationName: string
  controlId: string
  staffs: Staff[]
  evidenceFiles?: EvalFile[]
  setEvidences: (files: EvalFile[]) => void
}

const EvidenceFileUploader: React.FC<Props> = ({
  evaluationName,
  controlId,
  staffs,
  evidenceFiles,
  setEvidences
}) => {
  const classes = useStyles()
  const alertRef = React.useRef<AlertMessageHandler>(null)

  const onDrop = React.useCallback(
    (originalFiles) => {
      const acceptedFiles: File[] = []
      _.forEach(originalFiles, (file) => {
        acceptedFiles.push(new File([file], file.name.normalize('NFC'), { type: file.type }))
      })
      const fileInfos = evaluationParsingInfoUploadFileList('OE', acceptedFiles)
      if (_.every(fileInfos, (fileInfo) => fileInfo.fileType !== '증빙')) {
        alertRef.current?.showAlert('error', '증빙 파일을 업로드해주세요.')
        return
      }
      if (_.some(fileInfos, (fileInfo) => fileInfo.fileType !== '증빙')) {
        alertRef.current?.showAlert('warning', '증빙 파일을 업로드해주세요.')
      }

      const newEvidenceFiles: EvalFile[] = _.cloneDeep(evidenceFiles) || []
      _.forEach(fileInfos, (fileInfo) => {
        fileInfo.type = fileInfo.fileType
        if (fileInfo.state === State.OK) {
          if (evaluationName !== fileInfo.evaluationName) {
            fileInfo.state = State.UNRELATED_OPERATION_EVAL
          } else if (controlId !== fileInfo.controlId) {
            fileInfo.state = State.DIFFERENT_CONTROL_ID
          }
        }

        fileInfo.fileName = `${fileInfo.fileName}.${fileInfo.fileExtension}`
        if (fileInfo.type === '증빙') {
          const matched = _.findIndex(newEvidenceFiles, { fileName: fileInfo.fileName })
          if (matched >= 0) {
            _.pullAt(newEvidenceFiles, [matched])
          }
          newEvidenceFiles.push(fileInfo)
        }
      })
      setEvidences(newEvidenceFiles)
    },
    [evidenceFiles, evaluationName, controlId]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: !_.isEmpty(evidenceFiles)
  })

  const deleteFile = (allFiles: EvalFile[], file: EvalFile): void => {
    const newFiles = allFiles.filter((item) => item.fileName !== file.fileName)
    setEvidences(newFiles)
  }

  return (
    <div>
      <Typography.Title level={5}>증빙 파일 업로드</Typography.Title>
      <div
        className={clsx(classes.dropzone, { [classes.dragActive]: isDragActive })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {_.isEmpty(evidenceFiles) && <UploadIconAndText isDragActive={isDragActive} />}
        {!_.isEmpty(evidenceFiles) && (
          <CommonAgGrid
            autoSize="sizeColumnsToFit"
            noBorder
            columnDefs={getFileUploaderColumns(staffs, deleteFile)}
            domLayout="autoHeight"
            frameworkComponents={{ dateCellRenderer, StaffCellRenderer }}
            rowData={evidenceFiles}
          />
        )}
      </div>
      <NoticeTextView>
        증빙 파일명은{' '}
        <Typography.Text type="danger" strong>
          ({evaluationName})({controlId})(증빙)파일이름.확장자
        </Typography.Text>
        으로 업로드해야합니다.
      </NoticeTextView>
      <AlertMessage ref={alertRef} />
    </div>
  )
}

export default EvidenceFileUploader
