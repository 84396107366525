import { ColDef } from '@ag-grid-enterprise/all-modules'
import _ from 'lodash'

import { Control } from '../../../base/data/PRC'
import { getKoName } from '../../../base/data/Translation'
import { controlUpdate, controlDelete } from '../../../dataLoader/RCM/control'
import { modifyAndDeleteCellRenderer } from './PRCEditorUtils'

export function GetControlColumnDefs(
  openItemEditor: ((item: any) => void) | undefined,
  deleteItem: (item: any) => void
): ColDef[] {
  return [
    { headerName: '대분류', field: 'cycleName', sortable: true },
    { headerName: '중분류', field: 'categoryName', sortable: true },
    { headerName: '소분류', field: 'subCategoryName', sortable: true },
    { headerName: getKoName('control', 'id'), field: 'id', sortable: true },
    {
      headerName: getKoName('control', 'narrative'),
      field: 'narrative',
      flex: 1
    },
    {
      headerName: '수정/삭제',
      field: 'action',
      cellRenderer: modifyAndDeleteCellRenderer,
      cellRendererParams: {
        openItemEditor,
        deleteItem
      }
    }
  ]
}

export async function EditControl(control: Control, editedItem: any): Promise<any> {
  const newItem = {
    ...control,
    name: editedItem.name
  }

  await controlUpdate(control)

  return newItem
}

export async function DeleteControl(item: any): Promise<void> {
  console.log('delete control', item)
  return controlDelete(item.id)
}
