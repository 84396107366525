/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash'

import { evaluationGetAll } from '../Evaluation/evaluation'
import { staffGetAll } from '../RCM/staff'
import { dbService } from '../fbase'
import { storageGetDownloadUrlFromArchive } from './storage'

const createNotification = async (notificationObject) => {
  // 넘어온 notificationObject 를 바탕으로 메일을 보낼 수 있는 내용의 NOTIFICATION 을 생성해서 DB에 넣는다.
  // 그러면 CF 가 이를 읽고 메일을 보낼 것이다.

  const n2 = '\n\n' // two new line
  const n1t1 = '\n\t' // one new line, one tab
  const n1t2 = '\n\t\t' // one new line, two tab

  const comment = notificationObject.comment
  const evalType = notificationObject.evalType
  const evalName = notificationObject.evalName
  const type = notificationObject.type
  const controlIdList = notificationObject.controlIdList
  const evaluationData = notificationObject.evaluationData

  let subject
  let refName = ''
  const krEvalType = evalType === 'OE' ? '운영평가' : '설계평가'
  if (type === '1') {
    // 평가 수행 요청
    subject = `[${krEvalType}] (${evalName}) 평가 수행현황 및 수행완료요청`
    refName = evaluationData[0].data.base.refEvaluationName
  } else if (type === '2') {
    // 반려(재수행 요청)
    subject = `[${krEvalType}] (${evalName}) 평가 재수행 요청(반려)`
  } else if (type === '4') {
    // 제목, 코멘트가 넘어오는 커스텀 메일
    const title = notificationObject.title
    subject = `[${krEvalType}] (${evalName}) ${title}`
  } else if (type === '3') {
    // GUEST => STAFF 완료 메일
    const subject = '회원가입이 승인되었습니다.'

    const result = {
      subject,
      toObject: {},
      refName,
      type
    }

    const staffIdList = evaluationData
    const staffDataAll = await staffGetAll()
    staffIdList.forEach((targetStaffId) => {
      const staffData = _.find(staffDataAll, { id: targetStaffId })

      let text = `${staffData.name}(${targetStaffId}) 님의 가입이 승인 처리되었습니다.`
      text = `${text}${n1t1}아이디: ${targetStaffId}`
      text = `${text}${n1t1}이름: ${staffData.name}`
      text = `${text}${n1t1}부서: ${staffData.departmentName}`

      result.toObject[targetStaffId] = {
        linkObject: {},
        text
      }
    })

    return await dbService.collection('NOTIFICATION').add(result)
  }

  // ref 있는지 체크
  let controlIdToDownloadUrl = {}
  if (refName !== '') {
    controlIdToDownloadUrl = await storageGetDownloadUrlFromArchive(
      evalType,
      refName,
      controlIdList
    )
  }

  const controlToNotification = {}
  const staffToControl = {}

  const staffDataAll = await staffGetAll()

  // 컨트롤 돌면서 내용 만들기
  evaluationData.forEach((evalData) => {
    const controlId = evalData.controlId
    controlToNotification[controlId] = {}
    const notificationData = {
      text: '',
      url: controlIdToDownloadUrl === {} ? '' : controlIdToDownloadUrl[controlId]
    }

    let text = ''

    // 컨트롤 관련 정보
    const controlName = evalData.data.content.controlData.name
    text = `${controlId}: ${controlName}`
    const cycleName = evalData.data.content.controlData.processCycleName.replace(/\n/g, ' ')
    const categoryName = evalData.data.content.controlData.processCategoryName.replace(/\n/g, ' ')
    const subCategoryName = evalData.data.content.controlData.processSubCategoryName.replace(
      /\n/g,
      ' '
    )
    const narrative = evalData.data.content.controlData.narrative
    text = `${text}${n1t1}관련 분류(대분류/중분류/소분류): ${cycleName}/${categoryName}/${subCategoryName}`
    text = `${text}${n1t1}컨트롤 설명:`
    _.split(narrative, '\r\n').forEach((splitNarrative) => {
      if (splitNarrative !== '') {
        text = `${text}${n1t2}${splitNarrative}`
      }
    })

    // 통제담당현업자
    const ownerId = evalData.data.state.controlOwner
    const ownerName = evalData.data.content.staffData.name
    const ownerDepartment = evalData.data.content.staffData.departmentName
    text = `${text}${n1t1}통제담당자: ${ownerName}(${ownerDepartment} / ${ownerId})`
    if (!(ownerId in staffToControl)) {
      staffToControl[ownerId] = [controlId]
    } else {
      staffToControl[ownerId].push(controlId)
    }

    // 통제평가자
    const performerId = evalData.data.state.controlPerformer
    const performerData = _.find(staffDataAll, { id: performerId })
    const performerName = performerData.name
    const performerDepartment = performerData.departmentName
    text = `${text}${n1t1}통제평가자: ${performerName}(${performerDepartment} / ${performerId})`
    if (!(performerId in staffToControl)) {
      staffToControl[performerId] = [controlId]
    } else {
      staffToControl[performerId].push(controlId)
    }

    // 상태
    const state = evalData.data.state.state
    let krState
    switch (state) {
      case '1':
        krState = '미수행'
        break
      case '2':
        krState = '수행중'
        break
      case '3':
        // 아마 여기서 들어올 일은 없을듯
        krState = '수행완료'
        break
      case '4':
        krState = '재작성필요'
        break
      case '5':
        // 아마 여기서 들어올 일은 없을듯
        krState = '승인완료'
        break
    }
    text = `${text}${n1t1}상태: ${krState}`

    const files = evalData.data.content.files
    // 보고서파일: 파일이름(업데이터 업데이트시각)
    const recordFile = files.recordFile
    if (recordFile.fileName !== '') {
      const updaterId = recordFile.updater
      const updaterData = _.find(staffDataAll, { id: updaterId })
      const updaterName = updaterData.name
      const updateTime = recordFile.updateTime
      text = `${text}${n1t1}보고서파일: ${recordFile.fileName} - ${updaterName}(${updaterId}) 에 의해 ${updateTime} 에 업로드 됨`
    } else {
      text = `${text}${n1t1}보고서파일: 없음`
    }
    // 모집단파일
    if ('populationFile' in files) {
      const populationFile = files.populationFile
      if (populationFile.fileName !== '') {
        const updaterId = populationFile.updater
        const updaterData = _.find(staffDataAll, { id: updaterId })
        const updaterName = updaterData.name
        const updateTime = populationFile.updateTime
        text = `${text}${n1t1}모집단파일: ${populationFile.fileName} - ${updaterName}(${updaterId}) 에 의해 ${updateTime} 에 업로드 됨`
      } else {
        text = `${text}${n1t1}모집단파일: 없음`
      }
    }
    // 증빙파일
    if ('evidenceFile' in files) {
      const evidenceFile = files.evidenceFile
      if (Object.keys(evidenceFile).length !== 0) {
        text = `${text}${n1t1}증빙파일:`
        const fileNameList = Object.keys(evidenceFile)
        for (let i = 0; i < fileNameList.length; i++) {
          const fileName = fileNameList[i]
          const fileObject = evidenceFile[fileName]
          const index = i + 1
          const updaterId = fileObject.updater
          const updaterData = _.find(staffDataAll, { id: updaterId })
          const updaterName = updaterData.name
          const updateTime = fileObject.updateTime
          text = `${text}${n1t2}[${index}] ${fileName} - ${updaterName}(${updaterId}) 에 의해 ${updateTime} 에 업로드 됨`
        }
      } else {
        text = `${text}${n1t1}증빙파일: 없음`
      }
    }
    // 기타파일
    if ('extraFile' in files) {
      const extraFile = files.extraFile
      if (Object.keys(extraFile).length !== 0) {
        text = `${text}${n1t1}기타파일:`
        const fileNameList = Object.keys(extraFile)
        for (let i = 0; i < fileNameList.length; i++) {
          const fileName = fileNameList[i]
          const fileObject = extraFile[fileName]
          const index = i + 1
          const updaterId = fileObject.updater
          const updaterData = _.find(staffDataAll, { id: updaterId })
          const updaterName = updaterData.name
          const updateTime = fileObject.updateTime
          text = `${text}${n1t2}[${index}] ${fileName} - ${updaterName}(${updaterId}) 에 의해 ${updateTime} 에 업로드 됨`
        }
      } else {
        text = `${text}${n1t1}기타파일: 없음`
      }
    }

    notificationData.text = text
    controlToNotification[controlId] = notificationData
  })

  // staffToControlMap 에서 중복제거
  Object.keys(staffToControl).forEach((staffId) => {
    let controlIdListTemp = staffToControl[staffId]
    controlIdListTemp = [...new Set(controlIdListTemp)]
    staffToControl[staffId] = controlIdListTemp
  })

  const result = {
    subject,
    toObject: {},
    refName,
    type
  }

  Object.keys(staffToControl).forEach((staffId) => {
    const controlIdListTemp = staffToControl[staffId]
    const linkObject = {}
    let textResult = `${comment}`
    controlIdListTemp.forEach((controlId) => {
      textResult = textResult + n2 + controlToNotification[controlId].text
      // if (type !== '4' && refName !== '' && controlToNotification[controlId].url !== '') {
      if (type !== '4' && refName !== '' && !_.isNil(controlToNotification[controlId].url)) {  
        linkObject[controlId] = controlToNotification[controlId].url
      }
    })
    result.toObject[staffId] = {
      text: textResult,
      linkObject
    }
  })

  return await dbService.collection('NOTIFICATION').add(result)
}

export const mailAboutEvaluationStateAndRequest = async (
  evalType,
  evalName,
  controlIdList,
  extraComment
) => {
  /*
  평가의 수행요청을 요구하는 메일
  평가의 상태, 정보 그리고 코멘트
  평가의 각 컨트롤의 담당자 및 수행자에게

  type: "1"

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlIdList: String[] - CONTROL id list
  @param: extraComment: String - comment
  */

  const notificationObject = {
    type: '1',
    evalType,
    evalName,
    evaluationData: [],
    controlIdList,
    comment: extraComment
  }

  const evaluationDataAll = await evaluationGetAll(evalType, evalName)
  const filteredEvaluationDataList = _.filter(evaluationDataAll, (evalData) => {
    return controlIdList.includes(evalData.controlId)
  })

  notificationObject.evaluationData = filteredEvaluationDataList

  return await createNotification(notificationObject)
}

export const mailAboutEvaluationReject = async (evalType, evalName, controlId, extraComment) => {
  /*
  평가의 반려(재수행 요청) 를 알리는 메일
  평가의 상태, 정보 그리고 코멘트
  평가의 해당 컨트롤의 수행자에게

  type: "2"

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  @param: extraComment: String - comment
  */

  const notificationObject = {
    type: '2',
    evalType,
    evalName,
    evaluationData: [],
    controlIdList: [controlId],
    comment: extraComment
  }

  const evaluationDataAll = await evaluationGetAll(evalType, evalName)
  const filteredEvaluationDataList = _.filter(evaluationDataAll, (evalData) => {
    return controlId === evalData.controlId
  })

  notificationObject.evaluationData = filteredEvaluationDataList

  return await createNotification(notificationObject)
}

export const mailAboutGuestUpgrade = async (staffIdList) => {
  /*
  관리자에 의해 GUEST 가 STAFF 로 업그레이드 되면 완료되었다는 메일을 발송

  type: "3"

  @param: staffIdList: String[] - STAFF id list
  */

  if (staffIdList.length === 0) {
    return Promise.reject(new Error('해당하는 staffId 가 없습니다.'))
  }

  const notificationObject = {
    type: '3',
    evalType: '',
    evalName: '',
    evaluationData: staffIdList,
    controlIdList: [],
    comment: ''
  }

  return await createNotification(notificationObject)
}

export const mailCustom = async (evalType, evalName, controlIdList, title, extraComment) => {
  /*
  [title, extraComment] 인 메일을 발송
  기본적인 내용(기본적인 평가 관련 내용)은 밑에 붙을 것이고 수신자/제목/코멘트 만 전달

  type: "4"

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlIdList: String - CONTROL id List
  @param: String title - 메일 제목
  @param: extraComment: String - comment
  */

  const notificationObject = {
    type: '4',
    evalType,
    evalName,
    evaluationData: [],
    controlIdList,
    title,
    comment: extraComment
  }

  const evaluationDataAll = await evaluationGetAll(evalType, evalName)
  const filteredEvaluationDataList = _.filter(evaluationDataAll, (evalData) => {
    return controlIdList.includes(evalData.controlId)
  })

  notificationObject.evaluationData = filteredEvaluationDataList

  return await createNotification(notificationObject)
}
