import { makeStyles, Theme } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Col, DatePicker, Input, Row, Select } from 'antd'
import Form from 'antd/lib/form'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'

import { grey, lightBlue, lightGrey } from '../../base/color'

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    flexFlow: 'row'
  },
  valueItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    flexGrow: 1
  },
  borderLeft: {
    borderLeft: lightGrey.border
  },
  formText: {
    marginBottom: 0,
    width: '100%',
    '& .ant-form-item-control-input': {
      minHeight: 0
    }
  },
  formSelect: {
    maxWidth: 400,
    flexGrow: 1,
    marginBottom: 0
  },
  formDatePicker: {
    marginBottom: 0
  }
}))

interface Props {
  values: {
    key: string
    value: string
    span: number
    style?: React.CSSProperties
    type?: 'Text' | 'TextArea' | 'Select' | 'DatePicker'
    editable?: boolean
    options?: string[]
  }[]
  minHeight?: number
  noDivider?: boolean
  required?: boolean
}

const MultipleTableItem: React.FC<Props> = ({ values, minHeight, noDivider, required }) => {
  const classes = useStyles()

  return (
    <>
      <Row className={classes.item} style={{ minHeight }}>
        {_.map(values, ({ key, value, span, style, type, editable, options }, index) => {
          return (
            <Col
              key={index}
              className={
                index !== 0 ? clsx(classes.valueItem, classes.borderLeft) : classes.valueItem
              }
              span={span}
              style={style}
            >
              {!editable && (
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: _.includes(
                      [grey.background, lightBlue.background],
                      style?.backgroundColor
                    )
                      ? 'bold'
                      : 'normal'
                  }}
                >
                  {value}
                </Typography>
              )}
              {editable && type === 'Text' && (
                <Form.Item className={classes.formText} name={key}>
                  <Input />
                </Form.Item>
              )}
              {editable && type === 'TextArea' && (
                <Form.Item className={classes.formText} name={key}>
                  <Input.TextArea autoSize />
                </Form.Item>
              )}
              {editable && type === 'Select' && (
                <Form.Item className={classes.formSelect} name={key}>
                  <Select>
                    {_.map(options, (option) => (
                      <Select.Option value={option}>{option}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {editable && type === 'DatePicker' && (
                <Form.Item className={classes.formDatePicker} name={key}>
                  <DatePicker />
                </Form.Item>
              )}
            </Col>
          )
        })}
      </Row>
      {!noDivider && <Divider />}
    </>
  )
}

export default MultipleTableItem
