/* eslint-disable camelcase */
import _ from 'lodash'

export interface EvaluationDefect {
  weakState: '1' | '2' | '3' | '4' | '5'
}

export interface OperationDefect extends EvaluationDefect {
  weakStateData: {
    '1': { Answer: string; Question: string; Reason: string }
    '2': { Answer: string; Question: string; Reason: string }
    '3': { Answer: string; Question: string; Reason: string }
    '4': { Answer: string; Question: string; Reason: string }
    '5': { Answer: string; Question: string; Reason: string }
  }
}

export interface DesignDefectItem {
  content: string | boolean
  evidence: string
}

export interface DesignDefect extends EvaluationDefect {
  weakStateData: {
    control: {
      id: string
      narrative: DesignDefectItem
      policy: DesignDefectItem
      ITDependencySystem: DesignDefectItem
      MRC_YN: DesignDefectItem
      MRC_Number: DesignDefectItem
      MRC_IPE_Name: DesignDefectItem
      IPE_YN: DesignDefectItem
      IPE_Number: DesignDefectItem
      IPE_Name: DesignDefectItem
      department: DesignDefectItem
      owner: DesignDefectItem
      teamLeader: DesignDefectItem
      teamMember: DesignDefectItem
      keyControl: DesignDefectItem
      goal_Operation: DesignDefectItem
      goal_Trust: DesignDefectItem
      goal_Asset: DesignDefectItem
      goal_Override: DesignDefectItem
      goal_Law: DesignDefectItem
      accountCode: DesignDefectItem
      accountName: DesignDefectItem
      reportFootnotes: DesignDefectItem
      assertion_EO: DesignDefectItem
      assertion_C: DesignDefectItem
      assertion_RO: DesignDefectItem
      assertion_PD: DesignDefectItem
      assertion_Occur: DesignDefectItem
      assertion_Assessment: DesignDefectItem
      assertion_Measurement: DesignDefectItem
      period: DesignDefectItem
      preventDetective: DesignDefectItem
      autoManual: DesignDefectItem
      residualRiskLevel: DesignDefectItem
      controlRiskLevel: DesignDefectItem
      TOC_Procedure: DesignDefectItem
      TOC_Evidence: DesignDefectItem
      TOC_Population: DesignDefectItem
      TOC_PopulationCount: DesignDefectItem
      TOC_TestProperties: DesignDefectItem
      TOC_Exception: DesignDefectItem
      TOC_TestMethod_InquiryInspection: DesignDefectItem
      TOC_TestMethod_Observation: DesignDefectItem
      TOC_TestMethod_Reperformance: DesignDefectItem
    }
    risk: {
      id: string
      name: DesignDefectItem
      RoMM: DesignDefectItem
      inherentRiskLevel: DesignDefectItem
    }
  }
}

export function isOperationWeakState(value: EvaluationDefect): value is OperationDefect {
  return (
    _.has(value, 'weakStateData.1') &&
    _.has(value, 'weakStateData.2') &&
    _.has(value, 'weakStateData.3') &&
    _.has(value, 'weakStateData.4') &&
    _.has(value, 'weakStateData.5')
  )
}
