import _ from 'lodash'
import { ReactElement } from 'react'

export enum MenuName {
  Dashboard = 'Dashboard',
  RCM = 'RCM',
  RCMViewer = 'RCM 보기',
  RCMEditor = 'RCM 수정',
  RCMBackup = 'RCM 복구',
  RCMVersion = 'RCM 버전 관리',
  OperationEvaluation = '운영평가',
  OperationEvaluationCreation = '운영평가 개설',
  OperationEvaluationFileManagement = '운영평가 파일 관리',
  OperationEvaluationPopulationSampling = '운영평가 모집단 샘플링',
  OperationEvaluationEvidence = '운영평가 증빙 업로드',
  OperationEvaluationWritingReport = '운영평가 보고서 작성',
  OperationEvaluationManagement = '운영평가 관리',
  OperationEvaluationDefect = '운영평가 예외사항 미비점 평가',
  DesignEvaluation = '설계평가',
  DesignEvaluationCreation = '설계평가 개설',
  DesignEvaluationFileManagement = '설계평가 파일 관리',
  DesignEvaluationWritingReport = '설계평가 보고서 작성',
  DesignEvaluationManagement = '설계평가 관리',
  DesignEvaluationModification = '설계평가 수정사항 평가 및 반영',
  ResultReport = '결과 보고서',
  AdminPage = '관리자 페이지',
  FileBox = '파일 보관함'
}

export function convertToMenuStr(menu: MenuName): string {
  const enMenu: Record<MenuName, string> = {
    [MenuName.Dashboard]: 'dashboard',
    [MenuName.RCM]: 'rcm',
    [MenuName.RCMViewer]: 'rcm-viewer',
    [MenuName.RCMEditor]: 'rcm-editor',
    [MenuName.RCMBackup]: 'rcm-backup',
    [MenuName.RCMVersion]: 'rcm-version',
    [MenuName.OperationEvaluation]: 'operation-evaluation',
    [MenuName.OperationEvaluationCreation]: 'operation-evaluation-creation',
    [MenuName.OperationEvaluationFileManagement]: 'operation-evaluation-file-management',
    [MenuName.OperationEvaluationPopulationSampling]: 'operation-evaluation-population-sampling',
    [MenuName.OperationEvaluationEvidence]: 'operation-evaluation-evidence',
    [MenuName.OperationEvaluationWritingReport]: 'operation-evaluation-writing-report',
    [MenuName.OperationEvaluationManagement]: 'operation-evaluation-management',
    [MenuName.OperationEvaluationDefect]: 'operation-evaluation-defect',
    [MenuName.DesignEvaluation]: 'design-evaluation',
    [MenuName.DesignEvaluationCreation]: 'design-evaluation-creation',
    [MenuName.DesignEvaluationFileManagement]: 'design-evaluation-file-management',
    [MenuName.DesignEvaluationWritingReport]: 'design-evaluation-writing-report',
    [MenuName.DesignEvaluationManagement]: 'design-evaluation-management',
    [MenuName.DesignEvaluationModification]: 'design-evaluation-modification',
    [MenuName.ResultReport]: 'result-report',
    [MenuName.AdminPage]: 'admin',
    [MenuName.FileBox]: 'file-box'
  }

  return enMenu[menu]
}

export function getMenu(location: string, path: string): MenuName {
  return (
    _.find(_.values(MenuName), (menu) => location === `${path}/${convertToMenuStr(menu)}`) ||
    MenuName.Dashboard
  )
}

export interface AppMenu {
  name: MenuName
  icon?: ReactElement
  children?: AppMenu[]
  key?: string
}
