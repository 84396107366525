import { Divider, makeStyles, Theme, Typography } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import _ from 'lodash'
import React, { ReactElement } from 'react'

import { grey } from '../../../../base/color'
import { BasePRC, PRCType, Process, Control, Risk, RiskLevel } from '../../../../base/data/PRC'
import { Staff } from '../../../../base/data/Staff'
import { getKoName } from '../../../../base/data/Translation'
import TableItem, { ComplexTableItem } from '../../../common/TableItem'
import ControlView from '../../common/ControlView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: '1px solid lightgray',
    minWidth: 0
  },
  content: {
    wordBreak: 'break-word'
  },
  process: {
    background: '#F0F3FA'
  },
  risk: {
    background: '#FFFBEF'
  },
  control: {
    background: '#FDF7F7'
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  riskContainer: {
    border: grey.border
  }
}))

interface Props {
  item: BasePRC
  subItems?: BasePRC[]
  staffs?: Staff[]
}

const RCMItemRow: React.FC<Props> = ({ item, subItems, staffs }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const onClick = React.useCallback((): void => {
    setOpen(!open)
  }, [open])

  const itemClass = React.useMemo(() => {
    if (item.type === PRCType.process) {
      return classes.process
    } else if (item.type === PRCType.risk) {
      return classes.risk
    }
    return classes.control
  }, [item])

  const renderProcess = React.useCallback((process: Process): ReactElement => {
    return (
      <div className={classes.content}>
        <Typography variant="body2">{process.narrative}</Typography>
      </div>
    )
  }, [])

  const renderRisk = React.useCallback((risk: Risk): ReactElement => {
    return (
      <div className={classes.riskContainer}>
        <TableItem name={getKoName('risk', 'name')}>{risk.name}</TableItem>
        <TableItem name={getKoName('risk', 'RoMM')}>{risk.RoMM}</TableItem>
        <ComplexTableItem
          name={getKoName('risk', 'inherentRiskLevel')}
          value={risk.inherentRiskLevel}
          radioTypes={RiskLevel}
          noDivider
        />
      </div>
    )
  }, [])

  const renderControl = React.useCallback(
    (control: Control): ReactElement => {
      return (
        <div className={classes.content}>
          <ControlView noMargin control={control} staffs={staffs || []} />
        </div>
      )
    },
    [staffs]
  )

  const hasCollapse = React.useMemo(
    () =>
      !(
        item.type === PRCType.process &&
        _.isEmpty(_.get(item, 'narrative')) &&
        _.isEmpty(subItems)
      ),
    [item, subItems]
  )

  return (
    <div className={classes.root}>
      <ListItem className={itemClass} onClick={onClick}>
        <Typography variant="subtitle1">{item.id}</Typography>
        {hasCollapse && (
          <>
            <ListItemText />
            {open ? <ExpandLess /> : <ExpandMore />}
          </>
        )}
      </ListItem>
      {hasCollapse && (
        <Collapse unmountOnExit in={open} timeout="auto">
          <Divider />
          <List disablePadding className={classes.nested} component="div">
            {item.type === PRCType.process ? renderProcess(item as Process) : null}
            {item.type === PRCType.risk ? renderRisk(item as Risk) : null}
            {_.map(subItems, (element) => {
              return <RCMItemRow item={element} key={element.id} staffs={staffs} />
            })}
            {item.type === PRCType.control ? renderControl(item as Control) : null}
          </List>
        </Collapse>
      )}
    </div>
  )
}

export default RCMItemRow
