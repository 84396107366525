import { Tabs } from 'antd'
import React from 'react'

import { EvaluationType, getKoEvaluation } from '../../../base/data/Evaluation'
import CardTabs from '../../common/CardTabs'
import FileUploader from './FileUploader'
import TemplateDownload from './TemplateDownload'

interface Props {
  type: EvaluationType
}

const EvaluationFileManagement: React.FC<Props> = ({ type }) => {
  const [activeKey, setActiveKey] = React.useState('1')
  React.useEffect(() => {
    setActiveKey('1')
  }, [type])

  return (
    <CardTabs defaultActiveKey={activeKey} onChange={(key) => setActiveKey(key)}>
      <Tabs.TabPane key="1" tab={`${getKoEvaluation(type)} 템플릿 다운로드`}>
        <TemplateDownload active={activeKey === '1'} type={type} />
      </Tabs.TabPane>
      <Tabs.TabPane key="2" tab={`${getKoEvaluation(type)} 파일 업로드`}>
        <FileUploader active={activeKey === '2'} type={type} />
      </Tabs.TabPane>
    </CardTabs>
  )
}

export default EvaluationFileManagement
