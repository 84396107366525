import { makeStyles, Theme } from '@material-ui/core/styles'
import Checkbox from 'antd/lib/checkbox'
import Form, { Rule } from 'antd/lib/form'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Select from 'antd/lib/select'
import _ from 'lodash'
import React from 'react'

import { AutoManual, YesNo, Period, PreventDetective, RiskLevel } from '../../../base/data/PRC'
import { filterStaffs, renderStaffDepartment, Staff } from '../../../base/data/Staff'
import { getKoName } from '../../../base/data/Translation'
import TableHeader from '../../common/TableHeader'
import TableItem from '../../common/TableItem'

const useStyle = makeStyles((theme: Theme) => ({
  form: {
    marginBottom: 0,
    width: '100%'
  },
  select: {
    maxWidth: 330
  },
  formSelect: {
    maxWidth: 400,
    flexGrow: 1,
    marginBottom: 0
  }
}))

const RenderRadio = (radioEnum: any): React.ReactElement => {
  return (
    <Radio.Group>
      {_.map(radioEnum, (type) => (
        <Radio key={type} value={type}>
          {type}
        </Radio>
      ))}
    </Radio.Group>
  )
}

const RenderCheck = (checkNames: string[], name: string): React.ReactElement => {
  const options: Record<string, string> = _.reduce(
    checkNames,
    (result: Record<string, string>, key: string) => {
      result[key] = getKoName('control', `${name}_${key}`)
      return result
    },
    {}
  )
  return (
    <Checkbox.Group>
      {_.map(options, (optionStr, option) => {
        return (
          <Checkbox key={option} style={{ marginLeft: 0, marginRight: 8 }} value={option}>
            {optionStr}
          </Checkbox>
        )
      })}
    </Checkbox.Group>
  )
}

interface ItemProps {
  name: string
  radioTypes?: any
  checkTypes?: string[]
  noDivider?: boolean
  required?: boolean
  disabled?: boolean
}

const ItemRow: React.FC<ItemProps> = ({
  name,
  radioTypes,
  checkTypes,
  noDivider,
  required,
  disabled
}) => {
  const classes = useStyle()
  const koName = getKoName('control', name)
  const rules: Rule[] = []
  if (required) {
    rules.push({
      required: true,
      message: `${koName}을 입력해주세요.`
    })
  }

  let container
  if (radioTypes) container = RenderRadio(radioTypes)
  else if (checkTypes) container = RenderCheck(checkTypes, name)
  else container = <Input.TextArea autoSize disabled={disabled} />

  return (
    <TableItem key={name} name={koName} noDivider={noDivider} required={required}>
      <Form.Item className={classes.form} name={checkTypes ? `${name}_` : name} rules={rules}>
        {container}
      </Form.Item>
    </TableItem>
  )
}

interface Props {
  selectedStaff: Staff
  staffs?: Staff[]
}

const ControlEditView: React.FC<Props> = ({ selectedStaff, staffs }) => {
  const classes = useStyle()

  const staffSelectValidator = React.useCallback(
    (type: 'incharge' | 'owner'): ((rule: any, value: any) => Promise<void>) => {
      return (rule: any, value: any) => {
        if (_.isEmpty(value)) {
          return Promise.reject(new Error(`${getKoName('control', type)}를 선택해주세요.`))
        }
        return Promise.resolve()
      }
    },
    []
  )

  const staffSelectOptions = React.useCallback(
    (type: 'incharge' | 'owner') => {
      const filteredStaffs = filterStaffs(staffs || [], type === 'owner')
      return _.map(filteredStaffs, (staff) => (
        <Select.Option key={staff.id} value={staff.id}>
          {`${staff.name} (${staff.email})`}
        </Select.Option>
      ))
    },
    [staffs]
  )

  return (
    <>
      <ItemRow required name="narrative" />
      <TableHeader value="규정 및 자원" />
      <ItemRow name="policy" />
      <ItemRow name="ITDependencySystem" />
      <TableHeader value="MRC" />
      <ItemRow name="MRC_YN" />
      <ItemRow name="MRC_Number" />
      <ItemRow name="MRC_IPE_Name" />
      <TableHeader value="IPE 통제" />
      <ItemRow name="IPE_YN" />
      <ItemRow name="IPE_Number" />
      <ItemRow name="IPE_Name" />
      <TableHeader value="통제 조직" />
      <TableItem required name={getKoName('control', 'department')}>
        {renderStaffDepartment(selectedStaff)}
      </TableItem>
      {_.map(['incharge', 'owner'], (target: 'incharge' | 'owner') => (
        <TableItem key={target} name={getKoName('control', target)} required>
          <Form.Item
            className={classes.formSelect}
            name={target}
            rules={[() => ({ validator: staffSelectValidator(target) })]}
          >
            <Select className={classes.select} size="small">
              {staffSelectOptions(target)}
            </Select>
          </Form.Item>
        </TableItem>
      ))}
      <ItemRow name="teamLeader" />
      <ItemRow name="teamMember" />
      <TableHeader value="기타 통제 속성" />
      <ItemRow name="keyControl" radioTypes={YesNo} />
      <ItemRow name="goal" checkTypes={['Trust', 'Override', 'Operation', 'Law', 'Asset']} />
      <ItemRow name="accountCode" />
      <ItemRow name="accountName" />
      <ItemRow name="reportFootnotes" />
      <ItemRow
        name="assertion"
        checkTypes={['EO', 'C', 'RO', 'PD', 'Occur', 'Assessment', 'Measurement']}
      />
      <ItemRow name="period" radioTypes={Period} />
      <ItemRow name="preventDetective" radioTypes={PreventDetective} />
      <ItemRow name="autoManual" radioTypes={AutoManual} />
      <ItemRow name="residualRiskLevel" radioTypes={RiskLevel} />
      <TableHeader value="통제 운영 속성" />
      <ItemRow name="controlRiskLevel" radioTypes={RiskLevel} />
      <ItemRow name="TOC_Procedure" />
      <ItemRow name="TOC_Evidence" />
      <ItemRow name="TOC_Population" />
      <ItemRow name="TOC_PopulationCount" />
      <ItemRow name="TOC_TestProperties" />
      <ItemRow name="TOC_Exception" />
      <ItemRow
        checkTypes={['InquiryInspection', 'Observation', 'Reperformance']}
        name="TOC_TestMethod"
        noDivider
      />
    </>
  )
}

export default ControlEditView
