import { makeStyles, Theme } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Row } from 'antd'
import React from 'react'

import { lightGrey } from '../../base/color'

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    background: lightGrey.background,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  nameItem: {
    display: 'flex',
    alignItems: 'center'
  }
}))

interface Props {
  value: string
  minHeight?: number
  noDivider?: boolean
}

const TableHeader: React.FC<Props> = ({ value, minHeight, noDivider }) => {
  const classes = useStyles()
  return (
    <>
      <Row className={classes.item} style={{ minHeight }}>
        <Typography style={{ fontWeight: 'bold' }} variant="body1">
          {value}
        </Typography>
      </Row>
      {!noDivider && <Divider />}
    </>
  )
}

export default TableHeader
