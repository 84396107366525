import _ from 'lodash'
import React from 'react'

import { ServerFile } from '../../../base/data/BaseFile'
import { Evaluation, EvaluationType } from '../../../base/data/Evaluation'
import { getFiles, getFileViewColumns } from '../../../base/utils/FileUtils'
import TableViewer, { TableViewerHandler } from '../../common/TableViewer'

interface Props {
  type: EvaluationType
  document: Evaluation
}

const EvaluationFileView: React.FC<Props> = ({ type, document }) => {
  const [files, setFiles] = React.useState<ServerFile[]>([])
  const viewerRef = React.useRef<TableViewerHandler>(null)

  React.useEffect(() => {
    setFiles(getFiles(type, document))
  }, [type, document])

  return (
    <TableViewer
      noBorder
      columnDefs={getFileViewColumns()}
      domLayout="autoHeight"
      ref={viewerRef}
      rowData={files}
    />
  )
}

export default EvaluationFileView
