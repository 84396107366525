import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from 'antd/lib/typography'
import React, { ReactElement } from 'react'

import { grey } from '../../base/color'

const useStyles = makeStyles((theme: Theme) => ({
  notice: {
    backgroundColor: grey.background,
    border: grey.border,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const NoticeTextView: React.FC<{ children: string | ReactElement | (string | ReactElement)[] }> = ({
  children
}) => {
  const classes = useStyles()

  return (
    <div className={classes.notice}>
      <Typography.Text>{children}</Typography.Text>
    </div>
  )
}

export default NoticeTextView
