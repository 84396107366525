import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Row, Checkbox, Select } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { SelectValue } from 'antd/lib/select'
import React from 'react'

const { Option } = Select

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  checkbox: {
    display: 'block',
    height: 30
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  }
}))

interface OptionControlLabelsProps {
  type: string
  isRiskView: boolean
  isProcessView: boolean
  isControlView: boolean
  controlOption: string
  optionOnChange: (target: string, checked: boolean) => void
  controlOptionOnChange: (value: string) => void
}

const OptionControlLabels: React.FC<OptionControlLabelsProps> = ({
  type,
  isRiskView,
  isProcessView,
  isControlView,
  controlOption,
  optionOnChange,
  controlOptionOnChange
}) => {
  const classes = useStyles()
  const checkboxOnChange = (event: CheckboxChangeEvent): void => {
    optionOnChange(event.target.value as string, event.target.checked)
  }
  const selectOnChange = (value: SelectValue): void => {
    controlOptionOnChange(value as string)
  }
  return (
    <div>
      <Checkbox.Group>
        <Row>
          <Checkbox
            checked={isProcessView}
            className={classes.checkbox}
            disabled={type === 'process'}
            value="inquireProcessView"
            onChange={checkboxOnChange}
          >
            Process 포함 조회
          </Checkbox>
        </Row>
        <Row>
          <Checkbox
            checked={isRiskView}
            className={classes.checkbox}
            disabled={type === 'risk'}
            value="inquireRiskView"
            onChange={checkboxOnChange}
          >
            Risk 포함 조회
          </Checkbox>
        </Row>
        <Row>
          <Checkbox
            checked={isControlView}
            className={classes.checkbox}
            disabled={type === 'control'}
            value="inquireControlView"
            onChange={checkboxOnChange}
          >
            Control 포함 조회
          </Checkbox>
        </Row>
      </Checkbox.Group>
      <Divider className={classes.divider} />
      <Typography
        noWrap
        color={type === 'control' || isControlView ? 'textPrimary' : 'textSecondary'}
        variant="body2"
      >
        Control 옵션
      </Typography>
      <Select
        className={classes.select}
        defaultValue="both"
        disabled={type !== 'control' && !isControlView}
        value={controlOption}
        onChange={selectOnChange}
      >
        <Option value="both">모두</Option>
        <Option value="key control">핵심통제만</Option>
        <Option value="except key control">비핵심통제만</Option>
      </Select>
    </div>
  )
}

interface Props {
  type: string
  isRiskView: boolean
  isProcessView: boolean
  isControlView: boolean
  controlOption: string
  optionOnChange: (target: string, checked: boolean) => void
  controlOptionOnChange: (value: string) => void
}

const OptionSelector: React.FC<Props> = ({
  type,
  isRiskView,
  isProcessView,
  isControlView,
  controlOption,
  optionOnChange,
  controlOptionOnChange
}) => {
  return (
    <div>
      <Typography noWrap variant="subtitle1">
        옵션 선택{' '}
      </Typography>
      <OptionControlLabels
        controlOption={controlOption}
        controlOptionOnChange={controlOptionOnChange}
        isControlView={isControlView}
        isProcessView={isProcessView}
        isRiskView={isRiskView}
        optionOnChange={optionOnChange}
        type={type}
      />
    </div>
  )
}

export default OptionSelector
