import _ from 'lodash'

import { updateObjectForm } from '../Utils/updateObjectForm'
import { dbService } from '../fbase'
import { departmentListUpdatePathArray } from './department'

export const staffInsertDataListForInit = async (dataObjectList) => {
  /*
  Init 을 위해서 Excel 에서 넘어온 Staff Data List 및 추가 정보들을 Firestore 에 집어넣는다
  level 은 모두 NORMAL 로

  @param: dataObjectList: Object[]: 데이터 리스트
  */
  const staffInsertDataForInit = async (dataObject) => {
    // 단일 Data 를 집어넣는다.
    const targetCollectionName = 'STAFF'
    const targetDocId = dataObject.email
    const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

    return await docRef.set({
      id: dataObject.email,
      uid: dataObject.email,
      email: dataObject.email,
      name: dataObject.name,
      departmentName: dataObject.departmentName,
      level: 'UPLOADER',
      selfPath: 'STAFF/' + dataObject.email,
      controlPathArray: [],
      departmentPathArray: ['DEPARTMENT/' + dataObject.departmentName]
    })
  }

  const result = dataObjectList.map(async (dataObject) => {
    return await staffInsertDataForInit(dataObject)
  })

  console.log('Insert Staff Data For Init Done')
  return Promise.all(result)
}

export const staffGetWithId = async (staffId) => {
  // staffId 에 해당하는 항목만 가져온다.
  const targetCollectionName = 'STAFF'
  const docData = await dbService.collection(targetCollectionName).doc(staffId).get()

  return docData.data()
}

export const staffGetAll = async () => {
  // 모든 STAFF 데이터를 가져온다.
  const targetCollectionName = 'STAFF'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.data())
}

export const staffGetAllIdList = async () => {
  // 모든 STAFF 의 Id List 반환
  // @return: String[]
  const targetCollectionName = 'STAFF'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.id)
}

const staffUpdatePathArray = async (staffId, updateObject) => {
  /*
  STAFF 의 staffId 에 대해서
  updateObject 의 내용들을 수정

  @param: controlId: String - CONTROL id
  @param: updateObject{
    "cyclePathArray": {
      "add": [cycleId, ...],
      "del": [cycleId, ...]
    },
    "processPathArray": {
      "add": [processId, ...],
      "del": [processId, ...]
    },
    "riskPathArray": {
      "add": [riskId, ...],
      "del": [riskId, ...]
    },
    "controlPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    },
    "staffPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    },
    "departmentPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    }
  }
  */

  const finalUpdateObject = {}

  if (staffId === '') {
    return
  }

  const staffObject = await staffGetWithId(staffId)
  const iterKeyList = ['controlPathArray', 'departmentPathArray']
  iterKeyList.forEach((targetKey) => {
    let resultIdList = staffObject[targetKey].map((path) => path.split('/')[1])
    const addList = updateObject[targetKey].add
    addList.forEach((addId) => {
      resultIdList.push(addId)
    })

    const delList = updateObject[targetKey].del
    delList.forEach((delId) => {
      const idx = resultIdList.indexOf(delId)
      if (idx > -1) {
        resultIdList.splice(idx, 1)
      }
    })

    resultIdList = [...new Set(resultIdList)]
    const resultPathArray = resultIdList.map(
      (id) => targetKey.split('PathArray')[0].toUpperCase() + '/' + id
    )
    finalUpdateObject[targetKey] = resultPathArray
  })
  return await dbService.collection('STAFF').doc(staffId).update(finalUpdateObject)
}

export const staffListUpdatePathArray = async (staffObject) => {
  /*
  STAFF 의 staffIdList 에 속하는 staffId 들에 대해서
  staffUpdatePathArray 를 호출

  @param: staffId: String - STAFF id
  @param: updateObject{
    "staffId": {},
    ...
  }
  */

  const result = Object.keys(staffObject).map(async (staffId) => {
    return await staffUpdatePathArray(staffId, staffObject[staffId])
  })

  return Promise.all(result)
}

const staffFindChangePathFromCreate = async (staffId, departmentId) => {
  /*
  STAFF 를 생성할 때 생기는 연결된 path 들의 변경사항을 모두 찾는다.
  DEPARTMENT
  (CONTROL 은 어차피 최초 생성 때 없을 것이기 때문에 영향 X)

  @param: staffId: String - STAFF id to be created
  @param: departmentId: String - DEPARTMENT id
  @return: Object{

  }
  */

  // DEPARTMENT 업데이트 객체
  const departmentUpdateObject = {}
  departmentUpdateObject[departmentId] = _.cloneDeep(updateObjectForm)
  departmentUpdateObject[departmentId].staffPathArray.add = [staffId]

  return { departmentUpdateObject }
}

export const staffCreate = async (staffObject) => {
  /*
  새로운 STAFF 를 만든다

  @param: staffObject: Object{
    id: String - STAFF id (이메일 주소)
    uid: String - Firebase Auth uid
    department: String - DEPARTMENT id
    name: String - STAFF name(이름)
  }
  */

  const staffId = staffObject.id
  const departmentId = staffObject.department

  const targetCollectionName = 'STAFF'
  const targetDocId = staffId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const updateResult = await staffFindChangePathFromCreate(staffId, departmentId)

  return await docRef
    .set({
      id: staffId,
      uid: staffObject.uid,
      email: staffId,
      name: staffObject.name,
      departmentName: departmentId,
      level: 'UPLOADER',
      selfPath: 'STAFF/' + staffId,
      controlPathArray: [],
      departmentPathArray: ['DEPARTMENT/' + departmentId]
    })
    .then(() => departmentListUpdatePathArray(updateResult.departmentUpdateObject))
}
