/* eslint-disable */
// NOTE: useWorker와 사용하기 때문에 webpack을 사용하지 말 것.
const XLSX = require('xlsx')

export const excelParsingPopulationFile = new Function('fileObject',
  `
  /*
  운영평가 모집단 엑셀파일을 파싱한다.
  사전에 정의한 Template 파일이다.

  결과의 형태는 다음과 같을 것이다.
  A | B | C
  1 | 2 | 3
  11| 22| 33
  {
    headerRow: ["A", "B", "C"],
    columnCountIncludeNumbering: 3,
    rowCountExceptHeader: 2,
    rowObjectList: [
      {
        "A": 1,
        "B": 2,
        "C": 3,
      },
      {
        "A": 11,
        "B": 22,
        "C": 33,
      }
    ],
  }
  */
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader()
    fileReader.onloadend = (evt) => {
      fileObject.state = 1
      // console.log(evt);
      const {
        target: { result: targetExcelFile }
      } = evt

      const excelData = XLSX.read(targetExcelFile, {
        type: 'binary',
        sheetStubs: true,
        cellDates: true,
        dateNF: 'yyyy-mm-dd'
      })

      const workSheetName = '모집단'
      const workSheet = excelData.Sheets[workSheetName]
      let excelResult = {}
      let excelResultException = []
      // 시작 위치는 무조건 정해져있다. -> 9행
      const range = XLSX.utils.decode_range(workSheet['!ref'])
      range.s.r = 7 // 헤더도 읽고 싶어서
      workSheet['!ref'] = XLSX.utils.encode_range(range)
      let jsonExcelData = XLSX.utils.sheet_to_json(workSheet, { defval: '', raw: false})
      if (jsonExcelData.length < 2) {
        // 헤더만 있거나 아무것도 없는 상태
        fileObject.state = 3
        excelResultException.push('모집단 엑셀파일이 비었습니다.')
        fileObject.excelData = excelResult
        fileObject.excelException = excelResultException
        resolve(fileObject)
        return
      }
      // 헤더 가져오기
      const headerRow = []
      const rawHeaderData = jsonExcelData[0]
      if (!rawHeaderData) {
        fileObject.state = 3
        excelResultException.push('모집단 헤더가 비었습니다.')
        fileObject.excelData = excelResult
        fileObject.excelException = excelResultException
        resolve(fileObject)
        return
      }
      let emptyHeaderFlag = false
      const duplicateHeaderMap = {}
      Object.keys(rawHeaderData).forEach((k, index) => {
        if (index !== 0) {
          let value = rawHeaderData[k]
          if (value !== '') {
            if (!(value in duplicateHeaderMap)) {
              duplicateHeaderMap[value] = 0
            } else {
              const duplicateValue = duplicateHeaderMap[value] + 1
              duplicateHeaderMap[value] = duplicateValue
              value = value + '_' + duplicateValue
            }
            headerRow.push(value)
            if (emptyHeaderFlag) {
              fileObject.state = 3
              excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
              headerRow.splice(headerRow.length - 1, 0, '')
              emptyHeaderFlag = false
            }
          } else {
            emptyHeaderFlag = true
          }
        }
      })
      const columnCountIncludeNumbering = headerRow.length
      // 헤더는 이제 제외
      jsonExcelData = jsonExcelData.slice(1)
      // 나머지 데이터
      const rowObjectList = []
      jsonExcelData.forEach((rowData) => {
        const tempRowData = {}
        Object.values(rowData).forEach((value, index) => {
          try {
            if (index > 0 && (index <= columnCountIncludeNumbering || value !== '')) {
              tempRowData[headerRow[index - 1]] = value
            }
          } catch {
            fileObject.state = 3
            excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
          }
        })
        if (Object.keys(tempRowData).length !== columnCountIncludeNumbering) {
          fileObject.state = 3
          excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
        }
        rowObjectList.push(tempRowData)
      })
      excelResult = {
        headerRow,
        columnCountIncludeNumbering,
        rowCountExceptHeader: rowObjectList.length,
        rowObjectList
      }
      excelResultException = [...new Set(excelResultException)]
      if (excelResultException.length > 0) {
        fileObject.state = 3
      }
      fileObject.excelData = excelResult
      fileObject.excelException = excelResultException
      resolve(fileObject)
    }
    fileReader.onerror = reject
    fileReader.readAsBinaryString(fileObject.file)
  })
  `)
