import { CellValueChangedEvent, ColDef } from '@ag-grid-community/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

import { LoadStaffs, Staff } from '../../base/data/Staff'
import { getKoName, getLevelName } from '../../base/data/Translation'
import { UserLevel } from '../../base/data/User'
import { departmentGetAllIdList } from '../../dataLoader/RCM/department'
import { adminUpdateStaff } from '../../dataLoader/Utils/admin'
import firebase from '../../dataLoader/fbase'
import AlertMessage, { AlertMessageHandler } from '../common/AlertMessage'
import CircleBackdrop from '../common/CircleBackdrop'
import CommonAgGrid from '../common/CommonAgGrid'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  buttons: {
    display: 'flex',
    margin: theme.spacing(1),
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(2)
    }
  },
  modal: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

function getColumns(departments: string[], myId: string): ColDef[] {
  return [
    { headerName: 'ID', field: 'id', flex: 1, sortable: true },
    {
      headerName: '이름',
      field: 'name',
      flex: 1,
      sortable: true,
      editable: (params: any): boolean => params.data.level !== 'BLOCKED',
      cellStyle: (params: any) => {
        if (params.data.level !== 'BLOCKED') {
          return { backgroundColor: 'rgb(255, 248, 240, 0.6)' }
        }
        return null
      }
    },
    {
      headerName: '부서',
      field: 'departmentName',
      flex: 1,
      sortable: true,
      editable: (params: any): boolean => params.data.level !== 'BLOCKED',
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: departments
      },
      cellStyle: (params: any) => {
        if (params.data.level !== 'BLOCKED') {
          return { backgroundColor: 'rgb(255, 248, 240, 0.6)' }
        }
        return null
      }
    },
    {
      headerName: '권한',
      field: 'level',
      flex: 1,
      sortable: true,
      valueFormatter: (params: any) => getLevelName(params.value),
      filterParams: {
        valueFormatter: (params: any) => getLevelName(params.value)
      },
      editable: (params: any): boolean => params.data.id !== myId,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: _.values(UserLevel)
      },
      cellStyle: (params: any) => {
        if (params.data.id !== myId) {
          return { backgroundColor: 'rgb(255, 248, 240, 0.6)' }
        }
        return null
      }
    }
  ]
}

const UserManagement: React.FC<{ active: boolean }> = ({ active }) => {
  const classes = useStyles()
  const [staffs, setStaffs] = React.useState([] as Staff[])
  const [departments, setDepartments] = React.useState([] as string[])
  const [states, setStates] = React.useState({
    loading: false
  })
  const alertRef = React.useRef<AlertMessageHandler>(null)

  React.useEffect(() => {
    if (active) {
      Promise.all([LoadStaffs(true), departmentGetAllIdList()]).then((values) => {
        setStaffs(values[0])
        setDepartments(values[1])
      })
    }
  }, [active])

  const onStaffValueChanged = (event: CellValueChangedEvent): void => {
    const newStaffs = _.cloneDeep(staffs)
    const selectedStaff = _.find(newStaffs, { id: event.data.id })
    if (!selectedStaff) return

    setStates({
      ...states,
      loading: true
    })

    adminUpdateStaff({
      id: selectedStaff.id,
      name: event.data.name,
      department: event.data.departmentName,
      level: event.data.level
    })
      .then((result) => {
        setStates({ ...states, loading: false })
        if (event.data.level === 'BLOCKED') {
          const adminId = _.get(result, 'adminId') || 'ADMIN'
          alertRef.current?.showAlert(
            'info',
            `BLOCK 처리되는 아이디가 담당하는 통제의 ${getKoName(
              'control',
              'owner'
            )}이 ADMIN 계정인 ${adminId}으로 변경됩니다.`
          )
        }
      })
      .catch((e) => {
        console.log('Failed to update. ', e)
        LoadStaffs()
          .then((values) => {
            setStaffs(values)
          })
          .finally(() => {
            setStates({ ...states, loading: false })
          })
      })
  }

  return (
    <div className={classes.root}>
      <CommonAgGrid
        rowData={staffs}
        onCellValueChanged={onStaffValueChanged}
        columnDefs={getColumns(departments, firebase.auth().currentUser?.email || '')}
      />
      <CircleBackdrop open={states.loading} />
      <AlertMessage ref={alertRef} />
    </div>
  )
}

export default UserManagement
