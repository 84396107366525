/* eslint-disable no-undef */
/* eslint-disable camelcase */
import JSZip from 'jszip'
import _ from 'lodash'
import moment from 'moment'
import XLSX from 'xlsx'

import { controlGetWithId } from '../RCM/control'
import { staffGetAllIdList, staffGetWithId } from '../RCM/staff'
import { guestGetAllIdList } from './admin'
import { parsingReadMap_DE, parsingWriteMap_DE } from './excelDE'
import { parsingReadMap_OE, parsingWriteMap_OE } from './excelOE'
import { storageUploadFile } from './storage'

export const excelParsingForInit = async (excelFile) => {
  /*
  초기 세팅을 위한 엑셀을 파싱한다
  (
    Staff.xlsx
    Department.xlsx
    Process.xlsx
    RCM.xlsx
  )

  TODO: 초기세팅이기 때문에 엑셀의 무결성은 직접 엑셀파일을 열어서 확인해야한다.

  @param: excelFile: File: 실제 엑셀 파일 객체
  @return: [Object]
  */

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onloadend = (evt) => {
      const {
        target: { result: targetExcelFile }
      } = evt
      const excelData = XLSX.read(targetExcelFile, { type: 'binary' })
      const workSheetName = excelData.SheetNames[0]
      const workSheet = excelData.Sheets[workSheetName]

      // 헤더부터 읽기 위해서
      const range = XLSX.utils.decode_range(workSheet['!ref'])
      range.s.r = -1
      workSheet['!ref'] = XLSX.utils.encode_range(range)

      let jsonExcelData = XLSX.utils.sheet_to_json(workSheet, { defval: '' })

      // 헤더(칼럼 리스트)
      const firstRow = jsonExcelData[0]
      const keyList = Object.values(firstRow)

      // 헤더 제외
      jsonExcelData = jsonExcelData.slice(1)

      const finalJsonArray = []
      jsonExcelData.forEach((rowData) => {
        const tempJson = {}
        Object.values(rowData).forEach((v, index) => {
          let tempV = v
          // 숫자 값들은 String 으로
          if (typeof v === 'number') {
            tempV = v.toString()
          }
          tempV = tempV.trim() // 꼭!
          tempJson[keyList[index]] = tempV
        })
        finalJsonArray.push(tempJson)
      })
      resolve(finalJsonArray)
    }

    fileReader.onerror = reject
    fileReader.readAsBinaryString(excelFile)
  })
}

export const excelParsingForStaffRegister = async (excelFile) => {
  // Admin 페이지에서 Staff Register 를 위한 엑셀 파싱

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onloadend = async (evt) => {
      const {
        target: { result: targetExcelFile }
      } = evt
      const excelData = XLSX.read(targetExcelFile, { type: 'binary' })
      const wsName = excelData.SheetNames[0]
      const ws = excelData.Sheets[wsName]

      // to get header row
      // set range.s.r = row number(=== header row) - 1
      const range = XLSX.utils.decode_range(ws['!ref'])
      range.s.r = -1
      ws['!ref'] = XLSX.utils.encode_range(range)

      let jsonExcelData = XLSX.utils.sheet_to_json(ws, { defval: '' })
      jsonExcelData = jsonExcelData.slice(1) // except for header row

      // GUEST 와 STAFF 의 이메일을 모두 가져와야한다
      // 이것들이 우리의 Firebase Auth 에 있는 email
      // GUEST
      const guestEmailList = await guestGetAllIdList()
      const staffEmailList = await staffGetAllIdList()

      const parsingResult = []

      jsonExcelData.forEach((rowData) => {
        const tempRowData = {
          error: false,
          errorMsg: ''
        }
        Object.values(rowData).forEach((v, index) => {
          if (index === 0) {
            // 부서
            v = v.trim()
            tempRowData.Department = v
          } else if (index === 1) {
            // 이름
            v = v.trim()
            tempRowData.Name = v
          } else if (index === 2) {
            // 이메일
            v = v.trim()
            tempRowData.Email = v
          }
        })

        const emailString = tempRowData.Email
        const nameString = tempRowData.Name
        const departmentString = tempRowData.Department
        if (emailString === '' || nameString === '' || departmentString === '') {
          tempRowData.error = true
          tempRowData.errorMsg = '값이 없는 필드가 존재합니다.'
          parsingResult.push(tempRowData)
        } else if (!emailString.includes('@') || emailString !== emailString.toLowerCase()) {
          tempRowData.error = true
          tempRowData.errorMsg = '잘못된 이메일 주소입니다.'
          parsingResult.push(tempRowData)
        } else if (guestEmailList.includes(emailString)) {
          tempRowData.error = true
          tempRowData.errorMsg = 'GUEST 에 이미 등록된 이메일 주소입니다.'
          parsingResult.push(tempRowData)
        } else if (staffEmailList.includes(emailString)) {
          tempRowData.error = true
          tempRowData.errorMsg = '직원에 이미 등록된 이메일 주소입니다.'
          parsingResult.push(tempRowData)
        } else {
          parsingResult.push(tempRowData)
        }
      })

      resolve(parsingResult)
    }

    fileReader.onerror = reject
    fileReader.readAsBinaryString(excelFile)
  })
}

function parseExcelCell(workSheet, cellId) {
  if (workSheet[cellId].t === 'd') {
    return workSheet[cellId].w
  }
  return workSheet[cellId].v.toString().trim()
}

export const excelParsingForOE = async (fileObject) => {
  /*
  운영평가 보고서 엑셀파일을 파싱한다.
  업로드를 위한 파싱은 지정된 위치의 값만 읽으면 된다.

  각 값들의 위치는 바뀌지 않기 때문에 다음 객체의 정보를 바탕으로 한다.

  TODO: number 랑 id 랑 같은지 체크필요
  */
  const excelParsingReadMap_OE = _.cloneDeep(parsingReadMap_OE)
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onloadend = (evt) => {
      const {
        target: { result: targetExcelFile }
      } = evt
      const excelData = XLSX.read(targetExcelFile, {
        type: 'binary',
        sheetStubs: true,
        cellDates: true,
        dateNF: 'yyyy-mm-dd'
      })

      // 기본으로 "2"
      fileObject.resultState = '2'

      const excelResult = {}
      const excelResultException = []
      let startRowForSearch

      // Overview
      let wsName = 'Overview'
      let workSheet = excelData.Sheets[wsName]
      let wsMap = excelParsingReadMap_OE[wsName]
      Object.keys(wsMap).forEach((cellIndex) => {
        try {
          let value = parseExcelCell(workSheet, cellIndex)
          // 엑셀에서 음영처리한 부분의 다음과 같은 내부 값이 들어온다.
          // <phoneticPr fontId="3" type="noConversion"/>
          // 예외처리
          if (value[0] === '<' && value.slice(-1) === '>') {
            value = ''
          }

          excelResult[wsMap[cellIndex].name] = value

          // 특이사항 - 작성일자
          // 엑셀의 날짜형식이 특이해서 변환
          if (wsMap[cellIndex].name === 'createDate') {
            let newValue = moment(new Date(value))
            if (!newValue.isValid()) {
              newValue = moment()
            }

            excelResult[wsMap[cellIndex].name] = newValue.format('YYYY-MM-DD')
          }
          // 특이사항 - 운영 테스트결과
          if (wsMap[cellIndex].name === 'testResult') {
            if (value === '예외사항 발생') {
              fileObject.resultState = '3'
            }
          }
          // 특이사항 - 비유효 항목수
          // 보고서의 최종 결과는 비유효 항목수로 결정된다
          if (wsMap[cellIndex].name === 'invalidCount') {
            // 이 경우 value 는 무조건 string 형태의 숫자 값이다.(예외 없음)
            const numValue = Number(value)
            if (numValue > 0) {
              // 비유효 항목수가 1개 이상이라면
              fileObject.resultState = '3'
            }
          }
        } catch {
          excelResult[wsMap[cellIndex].name] = ''
          excelResultException.push(wsMap[cellIndex].korName)
        }
      })

      // 모집단 및 샘플링
      wsName = '모집단 및 샘플링'
      workSheet = excelData.Sheets[wsName]
      wsMap = excelParsingReadMap_OE[wsName]
      Object.keys(wsMap).forEach((cellIndex) => {
        try {
          const value = parseExcelCell(workSheet, cellIndex)
          excelResult[wsMap[cellIndex].name] = value
        } catch {
          excelResult[wsMap[cellIndex].name] = ''
          excelResultException.push(wsMap[cellIndex].korName)
        }
      })
      // 특이사항 - 샘플링 결과
      if (excelResult.samplingCount !== '' && excelResult.populationTableColumnCount !== '') {
        const samplingResult = {
          headerRow: [],
          rowObjectList: [],
          rowCountExceptHeader: Number(excelResult.samplingCount),
          columnCountIncludeNumbering: Number(excelResult.populationTableColumnCount) + 1
        }
        // headerRow 채우기
        let samplingResultFlag = true
        let cellAlphabet = 'B'
        const rowNumberToString = '19'
        for (let i = 0; i < samplingResult.columnCountIncludeNumbering; i++) {
          const cellKey = String.fromCharCode(cellAlphabet.charCodeAt(0) + i) + rowNumberToString
          try {
            const value = parseExcelCell(workSheet, cellKey)
            samplingResult.headerRow.push(value)
          } catch {
            const value = ''
            samplingResult.headerRow.push(value)
            samplingResultFlag = false
            excelResultException.push('샘플링 결과의 헤더가 없습니다.')
            break
          }
        }
        // 각 Row 채우기
        if (samplingResultFlag) {
          cellAlphabet = 'B'
          const rowNumber = 20
          // rowObjectList 채우기
          for (let i = 0; i < samplingResult.rowCountExceptHeader; i++) {
            const rowObj = {}
            for (let j = 0; j < samplingResult.columnCountIncludeNumbering; j++) {
              const cellKey =
                String.fromCharCode(cellAlphabet.charCodeAt(0) + j) + String(rowNumber + i)
              const rowKey = samplingResult.headerRow[j]
              try {
                const value = parseExcelCell(workSheet, cellKey)
                rowObj[rowKey] = value
              } catch {
                const value = ''
                rowObj[rowKey] = value
              }
            }
            samplingResult.rowObjectList.push(rowObj)
          }
          excelResult.samplingResult = samplingResult
        } else {
          excelResult.samplingResult = {}
        }
      } else {
        excelResultException.push('샘플링 결과')
        excelResult.samplingResult = {}
      }

      // 운영평가 수행
      wsName = '운영평가 수행'
      workSheet = excelData.Sheets[wsName]
      wsMap = excelParsingReadMap_OE[wsName]
      Object.keys(wsMap).forEach((cellIndex) => {
        try {
          const value = parseExcelCell(workSheet, cellIndex)
          excelResult[wsMap[cellIndex].name] = value
        } catch {
          excelResult[wsMap[cellIndex].name] = ''
          excelResultException.push(wsMap[cellIndex].korName)
        }
      })
      // 특이사항 - 테스트 수행기준
      if (excelResult.samplingAttributeCount !== '') {
        const attributeContents = {
          headerRow: ['Attribute', '테스트 상세내용', '예외사항 정의'],
          rowObjectList: [],
          rowCountExceptHeader: Number(excelResult.samplingAttributeCount),
          columnCountIncludeNumbering: 3
        }

        try {
          // 각 Row 채우기
          const cellAlphabet = 'B'
          const rowNumber = 12
          for (let i = 0; i < attributeContents.rowCountExceptHeader; i++) {
            const rowObj = {}
            for (let j = 0; j < 3; j++) {
              const cellKey =
                String.fromCharCode(cellAlphabet.charCodeAt(0) + j) + String(rowNumber + i)
              const rowKey = attributeContents.headerRow[j]
              rowObj[rowKey] = parseExcelCell(workSheet, cellKey)
            }
            attributeContents.rowObjectList.push(rowObj)
            startRowForSearch = rowNumber + i
          }
          excelResult.attributeContents = attributeContents
        } catch {
          excelResultException.push('테스트 수행 기준')
          excelResult.attributeContents = {}
        }
      } else {
        excelResultException.push('테스트 수행 기준')
        excelResult.attributeContents = {}
      }
      // 특이사항 - 테스트 수행 내역
      if (
        Object.keys(excelResult.attributeContents).length !== 0 &&
        excelResult.samplingCount !== '' &&
        excelResult.samplingAttributeCount !== ''
      ) {
        try {
          // 테스트 수행 내역 셀 찾기
          startRowForSearch += 1
          let count = 0
          let markRow
          while (true) {
            const cellKey = 'B' + String(startRowForSearch + count)
            if (typeof workSheet[cellKey] === 'undefined') {
              count += 1
              continue
            }
            if (workSheet[cellKey].t === 'z') {
              count += 1
              continue
            }
            const contentString = parseExcelCell(workSheet, cellKey)
            if (contentString === '테스트 수행 내역') {
              markRow = startRowForSearch + count
              break
            }
            count += 1
          }

          // 테스트 수행 내역
          const testContents = {
            headerRow: ['No'],
            rowObjectList: [],
            rowCountExceptHeader: Number(excelResult.samplingCount),
            columnCountIncludeNumbering: Number(excelResult.samplingAttributeCount) * 2 + 3
          }

          // headerRow 채우기
          const headerRowCount = Number(excelResult.samplingAttributeCount)
          for (let i = 0; i < headerRowCount; i++) {
            const baseName = 'Attribute_'
            testContents.headerRow.push(baseName + String(i + 1) + '/결과')
            testContents.headerRow.push(baseName + String(i + 1) + '/상세')
          }
          testContents.headerRow.push('테스트 결과')
          testContents.headerRow.push('증빙자료 Ref')

          // 각 Row 채우기
          const cellAlphabet = 'B'
          const rowNumber = markRow + 4
          for (let i = 0; i < testContents.rowCountExceptHeader; i++) {
            const rowObj = {}
            for (let j = 0; j < testContents.columnCountIncludeNumbering; j++) {
              const cellKey =
                String.fromCharCode(cellAlphabet.charCodeAt(0) + j) + String(rowNumber + i)
              const rowKey = testContents.headerRow[j]
              rowObj[rowKey] = parseExcelCell(workSheet, cellKey)
            }
            testContents.rowObjectList.push(rowObj)
            startRowForSearch = rowNumber + i
          }

          startRowForSearch += 8
          const controlException = parseExcelCell(workSheet, 'C' + Number(startRowForSearch))

          excelResult.testContents = testContents
          excelResult.controlException = controlException
        } catch {
          excelResultException.push(
            '테스트 수행 내역: 전체 칸에 빈칸이 존재하여서는 안됨. 특히 "증빙자료 Ref." 열이 비어있지 않은지 확인 필요'
          )
          excelResult.testContents = {}

          excelResultException.push('식별된 예외사항 기재')
          excelResult.controlException = ''
        }
      } else {
        excelResultException.push(
          '테스트 수행 내역: 전체 칸에 빈칸이 존재하여서는 안됨. 특히 "증빙자료 Ref." 열이 비어있지 않은지 확인 필요'
        )
        excelResult.testContents = {}

        excelResultException.push('식별된 예외사항 기재')
        excelResult.controlException = ''
      }

      // 최종 예외처리
      if (excelResultException.length > 0) {
        fileObject.state = 3
      }

      fileObject.excelData = excelResult
      fileObject.excelException = excelResultException
      resolve(fileObject)
    }

    fileReader.onerror = reject
    fileReader.readAsBinaryString(fileObject.file)
  })
}

export const excelParsingForDE = async (fileObject) => {
  /*
  설계평가 보고서 엑셀파일을 파싱한다.
  업로드를 위한 파싱은 지정된 위치의 값만 읽으면 된다.
  */
  const excelParsingReadMap_DE = _.cloneDeep(parsingReadMap_DE)
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onloadend = (evt) => {
      const {
        target: { result: targetExcelFile }
      } = evt
      const excelData = XLSX.read(targetExcelFile, {
        type: 'binary',
        sheetStubs: true,
        cellDates: true,
        dateNF: 'yyyy-mm-dd'
      })

      // 기본으로 "2"
      fileObject.resultState = '2'

      const excelResult = {}
      const excelResultException = []

      // 설계평가
      const wsName = '설계평가'
      const workSheet = excelData.Sheets[wsName]
      const wsMap = excelParsingReadMap_DE[wsName]
      Object.keys(wsMap).forEach((cellIndex) => {
        try {
          const value = parseExcelCell(workSheet, cellIndex)
          excelResult[wsMap[cellIndex].name] = value

          // 특이사항 - 작성일자
          // 엑셀의 날짜형식이 특이해서 변환
          if (
            wsMap[cellIndex].name === 'createDate' ||
            wsMap[cellIndex].name === 'baselineReference'
          ) {
            const newValue = moment(new Date(value)).format('YYYY-MM-DD')
            console.log('what ', wsMap, cellIndex, excelResult)
            console.log('what ', wsMap[cellIndex].name)
            excelResult[wsMap[cellIndex].name] = newValue
          }

          // 평가 결론
          if (wsMap[cellIndex].name === 'result') {
            console.log('value:', value)
            // Effective
            if (value === 'Effective') {
              fileObject.resultState = '2' // 문제 없음
            }
            // Ineffective
            else if (value === 'Ineffective') {
              fileObject.resultState = '3' // 문제 있음
              console.log('Ineffective')
            }
          }
        } catch {
          excelResult[wsMap[cellIndex].name] = ''
          excelResultException.push(wsMap[cellIndex].korName)
        }
      })

      // 최종 예외처리
      if (excelResultException.length > 0) {
        fileObject.state = 3
      }

      fileObject.excelData = excelResult
      fileObject.excelException = excelResultException
      resolve(fileObject)
    }

    fileReader.onerror = reject
    fileReader.readAsBinaryString(fileObject.file)
  })
}

export async function excelParsingPopulationFile(fileObject) {
  /*
  NOTE: 이 함수를 수정할 경우, readPopulationFile.js도 함께 수정해야 한다.

  운영평가 모집단 엑셀파일을 파싱한다.
  사전에 정의한 Template 파일이다.

  결과의 형태는 다음과 같을 것이다.
  A | B | C
  1 | 2 | 3
  11| 22| 33
  {
    headerRow: ["A", "B", "C"],
    columnCountIncludeNumbering: 3,
    rowCountExceptHeader: 2,
    rowObjectList: [
      {
        "A": 1,
        "B": 2,
        "C": 3,
      },
      {
        "A": 11,
        "B": 22,
        "C": 33,
      }
    ],
  }
  */
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader()
    fileReader.onloadend = (evt) => {
      try {
        fileObject.state = 1
        // console.log(evt);
        const {
          target: { result: targetExcelFile }
        } = evt

        const excelData = XLSX.read(targetExcelFile, {
          type: 'binary',
          sheetStubs: true,
          cellDates: true,
          dateNF: 'yyyy-mm-dd'
        })

        const workSheetName = '모집단'
        const workSheet = excelData.Sheets[workSheetName]
        let excelResult = {}
        let excelResultException = []
        // 시작 위치는 무조건 정해져있다. -> 9행
        const range = XLSX.utils.decode_range(workSheet['!ref'])
        range.s.r = 7 // 헤더도 읽고 싶어서
        workSheet['!ref'] = XLSX.utils.encode_range(range)
        let jsonExcelData = XLSX.utils.sheet_to_json(workSheet, { defval: '', raw: false })
        if (jsonExcelData.length < 2) {
          // 헤더만 있거나 아무것도 없는 상태
          fileObject.state = 3
          excelResultException.push('모집단 엑셀파일이 비었습니다.')
          fileObject.excelData = excelResult
          fileObject.excelException = excelResultException
          resolve(fileObject)
          return
        }
        // 헤더 가져오기
        const headerRow = []
        const rawHeaderData = jsonExcelData[0]
        if (!rawHeaderData) {
          fileObject.state = 3
          excelResultException.push('모집단 헤더가 비었습니다.')
          fileObject.excelData = excelResult
          fileObject.excelException = excelResultException
          resolve(fileObject)
          return
        }
        let emptyHeaderFlag = false
        const duplicateHeaderMap = {}
        Object.keys(rawHeaderData).forEach((k, index) => {
          if (index !== 0) {
            let value = rawHeaderData[k]
            if (value !== '') {
              if (!(value in duplicateHeaderMap)) {
                duplicateHeaderMap[value] = 0
              } else {
                const duplicateValue = duplicateHeaderMap[value] + 1
                duplicateHeaderMap[value] = duplicateValue
                value = value + '_' + duplicateValue
              }
              headerRow.push(value)
              if (emptyHeaderFlag) {
                fileObject.state = 3
                excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
                headerRow.splice(headerRow.length - 1, 0, '')
                emptyHeaderFlag = false
              }
            } else {
              emptyHeaderFlag = true
            }
          }
        })
        const columnCountIncludeNumbering = headerRow.length
        // 헤더는 이제 제외
        jsonExcelData = jsonExcelData.slice(1)
        // 나머지 데이터
        const rowObjectList = []
        jsonExcelData.forEach((rowData) => {
          const tempRowData = {}
          Object.values(rowData).forEach((value, index) => {
            try {
              if (index > 0 && (index <= columnCountIncludeNumbering || value !== '')) {
                tempRowData[headerRow[index - 1]] = value
              }
            } catch {
              fileObject.state = 3
              excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
            }
          })
          if (Object.keys(tempRowData).length !== columnCountIncludeNumbering) {
            fileObject.state = 3
            excelResultException.push('헤더가 없는 데이터 열이 존재합니다.')
          }
          rowObjectList.push(tempRowData)
        })
        excelResult = {
          headerRow,
          columnCountIncludeNumbering,
          rowCountExceptHeader: rowObjectList.length,
          rowObjectList
        }
        excelResultException = [...new Set(excelResultException)]
        if (excelResultException.length > 0) {
          fileObject.state = 3
        }
        fileObject.excelData = excelResult
        fileObject.excelException = excelResultException
        resolve(fileObject)
      } catch (error) {
        fileObject.state = 3
        fileObject.excelException = ['형식에 맞지 않은 파일입니다. Template 파일을 사용해주세요.']
        resolve(fileObject)
      }
    }
    fileReader.onerror = reject
    fileReader.readAsBinaryString(fileObject.file)
  })
}

const excelExtractXmlData = async (blob) => {
  const zip = new JSZip()
  const loadZip = await zip.loadAsync(blob)
  const array8Data = await loadZip.file('xl/sharedStrings.xml').async('nodebuffer')
  return array8Data
}

const excelExtractWorkSheetXmlData = async (blob) => {
  const zip = new JSZip()
  const loadZip = await zip.loadAsync(blob)
  const array8Data = await loadZip.file('xl/worksheets/sheet1.xml').async('nodebuffer')
  return array8Data
}

const excelDecodeXmlData = (rawXmlData) => {
  const stringDecoder = new TextDecoder('utf-8')
  const stringData = stringDecoder.decode(rawXmlData)
  const xmlParser = new DOMParser()
  const decodedXmlData = xmlParser.parseFromString(stringData, 'text/xml')

  return decodedXmlData
}

const excelEditXmlData_OE = async (controlId, updateObject, xmlData) => {
  // xml Data 를 알맞게 조작

  if (Object.keys(updateObject).length === 0) {
    // 빈 updateObject
    // 이 경우는 CONTROL 의 데이터를 불러와서 이를 바탕으로 만들면 된다.
    updateObject = await controlGetWithId(controlId)
  }

  // 모든 si element
  const siElementList = xmlData.getElementsByTagName('si')

  // parsingWriteMap 에 해당 Key 의 index 맵핑
  const parsingWriteMap = _.cloneDeep(parsingWriteMap_OE)
  const writeKeyList = Object.keys(parsingWriteMap)
  for (let i = 0; i < siElementList.length; i++) {
    const content = siElementList[i].getElementsByTagName('t')[0].textContent
    if (writeKeyList.includes(content)) {
      // 예외
      if (content === 'Yes') {
        parsingWriteMap.keyControl = i
        writeKeyList.push('keyControl')
      } else if (content === '일별수시') {
        parsingWriteMap.period = i
        writeKeyList.push('period')
      } else if (content === '수동통제') {
        parsingWriteMap.autoManual = i
        writeKeyList.push('autoManual')
      } else {
        parsingWriteMap[content] = i
      }
    }
  }

  // siElement 의 textContent 를 updateObject 의 값으로 변경
  writeKeyList.forEach((key) => {
    const index = parsingWriteMap[key]
    if (index !== -1) {
      siElementList[index].getElementsByTagName('t')[0].textContent = updateObject[key]
    }
  })

  return xmlData
}

const excelEditXmlData_DE = async (controlId, updateObject, xmlData, wsXmlData) => {
  // xml Data 를 알맞게 조작

  if (Object.keys(updateObject).length === 0) {
    // 빈 updateObject
    // 이 경우는 CONTROL 의 데이터를 불러와서 이를 바탕으로 만들면 된다.
    updateObject = await controlGetWithId(controlId)
  }

  // 통제 담당자(owner) 에 아이디와 이름을 함께 적어주고 싶어서 이름을 가져와서 합성한 뒤에 교체
  const staffId = updateObject.owner
  if (staffId !== '') {
    const staffData = await staffGetWithId(staffId)
    const staffName = staffData.name
    const newOwnerString = `${staffName}(${staffId})`
    updateObject.owner = newOwnerString
  }

  // 모든 si element
  const siElementList = xmlData.getElementsByTagName('si')

  // parsingWriteMap 에 해당 Key 의 index 맵핑
  const parsingWriteMap = _.cloneDeep(parsingWriteMap_DE)
  const writeKeyList = Object.keys(parsingWriteMap)
  for (let i = 0; i < siElementList.length; i++) {
    const content = siElementList[i].getElementsByTagName('t')[0].textContent
    if (writeKeyList.includes(content)) {
      if (content === 'Yes') {
        parsingWriteMap.keyControl = i
        writeKeyList.push('keyControl')
      } else if (content === '수동통제') {
        parsingWriteMap.autoManual = i
        writeKeyList.push('autoManual')
      } else if (content === '예방') {
        parsingWriteMap.preventDetective = i
        writeKeyList.push('preventDetective')
      } else if (content === '일별수시') {
        parsingWriteMap.period = i
        writeKeyList.push('period')
      } else if (content === 'H') {
        parsingWriteMap.riskInherentRiskLevel = i
        writeKeyList.push('riskInherentRiskLevel')
      } else if (content === 'M') {
        parsingWriteMap.residualRiskLevel = i
        writeKeyList.push('residualRiskLevel')
      } else if (content === 'L') {
        parsingWriteMap.controlRiskLevel = i
        writeKeyList.push('controlRiskLevel')
      } else {
        parsingWriteMap[content] = i
      }
    }
  }

  // siElement 의 textContent 를 updateObject 의 값으로 변경
  writeKeyList.forEach((key) => {
    const index = parsingWriteMap[key]
    if (index !== -1) {
      siElementList[index].getElementsByTagName('t')[0].textContent = updateObject[key]
    }
  })

  return {
    modifiedXmlData: xmlData,
    modifiedWorkSheetXmlData: wsXmlData
  }
}

const excelEncodeXmlData_OE = (xmlData) => {
  const xmlString = new XMLSerializer().serializeToString(xmlData)
  const stringEncoder = new TextEncoder('utf-8')
  const encodedXmlData = stringEncoder.encode(xmlString)

  return encodedXmlData
}

const excelEncodeXmlData_DE = (xmlData, wsXmlData) => {
  const xmlString = new XMLSerializer().serializeToString(xmlData)
  let wsXmlString = new XMLSerializer().serializeToString(wsXmlData)
  wsXmlString = wsXmlString.replaceAll(' xmlns=""', '')

  const stringEncoder = new TextEncoder('utf-8')
  const finalStringData = stringEncoder.encode(xmlString)
  const finalWsStringData = stringEncoder.encode(wsXmlString)

  return {
    encodedXmlData: finalStringData,
    encodedWorkSheetXmlData: finalWsStringData
  }
}

const excelMergeXmlToBlob_OE = async (originalBlobData, newXmlData) => {
  const zip = new JSZip()
  const loadZip = await zip.loadAsync(originalBlobData)
  const modifiedZip = await loadZip.file('xl/sharedStrings.xml', newXmlData)
  const newBlobData = await modifiedZip.generateAsync({ type: 'blob' })

  return newBlobData
}

const excelMergeXmlToBlob_DE = async (originalBlobData, newXmlData, newWsXmlData) => {
  const zip = new JSZip()
  const loadZip = await zip.loadAsync(originalBlobData)
  let modifiedZip = await loadZip.file('xl/sharedStrings.xml', newXmlData)
  modifiedZip = await loadZip.file('xl/worksheets/sheet1.xml', newWsXmlData)
  const newBlobData = await modifiedZip.generateAsync({ type: 'blob' })

  return newBlobData
}

const excelBlobToExcel_OE = (blobData, fileName) => {
  const newExcelFile = new File([blobData], fileName, {
    type: 'application/vnd.ms-excel.sheet.macroEnabled.12'
  })

  return newExcelFile
}

const excelBlobToExcel_DE = (blobData, fileName) => {
  const newExcelFile = new File([blobData], fileName, { type: 'application/haansoftxlsx' })

  return newExcelFile
}

const excelEditEvaluationTemplate_OE = async (controlId, updateObject, blobData) => {
  const fileName = `(운영평가)(${controlId})(수행전Template).xlsm`
  const fileFullPath = `Template/운영평가/${fileName}`

  const rawXmlData = await excelExtractXmlData(blobData)
  const decodedXmlData = excelDecodeXmlData(rawXmlData)
  const modifiedXmlData = await excelEditXmlData_OE(controlId, updateObject, decodedXmlData)
  const encodedXmlData = excelEncodeXmlData_OE(modifiedXmlData)
  const finalExcelBlobData = await excelMergeXmlToBlob_OE(blobData, encodedXmlData)
  const finalExcelFile = excelBlobToExcel_OE(finalExcelBlobData, fileName)

  return await storageUploadFile(fileFullPath, finalExcelFile)
}

const excelEditEvaluationTemplate_DE = async (controlId, updateObject, blobData) => {
  const fileName = `(설계평가)(${controlId})(수행전Template).xlsx`
  const fileFullPath = `Template/설계평가/${fileName}`

  const rawXmlData = await excelExtractXmlData(blobData)
  const rawWorkSheetXmlData = await excelExtractWorkSheetXmlData(blobData)
  const decodedXmlData = excelDecodeXmlData(rawXmlData)
  const decodedWorkSheetXmlData = excelDecodeXmlData(rawWorkSheetXmlData)
  const { modifiedXmlData, modifiedWorkSheetXmlData } = await excelEditXmlData_DE(
    controlId,
    updateObject,
    decodedXmlData,
    decodedWorkSheetXmlData
  )
  const { encodedXmlData, encodedWorkSheetXmlData } = excelEncodeXmlData_DE(
    modifiedXmlData,
    modifiedWorkSheetXmlData
  )
  const finalExcelBlobData = await excelMergeXmlToBlob_DE(
    blobData,
    encodedXmlData,
    encodedWorkSheetXmlData
  )
  const finalExcelFile = excelBlobToExcel_DE(finalExcelBlobData, fileName)

  return await storageUploadFile(fileFullPath, finalExcelFile)
}

export const excelEditEvaluationTemplate = async (evalType, controlId, updateObject, blobData) => {
  // 해당 Template을 업데이트 해서 업로드

  if (evalType === 'OE') {
    return await excelEditEvaluationTemplate_OE(controlId, updateObject, blobData)
  } else if (evalType === 'DE') {
    return await excelEditEvaluationTemplate_DE(controlId, updateObject, blobData)
  }
}

export const excelCreateRCM = async (fileName, versionId, rowDataList) => {
  /**
   * 원하는 파일명과 데이터를 받아서 해당 엑셀을 만든다
   *
   * @param: fileName: string - 생성할 엑셀 파일이름("PROCESS.xlsx")
   * @param: rowDataList: Object[] - 엑셀에 쓸 데이터들
   */

  const currentDate = moment().format('YYYYMMDD')
  if (fileName === 'PROCESS.xlsx') {
    fileName = `분류 및 프로세스(${versionId}).xlsx`
  } else if (fileName === 'RCM.xlsx') {
    fileName = `리스크 및 컨트롤(${versionId}).xlsx`
  }
  const excelWorkSheet = XLSX.utils.json_to_sheet(rowDataList)
  const excelWorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, fileName.split('.')[0])
  XLSX.writeFile(excelWorkBook, fileName)
}
