import { Theme, makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

import { LoadStaffs, Staff } from '../../base/data/Staff'
import EvaluationStatusView from './EvaluationStatusView'
import NoticeView from './NoticeView'
import TimelineView from './TimelineView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    justifyContent: 'center',
    padding: theme.spacing(2, 10, 2, 10),
    flex: 1
  },
  rowOne: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: theme.spacing(80),
    padding: theme.spacing(1)
  },
  meereLogo: {
    display: 'block',
    width: 190,
    height: 20,
    margin: theme.spacing(1, 2)
  },
  tmonCrop: {
    overflow: 'hidden',
    maxWidth: 120,
    maxHeight: 28,
    margin: theme.spacing(0.5, 2)
  },
  tmonLogo: {
    display: 'block',
    maxWidth: 'initial',
    maxHeight: 'initial',
    width: 120,
    height: 120,
    marginTop: '-40%',
    marginLeft: '-10%'
  }
}))

const Dashboard: React.FC = () => {
  const classes = useStyles()
  const [staffs, setStaffs] = React.useState<Staff[]>([])

  const logoImgContainer = React.useMemo(() => {
    if (process.env.REACT_APP_IMAGE_DIR === 'meere') {
      return (
        <img
          className={classes.meereLogo}
          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo_orange.svg`}
          alt="logo"
        />
      )
    } else if (process.env.REACT_APP_IMAGE_DIR === 'tmon') {
      return (
        <div className={classes.tmonCrop}>
          <img
            className={classes.tmonLogo}
            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGE_DIR}/logo.png`}
            alt="logo"
          />
        </div>
      )
    }
    return undefined
  }, [])

  React.useEffect(() => {
    LoadStaffs().then((newStaff) => {
      setStaffs(newStaff)
    })
  }, [])

  return (
    <div className={classes.root}>
      {logoImgContainer}
      <div className={classes.rowOne}>
        <NoticeView staffs={staffs} />
        <TimelineView />
      </div>
      <EvaluationStatusView staffs={staffs} />
    </div>
  )
}

export default Dashboard
