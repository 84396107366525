import { AgGridReact } from '@ag-grid-community/react'
import { AgGridEvent, AllModules, ColumnApi, GridApi } from '@ag-grid-enterprise/all-modules'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../base/color'
import { EvaluationSummary, getKoEvaluation } from '../../base/data/Evaluation'
import { parseDateStr } from '../../base/utils/TimeUtils'
import {
  evaluationGetAllBase,
  evaluationSetReference
} from '../../dataLoader/Evaluation/evaluation'
import AlertMessage, { AlertMessageHandler } from '../common/AlertMessage'
import NoticeTextView from '../common/NoticeTextView'
import { getShortEvalType } from '../evaluation/common/EvaluationUtils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  }
}))

const EMPTY = '(없음)'

function closedCellStyle(params: any): any {
  if (params.data.state === '2') {
    return { backgroundColor: '#eeeeee' }
  }
}

interface GridProps {
  gridApi?: GridApi
  columnApi?: ColumnApi
}

interface Props {
  type: 'operation' | 'design'
}

const ReferenceSetting: React.FC<Props> = ({ type }) => {
  const classes = useStyles()
  const [evaluations, setEvaluations] = React.useState<EvaluationSummary[]>([])
  const [gridApi, setGridApi] = React.useState<GridProps>()
  const alertRef = React.useRef<AlertMessageHandler>(null)

  const columns = React.useMemo(() => {
    return [
      {
        headerName: '상태',
        field: 'state',
        valueFormatter: (params: any): any => {
          return params.value === '1' ? '진행중' : '마감'
        }
      },
      {
        headerName: '이름',
        field: 'evalName',
        flex: 1
      },
      {
        headerName: '통제 개수',
        field: 'controlIdList',
        valueFormatter: (params: any): any => {
          return params.value.length
        }
      },
      {
        headerName: '시작일',
        field: 'startDate',
        valueFormatter: (params: any): any => {
          return parseDateStr('YYYYMMDD', params.value)
        }
      },
      {
        headerName: '종료일',
        field: 'endDate',
        valueFormatter: (params: any): any => {
          return parseDateStr('YYYYMMDD', params.value)
        }
      },
      {
        headerName: '참조 할 평가',
        field: 'refEvaluationName',
        valueFormatter: (params: any): any => params.value || EMPTY,
        editable: (params: any): boolean => params.data.state === '1',
        cellEditorSelector: (params: any): any => {
          if (params.data.state === '1') {
            return {
              component: 'agRichSelectCellEditor',
              params: {
                values: params.data.candidates
              }
            }
          }
        }
      }
    ]
  }, [])

  React.useEffect(() => {
    evaluationGetAllBase(getShortEvalType(type)).then((summaries) => {
      // prettier-ignore
      const closedEvaluationNames = _(summaries)
            .filter({ state: '2' })
            .map('evalName')
            .value()

      closedEvaluationNames.unshift('')
      _.forEach(summaries, (summary) => {
        _.set(summary, 'candidates', closedEvaluationNames)
      })
      setEvaluations(summaries as EvaluationSummary[])
    })
  }, [type])

  return (
    <div className={classes.content}>
      <Typography.Title level={3}>{getKoEvaluation(type)}</Typography.Title>
      <div className="ag-theme-cck" style={{ flex: 1, position: 'relative' }}>
        <AgGridReact
          enableCellChangeFlash
          columnDefs={columns}
          defaultColDef={{
            wrapText: true,
            autoHeight: true,
            resizable: true,
            lockPosition: true,
            filter: 'agSetColumnFilter',
            suppressSizeToFit: true,
            filterParams: {
              excelMode: 'windows' // can be 'windows' or 'mac'
            },
            cellStyle: closedCellStyle
          }}
          modules={AllModules}
          rowData={_.sortBy(evaluations, ['state'])}
          onColumnResized={(params: any) => params.api.resetRowHeights()}
          onCellValueChanged={(event) => {
            console.log('on cell value changed ', event)
            if (event.colDef.field === 'refEvaluationName') {
              evaluationSetReference(getShortEvalType(type), event.data.evalName, event.newValue)
                .then(() => {
                  alertRef.current?.showAlert('success', '설정완료')
                })
                .catch((e) => {
                  console.log(e)
                  alertRef.current?.showAlert('error', '설정실패')
                })
            }
          }}
          onGridReady={(event: AgGridEvent): void => {
            setGridApi({
              gridApi: event.api,
              columnApi: event.columnApi
            })
          }}
        />
      </div>
      <AlertMessage ref={alertRef} />
    </div>
  )
}

const ReferenceSettings: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <NoticeTextView>
        참조 설정된 평가가 있을 경우, 수행요청 메일을 전송시에 참조 설정된 평가의 파일을 함께
        전송합니다.
      </NoticeTextView>
      <ReferenceSetting type="operation" />
      <ReferenceSetting type="design" />
    </div>
  )
}

export default ReferenceSettings
