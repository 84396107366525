import moment from 'moment'

import { dbService } from '../fbase'

export const backupSetFlag = async () => {
  // 백업 플래그

  // 20개
  const maxNumber = 21

  const backupDocs = await dbService.collection('BACKUP').get()
  const today = moment().format('YYYYMMDD')

  let deleteFlag = false
  let deleteTargetDocId = ''
  let updateFlag = true
  const docIdList = []

  backupDocs.forEach((doc) => {
    const docId = doc.id
    docIdList.push(docId)
    if (docId === today) {
      updateFlag = false
    }
  })

  // 개수 체크
  if (docIdList.length >= maxNumber) {
    // 제일 오래된걸 지워야함
    // "init" 은 항상 마지막이라 안지워짐
    docIdList.sort()
    deleteFlag = true
    deleteTargetDocId = docIdList[0]
  }

  if (updateFlag) {
    // 업데이트 해야함
    if (deleteFlag) {
      // 지우고 업데이트
      return await dbService
        .collection('BACKUP')
        .doc(deleteTargetDocId)
        .delete()
        .then(() => {
          dbService.collection('BACKUP').doc(today)
.set({
            flag: true
          })
        })
    } else {
      // 안지우고 업데이트
      return await dbService.collection('BACKUP').doc(today)
.set({
        flag: true
      })
    }
  }
}

export const backupGetList = async () => {
  /*
  백업이 된 날짜 목록을 보여준다

  @return: String[] - 백업이 일어난 날짜 리스트
  [
    "20210201",
    ...
  ]
  */

  const collectionRef = dbService.collection('BACKUP')
  const collectionData = await collectionRef.get()

  const result = []
  collectionData.docs.forEach((doc) => {
    const data = doc.data()
    const flag = data.flag
    const startFlag = data.startFlag

    if (flag && startFlag) {
      result.push(doc.id)
    }
  })

  return result
}

const backupGetDocumentObjectFromCollection = async (collectionName) => {
  // Do Not Use!!
  const collectionRef = await dbService.collection(collectionName).get()
  const documentObjectList = collectionRef.docs.map((doc) => {
    const obj = {}
    obj[doc.id] = doc.data()
    return obj
  })

  return documentObjectList
}

const backupGetDocumentObjectFromCollectionList = async (collectionList) => {
  // Do Not Use!!
  const result = collectionList.map(async (collection) => {
    const documentObjectList = await backupGetDocumentObjectFromCollection(collection)

    const obj = {}
    obj[collection] = documentObjectList
    return obj
  })

  return Promise.all(result)
}

const backupInsertEachCollection = async (todayDate, collectionName, collectionData) => {
  // Do Not Use!!
  const baseDocRef = dbService.collection('BACKUP').doc(todayDate)
  const result = Object.keys(collectionData).map(async (documentId) => {
    const docRef = baseDocRef.collection(collectionName).doc(documentId)
    return docRef.set(collectionData[documentId])
  })
}

const backupInsertData = async (todayDate, backupData) => {
  // Do Not Use!!
  const result = Object.keys(backupData).map(async (collectionName) => {
    const eachCollectionResult = await backupInsertEachCollection(
      todayDate,
      collectionName,
      backupData[collectionName]
    )
    return eachCollectionResult
  })

  return Promise.all(result)
}

const backupMakeData = async () => {
  // Firestore 에서 Backup Data 를 만든다
  // Do Not Use!!
  // 내부적으로는 STAFF 도 백업은 하자 - 복구 시에는 제외될 것임

  // const targetCollectionList = ['DEPARTMENT', 'CYCLE', 'PROCESS', 'RISK', 'CONTROL']
  const targetCollectionList = [
    'DEPARTMENT',
    'CYCLE',
    'PROCESS',
    'RISK',
    'CONTROL',
    'STAFF',
    'NOTICE'
  ]

  const backupDataObject = {}
  const documentObjectFromAllCollection = await backupGetDocumentObjectFromCollectionList(
    targetCollectionList
  )
  documentObjectFromAllCollection.forEach((collectionObj) => {
    const collectionName = Object.keys(collectionObj)[0]
    backupDataObject[collectionName] = {}

    const targetCollectionObjectList = collectionObj[collectionName]
    targetCollectionObjectList.forEach((documentObject) => {
      const documentId = Object.keys(documentObject)[0]
      const documentData = documentObject[documentId]
      backupDataObject[collectionName][documentId] = documentData
    })
  })

  return backupDataObject
}

export const backupExecute = async (todayDate) => {
  // Do Not Use!!
  const backupData = await backupMakeData()
  return await backupInsertData(todayDate, backupData).then(() => {
    if (todayDate === 'init') {
      return dbService
        .collection('BACKUP')
        .doc(todayDate)
        .set({
          flag: true,
          date: moment().format('YYYYMMDD')
        })
    }
  })
}

const backupCheckValidation = async (targetDate) => {
  const doc = await dbService.collection('BACKUP').doc(targetDate).get()
  const data = doc.data()
  const flag = data.flag
  const startFlag = data.startFlag

  let valid = false

  if (targetDate === 'init') {
    return true
  }
  if (flag && startFlag) {
    valid = true
    return valid
  } else {
    return valid
  }
}

const backupGetDocumentObjectFromCollectionForRestore = async (targetDate, collectionName) => {
  // Collection 에 속한 Document 의 ID 리스트
  const collectionRef = await dbService
    .collection('BACKUP')
    .doc(targetDate)
    .collection(collectionName)
    .get()
  const documentObjectList = collectionRef.docs.map((doc) => {
    const obj = {}
    obj[doc.id] = doc.data()
    return obj
  })

  return documentObjectList
}

const backupGetDocumentObjectFromCollectionListForRestore = async (targetDate, collectionList) => {
  const result = collectionList.map(async (collection) => {
    const documentObjectList = await backupGetDocumentObjectFromCollectionForRestore(
      targetDate,
      collection
    )

    const obj = {}
    obj[collection] = documentObjectList
    return obj
  })

  return Promise.all(result)
}

const backupGetBackupData = async (targetDate) => {
  // 백업 자료 가져오기
  // STAFF 는 빼고 가져와야 한다

  const collectionList = ['DEPARTMENT', 'CYCLE', 'PROCESS', 'RISK', 'CONTROL', 'NOTICE']

  const backupDataObject = {}
  const documentObjectForBackup = await backupGetDocumentObjectFromCollectionListForRestore(
    targetDate,
    collectionList
  )
  documentObjectForBackup.forEach((collectionObj) => {
    const collectionName = Object.keys(collectionObj)[0]
    backupDataObject[collectionName] = {}

    const targetCollectionObjectList = collectionObj[collectionName]
    targetCollectionObjectList.forEach((documentObject) => {
      const documentId = Object.keys(documentObject)[0]
      const documentData = documentObject[documentId]
      backupDataObject[collectionName][documentId] = documentData
    })
  })

  return backupDataObject
}

const backupDeleteEachCollectionBeforeBackup = async (collectionName) => {
  // 해당 이름의 collection 을 삭제
  const collectionDocs = await dbService.collection(collectionName).get()
  return await collectionDocs.docs.map(async (doc) => {
    doc.ref.delete()
  })
}

const backupDeletionForBeforeBackup = async () => {
  // 백업을 하기 전에 해당되는 컬렉션들을 삭제
  const collectionList = ['DEPARTMENT', 'CYCLE', 'PROCESS', 'RISK', 'CONTROL', 'NOTICE']
  const result = collectionList.map(async (collectionName) => {
    return await backupDeleteEachCollectionBeforeBackup(collectionName)
  })

  return Promise.all(result)
}

const backupInsertDocumentForRestore = async (collectionName, collectionData) => {
  // Collection 의 Document 들을 넣는다.
  const result = Object.keys(collectionData).map(async (documentId) => {
    const docRef = dbService.collection(collectionName).doc(documentId)
    return docRef.set(collectionData[documentId])
  })

  return Promise.all(result)
}

const backupInsertCollectionForRestore = async (restoreData) => {
  // 만든 backupData 를 현재 DB에 넣는다
  const result = Object.keys(restoreData).map(async (collectionName) => {
    const eachCollectionResult = await backupInsertDocumentForRestore(
      collectionName,
      restoreData[collectionName]
    )
    return eachCollectionResult
  })

  return Promise.all(result)
}

export const backupRestore = async (targetDate) => {
  /*
  해당 날짜로 DB를 복구
  내부적으로 STAFF 자료는 있지만 제외하고 복구시킨다
  @param: targetDate - String - 목표날짜 "20210201"
  */

  // 해당날짜의 상태체크
  const valid = await backupCheckValidation(targetDate)

  if (!valid) {
    return Promise.reject(new Error(`${targetDate} 는 백업이 불가능한 날짜입니다.`))
  }

  // 해당날짜의 데이터 가져오기
  const restoreData = await backupGetBackupData(targetDate)

  return await backupDeletionForBeforeBackup().then(() =>
    backupInsertCollectionForRestore(restoreData)
  )
  // TODO: 우리 복구하고나면 STAFF랑 나머지 싱크 안맞는건 어떻게 하기로했더라..
}
