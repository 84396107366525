/* eslint-disable max-len */
import Tabs from 'antd/lib/tabs'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { lightBlue } from '../../../base/color'
import { BasePRC, Control, PRCType, Process, Risk } from '../../../base/data/PRC'
import { Staff } from '../../../base/data/Staff'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CardTabs from '../../common/CardTabs'
import CycleEditor from './CycleEditor'
import PRCEditor from './PRCEditor'
import {
  Cycle,
  getCycleToIdMap,
  LoadControl,
  LoadCycle,
  LoadProcess,
  LoadRisk,
  LoadStaff
} from './RCMEditorUtils'

type LoadType = 'cycle' | 'process' | 'risk' | 'control' | 'staff'

type GetReferenceFunction = (
  cycle: string,
  category: string,
  subCategory: string
) => Record<string, string>

function GetCycleIds(cycles: Cycle[]): {
  cycleNumber: string
  categoryNumber: string
  subCategoryNumber: string
}[] {
  const ids = []
  for (const cycle of cycles) {
    for (const category of cycle?.children) {
      for (const subCategory of category?.children) {
        ids.push({
          cycleNumber: cycle.id,
          categoryNumber: category.id,
          subCategoryNumber: subCategory.id
        })
      }
    }
  }
  return ids
}

const RCMEditor: React.FC = () => {
  const [states, setStates] = React.useState({
    loading: true
  })
  const [data, setData] = React.useState({
    cycle: [] as Cycle[],
    cycleNameToNumberMap: {} as Record<string, string>,
    process: [] as Process[],
    risk: [] as Risk[],
    control: [] as Control[],
    staff: [] as Staff[],
    processIds: {} as Record<string, { id: string; name: string; disabled: boolean }[]>,
    riskIds: {} as Record<string, { id: string; name: string; disabled: boolean }[]>,
    controlIds: {} as Record<string, { id: string; name: string; disabled: boolean }[]>
  })
  const [warning, setWarning] = React.useState({
    process: '',
    risk: '',
    control: '',
    riskMapping: '',
    controlMapping: ''
  })
  const [activeTab, setActiveTab] = React.useState('1')
  const alertRef = React.useRef<AlertMessageHandler>(null)

  const LoadPRCData = React.useCallback(
    (types: LoadType[]): void => {
      setStates({ ...states, loading: true })
      LoadControl().then((controls) => {
        const loads: Promise<any>[] = []
        _.forEach(types, (type) => {
          if (type === 'cycle') loads.push(LoadCycle())
          if (type === 'process') loads.push(LoadProcess(controls.data))
          if (type === 'risk') loads.push(LoadRisk(controls.data))
          if (type === 'staff') loads.push(LoadStaff())
        })

        Promise.all(loads).then((values) => {
          const newData = {}
          _.forEach(_.concat(values, controls), ({ type, data: value }) => {
            if (type === 'cycle') {
              Object.assign(newData, {
                cycle: value,
                cycleNameToNumberMap: _.reduce(
                  value,
                  (result, item: Cycle) => {
                    result[item.name] = item.id
                    return result
                  },
                  {} as Record<string, string>
                )
              })
            } else if (type === 'staff') {
              Object.assign(newData, { staff: value })
            } else {
              Object.assign(newData, {
                [type]: value,
                [`${type}Ids`]: getCycleToIdMap(type, value)
              })
            }
          })

          setData({
            ...data,
            ...newData
          })
          setStates({
            ...states,
            loading: false
          })
        })
      })
    },
    [states, data]
  )

  React.useEffect(() => {
    LoadPRCData(['cycle', 'process', 'risk', 'control', 'staff'])
  }, [])

  React.useEffect(() => {
    const newWarning = {
      process: '',
      risk: '',
      control: '',
      riskMapping: '',
      controlMapping: ''
    }

    for (const id of GetCycleIds(data.cycle)) {
      if (!newWarning.process && !_.find(data.process, id)) {
        newWarning.process = `분류 ${id.cycleNumber}.${id.categoryNumber}.${id.subCategoryNumber}에 프로세스가 존재하지 않습니다.`
      }
      if (!newWarning.risk && !_.find(data.risk, id)) {
        newWarning.risk = `분류 ${id.cycleNumber}.${id.categoryNumber}.${id.subCategoryNumber}에 리스크가 존재하지 않습니다.`
      }
      if (!newWarning.control && !_.find(data.process, id)) {
        newWarning.control = `분류 ${id.cycleNumber}.${id.categoryNumber}.${id.subCategoryNumber}에 컨트롤이 존재하지 않습니다.`
      }
    }

    const noProcess = _.find(data.risk, (risk) => _.isEmpty(risk.processIds))
    if (noProcess) {
      newWarning.riskMapping = `${noProcess.id}에 연결된 프로세스가 없습니다.`
    } else {
      const noControl = _.find(data.risk, (risk) => _.isEmpty(risk.controlIds))
      if (noControl) {
        newWarning.riskMapping = `${noControl.id}에 연결된 컨트롤이 없습니다.`
      }
    }

    const noRisk = _.find(data.control, (control) => _.isEmpty(control.riskIds))
    if (noRisk) {
      newWarning.controlMapping = `${noRisk.id}에 연결된 리스크가 없습니다.`
    } else {
      const noStaff = _.find(data.control, (control) => _.isEmpty(control.staffIds))
      if (noStaff) {
        newWarning.controlMapping = `${noStaff.id}에 연결된 담당자가 없습니다.`
      }
    }

    setWarning({
      ...warning,
      ...newWarning
    })
  }, [data.cycle, data.process, data.risk, data.control])

  const renderTabName = React.useCallback(
    (name: string, active: boolean, warningMessage: string): React.ReactElement => {
      return (
        <>
          {warningMessage && <Typography.Text type="danger">*</Typography.Text>}
          <Typography.Text style={{ color: active ? lightBlue.color : undefined }}>
            {name}
          </Typography.Text>
        </>
      )
    },
    []
  )

  const getReferenceIds = React.useCallback(
    (target: BasePRC[], key: 'id' | 'name'): GetReferenceFunction => {
      return (cycle: string, category: string, subCategory: string): Record<string, string> => {
        const results = _(target)
          .filter({
            cycleNumber: cycle,
            categoryNumber: category,
            subCategoryNumber: subCategory
          })
          .reduce((result, value) => {
            result[value.id] = _.get(value, key)
            return result
          }, {} as Record<string, string>)
        return results
      }
    },
    [data]
  )

  return (
    <>
      <CardTabs defaultActiveKey={activeTab} onChange={(activeKey) => setActiveTab(activeKey)}>
        <Tabs.TabPane key="1" tab="분류">
          <CycleEditor
            cycles={data.cycle}
            loadCycles={() => LoadPRCData(['cycle'])}
            loading={states.loading}
            setCycles={(newData) => setData({ ...data, cycle: newData })}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab={renderTabName('프로세스', activeTab === '2', warning.process)}>
          <PRCEditor
            cycles={data.cycle}
            data={data.process}
            loadData={() => LoadPRCData(['process'])}
            loading={states.loading}
            setData={(newData: any[]) => setData({ ...data, process: newData })}
            type="process"
            warning={warning.process}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab={renderTabName('리스크', activeTab === '3', warning.risk)}>
          <PRCEditor
            cycles={data.cycle}
            data={data.risk}
            loadData={() => LoadPRCData(['risk'])}
            loading={states.loading}
            setData={(newData: any[]) => setData({ ...data, risk: newData })}
            type="risk"
            warning={warning.risk}
            getReferenceIds={getReferenceIds(data.process, 'id')}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="4" tab={renderTabName('컨트롤', activeTab === '4', warning.control)}>
          <PRCEditor
            cycles={data.cycle}
            data={data.control}
            loadData={() => LoadPRCData(['control'])}
            loading={states.loading}
            setData={(newData: any[]) => setData({ ...data, control: newData })}
            staff={data.staff}
            type="control"
            warning={warning.control}
            getReferenceIds={getReferenceIds(data.risk, 'id')}
            getReferenceNames={getReferenceIds(data.risk, 'name')}
          />
        </Tabs.TabPane>
      </CardTabs>
      <AlertMessage ref={alertRef} />
    </>
  )
}

export default RCMEditor
