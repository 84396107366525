import _ from 'lodash'
import moment from 'moment'

const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export function getCurrentTime(): string {
  return moment().format(TIME_FORMAT) as string
}

export function getCurrentDate(): string {
  return moment().format('YYYY년 M월 D일') as string
}

export function parseTimeStr(timeStr: string): string {
  const parsed = moment(timeStr, TIME_FORMAT).format('LLL')
  if (_.isEqual(parsed, 'Invalid date')) {
    return timeStr
  }
  return parsed
}

export function parseDateStr(dateFormat: string, dateStr: string): string {
  const parsed = moment(dateStr, dateFormat).format('LL')
  if (_.isEqual(parsed, 'Invalid date')) {
    return dateStr
  }
  return parsed
}

export function parseDateTime(date: Date): string {
  const parsed = moment(date).format('LLL')
  if (_.isEqual(parsed, 'Invalid date')) {
    return ''
  }
  return parsed
}

export function toDateRange(start: string, end: string): string {
  const startDate = moment(start)
  const endDate = moment(end)
  if (startDate.year() === endDate.year()) {
    return `${startDate.format('YYYY-MM-DD')} ~ ${endDate.format('MM-DD')}`
  }
  return `${startDate.format('YYYY-MM-DD')} ~ ${endDate.format('YYYY-MM-DD')}`
}
