import { Tree } from 'antd'
import { DataNode } from 'antd/lib/tree'
import _ from 'lodash'
import React from 'react'

const MyTreeNode: React.FC<DataNode> = ({ title }) => {
  return (
    // <div style={{ backgroundColor: '#ffffffff' }}>{title}</div>
    <div>{title}</div>
  )
}

interface Props {
  treeData: DataNode[]
  expandedKeys: string[]
  selectedCategories: string[]
  onSelect: (selectedKeys: string[]) => void
}

const CategoryTree: React.FC<Props> = ({
  treeData,
  expandedKeys,
  selectedCategories,
  onSelect
}) => {
  const getSelectedKeys = (keys: (string | number)[], data: DataNode[]): string[] => {
    const sortedKeys = Array<string>(0)
    _.forEach(data, (item: DataNode) => {
      if (_.includes(keys, item.key)) {
        sortedKeys.push(item.key as string)
      }
      if (item.children) {
        sortedKeys.push(...getSelectedKeys(keys, item.children))
      }
    })
    return sortedKeys
  }

  const treeOnSelect = (keys: (string | number)[]): void => {
    const sortedKeys = getSelectedKeys(keys, treeData)
    onSelect(sortedKeys)
  }
  return (
    <Tree
      autoExpandParent
      defaultExpandAll
      multiple
      selectable
      expandedKeys={expandedKeys}
      selectedKeys={selectedCategories}
      titleRender={MyTreeNode}
      treeData={treeData}
      onSelect={treeOnSelect}
    />
  )
}

export default CategoryTree
