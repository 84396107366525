import _ from 'lodash'

import { BasePRC, isControl, YesNo } from '../base/data/PRC'
import { Staff } from '../base/data/Staff'

export type TreeKeyType =
  | 'cycle'
  | 'category'
  | 'subCategory'
  | 'department'
  | 'owner'
  | 'performer'

export interface TreeItem {
  type: TreeKeyType | 'process' | 'risk' | 'control'
  id: string
  name: string
  children?: TreeItem[]
  keyControl?: boolean
}

export function getName(
  key: TreeKeyType,
  cycles: any,
  departments: any,
  staffs: Staff[],
  id: string
): string {
  if (key === 'cycle') {
    const cycle = _.find(cycles, { cycleNumber: id })
    if (cycle) return _.get(cycle, 'cycleName')
  } else if (key === 'category') {
    const ids = id.split('-')
    if (ids.length === 2) {
      const cycle = _.find(cycles, { cycleNumber: ids[0], categoryNumber: ids[1] })
      if (cycle) return _.get(cycle, 'categoryName')
    }
  } else if (key === 'subCategory') {
    const ids = id.split('-')
    if (ids.length === 3) {
      const cycle = _.find(cycles, {
        cycleNumber: ids[0],
        categoryNumber: ids[1],
        subCategoryNumber: ids[2]
      })
      if (cycle) return _.get(cycle, 'subCategoryName')
    }
  } else if (key === 'department') {
    const department = _.find(departments, { id })
    if (department) return _.get(department, 'departmentName')
  } else {
    const staff = _.find(staffs, { id })
    if (staff) return _.get(staff, 'name')
  }
  return id
}

export function getDataTree(
  keys: TreeKeyType[],
  data: BasePRC[],
  cycles: any,
  departments: any,
  staffs: Staff[]
): TreeItem[] {
  if (_.isEmpty(keys)) {
    return data.map((value: BasePRC) => {
      return {
        type: value.type,
        id: value.id,
        name: value.name,
        keyControl: isControl(value) ? value.keyControl === YesNo.yes : undefined
      }
    })
  }
  const target = keys[0]
  return _(data)
    .groupBy((element) => {
      // const base = element as BasePRC
      if (target === 'cycle') {
        return element.cycleNumber
      } else if (target === 'category') {
        return element.cycleNumber + '-' + element.categoryNumber
      } else if (target === 'subCategory') {
        return element.cycleNumber + '-' + element.categoryNumber + '-' + element.subCategoryNumber
      } else if (target === 'department') {
        // Control
        return _.get(element, 'department')
      } else if (target === 'owner') {
        // Control
        return _.get(element, 'owner')
      } else if (target === 'performer') {
        // Control
        return _.get(element, 'performer')
      }
    })
    .map((values, key) => {
      const base = values as BasePRC[]
      return {
        type: target,
        id: key,
        name: getName(target, cycles, departments, staffs, key),
        children: getDataTree(_.drop(keys), base, cycles, departments, staffs)
      }
    })
    .value()
}
