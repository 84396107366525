import _ from 'lodash'

import { dbService } from '../fbase'

export const departmentInsertDataListForInit = async (dataObjectList) => {
  /*
  Init 을 위해서 Excel 에서 넘어온 Department Data List 및 추가 정보들을 Firestore 에 집어넣는다

  @param: dataObjectList: Object[]: 데이터 리스트
  */
  const departmentInsertDataForInit = async (dataObject) => {
    // 단일 Data 를 집어넣는다.
    const targetCollectionName = 'DEPARTMENT'
    const targetDocId = dataObject.name
    const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

    return await docRef.set({
      id: dataObject.name,
      uid: dataObject.name,
      name: dataObject.name,
      selfPath: 'DEPARTMENT/' + dataObject.name,
      staffPathArray: [],
      controlPathArray: []
    })
  }

  const result = dataObjectList.map(async (dataObject) => {
    return await departmentInsertDataForInit(dataObject)
  })

  console.log('Insert Department Data For Init Done')
  return Promise.all(result)
}

export const departmentGetWithId = async (departmentId) => {
  // departmentId 에 해당하는 항목만 가져온다.
  const targetCollectionName = 'DEPARTMENT'
  const docData = await dbService.collection(targetCollectionName).doc(departmentId).get()

  return docData.data()
}

export const departmentGetAll = async () => {
  // 모든 DEPARTMENT 데이터를 가져온다.
  const targetCollectionName = 'DEPARTMENT'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.data())
}

export const departmentGetAllIdList = async () => {
  // 모든 DEPARTMENT 의 Id List 반환
  // @return: String[]
  const targetCollectionName = 'DEPARTMENT'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.id)
}

const departmentUpdatePathArray = async (departmentId, updateObject) => {
  /*
  DEPARTMENT 의 departmentId 에 대해서
  updateObject 의 내용들을 수정

  @param: departmentId: String - DEPARTMENT id
  @param: updateObject{
    "cyclePathArray": {
      "add": [cycleId, ...],
      "del": [cycleId, ...]
    },
    "processPathArray": {
      "add": [processId, ...],
      "del": [processId, ...]
    },
    "riskPathArray": {
      "add": [riskId, ...],
      "del": [riskId, ...]
    },
    "controlPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    },
    "staffPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    },
    "departmentPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    }
  }
  */

  const finalUpdateObject = {}

  if (departmentId === '') {
    return
  }

  const departmentObject = await departmentGetWithId(departmentId)
  const iterKeyList = ['controlPathArray', 'staffPathArray']
  iterKeyList.forEach((targetKey) => {
    let resultIdList = departmentObject[targetKey].map((path) => path.split('/')[1])
    const addList = updateObject[targetKey].add
    addList.forEach((addId) => {
      resultIdList.push(addId)
    })

    const delList = updateObject[targetKey].del
    delList.forEach((delId) => {
      const idx = resultIdList.indexOf(delId)
      if (idx > -1) {
        resultIdList.splice(idx, 1)
      }
    })

    resultIdList = [...new Set(resultIdList)]
    const resultPathArray = resultIdList.map(
      (id) => targetKey.split('PathArray')[0].toUpperCase() + '/' + id
    )
    finalUpdateObject[targetKey] = resultPathArray
  })
  return await dbService.collection('DEPARTMENT').doc(departmentId)
.update(finalUpdateObject)
}

export const departmentListUpdatePathArray = async (departmentObject) => {
  /*
  DEPARTMENT 의 departmentIdList 에 속하는 departmentId 들에 대해서
  departmentUpdatePathArray 를 호출

  @param: departmentId: String - DEPARTMENT id
  @param: updateObject{
    "departmentId": {},
    ...
  }
  */

  const result = Object.keys(departmentObject).map(async (departmentId) => {
    return await departmentUpdatePathArray(departmentId, departmentObject[departmentId])
  })

  return Promise.all(result)
}

export const departmentCreate = async (departmentId) => {
  /*
  새로운 부서를 추가한다.
  필요한 것은 새로운 부서의 이름

   @param String departmentId - 새로운 부서의 이름(아이디)
  */

  // 기존에 존재하는 부서의 이름(아이디) 인지 체크
  const departmentDataAll = await departmentGetAll()
  const duplicateCheckForDepartmentId = _.find(departmentDataAll, { id: departmentId })
  if (!_.isUndefined(duplicateCheckForDepartmentId)) {
    return Promise.reject(new Error(`${departmentId} 는 이미 존재하는 부서입니다.`))
  }

  // 새로운 부서 추가
  const targetCollectionName = 'DEPARTMENT'
  const targetDocId = departmentId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef.set({
    id: departmentId,
    name: departmentId,
    uid: departmentId,
    selfPath: `DEPARTMENT/${departmentId}`,
    staffPathArray: [],
    controlPathArray: []
  })
}

export const departmentDelete = async (departmentId) => {
  /*
  부서를 삭제한다
  물론 해당 부서에는 소속된 직원이 한명도 없는 상태여야 가능하다
  
  @param String departmentId - 삭제할 부서의 이름(아이디)
  */

  // 해당 부서에 직원이 있는지 체크
  const departmentData = await departmentGetWithId(departmentId)
  const staffPathArray = departmentData.staffPathArray
  if (staffPathArray.length > 0) {
    return Promise.reject(
      new Error(`${departmentId} 에 소속된 직원이 있습니다. 먼저 다른 부서로 옮겨주세요`)
    )
  }

  // 부서 삭제
  const targetCollectionName = 'DEPARTMENT'
  const targetDocId = departmentId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef.delete()
}
