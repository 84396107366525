import { AgGridReact } from '@ag-grid-community/react'
import { AgGridEvent, AllModules, ColumnApi, GridApi } from '@ag-grid-enterprise/all-modules'
import { makeStyles, Theme } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import { Evaluation } from '../../../base/data/Evaluation'
import { DesignDefect, DesignDefectItem } from '../../../base/data/EvaluationDefect'
import { Staff } from '../../../base/data/Staff'
import TableHeader from '../../common/TableHeader'
import {
  CheckboxCellRenderer,
  Row,
  SwitchCellRenderer,
  checkEmptyReport,
  getDesignEvaluationColumns,
  getCellStyle,
  getTableData
} from '../common/DesignDefectUtils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
    border: grey.border,
    borderBottom: 0
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    margin: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  content: {
    overflowY: 'auto',
    padding: 0,
    flexGrow: 1
  },
  editableContent: {
    border: grey.border
  },
  description: {
    margin: theme.spacing(2),
    border: grey.border
  },
  performedButton: {
    marginRight: theme.spacing(1)
  }
}))

export interface DesignDefectHandler {
  getNewDesignDefect(): DesignDefect
}

interface Props {
  editable?: boolean
  staffs: Staff[]
  evaluation: Evaluation
}

const DesignDefectContentView = React.forwardRef<DesignDefectHandler, Props>(
  ({ editable, staffs, evaluation }, viewRef) => {
    const classes = useStyles()
    const [infoGridApi, setInfoGridApi] = React.useState({
      gridApi: {} as GridApi,
      gridColumnApi: {} as ColumnApi
    })
    const [methodGridApi, setMethodGridApi] = React.useState({
      gridApi: {} as GridApi,
      gridColumnApi: {} as ColumnApi
    })

    const [infoData, setInfoData] = React.useState<Row[]>([])
    const [methodData, setMethodData] = React.useState<Row[]>([])
    const [emptyCheck, setEmptyCheck] = React.useState({
      infoEmpty: true,
      methodEmpty: true
    })

    const prepareDataFromDesignDefect = React.useCallback(
      (newDesignDefect: DesignDefect): void => {
        newDesignDefect.weakStateData.control.id = evaluation.controlId
        newDesignDefect.weakStateData.risk.id = evaluation.data.content.riskData.id
        const {
          infoData: newInfoData,
          methodData: newMethodData,
          infoEmpty: newInfoEmpty,
          methodEmpty: newMethodEmpty
        } = getTableData(
          evaluation.data.content.riskData,
          evaluation.data.content.controlData,
          newDesignDefect,
          !!editable
        )
        setInfoData(newInfoData)
        setMethodData(newMethodData)
        setEmptyCheck({
          infoEmpty: newInfoEmpty,
          methodEmpty: newMethodEmpty
        })
      },
      [editable, evaluation]
    )

    React.useEffect(() => {
      if (!_.isEmpty(infoGridApi.gridApi)) {
        const params = { force: true, suppressFlash: false }
        infoGridApi.gridApi.refreshCells(params)
      }
    }, [infoData])
    React.useEffect(() => {
      if (!_.isEmpty(methodGridApi.gridApi)) {
        const params = { force: true, suppressFlash: false }
        methodGridApi.gridApi.refreshCells(params)
      }
    }, [methodData])

    const designDefect = React.useMemo(
      () => ({ weakStateData: evaluation.data.state.weakStateData } as DesignDefect),
      [evaluation]
    )
    React.useEffect(() => {
      prepareDataFromDesignDefect(designDefect)
    }, [designDefect])

    React.useImperativeHandle(viewRef, () => ({
      getNewDesignDefect() {
        const assignResults = (
          result: DesignDefect,
          row: { dataId: string; content: string; section: string; evidence: string }
        ): void => {
          if (row.dataId) {
            let content = row.content || ''
            // NOTE: Yes/No인 경우에, evidence만 있을 경우 자동으로 No를 추가한다.
            if (
              _.isEmpty(content) &&
              _.includes(['booleanSelector', 'yesNoSelector'], row.section) &&
              !_.isEmpty(row.evidence)
            ) {
              content = content ? 'Yes' : 'No'
            }

            _.set(result.weakStateData, row.dataId, {
              content,
              evidence: row.evidence || ''
            })
          }
        }

        const newEvaluationDefect = _.cloneDeep(designDefect)
        infoGridApi.gridApi.forEachNode((node) => {
          assignResults(newEvaluationDefect, node.data)
        })
        methodGridApi.gridApi.forEachNode((node) => {
          assignResults(newEvaluationDefect, node.data)
        })

        const checkNotChanged = (row: string | DesignDefectItem) => {
          return (
            !_.has(row, 'content') ||
            (_.get(row, 'content') === '' && _.get(row, 'evidence') === '')
          )
        }
        const notChanged =
          _.every(newEvaluationDefect.weakStateData.control, (row) => checkNotChanged(row)) &&
          _.every(newEvaluationDefect.weakStateData.risk, (row) => checkNotChanged(row))
        newEvaluationDefect.weakState = notChanged ? '3' : '4' // '3': 수정사항 있음. '4': 수정사항 없음
        return newEvaluationDefect
      }
    }))

    return (
      <>
        <div className={classes.root}>
          <TableHeader value="통제 정보 업데이트" />
          <div className="ag-theme-design-evaluation">
            <AgGridReact
              enableRangeSelection
              undoRedoCellEditing
              columnDefs={getDesignEvaluationColumns(staffs)}
              defaultColDef={{
                menuTabs: [],
                wrapText: true,
                autoHeight: true,
                resizable: true,
                lockPosition: true,
                suppressSizeToFit: true,
                cellStyle: getCellStyle
              }}
              domLayout="autoHeight"
              frameworkComponents={{ CheckboxCellRenderer, SwitchCellRenderer }}
              modules={AllModules}
              rowData={infoData}
              onCellValueChanged={(event) => {
                if (event.type === 'cellValueChanged' && event.data?.dataId === 'control.owner') {
                  const staff = _.find(staffs, { id: event.newValue })
                  if (staff) {
                    event.api.forEachNode((rowNode) => {
                      if (rowNode.data?.dataId === 'control.department') {
                        rowNode.setDataValue('content', staff.departmentName)
                      }
                    })
                  }
                }
                event.api.resetRowHeights()
                setEmptyCheck({
                  ...emptyCheck,
                  infoEmpty: checkEmptyReport(event.api)
                })
              }}
              onGridReady={(params: AgGridEvent): void => {
                setInfoGridApi({ gridApi: params.api, gridColumnApi: params.columnApi })
              }}
            />
          </div>
        </div>
        <div className={classes.root}>
          <TableHeader value="통제 평가 방법 업데이트" />
          <div className="ag-theme-design-evaluation">
            <AgGridReact
              enableRangeSelection
              undoRedoCellEditing
              columnDefs={getDesignEvaluationColumns(staffs)}
              defaultColDef={{
                menuTabs: [],
                wrapText: true,
                autoHeight: true,
                resizable: true,
                lockPosition: true,
                suppressSizeToFit: true,
                cellStyle: getCellStyle
              }}
              domLayout="autoHeight"
              frameworkComponents={{ CheckboxCellRenderer, SwitchCellRenderer }}
              modules={AllModules}
              rowData={methodData}
              onCellValueChanged={(event) => {
                event.api.resetRowHeights()
                setEmptyCheck({
                  ...emptyCheck,
                  methodEmpty: checkEmptyReport(event.api)
                })
              }}
              onGridReady={(params: AgGridEvent): void => {
                setMethodGridApi({ gridApi: params.api, gridColumnApi: params.columnApi })
              }}
            />
          </div>
        </div>
      </>
    )
  }
)

export default DesignDefectContentView
