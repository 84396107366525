import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import { EvalFile } from '../../../base/data/BaseFile'
import { Evaluation } from '../../../base/data/Evaluation'
import { Staff } from '../../../base/data/Staff'
import { evaluationStateToString } from '../common/EvaluationUtils'
import EvidenceFileUploader from './EvidenceFileUploader'
import EvidenceMappingView, { EvidenceMappingHandler } from './EvidenceMappingView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    border: grey.border,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    padding: theme.spacing(1)
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  }
}))

interface Props {
  staffs: Staff[]
  evaluation: Evaluation
  evidenceFiles: EvalFile[]
  evidences: string[]
  saveEvidences: (evidenceFiles: EvalFile[], evidences: string[]) => void
}

const EvidenceView: React.FC<Props> = ({
  staffs,
  evaluation,
  evidenceFiles,
  evidences,
  saveEvidences
}) => {
  const classes = useStyles()
  const [localEvidenceFiles, setLocalEvidenceFiles] = React.useState<EvalFile[]>([])
  const mappingViewRef = React.useRef<EvidenceMappingHandler>(null)

  React.useEffect(() => {
    setLocalEvidenceFiles(evidenceFiles)
  }, [evidenceFiles])

  return (
    <div>
      <div className={classes.saveButtonContainer}>
        <Typography.Text
          style={{
            marginRight: 4,
            visibility: _.includes(['3', '5'], evaluation?.data.state.state) ? 'visible' : 'hidden'
          }}
          type="danger"
        >
          {evaluationStateToString(evaluation.data.state.state)} 된 보고서는 변경할 수 없습니다.
        </Typography.Text>
        <Button
          type="primary"
          disabled={_.includes(['3', '5'], evaluation.data.state.state)}
          onClick={() =>
            saveEvidences(localEvidenceFiles, mappingViewRef.current?.getEvidenceMappings() || [])
          }
        >
          저장
        </Button>
      </div>
      <div className={classes.root}>
        <EvidenceFileUploader
          staffs={staffs}
          evaluationName={evaluation.data.base.name}
          controlId={evaluation.controlId}
          evidenceFiles={localEvidenceFiles}
          setEvidences={(files) => setLocalEvidenceFiles(files)}
        />
      </div>
      <div className={classes.root}>
        <EvidenceMappingView
          evaluation={evaluation}
          evidences={evidences}
          evidenceFiles={localEvidenceFiles}
          ref={mappingViewRef}
        />
      </div>
    </div>
  )
}

export default EvidenceView
