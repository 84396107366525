import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { staffGetAll } from '../../dataLoader/RCM/staff'
import { Evaluation } from './Evaluation'
import { getKoName } from './Translation'

export interface Staff {
  id: string
  name: string
  level: string // ADMIN | NORMAL | UPLOADER | WATCHER | BLOCKED
  departmentName: string
  email: string
  controlIds: string[]
}

function renderStaffText(value?: string): React.ReactElement {
  if (!_.isEmpty(value)) {
    return <Typography.Text>{value}</Typography.Text>
  }
  return (
    <Typography.Text type="danger">{getKoName('control', 'owner')}를 선택해주세요</Typography.Text>
  )
}

export function getStaffNameAndDepartment(staffs: Staff[], id: string): string {
  const staff = _.find(staffs, { id })
  if (!staff) {
    return id
  }

  return `[${staff.departmentName}] ${staff.name}`
}

export function getStaffNameAndEmail(staff?: Staff): string | undefined {
  if (!_.isEmpty(staff) && _.has(staff, 'name') && _.has(staff, 'email')) {
    return `${staff?.name} (${staff?.email})`
  }
  return undefined
}

export function getStaffNameAndEmailById(staffs: Staff[] | undefined, id: string): string {
  return getStaffNameAndEmail(_.find(staffs, { id }) as Staff) || id
}

export function renderStaffDepartment(staff?: Staff): React.ReactElement {
  return renderStaffText(staff?.departmentName)
}

export function renderStaff(staff?: Staff): React.ReactElement {
  return renderStaffText(getStaffNameAndEmail(staff))
}

export function StaffCellRenderer(params: any): React.ReactElement {
  return (
    <Typography.Text ellipsis={{ tooltip: params.valueFormatted }} style={{ width: '100%' }}>
      {params.valueFormatted}
    </Typography.Text>
  )
}

export async function LoadStaffs(includeBlocked?: boolean): Promise<Staff[]> {
  return staffGetAll().then((staffList) => {
    const newStaff: any = []
    _.forEach(staffList, ({ id, staffName: name, controlPathArray, ...rest }) => {
      if (!includeBlocked && rest.level === 'BLOCKED') {
        return
      }

      if (!_.find(newStaff, id)) {
        newStaff.push({
          id,
          name,
          controlIds: _.map(controlPathArray, (controlPath) => _.last(_.split(controlPath, '/'))),
          ...rest
        })
      }
    })
    return _.sortBy(newStaff, ['name'])
  })
}

export function StaffValueFormatter(staffs: Staff[]): (params: any) => any {
  return (params: any): any => {
    const value = _.get(params, 'value', '')
    const staff = getStaffNameAndEmailById(staffs, value)
    return staff || value
  }
}

export function StaffFormatValue(staffs: Staff[]): (value: any) => any {
  return (value: any): any => {
    const staff = getStaffNameAndEmailById(staffs, value)
    return staff || value
  }
}

export function getOwnerName(staffs: Staff[], evaluation: Evaluation | undefined): string {
  return (
    _.find(staffs, { id: evaluation?.data.state.controlOwner })?.name ||
    evaluation?.data.state.controlOwner ||
    ''
  )
}

export function getPerformerName(staffs: Staff[], evaluation: Evaluation | undefined): string {
  return (
    _.find(staffs, { id: evaluation?.data.state.controlPerformer })?.name ||
    evaluation?.data.state.controlPerformer ||
    ''
  )
}

export function filterStaffs(staffs: Staff[], isOwner: boolean): Staff[] {
  const levels = ['ADMIN', 'NORMAL']
  if (isOwner) {
    levels.push('UPLOADER')
  }

  return _(staffs)
    .map((staff) => (_.includes(levels, staff.level) ? staff : undefined))
    .compact()
    .value()
}
