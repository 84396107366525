import { ColDef, GridApi } from '@ag-grid-community/core'
import Checkbox from 'antd/lib/checkbox'
import Switch from 'antd/lib/switch'
import _ from 'lodash'
import React from 'react'

import { getEditableColor } from '../../../base/color'
import { Evaluation } from '../../../base/data/Evaluation'
import { DesignDefect } from '../../../base/data/EvaluationDefect'
import {
  AutoManual,
  Control,
  Period,
  PreventDetective,
  Risk,
  RiskLevel,
  YesNo
} from '../../../base/data/PRC'
import { StaffFormatValue, Staff, filterStaffs } from '../../../base/data/Staff'
import { getKoName } from '../../../base/data/Translation'

export interface Row {
  dataId?: string
  key: string
  base?: string
  content: string | boolean
  evidence?: string
  section?:
    | 'readOnly'
    | 'subTitle'
    | 'text'
    | 'selector'
    | 'booleanSelector'
    | 'yesNoSelector'
    | 'staffSelector'
    | 'departmentPrinter'
  editable?: boolean
  options?: string[]
}

function RequiredCellRenderer(props: any): HTMLElement {
  const text = document.createElement('p')
  text.style.marginBottom = '0'
  if (props.data.section === 'requiredText') {
    const span = document.createElement('span')
    span.style.color = 'red'
    span.style.fontWeight = 'normal'
    span.innerHTML = '*'
    text.appendChild(span)
  }
  text.innerHTML += props.value
  return text
}

export const CheckboxCellRenderer = (props: any): React.ReactElement => {
  return (
    <Checkbox
      checked={props.value === 'Yes' || (typeof props.value === 'boolean' && props.value)}
      onClick={(event) => {
        if (!props.data.editable || (props.isFinal && !props.data.hasFinal)) return
        const checked = (event.target as any)?.checked
        const colId = props.column.colId
        let dataValue
        if (_.includes(['Yes', 'No'], props.data[colId])) {
          dataValue = checked ? 'Yes' : 'No'
        } else {
          dataValue = checked
        }
        props.node.setDataValue(colId, dataValue)
      }}
    />
  )
}

export const SwitchCellRenderer = (props: any): React.ReactElement => {
  return (
    <Switch
      checked={props.value === 'Yes'}
      checkedChildren="Yes"
      unCheckedChildren="No"
      onClick={(checked) => {
        if (!props.data.editable || (props.isFinal && !props.data.hasFinal)) return
        const colId = props.column.colId
        props.node.setDataValue(colId, checked ? 'Yes' : 'No')
      }}
    />
  )
}

export function getCellStyle(params: any): any {
  const cellStyle = {
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '4px',
    paddingBottom: '4px',
    wordBreak: 'keep-all'
  }
  if (params.data.section === 'subTitle') {
    _.assign(cellStyle, {
      backgroundColor: '#f8f8f8',
      fontWeight: 700,
      fontSize: '13px',
      textAlign: 'center'
    })
  } else if (params.colDef.field === 'key') {
    _.assign(cellStyle, { backgroundColor: '#f8f8f8', fontWeight: 700, fontSize: '13px' })
  } else if (params.colDef.field === 'content' && params.data.section === 'readOnly') {
    _.assign(cellStyle, { backgroundColor: 'rgb(217, 217, 217, 0.45)' })
  } else if (params.colDef.editable || _.isEqual(params.colDef.field, 'hasFinal')) {
    _.assign(cellStyle, { backgroundColor: getEditableColor() })
  } else if (!params.colDef.editable) {
    _.assign(cellStyle, { backgroundColor: 'rgb(255, 255, 255, 0.45)' })
  }
  return cellStyle
}

function getSubTitleItem(base: string): Row {
  return {
    key: base,
    content: base + '(수정 대상 존재시 하기 기입)',
    evidence: base + '(변경항목 수정 이유 기입)',
    section: 'subTitle'
  }
}

function getReadOnlyItem(type: 'control' | 'risk', key: string, base: string): Row {
  return {
    key: getKoName(type, key),
    base,
    content: '수정불가',
    section: 'readOnly'
  }
}

function getBaseItem(
  defect: DesignDefect,
  type: 'control' | 'risk',
  key: string,
  base: string
): Row {
  return {
    dataId: `${type}.${key}`,
    key: getKoName(type, key) || key,
    section: 'text', // 'requiredText' or 'text'
    base,
    content: _.get(defect, `weakStateData.${type}.${key}.content`),
    evidence: _.get(defect, `weakStateData.${type}.${key}.evidence`)
  }
}

function getDesignItem(
  defect: DesignDefect,
  type: 'control' | 'risk',
  key: string,
  base: string,
  options?: string[]
): Row {
  const baseItem = getBaseItem(defect, type, key, base)
  if (_.isEmpty(options)) {
    return baseItem
  }

  return {
    ...baseItem,
    section: 'selector',
    options
  }
}

function getDesignBooleanItem(
  defect: DesignDefect,
  type: 'control' | 'risk',
  key: string,
  base: string
): Row {
  return {
    dataId: `${type}.${key}`,
    key: getKoName(type, key),
    base: base === 'Yes' || (typeof base === 'boolean' && base) ? '○' : '',
    content: _.get(defect, `weakStateData.${type}.${key}.content`) || '',
    evidence: _.get(defect, `weakStateData.${type}.${key}.evidence`) || '',
    section: 'booleanSelector'
  }
}

function getDesignYesNoItem(
  defect: DesignDefect,
  type: 'control' | 'risk',
  key: string,
  base: string
): any {
  const item = getDesignItem(defect, type, key, base)
  return {
    ...item,
    section: 'yesNoSelector'
  }
}

export function getDesignEvaluationColumns(staffs: Staff[]): ColDef[] {
  return [
    {
      headerName: '',
      field: 'key',
      editable: false,
      colSpan: (params: any): number => {
        if (params.data.section === 'subTitle') return 2
        return 1
      },
      cellRenderer: RequiredCellRenderer
    },
    {
      headerName: '기존 통제 정보',
      field: 'base',
      flex: 1,
      editable: false,
      valueFormatter: (params: any): any => {
        if (params.data.section === 'staffSelector') {
          return StaffFormatValue(staffs)(params.value)
        }
        return params.value
      }
    },
    {
      headerName: '수정대상',
      field: 'content',
      flex: 1,
      colSpan: (params: any): number => {
        if (params.data.section === 'readOnly') return 2
        return 1
      },
      editable: (params: any): boolean => {
        if (
          _.includes(
            ['subTitle', 'readOnly', 'booleanSelector', 'yesNoSelector', 'departmentPrinter'],
            params.data.section
          )
        ) {
          return false
        }
        return params.data.editable
      },
      cellRendererSelector: (params: any): any => {
        if (params.data.section === 'booleanSelector') {
          return { component: 'CheckboxCellRenderer' }
        } else if (params.data.section === 'yesNoSelector') {
          return { component: 'SwitchCellRenderer' }
        }
        return undefined
      },
      valueFormatter: (params: any): any => {
        if (params.data.section === 'staffSelector') {
          return StaffFormatValue(staffs)(params.value)
        }
        return params.value
      },
      cellEditorSelector: (params: any): any => {
        if (params.data.section === 'selector') {
          return {
            component: 'agRichSelectCellEditor',
            params: { values: params.data.options }
          }
        } else if (params.data.section === 'staffSelector') {
          const filteredStaffs = filterStaffs(staffs, params.data.dataId === 'control.owner')
          return {
            component: 'agRichSelectCellEditor',
            params: {
              values: _.map(filteredStaffs, (staff) => staff.id)
            }
          }
        }
        return {
          component: 'agLargeTextCellEditor',
          params: {
            maxLength: 5000,
            rows: 20
          }
        }
      }
    },
    {
      headerName: '수정근거',
      field: 'evidence',
      flex: 1,
      editable: (params: any): boolean => {
        if (_.includes(['subTitle', 'readOnly'], params.data.section)) {
          return false
        }
        return params.data.editable
      },
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: 5000,
        rows: 20
      }
    }
  ]
}

const getRowData = (api: GridApi): any[] => {
  const rowData: any[] = []
  api.forEachNode((node: any) => {
    rowData.push(node.data)
  })
  return rowData
}

const isEmptyReport = (rowData: any): boolean => {
  const content: any[] = []
  const evidence: any[] = []
  _.forEach(rowData, (row) => {
    if (_.includes(['subTitle', 'readOnly'], row.section)) return
    // No로 선택되어 있고 evidence칸이 비어있다면 비어있다고 간주한다.
    if (row.section === 'yesNoSelector' && row.content === 'No' && _.isEmpty(row.evidence)) {
      content.push(false)
      evidence.push(row.evidence)
    } else {
      content.push(row.content)
      evidence.push(row.evidence)
    }
  })
  return !(_.some(content, Boolean) || _.some(evidence, Boolean))
}

export function checkEmptyReport(api: GridApi): boolean {
  return isEmptyReport(getRowData(api))
}

export function getTableData(
  risk: Risk,
  control: Control,
  designDefect: DesignDefect,
  editable: boolean
): { infoData: Row[]; methodData: Row[]; infoEmpty: boolean; methodEmpty: boolean } {
  const infoData: Row[] = [
    getSubTitleItem('통제 기본 정보'),
    getReadOnlyItem('control', 'id', designDefect.weakStateData.control.id),
    getDesignItem(designDefect, 'control', 'narrative', control.narrative),
    getSubTitleItem('규정 및 자원'),
    getDesignItem(designDefect, 'control', 'policy', control.policy),
    getDesignItem(designDefect, 'control', 'ITDependencySystem', control.ITDependencySystem),
    getSubTitleItem('MRC'),
    getDesignYesNoItem(designDefect, 'control', 'MRC_YN', control.MRC_YN),
    getDesignItem(designDefect, 'control', 'MRC_Number', control.MRC_Number),
    getDesignItem(designDefect, 'control', 'MRC_IPE_Name', control.MRC_IPE_Name),
    getSubTitleItem('IPE 통제'),
    getDesignYesNoItem(designDefect, 'control', 'IPE_YN', control.IPE_YN),
    getDesignItem(designDefect, 'control', 'IPE_Number', control.IPE_Number),
    getDesignItem(designDefect, 'control', 'IPE_Name', control.IPE_Name),
    getSubTitleItem('통제 조직'),
    {
      ...getDesignItem(designDefect, 'control', 'department', control.department),
      section: 'departmentPrinter'
    },
    {
      ...getDesignItem(designDefect, 'control', 'incharge', control.incharge),
      section: 'staffSelector'
    },
    {
      ...getDesignItem(designDefect, 'control', 'owner', control.owner),
      section: 'staffSelector'
    },
    getDesignItem(designDefect, 'control', 'teamLeader', control.teamLeader),
    getDesignItem(designDefect, 'control', 'teamMember', control.teamMember),
    getSubTitleItem(getKoName('control', 'goal')),
    getDesignBooleanItem(designDefect, 'control', 'goal_Trust', control.goal_Trust),
    getDesignBooleanItem(designDefect, 'control', 'goal_Asset', control.goal_Asset),
    getDesignBooleanItem(designDefect, 'control', 'goal_Override', control.goal_Override),
    getDesignBooleanItem(designDefect, 'control', 'goal_Operation', control.goal_Operation),
    getDesignBooleanItem(designDefect, 'control', 'goal_Law', control.goal_Law),
    getSubTitleItem(getKoName('control', 'assertion')),
    getDesignBooleanItem(designDefect, 'control', 'assertion_EO', control.assertion_EO),
    getDesignBooleanItem(designDefect, 'control', 'assertion_C', control.assertion_C),
    getDesignBooleanItem(designDefect, 'control', 'assertion_RO', control.assertion_RO),
    getDesignBooleanItem(designDefect, 'control', 'assertion_PD', control.assertion_PD),
    getDesignBooleanItem(designDefect, 'control', 'assertion_Occur', control.assertion_Occur),
    getDesignBooleanItem(
      designDefect,
      'control',
      'assertion_Assessment',
      control.assertion_Assessment
    ),
    getDesignBooleanItem(
      designDefect,
      'control',
      'assertion_Measurement',
      control.assertion_Measurement
    ),
    getSubTitleItem('기타 통제 속성'),
    getDesignYesNoItem(designDefect, 'control', 'keyControl', control.keyControl || YesNo.no),
    getDesignItem(designDefect, 'control', 'accountCode', control.accountCode),
    getDesignItem(designDefect, 'control', 'accountName', control.accountName),
    getDesignItem(designDefect, 'control', 'reportFootnotes', control.reportFootnotes),
    getDesignItem(designDefect, 'control', 'period', control.period, [
      Period.byCase,
      Period.dailyAnyTime,
      Period.daily,
      Period.weekly,
      Period.monthly,
      Period.quarterly,
      Period.halfBiAnnual,
      Period.annual,
      Period.always
    ]),
    getDesignItem(designDefect, 'control', 'preventDetective', control.preventDetective, [
      PreventDetective.prevent,
      PreventDetective.detective
    ]),
    getDesignItem(designDefect, 'control', 'autoManual', control.autoManual, [
      AutoManual.auto,
      AutoManual.manual,
      AutoManual.ITAC
    ]),
    getDesignItem(designDefect, 'control', 'residualRiskLevel', control.residualRiskLevel, [
      RiskLevel.low,
      RiskLevel.moderate,
      RiskLevel.high
    ]),
    getSubTitleItem('관련 위험'),
    getReadOnlyItem('risk', 'id', risk.id),
    getDesignItem(designDefect, 'risk', 'name', risk.name),
    getDesignItem(designDefect, 'risk', 'RoMM', risk.RoMM),
    getDesignItem(designDefect, 'risk', 'inherentRiskLevel', risk.inherentRiskLevel, [
      RiskLevel.low,
      RiskLevel.moderate,
      RiskLevel.high
    ])
  ]
  const methodData: Row[] = [
    getSubTitleItem('통제 운영 속성'),
    getDesignItem(designDefect, 'control', 'controlRiskLevel', control.controlRiskLevel, [
      RiskLevel.low,
      RiskLevel.moderate,
      RiskLevel.high
    ]),
    getDesignItem(designDefect, 'control', 'TOC_Procedure', control.TOC_Procedure),
    getDesignItem(designDefect, 'control', 'TOC_Evidence', control.TOC_Evidence),
    getDesignItem(designDefect, 'control', 'TOC_Population', control.TOC_Population),
    getDesignItem(
      designDefect,
      'control',
      'TOC_PopulationCount',
      control.TOC_PopulationCount || ''
    ),
    getDesignItem(designDefect, 'control', 'TOC_TestProperties', control.TOC_TestProperties),
    getDesignItem(designDefect, 'control', 'TOC_Exception', control.TOC_Exception),
    getSubTitleItem(getKoName('control', 'TOC_TestMethod')),
    getDesignBooleanItem(
      designDefect,
      'control',
      'TOC_TestMethod_InquiryInspection',
      control.TOC_TestMethod_InquiryInspection
    ),
    getDesignBooleanItem(
      designDefect,
      'control',
      'TOC_TestMethod_Observation',
      control.TOC_TestMethod_Observation
    ),
    getDesignBooleanItem(
      designDefect,
      'control',
      'TOC_TestMethod_Reperformance',
      control.TOC_TestMethod_Reperformance
    )
  ]

  _.forEach(infoData, (row) => {
    row.editable = editable
  })
  _.forEach(methodData, (row) => {
    row.editable = editable
  })

  return {
    infoData,
    methodData,
    infoEmpty: isEmptyReport(infoData),
    methodEmpty: isEmptyReport(methodData)
  }
}

export function getEvaluationData(gridApi: GridApi, defect: DesignDefect): any {
  const data: any = {}
  gridApi.forEachNode((node: any) => {
    const row = node.data
    if (
      !_.includes(
        ['text', 'selector', 'booleanSelector', 'yesNoSelector', 'staffSelector'],
        row.section
      )
    ) {
      return
    }

    if (row.section === 'yesNoSelector' && row.content === 'No' && _.isEmpty(row.evidence)) {
      const yesNo = _.get(defect, `${row.dataId}.content`)
      if (yesNo !== 'Yes') {
        _.set(data, `${row.dataId}.content`, yesNo)
      }
    } else if (row.section === 'text') {
      _.set(data, `${row.dataId}.content`, row.content || '')
      _.set(data, `${row.dataId}.evidence`, row.evidence || '')
      return
    } else {
      _.set(data, `${row.dataId}.content`, row.content)
    }
    _.set(data, `${row.dataId}.evidence`, row.evidence)
  })
  return data
}

export function getDefectSummary(
  evaluation: Evaluation
): { category: string; key: string; content: string; evidence: string }[] {
  const defect = evaluation.data.state as DesignDefect
  const data: { category: string; key: string; content: string; evidence: string }[] = []

  const categories: Record<string, { key: 'risk' | 'control'; subKey: string }[]> = {
    '통제 기본 정보': [{ key: 'control', subKey: 'narrative' }],
    '규정 및 자원': [
      { key: 'control', subKey: 'policy' },
      { key: 'control', subKey: 'ITDependencySystem' }
    ],
    MRC: [
      { key: 'control', subKey: 'MRC_YN' },
      { key: 'control', subKey: 'MRC_Number' },
      { key: 'control', subKey: 'MRC_IPE_Name' }
    ],
    'IPE 통제': [
      { key: 'control', subKey: 'IPE_YN' },
      { key: 'control', subKey: 'IPE_Number' },
      { key: 'control', subKey: 'IPE_Name' }
    ],
    '통제 조직': [
      { key: 'control', subKey: 'department' },
      { key: 'control', subKey: 'incharge' },
      { key: 'control', subKey: 'owner' },
      { key: 'control', subKey: 'teamLeader' },
      { key: 'control', subKey: 'teamMember' }
    ],
    [getKoName('control', 'goal')]: [
      { key: 'control', subKey: 'goal_Trust' },
      { key: 'control', subKey: 'goal_Asset' },
      { key: 'control', subKey: 'goal_Override' },
      { key: 'control', subKey: 'goal_Operation' },
      { key: 'control', subKey: 'goal_Law' }
    ],
    [getKoName('control', 'assertion')]: [
      { key: 'control', subKey: 'assertion_EO' },
      { key: 'control', subKey: 'assertion_C' },
      { key: 'control', subKey: 'assertion_RO' },
      { key: 'control', subKey: 'assertion_PD' },
      { key: 'control', subKey: 'assertion_Occur' },
      { key: 'control', subKey: 'assertion_Assessment' },
      { key: 'control', subKey: 'assertion_Measurement' }
    ],
    '기타 통제 속성': [
      { key: 'control', subKey: 'keyControl' },
      { key: 'control', subKey: 'accountCode' },
      { key: 'control', subKey: 'accountName' },
      { key: 'control', subKey: 'reportFootnotes' },
      { key: 'control', subKey: 'period' },
      { key: 'control', subKey: 'preventDetective' },
      { key: 'control', subKey: 'autoManual' },
      { key: 'control', subKey: 'residualRiskLevel' }
    ],
    '관련 위험': [
      { key: 'risk', subKey: 'name' },
      { key: 'risk', subKey: 'RoMM' },
      { key: 'risk', subKey: 'inherentRiskLevel' }
    ],
    '통제 운영 속성': [
      { key: 'control', subKey: 'controlRiskLevel' },
      { key: 'control', subKey: 'TOC_Procedure' },
      { key: 'control', subKey: 'TOC_Evidence' },
      { key: 'control', subKey: 'TOC_Population' },
      { key: 'control', subKey: 'TOC_PopulationCount' },
      { key: 'control', subKey: 'TOC_TestProperties' },
      { key: 'control', subKey: 'TOC_Exception' },
      { key: 'control', subKey: 'TOC_TestMethod' },
      { key: 'control', subKey: 'TOC_TestMethod_InquiryInspection' },
      { key: 'control', subKey: 'TOC_TestMethod_Observation' },
      { key: 'control', subKey: 'TOC_TestMethod_Reperformance' }
    ]
  }

  _.forEach(categories, (values, category) => {
    _.forEach(values, ({ key, subKey }) => {
      const content = _.get(defect.weakStateData, `${key}.${subKey}.content`)
      const evidence = _.get(defect.weakStateData, `${key}.${subKey}.evidence`)
      if (content && evidence && !_.isEmpty(content) && !_.isEmpty(evidence)) {
        data.push({
          category,
          key: getKoName(key, subKey),
          content,
          evidence
        })
      }
    })
  })

  return data
}
