import { makeStyles, Theme, Typography } from '@material-ui/core'
import { Button, Col, Tree } from 'antd'
import _ from 'lodash'
import React, { ReactElement } from 'react'

import {
  collapseTree,
  expandTree,
  getCheckedLeaf,
  PRCDataNode
} from '../../../base/utils/TreeUtils'
import { BlueLinearProgress } from '../../common/BlueProgress'
import WhiteBoxView from '../../common/WhiteBoxView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflowY: 'auto'
  },
  content: {
    flexGrow: 1,
    minHeight: 0
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const renderLoading = (isReady: boolean): React.ReactElement => {
  return (
    <div>
      {isReady ? (
        <BlueLinearProgress />
      ) : (
        <Typography style={{ margin: 8 }} variant="body1">
          탐색할 항목을 먼저 선택해주세요.
        </Typography>
      )}
    </div>
  )
}

interface Props {
  RCMItems: PRCDataNode[]
  isReady: boolean
  itemOnChecked: (checkedIds: string[]) => void
}

const RCMDirectory: React.FC<Props> = ({ RCMItems, isReady, itemOnChecked }) => {
  const classes = useStyles()
  const [states, setStates] = React.useState({
    expandedKeys: [] as (string | number)[],
    checkedKeys: [] as (string | number)[]
  })

  React.useEffect(() => {
    setStates({
      ...states,
      checkedKeys: [] as (string | number)[]
    })
  }, [RCMItems])

  const onExpand = (expandedKeys: (string | number)[]): void => {
    setStates({
      ...states,
      expandedKeys: expandedKeys.slice()
    })
  }

  const renderHeader = (): ReactElement => {
    return (
      <>
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
          항목
        </Typography>
        <Col flex="auto"></Col>
        <Button
          className={classes.button}
          onClick={() => {
            setStates({
              ...states,
              expandedKeys: expandTree(RCMItems, states.expandedKeys)
            })
          }}
        >
          다음 수준 열기
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            setStates({
              ...states,
              expandedKeys: collapseTree(RCMItems, states.expandedKeys)
            })
          }}
        >
          현재 수준 닫기
        </Button>
      </>
    )
  }

  const renderMain = (): React.ReactElement => {
    if (_.isEmpty(RCMItems)) {
      return renderLoading(isReady)
    }
    return (
      <div className={classes.root}>
        <Tree
          checkable
          showIcon
          checkedKeys={states.checkedKeys}
          expandedKeys={[...states.expandedKeys]}
          selectable={false}
          showLine={{ showLeafIcon: false }}
          treeData={RCMItems}
          onCheck={(checked) => {
            setStates({ ...states, checkedKeys: checked as (string | number)[] })
            itemOnChecked(getCheckedLeaf(RCMItems, checked))
          }}
          onExpand={onExpand}
        />
      </div>
    )
  }

  return <WhiteBoxView headerView={renderHeader()} mainView={renderMain()} />
}

export default RCMDirectory
