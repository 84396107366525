import { ColDef } from '@ag-grid-enterprise/all-modules'
import _ from 'lodash'

import { getEditableColor } from '../../../base/color'
import { Risk, RiskLevel } from '../../../base/data/PRC'
import { getKoName } from '../../../base/data/Translation'
import { riskUpdate, riskDelete } from '../../../dataLoader/RCM/risk'
import { deleteCellRenderer } from './PRCEditorUtils'

export function GetRiskColumnDefs(deleteItem: (item: any) => void): ColDef[] {
  return [
    { headerName: '대분류', field: 'cycleName', sortable: true },
    { headerName: '중분류', field: 'categoryName', sortable: true },
    { headerName: '소분류', field: 'subCategoryName', sortable: true },
    { headerName: getKoName('risk', 'id'), field: 'id', sortable: true },
    {
      headerName: getKoName('risk', 'name'),
      field: 'name',
      editable: true,
      cellStyle: { backgroundColor: getEditableColor() },
      cellEditorParams: { unique: true },
      flex: 1,
      sortable: true
    },
    {
      headerName: getKoName('risk', 'RoMM'),
      field: 'RoMM',
      editable: true,
      cellStyle: { backgroundColor: getEditableColor() },
      sortable: true
    },
    {
      headerName: getKoName('risk', 'inherentRiskLevel'),
      field: 'inherentRiskLevel',
      editable: true,
      cellStyle: { backgroundColor: getEditableColor() },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: [RiskLevel.low, RiskLevel.moderate, RiskLevel.high]
      },
      sortable: true
    },
    {
      headerName: '삭제',
      field: 'action',
      cellRenderer: deleteCellRenderer,
      cellRendererParams: {
        deleteItem
      }
    }
  ]
}

export async function EditRisk(risk: Risk, editedItem: any): Promise<any> {
  await riskUpdate(editedItem)
  return editedItem
}

export async function DeleteRisk(item: any): Promise<void> {
  return riskDelete(item.id)
}
