import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react'
import { AgGridEvent, AllModules, ColumnApi, GridApi } from '@ag-grid-enterprise/all-modules'
import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    minHeight: 0
  },
  noBorder: {
    '& .ag-root-wrapper': {
      border: 0
    }
  }
}))

interface GridProps {
  gridApi: GridApi
  columnApi: ColumnApi
}

export interface CommonAgGridHandler {
  getGridApi: () => GridProps | undefined
}

// type Props = AgGridReactProps
interface Props extends AgGridReactProps {
  noBorder?: boolean
  autoSize?: 'autoSizeColumns' | 'sizeColumnsToFit' | undefined
  wrapText?: boolean
}

const CommonAgGrid = React.forwardRef<CommonAgGridHandler, Props>(
  ({ noBorder, autoSize, wrapText, ...rest }, gridRef) => {
    const classes = useStyles()
    const [gridApi, setGridApi] = React.useState<GridProps>()

    React.useImperativeHandle(gridRef, () => ({
      getGridApi() {
        return gridApi
      }
    }))

    const applyColumnSize = () => {
      if (gridApi && autoSize === 'autoSizeColumns') {
        setTimeout(() => {
          const allColumnIds: string[] = []
          _.forEach(gridApi.columnApi.getAllColumns(), (column) => {
            allColumnIds.push(column.getColId())
          })
          gridApi.columnApi.autoSizeColumns(allColumnIds)
        }, 100)
      } else if (gridApi && autoSize === 'sizeColumnsToFit') {
        gridApi.gridApi.sizeColumnsToFit()
      }
    }

    React.useEffect(() => {
      applyColumnSize()
    }, [rest.rowData, gridApi])

    return (
      <div className={clsx(classes.root, { [classes.noBorder]: noBorder })}>
        <div
          className={wrapText ? 'ag-theme-wrap-text' : 'ag-theme-cck'}
          style={{ height: '100%', position: 'relative' }}
        >
          <AgGridReact
            applyColumnDefOrder
            enableCellChangeFlash
            defaultColDef={{
              wrapText: true,
              autoHeight: true,
              resizable: true,
              lockPosition: true,
              filter: 'agSetColumnFilter',
              suppressSizeToFit: true,
              filterParams: {
                excelMode: 'windows' // can be 'windows' or 'mac'
              }
            }}
            modules={AllModules}
            onColumnResized={(params: any) => params.api.resetRowHeights()}
            onFilterChanged={(event) => event.api.refreshHeader()}
            onGridReady={(event: AgGridEvent): void => {
              setGridApi({
                gridApi: event.api,
                columnApi: event.columnApi
              })
            }}
            {...rest}
          />
        </div>
      </div>
    )
  }
)

export default CommonAgGrid
