import { blue } from '@ant-design/colors'
import { TextField, withStyles } from '@material-ui/core'

export const BlueInput = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderColor: blue.primary
    }
  }
})(TextField)
