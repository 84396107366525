import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import _ from 'lodash'
import React from 'react'

import { cycleUpdate } from '../../../dataLoader/RCM/cycle'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import EditableTable from '../../common/EditableTable'
import CycleCreationForm from './CycleCreationForm'
import { convertToCategory, Cycle } from './RCMEditorUtils'

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    flexDirection: 'column',
    minHeight: 0
  },
  button: {
    padding: theme.spacing(1)
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    flexDirection: 'row',
    minWidth: 0,
    height: 200
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}))

function addParent(newData: Cycle | undefined): { id: string; name: string }[] {
  const parents: { id: string; name: string }[] = []
  if (newData) {
    parents.push(...addParent(newData.parent))
    parents.push(newData)
  }
  return parents
}

function addChildren(
  parents: { id: string; name: string }[],
  newData: Cycle
): { id: string; name: string }[][] {
  const cycles: { id: string; name: string }[][] = []
  if (!_.isEmpty(newData.children)) {
    _.forEach(newData.children, (child) => {
      const newParents = [...parents, child]
      cycles.push(...addChildren(newParents, child))
    })
  } else {
    cycles.push(parents)
  }
  return cycles
}

async function updateCycle(newData: Cycle): Promise<void[]> {
  const parents = addParent(newData)
  const cycles: { id: string; name: string }[][] = addChildren(parents, newData)
  const updatePromiseList: Promise<void>[] = []
  _.forEach(cycles, (cycle) => {
    const cycleObject = {
      isNew: false,
      cycleNumber: cycle[0].id,
      cycleName: cycle[0].name,
      categoryNumber: cycle[1].id,
      categoryName: cycle[1].name,
      subCategoryNumber: cycle[2].id,
      subCategoryName: cycle[2].name
    }
    // eslint-disable-next-line no-console
    console.log(
      'Edit cycles ',
      cycleObject.cycleNumber,
      cycleObject.cycleName,
      cycleObject.categoryNumber,
      cycleObject.categoryName,
      cycleObject.subCategoryNumber,
      cycleObject.subCategoryName
    )
    updatePromiseList.push(cycleUpdate(cycleObject))
  })
  return Promise.all(updatePromiseList)
}

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    width: 100
  },
  {
    title: '이름',
    dataIndex: 'name',
    editable: true,
    unique: true
  }
]

const updateItems = (selected: string[], data: any, item: any): any => {
  if (_.isEmpty(selected)) {
    const index = _.findIndex(data, (value: any) => value.id === item.id)
    const newItem = { ...data[index], ...item }
    data.splice(index, 1, newItem)
    return newItem
  }
  const selectedIndex = _.findIndex(data, (value: any) => value.id === selected[0])
  return updateItems(_.drop(selected), data[selectedIndex].children, item)
}

interface Props {
  cycles: Cycle[]
  loading: boolean
  loadCycles: () => void
  setCycles: (newData: any[]) => void
}

const CycleEditor: React.FC<Props> = ({ cycles, loading, loadCycles, setCycles }) => {
  const classes = useStyle()
  const [editingKey, setEditingKey] = React.useState('')
  const [data, setData] = React.useState({
    categories: [] as Cycle[],
    subCategories: [] as Cycle[],
    selectedCycleId: '',
    selectedCategoryId: ''
  })
  const [states, setStates] = React.useState({
    loading: false,
    creationVisible: false
  })
  const alertRef = React.useRef<AlertMessageHandler>(null)

  React.useEffect(() => {
    const categories =
      convertToCategory(_.find(cycles, { id: data.selectedCycleId })?.children) || []
    const subCategories =
      convertToCategory(_.find(categories, { id: data.selectedCategoryId })?.children) || []
    setData({
      ...data,
      categories,
      subCategories
    })
  }, [cycles])

  const editItems = React.useCallback(
    (key: 'cycles' | 'categories' | 'subCategories', item: any): void => {
      const newCycles = cycles.slice()
      const selected: string[] = []
      if (key !== 'cycles') selected.push(data.selectedCycleId)
      if (key === 'subCategories') selected.push(data.selectedCategoryId)
      const newItem = updateItems(selected, newCycles, item)

      if (newItem) {
        setStates({ ...states, loading: true })
        updateCycle(newItem)
          .then(() => {
            setCycles(newCycles)
            alertRef.current?.showAlert('success', '수정 완료')
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log('Failed to edit cycle', e)
            alertRef.current?.showAlert('error', '수정 실패')
          })
          .finally(() => setStates({ ...states, loading: false }))
      }
    },
    [data, states]
  )

  return (
    <div className={classes.root}>
      <div className={classes.button}>
        <Button
          disabled={!_.isEmpty(editingKey)}
          loading={states.loading}
          size="large"
          type="primary"
          onClick={() => setStates({ ...states, creationVisible: true })}
        >
          분류 추가
        </Button>
        <CycleCreationForm
          cycles={cycles}
          visible={states.creationVisible}
          onCancel={(update) => {
            setStates({ ...states, creationVisible: false })
            if (update) {
              loadCycles()
            }
          }}
        />
      </div>
      <div className={classes.content}>
        <EditableTable
          columns={columns}
          data={cycles}
          editingKey={editingKey}
          loading={states.loading || loading}
          name="대분류"
          selectingKey={data.selectedCycleId}
          setEditingKey={(key) => setEditingKey(key)}
          onItemClick={(item: any) => {
            const matched = _.find(cycles, { id: item.id })
            if (matched && matched.id !== data.selectedCycleId) {
              setData({
                ...data,
                selectedCycleId: matched.id,
                selectedCategoryId: '',
                categories: matched.children,
                subCategories: [] as Cycle[]
              })
            }
          }}
          onItemEdit={(item?: { id: string; name: string }, error?: string) => {
            if (item) {
              editItems('cycles', item)
            }
            if (error) {
              alertRef.current?.showAlert('error', error)
            }
          }}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <EditableTable
          columns={columns}
          data={data.categories}
          editingKey={editingKey}
          loading={states.loading || loading}
          name="중분류"
          selectingKey={data.selectedCategoryId}
          setEditingKey={setEditingKey}
          onItemClick={(item: any) => {
            const matched = _.find(data.categories, { id: item.id })
            if (matched) {
              setData({
                ...data,
                selectedCategoryId: matched.id,
                subCategories: matched.children
              })
            }
          }}
          onItemEdit={(item?: { id: string; name: string }, error?: string) => {
            if (item) {
              editItems('categories', item)
            }
            if (error) {
              alertRef.current?.showAlert('error', error)
            }
          }}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <EditableTable
          noClickable
          columns={columns}
          data={data.subCategories}
          editingKey={editingKey}
          loading={states.loading || loading}
          name="소분류"
          setEditingKey={setEditingKey}
          onItemEdit={(item?: { id: string; name: string }, error?: string) => {
            if (item) {
              editItems('subCategories', item)
            }
            if (error) {
              alertRef.current?.showAlert('error', error)
            }
          }}
        />
      </div>
      <AlertMessage ref={alertRef} />
    </div>
  )
}

export default CycleEditor
