import { makeStyles, Theme, Typography } from '@material-ui/core'
import { Select } from 'antd'
import _ from 'lodash'
import React from 'react'

export const BASE_ITEM = '__new__'

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    width: 400,
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    borderBottom: '1px solid lightgray'
  },
  selectItem: {
    fontSize: theme.typography.h6.fontSize
  },
  selectText: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  selectDivider: {
    minHeight: 1,
    height: 1,
    width: '100%',
    background: 'lightgray',
    padding: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

type SelectProps = Parameters<typeof Select>[0]

interface Props extends SelectProps {
  // loading: boolean
  // defaultValue: {name: string; value: string}
  defaultName: string
  names?: string[]
  values: string[]
}

const DividerSelect: React.FC<Props> = ({ values, defaultName, names, ...restProps }) => {
  const classes = useStyles()
  const renderOption = (name: string, value?: string): React.ReactElement => {
    return (
      <Select.Option className={classes.selectItem} key={name} value={value || name}>
        <div className={classes.selectText}>
          <Typography variant="h6">{name}</Typography>
        </div>
      </Select.Option>
    )
  }

  return (
    <Select
      bordered={false}
      className={classes.select}
      listHeight={250}
      size="large"
      {...restProps}
    >
      {renderOption(defaultName, restProps.defaultValue as string)}
      {!_.isEmpty(values) && (
        <Select.Option disabled className={classes.selectDivider} key="divider" value="divider">
          -
        </Select.Option>
      )}
      {names &&
        _.map(_.zip(names, values), ([name, value]) => {
          if (name && value) {
            return renderOption(name, value)
          }
          return null
        })}
      {!names && _.map(values, (name: string) => renderOption(name))}
    </Select>
  )
}

export default DividerSelect
