import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { grey } from '../../../base/color'
import { EvalFile } from '../../../base/data/BaseFile'
import { DesignEvaluationData, Evaluation } from '../../../base/data/Evaluation'
import { Staff } from '../../../base/data/Staff'
import { getFilesForSaving } from '../../../base/utils/FileUtils'
import TableItem from '../../common/TableItem'
import { EDITABLE_DESIGN_COLUMNS, getDesignColumns } from '../common/DesignEvaluationUtils'
import { EvaluationReportEditorHandler } from '../report/EvaluationEditor'
import DesignDefectContentView, { DesignDefectHandler } from './DesignDefectContentView'
import DesignEvaluationTable from './DesignEvaluationTable'
import EvaluationFileUploader from './EvaluationFileUploader'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    border: grey.border,
    backgroundColor: 'white'
  },
  content: {
    margin: theme.spacing(2),
    border: grey.border,
    borderBottom: 0
  },
  textDescription: {
    margin: theme.spacing(0, 2, 0, 2)
  },
  description: {
    margin: theme.spacing(0, 2, 2, 2)
  },
  formSelect: {
    maxWidth: 400,
    flexGrow: 1,
    marginBottom: 0
  },
  item: {
    flexFlow: 'row'
  },
  valueItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    flexGrow: 1
  }
}))

interface Props {
  staffs: Staff[]
  evaluation: Evaluation
}

const DesignEvaluationReportEditor = React.forwardRef<EvaluationReportEditorHandler, Props>(
  ({ staffs, evaluation }, editorRef) => {
    const classes = useStyles()
    const [form] = Form.useForm()
    const [files, setFiles] = React.useState<EvalFile[]>([])

    const designColumns = React.useMemo(() => getDesignColumns(staffs), [staffs])
    const defectRef = React.useRef<DesignDefectHandler>(null)

    React.useImperativeHandle(editorRef, () => ({
      getNewEvaluation() {
        const { deletedFiles, newFiles } = getFilesForSaving('design', evaluation, files)
        const newDesignEvaluationData = {
          type: 'design',
          number: evaluation.controlId,
          considerationOneContent: form.getFieldValue('considerationOneContent'),
          considerationOneResult: form.getFieldValue('considerationOneResult'),
          considerationTwoContent: form.getFieldValue('considerationTwoContent'),
          considerationTwoResult: form.getFieldValue('considerationTwoResult'),
          considerationThreeContent: form.getFieldValue('considerationThreeContent'),
          considerationThreeResult: form.getFieldValue('considerationThreeResult'),
          considerationFourContent: form.getFieldValue('considerationFourContent'),
          considerationFourResult: form.getFieldValue('considerationFourResult'),
          considerationFiveContent: form.getFieldValue('considerationFiveContent'),
          considerationFiveResult: form.getFieldValue('considerationFiveResult'),
          baselineReference: form.getFieldValue('baselineReference')?.format('YYYY-MM-DD'),
          result: form.getFieldValue('result')
        } as DesignEvaluationData

        const designDefect = defectRef.current?.getNewDesignDefect()
        return {
          evaluationData: newDesignEvaluationData,
          deletedFiles,
          newFiles,
          weakStateData: designDefect || undefined
        }
      }
    }))

    const setFormWithEvaluation = React.useCallback(
      (newEvaluation: Evaluation) => {
        const evalData = newEvaluation.data.content.evalData as DesignEvaluationData
        form.resetFields()
        form.setFieldsValue({
          considerationOneContent: evalData.considerationOneContent,
          considerationOneResult: evalData.considerationOneResult || 'Effective',
          considerationTwoContent: evalData.considerationTwoContent,
          considerationTwoResult: evalData.considerationTwoResult || 'Effective',
          considerationThreeContent: evalData.considerationThreeContent,
          considerationThreeResult: evalData.considerationThreeResult || 'Effective',
          considerationFourContent: evalData.considerationFourContent,
          considerationFourResult: evalData.considerationFourResult || 'Effective',
          considerationFiveContent: evalData.considerationFiveContent,
          considerationFiveResult: evalData.considerationFiveResult || 'Effective',
          baselineReference: moment(evalData.baselineReference),
          result: evalData.result || 'Effective'
        })
      },
      [form]
    )

    React.useEffect(() => {
      setFormWithEvaluation(evaluation)
    }, [evaluation])

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <DesignEvaluationTable columns={designColumns.processColumns} evaluation={evaluation} />
        </div>
        <div className={classes.content}>
          <EvaluationFileUploader
            staffs={staffs}
            evaluation={evaluation}
            files={files}
            setFiles={setFiles}
            type="design"
            updateEvaluation={(excelData: any) => {
              const newEvaluation = _.cloneDeep(evaluation)
              _.assign(newEvaluation, excelData)
              setFormWithEvaluation(newEvaluation)
            }}
          />
          <div className={classes.description}>
            <Typography.Text type="secondary">
              업로드하려는 파일을 상단의 박스로 끌어 놓기
            </Typography.Text>
          </div>
          <Divider />
        </div>
        <div className={classes.content}>
          <DesignEvaluationTable
            columns={designColumns.riskControlColumn}
            evaluation={evaluation}
          />
        </div>
        <div className={classes.content}>
          <DesignEvaluationTable
            columns={designColumns.controlDetailColumns}
            evaluation={evaluation}
          />
        </div>

        <Form.Provider>
          <Form form={form} name="DesignEvaluationReportEditor">
            <div className={classes.content}>
              <DesignEvaluationTable
                columns={EDITABLE_DESIGN_COLUMNS.considerationColumns}
                evaluation={evaluation}
              />
            </div>
            <div className={classes.content}>
              <DesignEvaluationTable
                columns={EDITABLE_DESIGN_COLUMNS.TOCColumns}
                evaluation={evaluation}
              />
            </div>
            <Typography.Text className={classes.textDescription} type="secondary">
              1. 기존 통제사항 중 변경사항 존재시 반드시 Ineffective 처리(추후 내부회계관리자와
              협의하여 RCM 수정 )
            </Typography.Text>
            <br />
            <Typography.Text className={classes.textDescription} type="secondary">
              2. 상기 평가결론 중 Ineffective 존재시 최종 결과는 Ineffective
            </Typography.Text>
            <div className={classes.content} style={{ marginTop: 0 }}>
              <TableItem name="평가 결론">
                <Form.Item className={classes.formSelect} name="result">
                  <Select>
                    <Select.Option value="Effective">Effective</Select.Option>
                    <Select.Option value="Ineffective">Ineffective</Select.Option>
                  </Select>
                </Form.Item>
              </TableItem>
            </div>
          </Form>
        </Form.Provider>
        <DesignDefectContentView
          editable={true}
          staffs={staffs}
          evaluation={evaluation}
          ref={defectRef}
        />
      </div>
    )
  }
)

export default DesignEvaluationReportEditor
