import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import useResizeAware from 'react-resize-aware'

import { grey } from '../../../base/color'
import { Evaluation, EvaluationType } from '../../../base/data/Evaluation'
import { EvaluationDefect, isOperationWeakState } from '../../../base/data/EvaluationDefect'
import { LoadStaffs, Staff } from '../../../base/data/Staff'
import TableHeader from '../../common/TableHeader'
import TableItem from '../../common/TableItem'
import { isAbleToDefect, weakStateToString } from '../common/EvaluationUtils'
import OperationEvaluationDefectView from './OperationEvaluationDefectView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: grey.border,
    margin: theme.spacing(2)
  },
  warningText: {
    marginRight: theme.spacing(2)
  },
  invisibleWarningText: {
    visibility: 'hidden'
  },
  stringText: {
    whiteSpace: 'break-spaces'
  },
  table: {
    padding: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}))

interface Props {
  type: EvaluationType
  evaluation: Evaluation
  evaluationDefect: EvaluationDefect
  onSaveEvaluationDefect: (evaluationDefect: EvaluationDefect) => Promise<void>
}

const EvaluationDefectSummary: React.FC<Props> = ({
  type,
  evaluation,
  evaluationDefect,
  onSaveEvaluationDefect
}) => {
  const classes = useStyles()
  const [items, setItems] = React.useState(
    [] as { name: string; value: any; noDivider?: boolean }[]
  )
  const [states, setStates] = React.useState({ visible: false })
  const [staffs, setStaffs] = React.useState<Staff[]>([])
  const [resizeListener, sizes] = useResizeAware()

  React.useEffect(() => {
    LoadStaffs().then((newStaffs) => {
      setStaffs(newStaffs)
    })
  }, [])

  React.useEffect(() => {
    const newItems: { name: string; value: any; noDivider?: boolean }[] = []
    let weakState = evaluationDefect.weakState
    if (type === 'operation' && evaluation.data.state.state !== '5') {
      weakState = '1'
    }

    newItems.push({
      name: '상태',
      value: weakStateToString(type, weakState)
    })

    if (type === 'operation' && isOperationWeakState(evaluationDefect)) {
      _.forEach(['1', '2', '3', '4', '5'], (key: '1' | '2' | '3' | '4' | '5') => {
        if (_.isEmpty(evaluationDefect.weakStateData[key].Answer)) {
          return
        }
        newItems.push({
          name: `${key}. 질문/답변`,
          value: (
            <span>
              {evaluationDefect.weakStateData[key].Question}
              <span style={{ marginLeft: 12, fontWeight: 'bold' }}>
                {evaluationDefect.weakStateData[key].Answer}
              </span>
            </span>
          )
        })
        newItems.push({
          name: `${key}. 근거`,
          value: evaluationDefect.weakStateData[key].Reason
        })
      })
    }

    const last = _.last(newItems)
    if (last) {
      last.noDivider = true
    }
    setItems(newItems)
  }, [evaluationDefect])

  return (
    <div>
      {resizeListener}
      <div className={classes.button}>
        <Typography.Text
          className={clsx(classes.warningText, {
            [classes.invisibleWarningText]: isAbleToDefect(evaluation)
          })}
          strong
          type="danger"
        >
          {type === 'operation' &&
            evaluation.data.state.state !== '5' &&
            '승인 완료 이후에 미비점 평가를 진행할 수 있습니다.'}
          {evaluation.data.state.resultState === '2' && '문제가 없는 통제입니다.'}
        </Typography.Text>
        {type === 'operation' && (
          <Button
            type="primary"
            disabled={!isAbleToDefect(evaluation)}
            onClick={() => setStates({ ...states, visible: true })}
          >
            미비점 평가
          </Button>
        )}
      </div>
      <div className={classes.root}>
        <TableHeader value="미비점 평가" />
        {_.map(items, (item) => {
          return (
            <TableItem key={item.name} name={item.name} noDivider={item.noDivider}>
              {item.value}
            </TableItem>
          )
        })}
      </div>
      <Modal
        bodyStyle={{ padding: 0 }}
        footer={
          type === 'operation'
            ? [
                <Button key="back" onClick={() => setStates({ ...states, visible: false })}>
                  닫기
                </Button>
              ]
            : []
        }
        maskClosable={false}
        title="미비점 평가 수행"
        visible={states.visible}
        width={sizes.width ? sizes.width * 0.8 : 1000}
        onCancel={() => setStates({ ...states, visible: false })}
        onOk={() => setStates({ ...states, visible: false })}
      >
        {type === 'operation' && (
          <OperationEvaluationDefectView
            onSaveEvaluationDefect={(newEvaluationDefect) => {
              return onSaveEvaluationDefect(newEvaluationDefect).then(() => {
                setStates({ ...states, visible: false })
              })
            }}
          />
        )}
      </Modal>
    </div>
  )
}

export default EvaluationDefectSummary
