import _ from 'lodash'

import { PRCType } from '../../base/data/PRC'
import { adminGetOrCreateUser } from '../Utils/admin'
import { backupSetFlag } from '../Utils/backup'
import { updateObjectForm } from '../Utils/updateObjectForm'
import { dbService } from '../fbase'
import { controlListUpdatePathArray } from './control'
import { cycleGetAll, cycleGetWithId, cycleListUpdatePathArray } from './cycle'
import { riskListUpdatePathArray } from './risk'

export const processInsertDataListForInit = async (dataObjectList) => {
  /*
  Init 을 위해서 Excel 에서 넘어온 Prcoess Data List 및 추가 정보들을 Firestore 에 집어넣는다

  @param: dataObjectList: Object[]: 데이터 리스트
  */
  const processInsertDataForInit = async (dataObject) => {
    // 단일 Data 를 집어넣는다.
    const cycleId = [
      dataObject.cycleNumber,
      dataObject.categoryNumber,
      dataObject.subCategoryNumber
    ].join('-')
    // TODO: Id 체계에 따라 수정할 부분
    if (cycleId + '-' + dataObject.number !== dataObject.code) {
      // cycleId 와 processNumber 를 조합해서 만든게 processCode 와 같지 않다면 문제가 있는것임
      console.log(
        `Error. cycleId(${cycleId}) + processNumber(${dataObject.number}) !== processCode(${dataObject.code})`
      )
      return
    }

    const targetCollectionName = 'PROCESS'
    const targetDocId = dataObject.code
    const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

    return await docRef.set({
      id: dataObject.code,
      uid: dataObject.code,
      number: dataObject.number,
      code: dataObject.code,
      name: dataObject.name,
      narrative: dataObject.narrative,
      selfPath: 'PROCESS/' + dataObject.code,
      cyclePathArray: ['CYCLE/' + cycleId],
      riskPathArray: [],
      controlPathArray: []
    })
  }

  const result = dataObjectList.map(async (dataObject) => {
    return await processInsertDataForInit(dataObject)
  })

  console.log('Insert Process Data For Init Done')
  return Promise.all(result)
}

export const processGetWithId = async (processId) => {
  // processId 에 해당하는 항목만 가져온다.
  const targetCollectionName = 'PROCESS'
  const docData = await dbService.collection(targetCollectionName).doc(processId)
.get()

  return docData.data()
}

export const processGetAll = async () => {
  // 모든 PROCESS 데이터를 가져온다.
  const targetCollectionName = 'PROCESS'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.data())
}

const processUpdatePathArray = async (processId, updateObject) => {
  /*
  PROCESS 의 processId 에 대해서
  updateObject 의 내용들을 수정

  @param: processId: String - PROCESS id
  @param: updateObject{
    "cyclePathArray": {
      "add": [cycleId, ...],
      "del": [cycleId, ...]
    },
    "processPathArray": {
      "add": [processId, ...],
      "del": [processId, ...]
    },
    "riskPathArray": {
      "add": [riskId, ...],
      "del": [riskId, ...]
    },
    "controlPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    }
  }
  */

  const finalUpdateObject = {}

  const processObject = await processGetWithId(processId)
  const iterKeyList = ['cyclePathArray', 'riskPathArray', 'controlPathArray']
  iterKeyList.forEach((targetKey) => {
    let resultIdList = processObject[targetKey].map((path) => path.split('/')[1])
    const addList = updateObject[targetKey].add
    addList.forEach((addId) => {
      resultIdList.push(addId)
    })

    const delList = updateObject[targetKey].del
    delList.forEach((delId) => {
      const idx = resultIdList.indexOf(delId)
      if (idx > -1) {
        resultIdList.splice(idx, 1)
      }
    })

    resultIdList = [...new Set(resultIdList)]
    const resultPathArray = resultIdList.map(
      (id) => targetKey.split('PathArray')[0].toUpperCase() + '/' + id
    )
    finalUpdateObject[targetKey] = resultPathArray
  })
  return await dbService.collection('PROCESS').doc(processId)
.update(finalUpdateObject)
}

export const processListUpdatePathArray = async (updateObject) => {
  /*
  PROCESS 의 processIdList 에 속하는 processId 들에 대해서
  processUpdatePathArray 를 호출

  @param: processId: String - PROCESS id
  @param: updateObject{
    "processId": {},
    ...
  }
  */

  const result = Object.keys(updateObject).map(async (processId) => {
    return await processUpdatePathArray(processId, updateObject[processId])
  })

  return Promise.all(result)
}

export const processGetAllContainCycle = async (controls) => {
  /*
  PROCESS의 정보전체에 분류(CYCLE) 정보를 추가해서 가져온다.
  추가되는 CYCLE 의 내용은 대분류(cycleNumber, cycleName), 중분류(categoryNumber, categoryName), 소분류(subCategoryNumber, subCategoryName) 이 필요하다.

  riskPathArray => riskIdList

  UserLevel이 NORMAL, UPLOADER일 경우, 해당되는 Control이 속한 Process만 반환한다.

  @return: Object[]
  [
    {
      categoryName: "비용의 승인 및 결제", // 중분류 이름
      categoryNumber: "01",  // 중분류 번호
      cycleName: "비용",  // 대분류 이름
      cycleNumber: "EX",  // 대분류 번호
      id: "EX-01-01-01",  // PROCESS id
      name: "법인세비용 계산",  // PROCESS name
      narrative: "1. 현재 회사는 비용 집행에 관한 내부 규정을 구비하고 있으며 각 현업 담당자는 비용 발생하거나 비용에 대한 수요 발생시 규정에  따라 기안서, 출장명령서, 지출결의서, 접대비정산보고서를 작성한다.",  // PROCESS narrative
      riskIdList: ["EX-01-01-01-R01"],  // risk id list
      subCategoryName: "비용의 발생 및 비용의 수요발생",  // 소분류 이름
      subCategoryNumber: "01"  // 소분류 번호
    }
  ]
  */

  const cycleDataAll = await cycleGetAll() // CYCLE 데이터 전체
  const processDataAll = await processGetAll() // PROCESS 데이터 전체
  const user = await adminGetOrCreateUser()

  return _(processDataAll)
    .map((processObject) => {
      const processId = processObject.id
      // TODO: Id 체계에 따라 수정할 부분
      const splitProcessId = processId.split('-')
      const cycleId = _.join(_.slice(splitProcessId, 0, 3), '-')
      const relateCycleObject = _.find(cycleDataAll, { id: cycleId })
      const controlIds = _.map(processObject.controlPathArray, (path) => _.last(_.split(path, '/')))

      if (
        (user.level === 'UPLOADER' || user.level === 'NORMAL') &&
        !_.some(controlIds, (controlId) => _.find(controls, { id: controlId }))
      ) {
        return undefined
      }

      return {
        type: PRCType.process,
        id: processId, // PROCESS id
        name: processObject.name, // PROCESS name
        narrative: processObject.narrative, // PROCESS narrative
        cycleNumber: relateCycleObject.cycleNumber,
        cycleName: relateCycleObject.cycleName,
        categoryNumber: relateCycleObject.categoryNumber,
        categoryName: relateCycleObject.categoryName,
        subCategoryNumber: relateCycleObject.subCategoryNumber,
        subCategoryName: relateCycleObject.subCategoryName,
        riskIds: _.map(processObject.riskPathArray, (path) => _.last(_.split(path, '/'))),
        controlIds
      }
    })
    .compact()
    .value()
}

export const processUpdate = async (processObject) => {
  /*
  PROCESS 의 내용을 수정한다
  name, narrative 항목만 수정이 가능하다

  @param: Object{}
  {
    processId: "ELC-01-01-01",
    processName: "새로운 processName",
    processNarrative: "새로운 processNarrative",
  }
  */

  const targetCollectionName = 'PROCESS'
  const targetDocId = processObject.id
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef
    .update({
      name: processObject.name,
      narrative: processObject.narrative
    })
    .then(() => backupSetFlag())
}

export const processFindNewNumberToCreate = async (
  cycleNumber,
  categoryNumber,
  subCategoryNumber
) => {
  /*
  새로운 PROCESS 를 만들기 위해서 필요한 새로운 number 를 리턴.
  해당 cycleNumber(대분류), categoryNumber(중분류), subCategoryNumber(소분류) 에 속하는
  프로세스들의 번호중 제일 높은 것의 +1 을 리턴.

  @param: String: cycleNumber - 대분류
  @param: String: categoryNumber - 중분류
  @param: String: subCategoryNumber - 소분류
  @return String: maxNumber+1("03" 식의 포맷으로)
  */

  const processDataAll = await processGetAll()

  // 같은 CYCLE 에 속하는 PROCESS 목록
  const processTargetList = _.filter(processDataAll, (processObject) => {
    const processId = processObject.id
    // TODO: Id 체계에 따라 수정할 부분
    const splitProcessId = processId.split('-')
    const cycleId = _.join(_.slice(splitProcessId, 0, 3), '-')
    return cycleId === [cycleNumber, categoryNumber, subCategoryNumber].join('-')
  })

  let maxNumber = 0
  processTargetList.forEach((processObject) => {
    const stringNumber = processObject.number
    const intNumber = Number(stringNumber)
    if (intNumber > maxNumber) {
      maxNumber = intNumber
    }
  })
  const newIntNumber = maxNumber + 1
  const newStringNumber = newIntNumber < 10 ? '0' + String(newIntNumber) : String(newIntNumber)

  return newStringNumber
}

const processFindChangePathFromCreate = async (processId, cycleId) => {
  /*
  PROCESS 를 생성할 때 생기는 연결된 path 들이 변경사항을 모두 찾는다.
  CYCLE

  @param: processId: String - PROCESS id to be created
  @param: cycleId: String - CYCLE id
  @return: Object{
    cycleUpdateObject, riskUpdateObject, controlUpdateObject
    생긴건 updateObjectForm 참조
  }
  */

  const cycleData = await cycleGetWithId(cycleId)

  // CYCLE 업데이트 객체
  const cycleUpdateObject = {}
  cycleUpdateObject[cycleId] = _.cloneDeep(updateObjectForm)
  cycleUpdateObject[cycleId].processPathArray.add = [processId]

  return { cycleUpdateObject }
}

export const processCreate = async (processObject) => {
  /*
  새로운 PROCESS 를 만든다
  PROCESS 생성 시에 연결되는 CYCLE 정보를 모두 넣는다.(cycle, category, subCategory)

  속하는 CYCLE 의 processPathArray 에 PROCESS 를 추가한다

  @param: Object{}
  {
    cycleNumber: String - "EX",
    categoryNumber: String - "01",
    subCategoryNumber: String - "01",
    processNumber: String - "04",
    processName: String - "테스트 프로세스 네임",
    processNarrative: String - "테스트 프로세스 내러티브",
  }
  */

  const {
    cycleNumber,
    categoryNumber,
    subCategoryNumber,
    processNumber,
    processName,
    processNarrative
  } = processObject
  const cycleId = [cycleNumber, categoryNumber, subCategoryNumber].join('-')
  // TODO: Id 체계에 따라 수정할 부분
  const processId = cycleId + '-' + processNumber

  const targetCollectionName = 'PROCESS'
  const targetDocId = processId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const cycleData = await cycleGetWithId(cycleId)
  const updateResult = await processFindChangePathFromCreate(processId, cycleId)

  return await docRef
    .set({
      id: processId,
      uid: processId,
      code: processId,
      name: processName,
      narrative: processNarrative,
      number: processNumber,

      selfPath: 'PROCESS/' + processId,
      cyclePathArray: ['CYCLE/' + cycleId],
      riskPathArray: [],
      controlPathArray: []
    })
    .then(() => {
      cycleListUpdatePathArray(updateResult.cycleUpdateObject)
    })
    .then(() => backupSetFlag())
}

export const processCheckBeforeDelete = async (processId) => {
  /*
  PROCESS 에 연결된 RISK 가 있는지 체크해서 해당 리스트를 반환
  연결된 RISK 가 없다면 빈 리스트 [] 가 반환 될 것이다.

  @param: processId: String - PROCESS id
  @return: String[] - 연결된 RISK id list
  */
  const processData = await processGetWithId(processId)
  const riskPathArray = processData.riskPathArray
  const riskIdList = riskPathArray.map((riskPath) => riskPath.split('/')[1])

  return riskIdList
}

const processFindChangePathFromDelete = async (processId) => {
  /*
  PROCESS 를 삭제할 때 생기는 연결된 path 들의 변경사항을 모두 찾는다.
  CYCLE, RISK, CONTROL

  @param: processId: String - PROCESS id to be deleted
  @return: Object{
    cycleUpdateObject, riskUpdateObject, controlUpdateObject
    생긴건 updateObjectForm 참조
  }
  */

  const processData = await processGetWithId(processId)
  // TODO: Id 체계에 따라 수정할 부분
  const splitProcessId = processId.split('-')
  const cycleId = _.join(_.slice(splitProcessId, 0, 3), '-')
  const riskIdList = processData.riskPathArray.map((riskPath) => riskPath.split('/')[1])
  const controlIdList = processData.controlPathArray.map((controlPath) => controlPath.split('/')[1])

  // CYCLE 업데이트 객체
  const cycleUpdateObject = {}
  cycleUpdateObject[cycleId] = _.cloneDeep(updateObjectForm)
  cycleUpdateObject[cycleId].processPathArray.del = [processId]

  // RISK 업데이트 객체
  const riskUpdateObject = {}
  riskIdList.forEach((riskId) => {
    riskUpdateObject[riskId] = _.cloneDeep(updateObjectForm)
    riskUpdateObject[riskId].processPathArray.del = [processId]
  })

  // CONTROL 업데이트 객체
  const controlUpdateObject = {}
  controlIdList.forEach((controlId) => {
    controlUpdateObject[controlId] = _.cloneDeep(updateObjectForm)
    controlUpdateObject[controlId].processPathArray.del = [processId]
  })

  return { cycleUpdateObject, riskUpdateObject, controlUpdateObject }
}

export const processDelete = async (processId) => {
  /*
  ID 의 체계가 바뀌어서
  PROCESS 밑에는 RISK 들이 귀속되어 있다.
  그래서 PROCESS 를 마구 지울 수 없다.
  지우기 전에 연결된(귀속된) RISK 가 있는지 체크가 필요하다
  => processCheckBeforeDelete 를 사전에 호출해서 UI 에서 막아야한다(존재하면 여기서도 Error 를 띄우지만)
  만약에 존재한다면 RISK 탭으로 가서 직접 삭제하고 난 뒤에 PROCESS 를 지울 수 있게 해줘야할듯

  PROCESS 를 삭제한다
  연결된 CYCLE 의 processPathArray 에서 삭제한다
  연결된 RISK 의 processPathArray 에서 삭제한다
  연결된 CONTROL 의 processPathArray 에서 삭제한다
  연결된 CONTROL 의 processMappingCode 에서 삭제한다

  @param: processId: String - "EX-01-01-04"
  */

  const riskIdList = await processCheckBeforeDelete(processId)
  if (riskIdList.length > 0) {
    // 연결된 RISK 가 존재. 삭제 불가
    return Promise.reject(new Error('삭제 불가. 연결된 RISK 가 존재합니다.', riskIdList))
  }

  const targetCollectionName = 'PROCESS'
  const targetDocId = processId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const updateResult = await processFindChangePathFromDelete(processId)

  return await docRef
    .delete()
    .then(() => {
      cycleListUpdatePathArray(updateResult.cycleUpdateObject)
      riskListUpdatePathArray(updateResult.riskUpdateObject)
      controlListUpdatePathArray(updateResult.controlUpdateObject)
    })
    .then(() => backupSetFlag())
}
