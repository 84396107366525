import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { blue } from '@ant-design/colors'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Modal from 'antd/lib/modal'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import { EvalFile, ServerFile, State, stateToString } from '../../../base/data/BaseFile'
import {
  Evaluation,
  EvaluationData,
  EvaluationType,
  getKoEvaluation
} from '../../../base/data/Evaluation'
import { Staff, StaffCellRenderer } from '../../../base/data/Staff'
import { dateCellRenderer, getFiles, getFileViewColumns } from '../../../base/utils/FileUtils'
import {
  evaluationParsingFiles,
  evaluationParsingInfoUploadFileList
} from '../../../dataLoader/Evaluation/evaluation'
import TableViewer, { TableViewerHandler } from '../../common/TableViewer'
import { deleteCellRenderer, stateCellRenderer } from '../common/DropBox'
import { getShortEvalType } from '../common/EvaluationUtils'
import UploadIconAndText from '../common/UploadIconAndText'
import FileNameNoticeView from '../file/FileNameNoticeView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerText: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    flex: 1
  },
  dropzone: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: 'WhiteSmoke',
    border: '1px dashed lightgray'
  },
  dragActive: {
    borderColor: blue.primary
  }
}))

export function getFileUploaderColumns(
  staffs: Staff[],
  deleteFile: (allFiles: EvalFile[], file: EvalFile) => void
): (ColDef | ColGroupDef)[] {
  return [
    ...getFileViewColumns(staffs),
    {
      headerName: '파일 상태',
      field: 'state',
      cellRenderer: stateCellRenderer,
      valueFormatter: (params: any): any => stateToString(params.value)
    },
    {
      headerName: '삭제',
      field: 'delete',
      width: 120,
      cellRenderer: deleteCellRenderer,
      cellRendererParams: { deleteFile }
    }
  ]
}

interface Props {
  type: EvaluationType
  staffs: Staff[]
  evaluation: Evaluation
  updateEvaluation: (evaluation: EvaluationData) => void
  files: EvalFile[]
  setFiles: React.Dispatch<React.SetStateAction<EvalFile[]>>
}

const EvaluationFileUploader: React.FC<Props> = ({
  type,
  staffs,
  evaluation,
  updateEvaluation,
  files,
  setFiles
}) => {
  const classes = useStyles()
  const viewerRef = React.useRef<TableViewerHandler>(null)

  React.useEffect(() => {
    if (!_.isEmpty(evaluation.data.content.files)) {
      setFiles(
        _.map(getFiles(type, evaluation), (serverFile: ServerFile): EvalFile => {
          return {
            ...serverFile,
            state: State.SAVED_FILE
          } as EvalFile
        })
      )
    }
  }, [evaluation])

  const onDrop = React.useCallback(
    (originalFiles) => {
      const acceptedFiles: File[] = []
      _.forEach(originalFiles, (file) => {
        acceptedFiles.push(new File([file], file.name.normalize('NFC'), { type: file.type }))
      })

      const fileInfos = evaluationParsingInfoUploadFileList(getShortEvalType(type), acceptedFiles)
      evaluationParsingFiles(getShortEvalType(type), fileInfos).then((parsedFiles: any) => {
        const allFiles = files.slice()
        _.forEach(parsedFiles, (file) => {
          if (file.state === State.INVALID_NAME) {
            file.evaluationName = ''
            file.controlId = ''
          }

          const fileName = `${file.fileName}.${file.fileExtension}`
          const matched = _.findIndex(allFiles, { fileName })
          if (matched >= 0 && (file.state === State.OK || allFiles[matched]?.state !== State.OK)) {
            _.pullAt(allFiles, [matched])
            file.state = State.ALREADY_EXISTING
          } else if (matched >= 0) {
            return
          }

          let state = file.state

          if (state === State.OK) {
            if (file.evaluationName !== evaluation.data.base.name) {
              state = State.UNRELATED_OPERATION_EVAL
            } else if (file.controlId !== evaluation.controlId) {
              state = State.UNRELATED_FILE
            }
          }

          if (state === State.UNRELATED_FILE) {
            state = State.DIFFERENT_CONTROL_ID
          }

          if (file.fileType === '보고서' && _.includes([State.OK, State.ALREADY_EXISTING], state)) {
            Modal.confirm({
              title: getKoEvaluation(type) + '보고서 업데이트',
              content: (
                <>
                  <Typography.Text strong>{fileName}</Typography.Text>
                  <Typography.Text>현재 보고서 파일을 업데이트 하시겠습니까?</Typography.Text>
                </>
              ),
              okText: '업데이트',
              cancelText: '취소',
              onOk: () => updateEvaluation(file.excelData)
            })
          }

          allFiles.push({
            type: file.fileType as '보고서' | '모집단' | '증빙' | '기타',
            fileName,
            evaluationName: file.evaluationName,
            controlId: file.controlId,
            state,
            file: file.file,
            excelData: file.excelData,
            excelException: file.excelException
          })
        })

        setFiles(allFiles)
      })
    },
    [files]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: !_.isEmpty(files)
  })

  const deleteFile = (allFiles: EvalFile[], file: EvalFile): void => {
    const newFiles = allFiles.filter((item) => item.fileName !== file.fileName)
    setFiles(newFiles)
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography.Text className={classes.headerText}>파일 업로드</Typography.Text>
        <FileNameNoticeView evaluationType={type} />
      </div>
      <div
        className={clsx(classes.dropzone, { [classes.dragActive]: isDragActive })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {_.isEmpty(files) && <UploadIconAndText isDragActive={isDragActive} />}
        {!_.isEmpty(files) && (
          <TableViewer
            // autoSizeColumns
            noBorder
            columnDefs={getFileUploaderColumns(staffs, deleteFile)}
            domLayout="autoHeight"
            frameworkComponents={{ dateCellRenderer, StaffCellRenderer }}
            ref={viewerRef}
            rowData={files}
          />
        )}
      </div>
    </div>
  )
}

export default EvaluationFileUploader
