import _ from 'lodash'
import React from 'react'

import { grey, lightBlue } from '../../../base/color'
import { getStaffNameAndEmailById, Staff } from '../../../base/data/Staff'
import { getKoName } from '../../../base/data/Translation'
import { parseDateStr } from '../../../base/utils/TimeUtils'

export function getConsiderations(): {
  title: string
  content: string
  resultKey: string
  contentKey: string
}[] {
  return [
    {
      title: '통제 목적의 적절성 및 위험평가에서 인식된 위험/경영진의 주장과의 연관성',
      content:
        '통제목적(파악된 Risk & 경영진의 주장)에 통제활동이 부합하는가? (실제 Risk를 감소시키는가?)',
      resultKey: 'data.content.evalData.considerationOneResult',
      contentKey: 'data.content.evalData.considerationOneContent'
    },
    {
      title: '통제활동이 수행되는 기간, 빈도 및 일관성',
      content:
        '통제가 적용된 기간은 전체기간에 적용되었는가? (통제가 설계되어 수행된 기간은 충분한지)\n' +
        '통제 수행 빈도는 적정한가? (통제 수행 빈도가 위험을 적시에 예방하거나 적발할 수 있는지)\n' +
        '통제가 수행되는 빈도는 통제기간 동안 일관되게 적용되었는가?',
      resultKey: 'data.content.evalData.considerationTwoResult',
      contentKey: 'data.content.evalData.considerationTwoContent'
    },
    {
      title: '통제 수행 수준 및 예측가능성',
      content: '통제활동 수행의 세부항목/수준 혹은 예측기준 설정 방안은 충분한가?',
      resultKey: 'data.content.evalData.considerationThreeResult',
      contentKey: 'data.content.evalData.considerationThreeContent'
    },
    {
      title: '통제에 사용되는 정보의 신뢰성 확보',
      content: '통제수행에 필요한 정보의 신뢰성 확보 방안은 적절히 설계되었는가?',
      resultKey: 'data.content.evalData.considerationFourResult',
      contentKey: 'data.content.evalData.considerationFourContent'
    },
    {
      title: '예외사항 발생시 대응',
      content: '통제 수행 중에 발생하는 예외사항에 대한 추가 절차는 충분한가?',

      resultKey: 'data.content.evalData.considerationFiveResult',
      contentKey: 'data.content.evalData.considerationFiveContent'
    }
  ]
}

interface DesignEvaluationColumnDef {
  span: number
  style?: React.CSSProperties
  valueFormatter?: (params: string) => string
}

export interface DesignEvaluationColumnKeyDef extends DesignEvaluationColumnDef {
  key: string
  type?: 'Text' | 'TextArea' | 'Select' | 'DatePicker'
  editable?: boolean
  options?: string[]
}

export interface DesignEvaluationColumnValueDef extends DesignEvaluationColumnDef {
  value: string
}

export function isDesignEvaluationKeyDef(
  value: DesignEvaluationColumnDef
): value is DesignEvaluationColumnKeyDef {
  return _.has(value, 'key')
}

export function isDesignEvaluationValueDef(
  value: DesignEvaluationColumnDef
): value is DesignEvaluationColumnValueDef {
  return _.has(value, 'value')
}

export function getDesignColumns(
  staffs: Staff[]
): Record<string, (DesignEvaluationColumnKeyDef | DesignEvaluationColumnValueDef)[][]> {
  return {
    processColumns: [
      [
        {
          value: getKoName('common', 'cycleName'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.controlData.cycleName',
          span: 9
        },
        {
          value: getKoName('common', 'processCode'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.processCode',
          span: 9
        }
      ],
      [
        {
          value: getKoName('common', 'categoryName'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.controlData.categoryName',
          span: 9
        },
        {
          value: getKoName('control', 'createDate'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.evalData.createDate',
          span: 9,
          valueFormatter: (value) => parseDateStr('YYYY-MM-DD', value)
        }
      ],
      [
        {
          value: getKoName('common', 'subCategoryName'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.controlData.subCategoryName',
          span: 9
        },
        {
          value: getKoName('control', 'inchargeInRCM'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.controlData.incharge',
          span: 9,
          valueFormatter: (value: string): string => getStaffNameAndEmailById(staffs, value)
        }
      ],
      [
        {
          value: getKoName('control', 'department'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.state.departmentName',
          span: 9
        },
        {
          value: getKoName('control', 'ownerInRCM'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.controlData.owner',
          span: 9,
          valueFormatter: (value: string): string => getStaffNameAndEmailById(staffs, value)
        }
      ]
    ],
    riskControlColumn: [
      [
        {
          value: getKoName('risk', 'id'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.riskData.id',
          span: 3
        },
        {
          value: getKoName('risk', 'name'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.riskData.name',
          span: 15
        }
      ],
      [
        {
          value: getKoName('control', 'id'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'controlId',
          span: 3
        },
        {
          value: getKoName('control', 'narrative'),
          span: 3,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.controlData.narrative',
          span: 15
        }
      ],
      [
        {
          value: '통제유형',
          span: 24,
          style: { backgroundColor: grey.background, justifyContent: 'center' }
        }
      ],
      [
        {
          value: getKoName('control', 'keyControl'),
          span: 3,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.keyControl',
          span: 3
        },
        {
          value: getKoName('control', 'autoManual'),
          span: 3,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.autoManual',
          span: 3
        },
        {
          value: getKoName('control', 'preventDetective'),
          span: 3,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.preventDetective',
          span: 3
        },
        {
          value: getKoName('control', 'period'),
          span: 3,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.period',
          span: 3
        }
      ]
    ],
    controlDetailColumns: [
      [
        {
          value: '통제위험평가',
          span: 24,
          style: { backgroundColor: grey.background, justifyContent: 'center' }
        }
      ],
      [
        {
          value: getKoName('risk', 'inherentRiskLevel'),
          span: 5,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.riskData.inherentRiskLevel',
          span: 3
        },
        {
          value: getKoName('control', 'residualRiskLevel'),
          span: 5,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.residualRiskLevel',
          span: 3
        },
        {
          value: getKoName('control', 'controlRiskLevel'),
          span: 5,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.controlRiskLevel',
          span: 3
        }
      ]
    ],
    considerationColumns: [
      [
        {
          value: '통제활동의 정확도(precision) 수준에 대한 이해',
          span: 24,
          style: { backgroundColor: grey.background, justifyContent: 'center' }
        }
      ],
      ..._.flatten(
        _.map(getConsiderations(), ({ title, content, resultKey, contentKey }) => {
          return [
            [
              {
                value: title,
                span: 18,
                style: { backgroundColor: lightBlue.background }
              },
              { value: '평가 결론', span: 3, style: { backgroundColor: grey.background } },
              { key: resultKey, span: 3 }
            ],
            [
              { value: content, span: 8, style: { backgroundColor: '#E2EFDA' } },
              {
                key: contentKey,
                span: 16
              }
            ]
          ]
        })
      )
    ],
    TOCColumns: [
      [
        {
          value: '통제평가절차',
          span: 24,
          style: { backgroundColor: grey.background, justifyContent: 'center' }
        }
      ],
      [
        {
          value: getKoName('control', 'TOC_TestMethod_InquiryInspection'),
          span: 4,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.TOC_TestMethod_InquiryInspection',
          span: 4
        },
        {
          value: getKoName('control', 'TOC_TestMethod_Observation'),
          span: 4,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.TOC_TestMethod_Observation',
          span: 4
        },
        {
          value: getKoName('control', 'TOC_TestMethod_Reperformance'),
          span: 4,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.TOC_TestMethod_Reperformance',
          span: 4
        }
      ],
      [
        {
          value: getKoName('control', 'TOC_Procedure'),
          span: 4,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.TOC_Procedure',
          span: 12
        },
        {
          value: getKoName('control', 'TOC_Evidence'),
          span: 4,
          style: { backgroundColor: lightBlue.background }
        },
        {
          key: 'data.content.controlData.TOC_Evidence',
          span: 4
        }
      ],
      [
        {
          value: '설계일자 (Baseline) Reference',
          span: 4,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.evalData.baselineReference',
          span: 4
        },
        {
          value: getKoName('control', 'MRC_Number'),
          span: 4,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.controlData.MRC_Number',
          span: 4
        },
        {
          value: getKoName('control', 'IPE_Number'),
          span: 4,
          style: { backgroundColor: grey.background }
        },
        {
          key: 'data.content.controlData.IPE_Number',
          span: 4
        }
      ]
    ]
  }
}

export const EDITABLE_DESIGN_COLUMNS: Record<
  string,
  (DesignEvaluationColumnKeyDef | DesignEvaluationColumnValueDef)[][]
> = {
  considerationColumns: [
    [
      {
        value: '통제활동의 정확도(precision) 수준에 대한 이해',
        span: 24,
        style: { backgroundColor: grey.background, justifyContent: 'center' }
      }
    ],
    ..._.flatten(
      _.map(
        getConsiderations(),
        ({
          title,
          content,
          resultKey,
          contentKey
        }): (DesignEvaluationColumnKeyDef | DesignEvaluationColumnValueDef)[][] => {
          return [
            [
              {
                value: title,
                span: 18,
                style: { backgroundColor: lightBlue.background }
              },
              {
                value: '평가 결론',
                span: 3,
                style: { backgroundColor: grey.background }
              },
              {
                key: _.last(_.split(resultKey, '.')) || '',
                span: 3,
                editable: true,
                type: 'Select',
                options: ['Effective', 'Ineffective']
              }
            ],
            [
              {
                value: content,
                span: 8,
                style: { backgroundColor: '#E2EFDA' }
              },
              {
                key: _.last(_.split(contentKey, '.')) || '',
                span: 16,
                type: 'TextArea',
                editable: true
              }
            ]
          ]
        }
      )
    )
  ],
  TOCColumns: [
    [
      {
        value: '통제평가절차',
        span: 24,
        style: { backgroundColor: grey.background, justifyContent: 'center' }
      }
    ],
    [
      {
        value: getKoName('control', 'TOC_TestMethod_InquiryInspection'),
        span: 4,
        style: { backgroundColor: lightBlue.background }
      },
      {
        key: 'data.content.controlData.TOC_TestMethod_InquiryInspection',
        span: 4
      },
      {
        value: getKoName('control', 'TOC_TestMethod_Observation'),
        span: 4,
        style: { backgroundColor: lightBlue.background }
      },
      {
        key: 'data.content.controlData.TOC_TestMethod_Observation',
        span: 4
      },
      {
        value: getKoName('control', 'TOC_TestMethod_Reperformance'),
        span: 4,
        style: { backgroundColor: lightBlue.background }
      },
      {
        key: 'data.content.controlData.TOC_TestMethod_Reperformance',
        span: 4
      }
    ],
    [
      {
        value: getKoName('control', 'TOC_Procedure'),
        span: 4,
        style: { backgroundColor: lightBlue.background }
      },
      {
        key: 'data.content.controlData.TOC_Procedure',
        span: 12
      },
      {
        value: getKoName('control', 'TOC_Evidence'),
        span: 4,
        style: { backgroundColor: lightBlue.background }
      },
      {
        key: 'data.content.controlData.TOC_Evidence',
        span: 4
      }
    ],
    [
      {
        value: '설계일자 (Baseline) Reference',
        span: 4,
        style: { backgroundColor: grey.background }
      },
      {
        key: 'baselineReference',
        span: 4,
        editable: true,
        type: 'DatePicker'
      },
      {
        value: getKoName('control', 'MRC_Number'),
        span: 4,
        style: { backgroundColor: grey.background }
      },
      {
        key: 'data.content.controlData.MRC_Number',
        span: 4
      },
      {
        value: getKoName('control', 'IPE_Number'),
        span: 4,
        style: { backgroundColor: grey.background }
      },
      {
        key: 'data.content.controlData.IPE_Number',
        span: 4
      }
    ]
  ]
}
