import { ColDef } from '@ag-grid-enterprise/all-modules'
import { Theme, makeStyles } from '@material-ui/core/styles'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'
import { useCookies } from 'react-cookie'

import { grey } from '../../base/color'
import { Evaluation, getKoEvaluation } from '../../base/data/Evaluation'
import { Staff, StaffValueFormatter } from '../../base/data/Staff'
import { adminGetOrCreateUser } from '../../dataLoader/Utils/admin'
import DividerSelect, { BASE_ITEM } from '../common/DividerSelect'
import {
  EvaluationSelectAttribute,
  evaluationStateToString,
  getEvaluationNamesWithDate,
  loadEvaluationDetails,
  loadEvaluationNames,
  weakStateToString
} from '../evaluation/common/EvaluationUtils'
import EvaluationTable from '../evaluation/view/EvaluationTable'
import StatusView, { StatisticsRow, StatisticsType } from './StatusView'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    border: grey.border,
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  header: {
    padding: theme.spacing(2)
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 1)
  },
  subTitle: {
    margin: theme.spacing(1, 4)
  },
  progressView: {
    margin: theme.spacing(1)
  }
}))

function getStateColumnMap(staffs: Staff[]): {
  departmentName: ColDef[]
  controlOwner: ColDef[]
  controlPerformer: ColDef[]
} {
  const defaultColumns: ColDef[] = [
    { headerName: '총 평가대상', field: 'totalCount', sortable: true },
    { headerName: '미수행', field: 'state.1', sortable: true },
    { headerName: '수행중', field: 'state.2', sortable: true },
    { headerName: '수행완료', field: 'state.3', sortable: true },
    { headerName: '재작성필요', field: 'state.4', sortable: true },
    { headerName: '승인완료', field: 'state.5', sortable: true }
  ]

  return {
    departmentName: _.concat({ headerName: '부서', field: 'key' }, defaultColumns),
    controlOwner: _.concat(
      {
        headerName: '통제 담당자',
        field: 'key',
        cellRenderer: 'StaffCellRenderer',
        valueFormatter: StaffValueFormatter(staffs),
        filterParams: {
          valueFormatter: StaffValueFormatter(staffs)
        }
      },
      defaultColumns
    ),
    controlPerformer: _.concat(
      {
        headerName: '통제 평가자',
        field: 'key',
        cellRenderer: 'StaffCellRenderer',
        valueFormatter: StaffValueFormatter(staffs),
        filterParams: {
          valueFormatter: StaffValueFormatter(staffs)
        }
      },
      defaultColumns
    )
  }
}

function getWeakStateColumnMap(
  evaluationType: 'operation' | 'design',
  staffs: Staff[]
): {
  departmentName: ColDef[]
  controlOwner: ColDef[]
  controlPerformer: ColDef[]
} {
  const defaultColumns: ColDef[] = [
    { headerName: '총 평가대상', field: 'totalCount', sortable: true },
    { headerName: weakStateToString(evaluationType, '1'), field: 'weakState.1', sortable: true },
    { headerName: weakStateToString(evaluationType, '2'), field: 'weakState.2', sortable: true },
    { headerName: weakStateToString(evaluationType, '3'), field: 'weakState.3', sortable: true },
    { headerName: weakStateToString(evaluationType, '4'), field: 'weakState.4', sortable: true }
  ]

  if (evaluationType === 'operation') {
    defaultColumns.push({
      headerName: weakStateToString(evaluationType, '5'),
      field: 'weakState.5',
      sortable: true
    })
  }

  return {
    departmentName: _.concat({ headerName: '부서', field: 'key' }, defaultColumns),
    controlOwner: _.concat(
      {
        headerName: '통제 담당자',
        field: 'key',
        cellRenderer: 'StaffCellRenderer',
        valueFormatter: StaffValueFormatter(staffs),
        filterParams: {
          valueFormatter: StaffValueFormatter(staffs)
        }
      },
      defaultColumns
    ),
    controlPerformer: _.concat(
      {
        headerName: '통제 평가자',
        field: 'key',
        cellRenderer: 'StaffCellRenderer',
        valueFormatter: StaffValueFormatter(staffs),
        filterParams: {
          valueFormatter: StaffValueFormatter(staffs)
        }
      },
      defaultColumns
    )
  }
}

interface Props {
  staffs: Staff[]
}

function preprocessDoughnutData(
  evaluationType: 'operation' | 'design',
  evaluations: Evaluation[]
): {
  state: Record<string, number>
  weakState: Record<string, number>
} {
  const counts = {
    state: _.assign({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }, _.countBy(evaluations, 'data.state.state')),
    weakState: _.assign({ 1: 0, 2: 0, 3: 0, 4: 0 }, _.countBy(evaluations, 'data.state.weakState')),
    resultState: _.assign(
      { 1: 0, 2: 0, 3: 0, 4: 0 },
      _.countBy(evaluations, 'data.state.resultState')
    )
  }

  if (evaluationType === 'operation' && !_.has(counts, 'weakState.5')) {
    _.set(counts, 'weakState.5', 0)
  }

  const calculateRatio = (
    countMap: Record<string, number>,
    toString: (value: string) => string
  ) => {
    const numberOfState = _.sum(_.values(countMap))
    return _.reduce(
      countMap,
      (result, value, key) => {
        result[toString(key)] = _.round((value / numberOfState) * 100, 2)
        return result
      },
      {} as Record<string, number>
    )
  }

  return {
    state: calculateRatio(counts.state, evaluationStateToString),
    weakState: calculateRatio(counts.weakState, (value: string) =>
      weakStateToString(evaluationType, value)
    )
  }
}

function preprocessEvaluationDataInner(
  target: StatisticsType,
  evaluationType: 'operation' | 'design',
  evaluations: Evaluation[]
): StatisticsRow[] {
  const groupedEvaluations = _.groupBy(evaluations, (evaluation) => {
    return _.get(evaluation, `data.state.${target}`)
  })
  const defaultWeakState =
    evaluationType === 'operation' ? { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } : { 1: 0, 2: 0, 3: 0, 4: 0 }

  const allStates = _.map(groupedEvaluations, (value, key) => {
    const state = _.assign({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }, _.countBy(value, 'data.state.state'))
    return {
      key,
      totalCount: _.sum(_.values(state)),
      state,
      weakState: _.assign(_.cloneDeep(defaultWeakState), _.countBy(value, 'data.state.weakState')),
      resultState: _.assign({ 1: 0, 2: 0, 3: 0, 4: 0 }, _.countBy(value, 'data.state.resultState'))
    }
  })

  allStates.push({
    key: '합계',
    totalCount: _.sum(_.map(allStates, 'totalCount')),
    state: _.assign({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }, _.countBy(evaluations, 'data.state.state')),
    weakState: _.assign(
      _.cloneDeep(defaultWeakState),
      _.countBy(evaluations, 'data.state.weakState')
    ),
    resultState: _.assign(
      { 1: 0, 2: 0, 3: 0, 4: 0 },
      _.countBy(evaluations, 'data.state.resultState')
    )
  })

  return allStates
}

function preprocessEvaluationData(
  evaluationType: 'operation' | 'design',
  evaluations: Evaluation[]
): {
  doughnutData: {
    state: Record<string, number>
    weakState: Record<string, number>
  }
  departmentName: StatisticsRow[]
  controlOwner: StatisticsRow[]
  controlPerformer: StatisticsRow[]
} {
  return {
    doughnutData: preprocessDoughnutData(evaluationType, evaluations),
    departmentName: preprocessEvaluationDataInner('departmentName', evaluationType, evaluations),
    controlOwner: preprocessEvaluationDataInner('controlOwner', evaluationType, evaluations),
    controlPerformer: preprocessEvaluationDataInner('controlPerformer', evaluationType, evaluations)
  }
}

const EvaluationStatusView: React.FC<Props> = ({ staffs }) => {
  const classes = useStyles()
  const [userLevel, setUserLevel] = React.useState('NORMAL')
  const [stateDoughnut, setStateDoughnut] = React.useState<Record<string, number>>({})
  const [weakStateDoughnut, setWeakStateDoughnut] = React.useState<Record<string, number>>({})
  const [statisticData, setStatisticData] = React.useState<{
    departmentName: StatisticsRow[]
    controlOwner: StatisticsRow[]
    controlPerformer: StatisticsRow[]
  }>({
    departmentName: [],
    controlOwner: [],
    controlPerformer: []
  })

  const [evaluationType, setEvaluationType] = React.useState<'operation' | 'design'>('operation')
  const [select, setSelect] = React.useState<EvaluationSelectAttribute>({
    loading: false,
    selectedName: BASE_ITEM,
    names: [],
    summaries: [],
    evaluationDetails: []
  })
  const [cookies, setCookie, removeCookie] = useCookies([
    'dashboardEvaluationType',
    'dashboardEvaluationName'
  ])

  const stateColumnMap = React.useMemo(() => getStateColumnMap(staffs), [staffs])
  const weakStateColumnMap = React.useMemo(
    () => getWeakStateColumnMap(evaluationType, staffs),
    [staffs, evaluationType]
  )

  React.useEffect(() => {
    adminGetOrCreateUser().then((user) => {
      setUserLevel(_.get(user, 'level'))
    })
  }, [])

  React.useEffect(() => {
    if (select.evaluationDetails) {
      const { doughnutData, ...rest } = preprocessEvaluationData(
        evaluationType,
        select.evaluationDetails
      )
      setStateDoughnut(doughnutData.state)
      setWeakStateDoughnut(doughnutData.weakState)
      setStatisticData(rest)
    }
  }, [evaluationType, select.evaluationDetails])

  React.useEffect(() => {
    const typeCookie = cookies.dashboardEvaluationType
    const nameCookie = cookies.dashboardEvaluationName
    if (typeCookie && nameCookie) {
      setEvaluationType(typeCookie)
      loadEvaluationNames(typeCookie, nameCookie, select, setSelect)
    } else {
      loadEvaluationNames(evaluationType, undefined, select, setSelect)
    }
  }, [evaluationType])

  const onEvaluationSelect = React.useCallback(
    (evaluationName: string): void => {
      loadEvaluationDetails(evaluationType, evaluationName, select, setSelect)
      setCookie('dashboardEvaluationType', evaluationType)
      setCookie('dashboardEvaluationName', evaluationName)
    },
    [evaluationType, select]
  )

  return (
    <div className={classes.root}>
      <Typography.Title className={classes.header} level={4}>
        운영/설계평가 진행 상태
      </Typography.Title>
      <div className={classes.selectContainer}>
        <DividerSelect
          style={{ marginRight: 16 }}
          defaultName="운영/설계평가 선택"
          defaultValue={BASE_ITEM}
          loading={select.loading}
          value={evaluationType}
          names={['운영평가', '설계평가']}
          values={['operation', 'design']}
          onChange={(value) => {
            if (value === 'operation' || value === 'design') {
              removeCookie('dashboardEvaluationType')
              removeCookie('dashboardEvaluationName')
              setSelect({
                ...select,
                selectedName: BASE_ITEM,
                names: [],
                evaluationDetails: []
              })
              setEvaluationType(value)
            }
          }}
        />
        <DividerSelect
          defaultName={`${getKoEvaluation(evaluationType)} 선택`}
          defaultValue={BASE_ITEM}
          loading={select.loading}
          names={getEvaluationNamesWithDate(select.summaries)}
          value={select.selectedName}
          values={select.names}
          onChange={(value) => onEvaluationSelect(value as string)}
        />
      </div>
      {select.selectedName !== BASE_ITEM && select.evaluationDetails && (
        <>
          <Typography.Title className={classes.subTitle} level={5}>
            통제 평가 진행 상황
          </Typography.Title>
          <div className={classes.progressView}>
            <EvaluationTable
              evaluationDetails={select.evaluationDetails || []}
              evaluationType={evaluationType}
              height={300}
              loading={select.loading}
              staffs={staffs}
              type="management"
            />
          </div>
        </>
      )}
      {userLevel === 'ADMIN' && select.selectedName !== BASE_ITEM && select.evaluationDetails && (
        <>
          <Typography.Title className={classes.subTitle} level={5}>
            통제 평가 수행 내역
          </Typography.Title>
          <StatusView
            doughnutTitle="통제 평가 수행 내역 (단위 : %)"
            doughnutData={stateDoughnut}
            columnMap={stateColumnMap}
            tableData={statisticData}
          />
          <Typography.Title className={classes.subTitle} level={5}>
            미비점 평가 수행 내역
          </Typography.Title>
          <StatusView
            doughnutTitle="미비점 평가 수행 내역 (단위 : %)"
            doughnutData={weakStateDoughnut}
            columnMap={weakStateColumnMap}
            tableData={statisticData}
          />
        </>
      )}
    </div>
  )
}

export default EvaluationStatusView
