import { blue } from '@ant-design/colors'
import LinearProgress from '@material-ui/core/LinearProgress'
import { createStyles, Theme, withStyles } from '@material-ui/core/styles'

export const BlueLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
      backgroundColor: blue.primary
    }
  })
)(LinearProgress)
