import { ColDef, ColGroupDef } from '@ag-grid-community/all-modules'
import { blue } from '@ant-design/colors'
import { useWorker } from '@koale/useworker'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import { EvalFile, ServerFile, State, stateToString } from '../../../base/data/BaseFile'
import { TableContent } from '../../../base/data/Evaluation'
import { Staff, StaffCellRenderer } from '../../../base/data/Staff'
import {
  dateCellRenderer,
  getFileSizeByMB,
  getFileViewColumns,
  MAX_POPULATION_SIZE
} from '../../../base/utils/FileUtils'
import { evaluationParsingInfoUploadFileList } from '../../../dataLoader/Evaluation/evaluation'
import { excelParsingPopulationFile } from '../../../dataLoader/Utils/readPopulationFile'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import CircleBackdrop from '../../common/CircleBackdrop'
import CommonAgGrid from '../../common/CommonAgGrid'
import NoticeTextView from '../../common/NoticeTextView'
import { stateCellRenderer } from '../common/DropBox'
import UploadIconAndText from '../common/UploadIconAndText'

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: 'WhiteSmoke',
    border: '1px dashed lightgray',
    marginBottom: theme.spacing(1)
  },
  dragActive: {
    borderColor: blue.primary
  }
}))

function getFileUploaderColumns(staffs: Staff[]): (ColDef | ColGroupDef)[] {
  return [
    ...getFileViewColumns(staffs),
    {
      headerName: '파일 상태',
      field: 'state',
      cellRenderer: stateCellRenderer,
      valueFormatter: (params: any): any => stateToString(params.value)
    }
  ]
}

export interface PopulationFileUploaderHandler {
  getPopulationFiles: () => EvalFile[]
}

interface Props {
  evaluationName: string
  controlId: string
  staffs: Staff[]
  populationFile?: EvalFile
  setPopulation: (fileName: string, population: TableContent | undefined, files: EvalFile[]) => void
}

const PopulationFileUploader = React.forwardRef<PopulationFileUploaderHandler, Props>(
  ({ evaluationName, controlId, staffs, populationFile, setPopulation }, viewRef) => {
    const classes = useStyles()
    const [states, setStates] = React.useState({
      saveLoading: false
    })
    const alertRef = React.useRef<AlertMessageHandler>(null)
    const [files, setFiles] = React.useState<EvalFile[]>([])
    const [readFileWorker] = useWorker(excelParsingPopulationFile as any, {
      remoteDependencies: ['https://unpkg.com/xlsx@0.17.3/dist/xlsx.full.min.js']
    })

    React.useImperativeHandle(viewRef, () => ({
      getPopulationFiles() {
        return files
      }
    }))

    const onDrop = React.useCallback(
      (originalFiles) => {
        const acceptedFiles: File[] = []
        _.forEach(originalFiles, (file) => {
          acceptedFiles.push(new File([file], file.name.normalize('NFC'), { type: file.type }))
        })

        const fileInfos = evaluationParsingInfoUploadFileList('OE', acceptedFiles)
        if (fileInfos.length !== 1) {
          alertRef.current?.showAlert('error', '하나의 모집단 파일을 업로드해주세요.')
          return
        }
        if (fileInfos[0].fileType !== '모집단') {
          alertRef.current?.showAlert('error', '모집단 파일을 업로드해주세요.')
          return
        }

        const newPopulationFile = fileInfos[0]
        newPopulationFile.type = newPopulationFile.fileType
        if (newPopulationFile.state === State.OK) {
          if (evaluationName !== newPopulationFile.evaluationName) {
            newPopulationFile.state = State.UNRELATED_OPERATION_EVAL
          } else if (controlId !== newPopulationFile.controlId) {
            newPopulationFile.state = State.DIFFERENT_CONTROL_ID
          }
        }
        if (newPopulationFile.state !== State.OK) {
          setFiles(fileInfos)
          return
        }

        if (!_.endsWith(newPopulationFile.fileName, newPopulationFile.fileExtension)) {
          newPopulationFile.fileName = _.join(
            [newPopulationFile.fileName, newPopulationFile.fileExtension],
            '.'
          )
        }

        console.log('on drop ', getFileSizeByMB(newPopulationFile.file))
        setStates({ saveLoading: true })
        if (getFileSizeByMB(newPopulationFile.file) >= MAX_POPULATION_SIZE) {
          setPopulation(newPopulationFile.file.name, undefined, fileInfos)
          setFiles(fileInfos)
          setStates({ saveLoading: false })
        } else {
          readFileWorker(newPopulationFile)
            .then((newPopulation: any) => {
              if (newPopulation.state === State.OK) {
                setPopulation(newPopulation.file.name, newPopulation.excelData, fileInfos)
              }
              setFiles(fileInfos)
            })
            .catch((error: any) => {
              console.log('Failed to read file', error)
              alertRef.current?.showAlert(
                'error',
                '잘못된 형식의 모집단 파일입니다. 모집단 Template파일을 사용하여 다시 업로드하거나 모집단 표에 직접 붙여 넣어 주세요.'
              )
            })
            .finally(() => setStates({ saveLoading: false }))
        }
      },
      [controlId, evaluationName, files, states]
    )

    React.useEffect(() => {
      if (!_.isEmpty(populationFile?.fileName)) {
        const newFile = populationFile as EvalFile
        setFiles([
          {
            ...newFile,
            evaluationType: 'operation',
            evaluationName,
            controlId
          }
        ])
      } else {
        setFiles([])
      }
    }, [controlId, populationFile])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      noClick: !_.isEmpty(files)
    })

    return (
      <div>
        <Typography.Title level={5}>모집단 파일 업로드</Typography.Title>
        <div
          className={clsx(classes.dropzone, { [classes.dragActive]: isDragActive })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {_.isEmpty(files) && <UploadIconAndText isDragActive={isDragActive} />}
          {!_.isEmpty(files) && (
            <CommonAgGrid
              noBorder
              columnDefs={getFileUploaderColumns(staffs)}
              domLayout="autoHeight"
              frameworkComponents={{ dateCellRenderer, StaffCellRenderer }}
              rowData={files}
            />
          )}
        </div>
        <NoticeTextView>
          <Typography.Text>
            모집단 파일명은{' '}
            <Typography.Text type="danger" strong>
              ({evaluationName})({controlId})(모집단)파일이름.xlsx
            </Typography.Text>
            으로 업로드해야합니다.
          </Typography.Text>
          <br />
          <Typography.Text>
            모집단 Template 파일은{' '}
            <Typography.Text type="danger" strong>
              운영평가 파일 관리
            </Typography.Text>
            {' / '}
            <Typography.Text type="danger" strong>
              템플릿 다운로드
            </Typography.Text>
            에서 다운로드 할 수 있습니다.
          </Typography.Text>
        </NoticeTextView>
        <AlertMessage ref={alertRef} />
        <CircleBackdrop open={states.saveLoading} />
      </div>
    )
  }
)

export default PopulationFileUploader
