import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { blue } from '@ant-design/colors'
import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useDropzone } from 'react-dropzone'

import {
  ERROR_STATES,
  EvaluationFile,
  State,
  stateToColor,
  stateToString
} from '../../../base/data/BaseFile'
import {
  DesignEvaluationData,
  EvaluationType,
  getKoEvaluation,
  OperationEvaluationData
} from '../../../base/data/Evaluation'
import {
  evaluationParsingFiles,
  evaluationParsingInfoUploadFileList
} from '../../../dataLoader/Evaluation/evaluation'
import TableViewer, { TableViewerHandler } from '../../common/TableViewer'
import { getShortEvalType } from './EvaluationUtils'
import UploadIconAndText from './UploadIconAndText'

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: 'WhiteSmoke',
    border: '1px dashed lightgray'
  },
  dragActive: {
    borderColor: blue.primary
  },
  fileTable: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  }
}))

function fileNameCellRenderer(params: any): HTMLElement {
  const span = document.createElement('span')
  span.innerHTML = params.value + '.' + params.data.fileExtension
  return span
}

export function stateCellRenderer(params: any): HTMLElement {
  const span = document.createElement('span')
  span.innerHTML = params.valueFormatted
  span.style.color = stateToColor(params.data.state)

  if (params.data.state === State.PARSING_ERROR && params.data?.excelException) {
    span.innerText += ' (' + params.data.excelException.join(', ') + ')'
  }
  return span
}

function previewCellRenderer(params: any): HTMLElement {
  if (!_.includes(ERROR_STATES, params.data.state) && _.isEqual(params.data.fileType, '보고서')) {
    const aElement = document.createElement('a')
    aElement.innerHTML = '미리보기'
    aElement.onclick = () => {
      params.setReport(params.data?.evaluationName, params.data?.controlId, params.data?.excelData)
    }
    return aElement
  }
  const span = document.createElement('span')
  span.innerHTML = '-'
  return span
}

export function deleteCellRenderer(params: any): HTMLElement {
  const files: any[] = []
  params.api.forEachNode((node: any) => {
    files.push(node.data)
  })
  const aElement = document.createElement('a')
  aElement.innerHTML = '삭제'
  aElement.onclick = () => params.deleteFile(files, params.data)
  return aElement
}

function updateFileKeys(files: EvaluationFile[]): void {
  let maxLevel = _.max(_.map(files, (file) => file.key)) || 0
  _.forEach(files, (file) => {
    if (!file.key) {
      file.key = maxLevel + 1
      maxLevel += 1
    }
  })
}

interface Props {
  type: EvaluationType
  files: EvaluationFile[]
  setFiles: (files: EvaluationFile[]) => void
  setReport: (
    evaluationName: string,
    controlId: string,
    evaluationData: OperationEvaluationData | DesignEvaluationData
  ) => void
}

const DropBox: React.FC<Props> = ({ type, files, setFiles, setReport }) => {
  const classes = useStyles()
  const [tmpFiles, setTmpFiles] = React.useState<EvaluationFile[]>()

  const columns = React.useMemo(
    (): (ColDef | ColGroupDef)[] => [
      { headerName: '파일 이름', field: 'fileName', flex: 2, cellRenderer: fileNameCellRenderer },
      {
        headerName: `${getKoEvaluation(type)} 이름`,
        field: 'evaluationName'
      },
      { headerName: '통제번호', field: 'controlId', flex: 1 },
      { headerName: '파일 종류', field: 'fileType', width: 120 },
      {
        headerName: '파일 상태',
        field: 'state',
        cellRenderer: stateCellRenderer,
        valueFormatter: (params: any): any => stateToString(params.value),
        flex: 3
      },
      {
        headerName: '미리보기',
        field: 'preview',
        width: 120,
        cellRenderer: previewCellRenderer,
        cellRendererParams: { setReport }
      },
      {
        headerName: '삭제',
        field: 'delete',
        width: 120,
        cellRenderer: deleteCellRenderer,
        cellRendererParams: {
          deleteFile: (allFiles: EvaluationFile[], file: EvaluationFile): void => {
            const newFiles = allFiles.filter((item) => item.fileName !== file.fileName)
            updateFileKeys(newFiles)
            setTmpFiles(newFiles)
          }
        }
      }
    ],
    []
  )
  const viewerRef = React.useRef<TableViewerHandler>(null)

  React.useEffect(() => {
    setFiles(tmpFiles || [])
  }, [tmpFiles])

  const onDrop = React.useCallback(
    (originalFiles) => {
      const acceptedFiles: File[] = []
      _.forEach(originalFiles, (file) => {
        acceptedFiles.push(new File([file], file.name.normalize('NFC'), { type: file.type }))
      })
      const fileInfos = evaluationParsingInfoUploadFileList(getShortEvalType(type), acceptedFiles)
      evaluationParsingFiles(getShortEvalType(type), fileInfos).then((parsedFiles: any) => {
        const allFiles = files.slice()
        _.forEach(parsedFiles, (file) => {
          if (file.state === State.INVALID_NAME) {
            file.evaluationName = ''
            file.controlId = ''
            file.fileType = ''
          }

          const matched = _.findIndex(allFiles, { fileName: file.fileName })
          if (matched >= 0 && (file.state === State.OK || allFiles[matched]?.state !== State.OK)) {
            _.pullAt(allFiles, [matched])
            allFiles.push(file)
          } else if (matched < 0) {
            allFiles.push(file)
          }
        })

        updateFileKeys(allFiles)
        setFiles(allFiles)
      })
    },
    [files]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: !_.isEmpty(files)
  })

  return (
    <div
      className={clsx(classes.dropzone, { [classes.dragActive]: isDragActive })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {_.isEmpty(files) && <UploadIconAndText isDragActive={isDragActive} />}
      {!_.isEmpty(files) && (
        <div className={classes.fileTable}>
          <TableViewer noBorder columnDefs={columns} ref={viewerRef} rowData={files} />
        </div>
      )}
    </div>
  )
}

export default DropBox
