import Tabs from 'antd/lib/tabs'
import React from 'react'

import CardTabs from '../common/CardTabs'
import DepartmentManagement from './DepartmentManagement'
import GuestManagement from './GuestManagement'
import ReferenceSettings from './ReferenceSettings'
import UserManagement from './UserManagement'

const AdminPage: React.FC = () => {
  const [activeKey, setActiveKey] = React.useState('1')

  return (
    <CardTabs defaultActiveKey={activeKey} onChange={(key) => setActiveKey(key)}>
      <Tabs.TabPane key="1" tab="사용자 등록">
        <GuestManagement active={activeKey === '1'} />
      </Tabs.TabPane>
      <Tabs.TabPane key="2" tab="사용자 관리">
        <UserManagement active={activeKey === '2'} />
      </Tabs.TabPane>
      <Tabs.TabPane key="3" tab="부서 추가/삭제">
        <DepartmentManagement active={activeKey === '3'} />
      </Tabs.TabPane>
      <Tabs.TabPane key="4" tab="운영/설계 평가 참조 설정">
        <ReferenceSettings />
      </Tabs.TabPane>
    </CardTabs>
  )
}

export default AdminPage
