import _ from 'lodash'

function recur(values: any[], n: number): any {
  if (--n < 0) {
    return [[]]
  }
  const result: any[] = []
  values.forEach((value, index, array) => {
    array = array.slice()
    array.splice(index, 1)
    recur(array, n).forEach((permutation: any) => {
      permutation.unshift(value)
      result.push(permutation)
    })
  })
  return result
}

export function permutations(collection: any[], n: number): any {
  const values = _.values(collection)
  if (values.length < n) {
    return []
  }
  return recur(values, n)
}
