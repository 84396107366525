/* eslint-disable no-undef */
import axios from 'axios'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import _ from 'lodash'

import { storageService } from '../fbase'

export const storageGetDownloadUrl = async (fileFullPath) => {
  // Cloud Storage 에서 fileFullPath 의 download url 을 가져온다.

  const storageRef = storageService.ref()
  const downloadUrl = await storageRef.child(fileFullPath).getDownloadURL()

  return downloadUrl
}

export const storageDownloadBlobFromUrl = async (downloadUrl) => {
  // Cloud Storage 에서 downloadUrl 의 파일을 blob 으로 가져옴
  const downloadBlob = await axios.get(downloadUrl, { responseType: 'blob' }).then((response) => {
    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    })
    return blob
  })

  return downloadBlob
}

export const storageSaveFromBlob = async (blobData, fileName) => {
  // blob data 를 fileName 으로 저장
  saveAs(blobData, fileName)
}

export const storageDownloadFromFilePath = async (fileFullPath, fileName) => {
  // fileFullPath 파일을 fileName 으로 다운로드
  const url = await storageGetDownloadUrl(fileFullPath)
  const blob = await storageDownloadBlobFromUrl(url)

  return await storageSaveFromBlob(blob, fileName)
}

export const storageUploadFile = async (filePath, fileData) => {
  // 파일 하나를 Storage 에 업로드
  const storageRef = storageService.ref()
  const fileRef = storageRef.child(filePath)
  return await fileRef.put(fileData)
}

export const storageUploadFileFromObject = async (filePath, fileObject) => {
  // 파일 Object 로부터 Storage 에 업로드
  const fileData = fileObject.file

  return await storageUploadFile(filePath, fileData)
}

export const storageUploadZipFromBlob = (filePath, objList) => {
  // blobData 를 Zip 으로 만든 뒤에 Storage 에 controlId 로 upload
  const zip = new JSZip()
  objList.forEach((obj) => {
    const fileName = obj.fileName
    const fileType = obj.fileTypeInZip
    const blobData = obj.fileBlob
    zip.file(`${fileType}/${fileName}`, blobData, { binary: true })
  })
  zip.generateAsync({ type: 'blob' }).then((content) => {
    const storageRef = storageService.ref()
    const fileRef = storageRef.child(filePath)
    const result = fileRef.put(content)
    return result
  })
}

export const storageUploadFileListForEvaluation = async (evalType, fileList) => {
  /*
  파일들을 Storage 에 업로드
  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: fileObjectArr: Object[] evaluationParsingInfoUploadFileList 에서 받은 리턴 값 그대로
  */
  const result = fileList.map(async (fileObject) => {
    let filePath
    if (evalType === 'OE') {
      filePath = `운영평가/${fileObject.evaluationName}/${fileObject.controlId}/${fileObject.fileName}.${fileObject.fileExtension}`
    } else if (evalType === 'DE') {
      filePath = `설계평가/${fileObject.evaluationName}/${fileObject.controlId}/${fileObject.fileName}.${fileObject.fileExtension}`
    }
    return storageUploadFileFromObject(filePath, fileObject)
  })

  return Promise.all(result)
}

export const storageDeleteFile = async (filePath) => {
  // 파일 하나를 Storage 에서 삭제
  const storageRef = storageService.ref()
  const fileRef = storageRef.child(filePath)

  return await fileRef.delete()
}

const storageDeleteDirectory = async (directoryPath) => {
  // 폴더 하나를 Storage 에서 삭제(하위 파일은 모두 폴더가 아닌 파일이어야 한다)
  const storageRef = storageService.ref()
  const directoryRef = storageRef.child(directoryPath)
  const fileListObject = await directoryRef.list()

  fileListObject.items.forEach((item) => {
    const fileFullPath = item.fullPath
    storageRef.child(fileFullPath).delete()
  })
}

export const storageDeleteEvaluationFile = async (evalType, evalName, controlId, fileName) => {
  // 해당 파일을 Storage 에서 파일 하나를 삭제
  let filePath
  if (evalType === 'OE') {
    filePath = `운영평가/${evalName}/${controlId}/${fileName}`
  } else if (evalType === 'DE') {
    filePath = `설계평가/${evalName}/${controlId}/${fileName}`
  }

  return await storageDeleteFile(filePath)
}

export const storageDeleteAllEvaluationFile = async (evalType, evalName) => {
  // 해당 평가에 해당하는 폴더 전체를 Storage 에서 삭제
  let filePath
  if (evalType === 'OE') {
    filePath = `운영평가/${evalName}`
  } else if (evalType === 'DE') {
    filePath = `설계평가/${evalName}`
  }

  const storageRef = storageService.ref()
  const directoryRef = storageRef.child(filePath)
  const directoryObject = await directoryRef.list()
  const directoryList = directoryObject.prefixes.map((prefix) => {
    return prefix.fullPath
  })

  return Promise.all(
    directoryList.map(async (directoryPath) => {
      return storageDeleteDirectory(directoryPath)
    })
  )
}

export const storageGetDownloadUrlFromArchive = async (evalType, refEvalName, controlIdList) => {
  // Archive 에서 refEval 의 controlIdList 에 해당하는 다운로드 url 들을 가져온다

  const result = {}
  let evalPath
  if (evalType === 'OE') {
    evalPath = '운영평가'
  } else if (evalType === 'DE') {
    evalPath = '설계평가'
  }
  const storageRef = storageService.ref()
  const targetDirPath = `Archive/${evalPath}/${refEvalName}`
  const targetDirRef = storageRef.child(targetDirPath)
  const childFileListRef = await targetDirRef.list()
  const childFileList = childFileListRef.items

  const tempResult = await Promise.all(
    childFileList.map(async (storageFileObject) => {
      const fileName = storageFileObject.name
      const fileFullPath = storageFileObject.fullPath
      const controlId = fileName.split('.zip')[0]
      const url = await storageGetDownloadUrl(fileFullPath)
      return {
        controlId,
        url
      }
    })
  )

  tempResult.forEach((tempObj) => {
    result[tempObj.controlId] = tempObj.url
  })

  return result
}

export const hasFile = async (evalType, evaluationName, controlId, fileName) => {
  let evalPath
  if (evalType === 'OE') {
    evalPath = '운영평가'
  } else if (evalType === 'DE') {
    evalPath = '설계평가'
  }
  const storageRef = storageService.ref()
  const filePath = `${evalPath}/${evaluationName}/${controlId}/${fileName}`
  try {
    const downloadUrl = await storageRef.child(filePath).getDownloadURL()
    return !_.isEmpty(downloadUrl)
  } catch (e) {
    console.log('Not found ', evaluationName, controlId, fileName)
    return false
  }
}
