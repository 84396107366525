/* eslint-disable newline-per-chained-call */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import _ from 'lodash'
import moment from 'moment'

import { State } from '../../base/data/BaseFile'
import { EvaluationSummary } from '../../base/data/Evaluation'
import { getFileSizeByMB, MAX_POPULATION_SIZE } from '../../base/utils/FileUtils'
import { postprocessEvaluation } from '../../component/evaluation/common/EvaluationUtils'
import { LoadStaff } from '../../component/rcm/editor/RCMEditorUtils'
import { controlGetWithId, controlUpdate } from '../RCM/control'
import { controlMappingWithStaff } from '../RCM/mapping'
import { riskGetWithId, riskUpdate } from '../RCM/risk'
import { staffGetWithId } from '../RCM/staff'
import { adminGetOrCreateUser } from '../Utils/admin'
import {
  excelParsingForDE,
  excelParsingForOE,
  excelEditEvaluationTemplate,
  excelParsingPopulationFile
} from '../Utils/excel'
import {
  storageDeleteEvaluationFile,
  storageDownloadBlobFromUrl,
  storageUploadZipFromBlob,
  storageDeleteAllEvaluationFile,
  storageUploadFileListForEvaluation,
  storageGetDownloadUrl,
  storageDownloadFromFilePath,
  storageUploadFile,
  storageDeleteFile,
  hasFile
} from '../Utils/storage'
import { dbService, authService } from '../fbase'
import { checkEvaluationName, exportToExcel } from './evaluationUtils'

async function FilterUnrelatedEvaluationIds(evalType, targetCollectionName, evaluationIds) {
  const user = await adminGetOrCreateUser()
  if (user.level === 'ADMIN') {
    return evaluationIds
  } else if (user.level === 'WATCHER') {
    // NOTE: 완료된 평가 반환
    const closedEvaluations = _.compact(
      await Promise.all(
        _.map(evaluationIds, async (id) => {
          const baseSnapshots = await dbService
            .collection(targetCollectionName)
            .doc(id)
            .collection('개요')
            .doc('개요')
            .get()
          return baseSnapshots.get('state') === '2' ? id : undefined
        })
      )
    )
    return closedEvaluations
  }

  const relatedEvaluations = _.compact(
    await Promise.all(
      _.map(evaluationIds, async (id) => {
        const stateSnapshots = await dbService
          .collection(targetCollectionName)
          .doc(id)
          .collection('상태')
          .get()

        const controlIds = []
        stateSnapshots.forEach((doc) => {
          if (doc.get('controlOwner') === user.id || doc.get('controlPerformer') === user.id) {
            controlIds.push(doc.id)
          }
        })
        return !_.isEmpty(controlIds) ? id : undefined
      })
    )
  )

  const referencedEvaluations = _.compact(
    await Promise.all(
      _.map(relatedEvaluations, async (id) => {
        const baseSnapshots = await dbService
          .collection(targetCollectionName)
          .doc(id)
          .collection('개요')
          .doc('개요')
          .get()
        return baseSnapshots.get('refEvaluationName')
      })
    )
  )

  return _.uniq(_.concat(relatedEvaluations, referencedEvaluations))
}

export const evaluationGetAllIdList = async (evalType) => {
  /*
  evalType 에 해당하는 종류의 평가 전체의 이름을 반환한다.

  @param: evalType: String - "OE"(운영평가), "DE"(설계평가)
  @return: String[] - 해당 평가 전체의 이름 리스트
  */

  let targetCollectionName
  if (evalType === 'OE') {
    targetCollectionName = '운영평가'
  } else if (evalType === 'DE') {
    targetCollectionName = '설계평가'
  }

  const collectionData = await dbService.collection(targetCollectionName).get()

  const result = []
  collectionData.forEach((doc) => {
    result.push(doc.id)
  })
  return FilterUnrelatedEvaluationIds(evalType, targetCollectionName, result)
}

const evaluationGetCreateDate = async (evalType, evalName, controlId) => {
  // 해당 보고서 파일이 업데이트 된 적이 있는지 createDate 를 통해서 체크

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }

  const docRef = dbService
    .collection(collectionName)
    .doc(evalName)
    .collection('내역')
    .doc(controlId)
  const docData = await docRef.get()
  const data = docData.data()

  if (!_.has(data, 'evalData')) {
    return ''
  } else if (!_.has(data, 'evalData.createDate')) {
    return
  }

  return data.evalData.createDate
}

async function evaluationGetBase(evalType: 'OE' | 'DE', evalName: string): EvaluationSummary {
  /*
  evalType, evalName 에 해당하는 평가의 내용 전체(개요)를 반환한다.

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @return: Object{
    evalType: "OE", // 평가의 종류 - "OE" or "DE"
    evalName: "2021_운영평가",  // 평가의 이름
    controlIdList: [],  // 선택된 CONTROL id list
    startDate: "",
    endDate: "",
    closedTime: "", // 마감 시간
    state: "1", // "1": 진행중 "2": 마감
    refEvaluationName: "" // 참조된 평가
  }
  */

  const result = {
    evalType,
    evalName,
    controlIdList: [], // 선택된 CONTROL id list
    startDate: '',
    endDate: '',
    closeTime: '',
    state: '1',
    refEvaluationName: ''
  }

  if (evalName === '신규') {
    return result
  } else if (evalName !== '') {
    let targetCollectionName

    if (evalType === 'OE') {
      targetCollectionName = '운영평가'
    } else if (evalType === 'DE') {
      targetCollectionName = '설계평가'
    }

    const docData = await dbService
      .collection(targetCollectionName)
      .doc(evalName)
      .collection('개요')
      .doc('개요')
      .get()

    const evalData = docData.data()
    result.startDate = evalData.startDate
    result.endDate = evalData.endDate
    result.controlIdList = evalData.controlArray
    result.closeTime = evalData.closeTime
    result.state = evalData.state
    result.refEvaluationName = evalData.refEvaluationName
  }

  return result
}

export async function evaluationGetAllBase(evalType: 'OE' | 'DE'): Promise<EvaluationSummary[]> {
  const ids = await evaluationGetAllIdList(evalType)
  return Promise.all(_.map(ids, async (id) => evaluationGetBase(evalType, id)))
}

export const evaluationGetStateWithControlId = async (evalType, evalName, controlId) => {
  /*
  evalType, evalName, controlId 의
  "상태"의 내용을 모두 가져오는 함수

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id

  @return: Object{}
  {
    accomplishTime: "",
    approvalTime: "",
    controlApprover: "",
    controlOwner: "smcho@ccksolution.com",
    controlPerformer: "kth2423@naver.com",
    departmentName: "매출관리팀",

    state: "", // "1": 미수행, "2": 수행중, "3": 수행완료, "4": 재작성필요, "5": 승인완료
    resultState: "", // 운영평가("1": default, "2": 특이사항 없음, "3": 예외사항 발생, "4": 처리 완료), 설계평가("1": default, "2": Ineffective, "3": Effective, "4": 처리 완료)
    resultStateUpdateTime: "",
    weakState: "", // default/단순한미비점/유의한미비점/중요한취약점
    weakStateUpdateTime: "",  // 운영평가("1": default, "2": 단순한 미비점, "3": 유의한 미비점, "4": 중요한 취약점), 설계평가("1": default, "2": 수정사항 없음 - 아무 내용도 수정할 게 없어서 안쓴거임, "3": 수정사항 존재 - 아직 미반영, "4": "2"와 "3" 에 대해서 수정사항 반영(RCM) 완료
    weakStateData: {},  // 운영평가(flowChart), 설계평가(RCM 미비점의 내용 - (구)설계평가)
  }
  */

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }
  const docRef = dbService
    .collection(collectionName)
    .doc(evalName)
    .collection('상태')
    .doc(controlId)
  const docData = await docRef.get()
  const data = docData.data()

  return data
}

export const evaluationGetStateAll = async (evalType, evalName) => {
  /*
  evalType, evalName 의
  "상태"의 내용을 모두 가져오는 함수

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @return: Object[]: evaluationGetStateWithControlId 참조
  [
    {
      controlId: "",  // CONTROL id
      data: {}, // data
    },
    ...
  ]
  */

  const evalBaseData = await evaluationGetBase(evalType, evalName)
  const controlIdList = evalBaseData.controlIdList

  const result = controlIdList.map(async (controlId) => {
    const data = await evaluationGetStateWithControlId(evalType, evalName, controlId)
    return { controlId, data }
  })

  return Promise.all(result)
}

export const evaluationGetContentWithControlId = async (evalType, evalName, controlId) => {
  /*
  evalType, evalName, controlId 의
  "내역"의 내용을 모두 가져오는 함수

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id

  @return: Object{}
  {
    controlData: {},  // control 내용
    files: {}, // files 내용
    riskData: {}, // risk 내용
    staffData: {}, // staff 내용
  }
  */
  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }

  const docRef = dbService
    .collection(collectionName)
    .doc(evalName)
    .collection('내역')
    .doc(controlId)
  const docData = await docRef.get()
  const data = docData.data()

  return data
}

export const evaluationGetContentAll = async (evalType, evalName) => {
  /*
  evalType, evalName, controlId 의
  "내역"의 내용을 모두 가져오는 함수

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @return: Object[]: evaluationGetContentWithControlId 참조
  [
    {
      controlId: "",  // CONTROL id
      data: {}, // data
    },
    ...
  ]
  */

  const evalBaseData = await evaluationGetBase(evalType, evalName)
  const controlIdList = evalBaseData.controlIdList

  const result = controlIdList.map(async (controlId) => {
    const data = await evaluationGetContentWithControlId(evalType, evalName, controlId)
    return { controlId, data }
  })

  return Promise.all(result)
}

const evaluationGetPartialContentWithControlIdOE = async (evalName, controlId) => {
  /*
  운영평가의 controlId 에 해당하는 "내역" 중 일부를 반환
  controlId, evalData, files => 보고서 업데이트 할 때 필요

  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  */

  const docRef = dbService.collection('운영평가').doc(evalName).collection('내역').doc(controlId)

  return await docRef.get().then((doc) => {
    const data = doc.data()

    return {
      controlId,
      evalData: data.evalData === undefined ? {} : data.evalData,
      files: data.files === undefined ? {} : data.files
    }
  })
}

const evaluationGetPartialContentWithControlIdDE = async (evalName, controlId) => {
  /*
  설계평가의 controlId 에 해당하는 "내역" 중 일부를 반환
  controlId, evalData, files => 보고서 업데이트 할 때 필요

  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  */

  const docRef = dbService.collection('설계평가').doc(evalName).collection('내역').doc(controlId)

  return await docRef.get().then((doc) => {
    const data = doc.data()

    return {
      controlId,
      evalData: data.evalData === undefined ? {} : data.evalData,
      files: data.files === undefined ? {} : data.files
    }
  })
}

export const evaluationGetWithId = async (evalType, evalName, controlId) => {
  /*
  evalType, evalName 의
  "개요", "상태", "내역" 의 내용을 모두 가져오는 함수

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @return: Object{}
  {
    "base": {}, // "개요", evaluationGetBase 참조
    "state": {},  // "상태", evaluationGetStateWithControlId 참조
    "content": {},  // "내역", evaluationGetContentWithControlId 참조
  }
  */

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }

  const baseDocRef = dbService
    .collection(collectionName)
    .doc(evalName)
    .collection('개요')
    .doc('개요')
  const baseDocData = await baseDocRef.get()
  const stateDocRef = dbService
    .collection(collectionName)
    .doc(evalName)
    .collection('상태')
    .doc(controlId)
  const stateDocData = await stateDocRef.get()
  const contentDocRef = dbService
    .collection(collectionName)
    .doc(evalName)
    .collection('내역')
    .doc(controlId)
  const contentDocData = await contentDocRef.get()
  const contentData = contentDocData.data()

  await Promise.all(
    _.map([contentData.files.recordFile, contentData.files.populationFile], async (target) => {
      if (target && (!target?.fileName || !target.checked)) {
        target.fileName = ''
      }
    })
  )
  await Promise.all(
    _.map([contentData.files.evidenceFile, contentData.files.extraFile], async (targets) => {
      await Promise.all(
        _.map(targets, async (target, key) => {
          if (target && (!target?.fileName || !target.checked)) {
            _.unset(targets, key)
          }
        })
      )
    })
  )

  return {
    base: baseDocData.data(),
    state: stateDocData.data(),
    content: contentData
  }
}

async function FilterUnrelatedEvaluations(evalType, baseData, evaluations) {
  const user = await adminGetOrCreateUser()
  if (user.level === 'ADMIN' || user.level === 'WATCHER') {
    return evaluations
  }

  if (baseData.state === '1') {
    // NOTE: 마감되지 않은 데이터인 경우, 통제 담당자, 통제 평가자에 본인있는 통제만 전달
    return _.filter(
      evaluations,
      ({ data }) => data.state.controlOwner === user.id || data.state.controlPerformer === user.id
    )
  }

  const targetCollectionName = evalType === 'OE' ? '운영평가' : '설계평가'
  const evaluationSnapshot = await dbService.collection(targetCollectionName).get()
  const baseSnapshotPromises = []
  evaluationSnapshot.forEach((doc) => {
    const baseSnapshot = dbService
      .collection(targetCollectionName)
      .doc(doc.id)
      .collection('개요')
      .doc('개요')
      .get()
    baseSnapshotPromises.push(baseSnapshot)
  })

  const baseSnapshots = await Promise.all(baseSnapshotPromises)
  const referencingEvaluationIds = _(baseSnapshots)
    .map((baseSnapshot) => {
      if (baseSnapshot.get('refEvaluationName') === baseData.evalName) {
        return baseSnapshot.get('id')
      }
      return undefined
    })
    .compact()
    .value()

  const relatedControlIds = _.flatten(
    await Promise.all(
      _.map(referencingEvaluationIds, async (id) => {
        const controlStatesSnapshot = await dbService
          .collection(targetCollectionName)
          .doc(id)
          .collection('상태')
          .get()

        const controlIds = []
        controlStatesSnapshot.forEach((doc) => {
          if (doc.get('controlOwner') === user.id || doc.get('controlPerformer') === user.id) {
            controlIds.push(doc.id)
          }
        })
        return controlIds
      })
    )
  )

  return _.filter(evaluations, ({ controlId }) => _.includes(relatedControlIds, controlId))
}

export const evaluationGetAll = async (evalType, evalName) => {
  /*
  evalType, evalName 의
  "개요", "상태", "내역" 의 내용을 모두 가져오는 함수
  (구) getAllControlsFromOE

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @return: Object[]: evaluationGetWithId 참조
  [
    {
      controlId: "",  // CONTROL id
      data: {}, // data
    },
    ...
  ]
  */
  const evalBaseData = await evaluationGetBase(evalType, evalName)
  const controlIdList = evalBaseData.controlIdList

  const result = await Promise.all(
    _.map(controlIdList, async (controlId) => {
      const data = await evaluationGetWithId(evalType, evalName, controlId)
      return { controlId, data }
    })
  )
  return FilterUnrelatedEvaluations(evalType, evalBaseData, result)
}

export const evaluationGetAllWithFilter = async (
  evalType,
  evalName,
  filterKey,
  filterValueList
) => {
  /*
  evaluationGetAll 의 내용들에서
  "상태" 의 특정 값에 따른 filter 후 내용을 구하고 싶어서
  filterKey - 포함되길 원하는 종류
  filterValueList - 포함되길 원하는 값들

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: filterKey: String - "상태" 에서 분류하고 싶은 값
  @param: filterValueList: String[] - filterKey 의 해당되는 값들의 list

  FIY.
  filterKey
  "상태" 의 필터링할만한 값들

  state - 상태
  "1": 미수행
  "2": 수행중
  "3": 수행완료
  "4": 재작성필요
  "5": 승인완료

  resultState - 운영평가
  "1": default(생성시 들어가는 상태)
  "2": 운영 테스트 결과 문제 없음(특이사항 없음)
  "3": 운영 테스트 결과 문제 있음(예외사항 발생)
  "4": 처리 완료(일단은 flowChart 완료시 자동으로 업데이트, 추후에 미비점 및 취약점의 후속처리가 생기면 그때 자동으로 업데이트로 변경)

  resultState - 설계평가
  "1": default(생성시 들어가는 상태) (보고서 없이 그냥 생성시)
  "2": Ineffective(문제있음)
  "3": Effective(문제없음)
  "4": 처리 완료(이게 미비점 반영해서 RCM 수정하면 자동으로 같이 처리되게)

  weakState - 운영평가
  "1": default(생성시 들어가는 상태, reset 시)
  "2":단순한 미비점
  "3":유의한 미비점
  "4":중요한 취약점

  weakState - 설계평가
  "1": default(생성시 들어가는 상태, reset 시)
  "2": 수정사항 없음 - 아무 내용도 수정할 게 없어서 안쓴거임
  "3": 수정사항 존재 - 아직 미반영
  "4": "2"와 "3" 에 대해서 수정사항 반영(RCM) 완료

  */
  if (!Array.isArray(filterValueList) || filterValueList.length === 0) {
    return Promise.reject(new Error('filterValueList 를 체크해주세요'))
  }

  const dataAll = await evaluationGetAll(evalType, evalName)
  const result = {}
  if (filterKey === 'state') {
    // 유효한 filterValue 체크
    const allValueList = ['1', '2', '3', '4', '5']
    const valid = _.difference(filterValueList, allValueList).length === 0
    if (!valid) {
      return Promise.reject(new Error('filterValueList 를 체크해주세요'))
    }

    filterValueList.forEach((filterValue) => {
      result[filterValue] = _.filter(dataAll, (dataObject) => {
        const resultState = dataObject.data.state.resultState
        return resultState === filterValue
      })
    })
  } else if (filterKey === 'resultState' || filterKey === 'weakState') {
    // 유효한 filterValue 체크
    const allValueList = ['1', '2', '3', '4']
    const valid = _.difference(filterValueList, allValueList).length === 0
    if (!valid) {
      return Promise.reject(new Error('filterValueList 를 체크해주세요'))
    }

    filterValueList.forEach((filterValue) => {
      result[filterValue] = _.filter(dataAll, (dataObject) => {
        const resultState = dataObject.data.state.resultState
        return resultState === filterValue
      })
    })
  } else if (filterKey === 'owner') {
    filterValueList.forEach((filterValue) => {
      result[filterValue] = _.filter(dataAll, (dataObject) => {
        const controlOwner = dataObject.data.state.controlOwner
        return controlOwner === filterValue
      })
    })
  } else if (filterKey === 'performer') {
    filterValueList.forEach((filterValue) => {
      result[filterValue] = _.filter(dataAll, (dataObject) => {
        const controlPerformer = dataObject.data.state.controlPerformer
        return controlPerformer === filterValue
      })
    })
  } else if (filterKey === 'department') {
    filterValueList.forEach((filterValue) => {
      result[filterValue] = _.filter(dataAll, (dataObject) => {
        const departmentName = dataObject.data.state.departmentName
        return departmentName === filterValue
      })
    })
  } else {
    return Promise.reject(new Error('지원하지 않는 filterKey 입니다.'))
  }

  return result
}

export const evaluationGetAllControlIdWithFilter = async (
  evalType,
  evalName,
  filterKey,
  filterValueList
) => {
  // 다 같은데 그냥 evaluationGetAllWithFilter 에서 controlId 만 반환

  const preData = await evaluationGetAllWithFilter(evalType, evalName, filterKey, filterValueList)

  const result = {}
  Object.keys(preData).forEach((key) => {
    result[key] = []
    const dataList = preData[key]
    dataList.forEach((data) => {
      result[key].push(data.controlId)
    })
  })

  return result
}

const evaluationGetContentWithControlIdList = async (evalType, evalName, controlIdList) => {
  /*
  평가의 controlIdList 에 해당하는 "내역" 들을 반환

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlIdList: String[] - CONTROL id list
  */

  let result
  if (evalType === 'OE') {
    result = controlIdList.map(async (controlId) => {
      const controlInfoObj = await evaluationGetPartialContentWithControlIdOE(evalName, controlId)
      return controlInfoObj
    })
  } else if (evalType === 'DE') {
    result = controlIdList.map(async (controlId) => {
      const controlInfoObj = await evaluationGetPartialContentWithControlIdDE(evalName, controlId)
      return controlInfoObj
    })
  }

  return Promise.all(result)
}

const evaluationCheckExist = async (evalType, evalName) => {
  // evalType, evalName 에 해당하는 평가가 존재하는지 체크

  let targetCollectionName
  if (evalType === 'OE') {
    targetCollectionName = '운영평가'
  } else if (evalType === 'DE') {
    targetCollectionName = '설계평가'
  }

  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)
  const doc = await docRef.get()
  const result = doc.exists

  return result
}

const evaluationGetModifiedDataForControl = async (controlId) => {
  /*
  controlId 에 해당하는 내용을 평가 생성을 위해 알맞게 바꿔서 전달한다.
  필요없는 것 지우고 필요한 것 추가

  원하는 내용은 :
  기본적인 CONTROL 내용
  cyclePathArray => X
  processPathArray => X
  riskPathArray => X
  departmentPathArray => X
  staffPathArray => staffData
  riskData
  */

  const controlData = await controlGetWithId(controlId)
  delete controlData.cyclePathArray
  delete controlData.processPathArray
  delete controlData.processMappingCode
  delete controlData.riskPathArray
  delete controlData.staffPathArray
  delete controlData.departmentPathArray

  const staffId = controlData.owner
  const staffData = await staffGetWithId(staffId)
  delete staffData.controlPathArray
  delete staffData.departmentPathArray

  const riskId = controlData.riskNumber
  const riskData = await riskGetWithId(riskId)
  delete riskData.cyclePathArray
  delete riskData.processPathArray
  delete riskData.controlPathArray

  return { controlData, staffData, riskData }
}

const evaluationCreateOE_Base = async (evalObject) => {
  // 중첩된 Collection-Doc 구조를 위해서 어쩔 수 없이 나눠서 만드는거임
  const evalName = evalObject.evalName.trim()

  const targetCollectionName = '운영평가'
  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef.set({})
}

const evaluationCreateOE_Total = async (evalObject) => {
  // 새로운 운영평가의 "개요" 생성

  const evalName = evalObject.evalName.trim()

  const targetCollectionName = '운영평가'
  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef.collection('개요').doc('개요').set({
    id: evalName,
    name: evalName,
    startDate: evalObject.startDate,
    endDate: evalObject.endDate,
    controlArray: evalObject.controlIdList,
    state: '1',
    closeTime: '',
    refEvaluationName: ''
  })
}

const evaluationCreateOE_Content = async (evalObject) => {
  // 새로운 운영평가의 "내역" 생성
  const evalName = evalObject.evalName.trim()

  const targetCollectionName = '운영평가'
  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const contentCollectionName = '내역'
  const collectionRef = docRef.collection(contentCollectionName)

  const result = evalObject.controlIdList.map(async (controlId) => {
    const resultData = await evaluationGetModifiedDataForControl(controlId)
    return await collectionRef.doc(controlId).set({
      controlData: resultData.controlData,
      riskData: resultData.riskData,
      staffData: resultData.staffData,
      files: {
        recordFile: {
          updateTime: '',
          updater: '',
          fileName: '',
          checked: false
        },
        populationFile: {
          updateTime: '',
          updater: '',
          fileName: '',
          checked: false
        },
        evidenceFile: {},
        extraFile: {}
      }
    })
  })

  return Promise.all(result)
}

const evaluationCreateOE_State = async (evalObject) => {
  // 새로운 운영평가의 "상태" 생성

  const evalName = evalObject.evalName.trim()

  const targetCollectionName = '운영평가'
  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const contentCollectionName = '상태'
  const collectionRef = docRef.collection(contentCollectionName)

  const result = evalObject.controlIdList.map(async (controlId) => {
    const controlData = await controlGetWithId(controlId)
    return await collectionRef.doc(controlId).set({
      controlPerformer: controlData.incharge, // 변경점(20211026) - RCM(CONTROL)에 추가된 incharge 의 값을 가져와서 넣는다
      controlOwner: controlData.owner,
      departmentName: controlData.department,
      controlApprover: '',
      approvalTime: '',
      accomplishTime: '',
      state: '1',
      resultState: '1',
      resultStateUpdateTime: '',
      weakState: '1',
      weakStateUpdateTime: '',
      weakStateData: {
        1: {
          Question: '미비점으로 인한 재무제표 왜곡표시 발생가능성이 낮지 않은 경우인가?',
          Answer: '',
          Reason: ''
        },
        2: {
          Question: '잠재적인 왜곡표시의 규모가 재무제표에 미치는 영향이 중요한가',
          Answer: '',
          Reason: ''
        },
        3: {
          Question:
            '잠재적인 왜곡표시의 규모를 중요한 수준 미만으로 줄일 수 있는 보완통제가 있는가?',
          Answer: '',
          Reason: ''
        },
        4: {
          Question:
            '회계와 내부회계관리제도에 충분한 전문지식을 갖춘 관리자는 이 미비점을 중요한 취약점이라고 결론 내릴것으로 판단되는가?',
          Answer: '',
          Reason: ''
        },
        5: {
          Question: '미비점의 수준이 재무보고 관리감독기구(감사위원회 등)가 주목할 만큼 중요한가?',
          Answer: '',
          Reason: ''
        }
      }
    })
  })

  return Promise.all(result)
}

const evaluationCreateOE = async (evalObject) => {
  /*
  새로운 운영평가 생성
  */
  return await evaluationCreateOE_Base(evalObject)
    .then(() => evaluationCreateOE_Total(evalObject))
    .then(() => evaluationCreateOE_Content(evalObject))
    .then(() => evaluationCreateOE_State(evalObject))
}

const evaluationCreateDE_Base = async (evalObject) => {
  // 중첩된 Collection-Doc 구조를 위해서 어쩔 수 없이 나눠서 만드는거임
  const evalName = evalObject.evalName.trim()

  const targetCollectionName = '설계평가'
  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef.set({})
}

const evaluationCreateDE_Total = async (evalObject) => {
  // 새로운 설계평가의 "개요" 생성
  const evalName = evalObject.evalName.trim()

  const targetCollectionName = '설계평가'
  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef.collection('개요').doc('개요').set({
    id: evalName,
    name: evalName,
    startDate: evalObject.startDate,
    endDate: evalObject.endDate,
    controlArray: evalObject.controlIdList,
    state: '1',
    closeTime: '',
    refEvaluationName: ''
  })
}

const evaluationCreateDE_Content = async (evalObject) => {
  // 새로운 설계평가의 "내역" 생성
  const evalName = evalObject.evalName.trim()

  const targetCollectionName = '설계평가'
  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const contentCollectionName = '내역'
  const collectionRef = docRef.collection(contentCollectionName)

  const result = evalObject.controlIdList.map(async (controlId) => {
    const resultData = await evaluationGetModifiedDataForControl(controlId)
    return await collectionRef.doc(controlId).set({
      controlData: resultData.controlData,
      riskData: resultData.riskData,
      staffData: resultData.staffData,
      files: {
        recordFile: {
          updateTime: '',
          updater: '',
          fileName: '',
          checked: false
        },
        extraFile: {}
      }
    })
  })

  return Promise.all(result)
}

const evaluationCreateDE_State = async (evalObject) => {
  // 새로운 설계평가의 "상태" 생성

  const evalName = evalObject.evalName.trim()

  const targetCollectionName = '설계평가'
  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const contentCollectionName = '상태'
  const collectionRef = docRef.collection(contentCollectionName)

  const result = evalObject.controlIdList.map(async (controlId) => {
    const controlData = await controlGetWithId(controlId)
    const riskId = controlData.riskNumber
    return await collectionRef.doc(controlId).set({
      controlPerformer: controlData.incharge, // 변경점(20211026) - RCM(CONTROL)에 추가된 incharge 의 값을 가져와서 넣는다
      controlOwner: controlData.owner,
      departmentName: controlData.department,
      controlApprover: '',
      accomplishTime: '',
      approvalTime: '',
      state: '1',
      resultState: '1',
      resultStateUpdateTime: '',
      weakState: '1',
      weakStateUpdateTime: '',
      weakStateData: {
        control: {
          id: controlId,
          narrative: { content: '', evidence: '' },
          policy: { content: '', evidence: '' },
          ITDependencySystem: { content: '', evidence: '' },
          MRC_YN: { content: '', evidence: '' },
          MRC_Number: { content: '', evidence: '' },
          MRC_IPE_Name: { content: '', evidence: '' },
          IPE_YN: { content: '', evidence: '' },
          IPE_Number: { content: '', evidence: '' },
          IPE_Name: { content: '', evidence: '' },
          department: { content: '', evidence: '' },
          owner: { content: '', evidence: '' },
          teamLeader: { content: '', evidence: '' },
          teamMember: { content: '', evidence: '' },
          keyControl: { content: '', evidence: '' },
          goal_Operation: { content: '', evidence: '' },
          goal_Trust: { content: '', evidence: '' },
          goal_Asset: { content: '', evidence: '' },
          goal_Override: { content: '', evidence: '' },
          goal_Law: { content: '', evidence: '' },
          accountCode: { content: '', evidence: '' },
          accountName: { content: '', evidence: '' },
          reportFootnotes: { content: '', evidence: '' },
          assertion_EO: { content: '', evidence: '' },
          assertion_C: { content: '', evidence: '' },
          assertion_RO: { content: '', evidence: '' },
          assertion_PD: { content: '', evidence: '' },
          assertion_Occur: { content: '', evidence: '' },
          assertion_Assessment: { content: '', evidence: '' },
          assertion_Measurement: { content: '', evidence: '' },
          period: { content: '', evidence: '' },
          preventDetective: { content: '', evidence: '' },
          autoManual: { content: '', evidence: '' },
          residualRiskLevel: { content: '', evidence: '' },
          controlRiskLevel: { content: '', evidence: '' },
          TOC_Procedure: { content: '', evidence: '' },
          TOC_Evidence: { content: '', evidence: '' },
          TOC_Population: { content: '', evidence: '' },
          TOC_PopulationCount: { content: '', evidence: '' },
          TOC_TestProperties: { content: '', evidence: '' },
          TOC_Exception: { content: '', evidence: '' },
          TOC_TestMethod_InquiryInspection: { content: '', evidence: '' },
          TOC_TestMethod_Observation: { content: '', evidence: '' },
          TOC_TestMethod_Reperformance: { content: '', evidence: '' }
        },
        risk: {
          id: riskId,
          name: { content: '', evidence: '' },
          RoMM: { content: '', evidence: '' },
          inherentRiskLevel: { content: '', evidence: '' }
        }
      }
    })
  })

  return Promise.all(result)
}

const evaluationCreateDE = async (evalObject) => {
  /*
  새로운 설계평가 생성
  */
  return await evaluationCreateDE_Base(evalObject)
    .then(() => evaluationCreateDE_Total(evalObject))
    .then(() => evaluationCreateDE_Content(evalObject))
    .then(() => evaluationCreateDE_State(evalObject))
}

export const evaluationCreate = async (evalObject) => {
  /*
  evalType 에 해당하는 새로운 평가를 만든다
  isNew 삭제 - 의미 없음

  @param: evalObject: Object{
    evalType: "OE", // 평가의 종류 - "OE" or "DE"
    evalName: "2021_운영평가",  // 평가의 이름
    controlIdList: [],  // 선택된 CONTROL id list
    startDate: "",
    endDate: "",
  }

  바뀐 운영/설계평가 구조
  "운영평가"/[평가이름]/"개요"/"개요"(어쩔수없음)
                      /"내역"
                      /"상태"

  "설계평가"/[평가이름]/"개요"/"개요"(어쩔수없음)
                      /"내역"
                      /"상태"
  */

  const { evalType, evalName } = evalObject

  // 혹시 이미 존재하는 이름의 평가인지 체크(같은 종류, 같은 이름 이면 생성 불가능)
  const isExist = await evaluationCheckExist(evalType, evalName)
  if (isExist) {
    return Promise.reject(
      new Error(`이미 같은 종류(${evalType}), 같은 이름(${evalName}) 의 평가가 있습니다.`)
    )
  }

  // 이제 새로운 평가를 생성한다.
  if (evalType === 'OE') {
    return await evaluationCreateOE(evalObject)
  } else if (evalType === 'DE') {
    return await evaluationCreateDE(evalObject)
  }
}

export const evaluationCheckEditable = async (evalType, evalName) => {
  /*
  해당 평가가 수정가능한지 체크
  Closed 된건지 확인
  "개요" 의 state 가 "1" 인지 체크

  @return boolean True("1") False("2")
  */

  const evaluationBaseData = await evaluationGetBase(evalType, evalName)
  const state = evaluationBaseData.state

  return state === '1'
}

export const evaluationDelete = async (evalType, evalName) => {
  /*
  평가를 삭제

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  */

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  let targetCollectionName
  if (evalType === 'OE') {
    targetCollectionName = '운영평가'
  } else if (evalType === 'DE') {
    targetCollectionName = '설계평가'
  }

  const targetDocId = evalName
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  // 개요 삭제
  const totalDocResult = await docRef.collection('개요').doc('개요').delete()
  // 내역 삭제
  const contentDocs = await docRef.collection('내역').get()
  const contentResult = await Promise.all(contentDocs.docs.map((doc) => doc.ref.delete()))
  // 상태 삭제
  const stateDocs = await docRef.collection('상태').get()
  const stateResult = await stateDocs.docs.map((doc) => doc.ref.delete())

  return await docRef.delete().then(() => {
    storageDeleteAllEvaluationFile(evalType, evalName)
  })
}

export const evaluationParsingInfoUploadFileList = (evalType, fileList) => {
  /*
  입력 받은 파일리스트를 파싱해서 파일들 정보를 리턴
  @param fileList: FileList (input type=file 로 받은 정보 그대로 전체)
  @return Object[] 파싱한 파일정보를 담은 Object 들의 리스트
  */
  const fileArray = Array.from(fileList)

  const parsedFileList = []
  fileArray.forEach((file) => {
    let fileName
    let fileExtension
    let state
    let evaluationName
    let controlId
    let fileType
    let fileContent
    fileName = fileExtension = evaluationName = controlId = fileType = fileContent = ''
    state = 1

    fileName = file.name.slice(0, file.name.lastIndexOf('.'))
    fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
    fileContent = ''

    try {
      const result = checkEvaluationName(fileName)
      if (result) {
        evaluationName = result.evaluationName
        controlId = result.controlId
        fileType = result.fileType
      } else {
        state = State.INVALID_STRUCTURE
      }
    } catch (e) {
      console.log('Invalid file name', e)
      state = State.INVALID_NAME
    }

    if (evalType === 'DE' && fileType !== '기타') {
      state = State.INVALID_NAME
    }
    // 모집단의 제한사항 검사 - 무조건 확장자는 xlsx
    if (fileType === '모집단' && fileExtension !== 'xlsx') {
      // 모집단의 경우 확장자가 xlsx 가 아니면 업로드 불가!
      state = State.INVALID_POPULATION_FILE_EXTENSION
    }

    const dotResult = file.name.match(/\./g)
    if (dotResult.length == null || dotResult.length !== 1) {
      // 21 상태 신규로 만듬 "확장자 표시 이외 "." 사용 금지"
      state = State.INVALID_DOT
    }

    const fileObj = {
      fileName,
      fileExtension,
      state,
      evaluationName,
      controlId,
      fileType,
      fileContent,
      file
    }
    parsedFileList.push(fileObj)
  })
  return parsedFileList
}

const evaluationUpdateResultState = async (evalType, evalName, controlId, finalState) => {
  /*
  평가-상태 의 resultState 항목을 수정한다

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  @param: finalState: String - "1", "2", "3", "4"

  FIY. resultState
  운영평가 - resultState
  "1": default. 생성 시 상태
  "2": 특이사항 없음(미비점 평가 대상이 아님)
  "3": 예외사항 발생(미비점 평가 대상에 해당)

  설계평가 - resultState
  "1": default. 생성 시 상태
  "2": Effective(미비점 평가 대상이 아님)
  "3": Ineffective(미비점 평가 대상에 해당)
  */

  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }

  const docRef = dbService
    .collection(collectionName)
    .doc(evalName)
    .collection('상태')
    .doc(controlId)

  return await docRef.update({
    resultState: finalState,
    resultStateUpdateTime: currentTime
  })
}

export const evaluationParsingFiles = async (evalType, fileList) => {
  /*
  파일Object 리스트를 받아서 엑셀파일(보고서만)을 파싱한 데이터를 추가해서 리턴
  @param fileList: evaluationParsingInfoUploadFileList 에서 받은 리턴값 그대로
  */

  const result = _.map(fileList, async (fileObject) => {
    if (fileObject.fileType === '보고서' && fileObject.state === 1) {
      let excelParsingResult
      if (evalType === 'OE') {
        excelParsingResult = await excelParsingForOE(fileObject)
      } else if (evalType === 'DE') {
        excelParsingResult = await excelParsingForDE(fileObject)
      }

      return excelParsingResult
    } else if (fileObject.fileType === '모집단' && fileObject.state === State.OK) {
      return excelParsingPopulationFile(fileObject)
    } else {
      return fileObject
    }
  })

  return Promise.all(result)
}

const evaluationUpdateFromExcelOE = async (evalType, evalName, fileList) => {
  // 엑셀파일로부터 운영평가 업데이트

  const currentUserId = authService.currentUser.email
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  const uploadTargetObject = {}

  // 해당 controlIdList
  let controlIdList = fileList.map((fileObject) => fileObject.controlId)
  controlIdList = [...new Set(controlIdList)]

  // "내역" - controlData, riskData, staffData 제외
  const prevEvaluationData = await evaluationGetContentWithControlIdList(
    evalType,
    evalName,
    controlIdList
  )

  prevEvaluationData.forEach((data) => {
    const controlId = data.controlId
    uploadTargetObject[controlId] = data
    uploadTargetObject[controlId].excelDataValidation = false
  })

  // 재구성
  fileList.forEach((fileObj) => {
    const controlId = fileObj.controlId
    const fileName = fileObj.fileName
    const fileExtension = fileObj.fileExtension
    const fileType = fileObj.fileType
    const resultState = fileObj.resultState
    if (fileType === '보고서') {
      const targetFileName = fileName + '.' + fileExtension
      // 파일 무조건 한개
      uploadTargetObject[controlId].files.recordFile = {
        updateTime: currentTime,
        updater: currentUserId,
        fileName: targetFileName
      }
      uploadTargetObject[controlId].excelData = fileObj.excelData
      uploadTargetObject[controlId].excelData.updateTime = currentTime
      if (fileObj.state === 1 || fileObj.state === 102) {
        uploadTargetObject[controlId].excelDataValidation = true
        uploadTargetObject[controlId].resultState = resultState
      }
    } else if (fileType === '모집단') {
      const targetFileName = fileName + '.' + fileExtension
      // 파일 무조건 한개
      uploadTargetObject[controlId].files.populationFile = {
        updateTime: currentTime,
        updater: currentUserId,
        fileName: targetFileName
      }
    } else if (fileType === '증빙') {
      const targetFileName = fileName + '.' + fileExtension
      // 파일 여러개 가능
      const tmpObj = {
        updateTime: currentTime,
        updater: currentUserId,
        fileName: targetFileName
      }
      uploadTargetObject[controlId].files.evidenceFile[targetFileName] = tmpObj
    } else if (fileType === '기타') {
      const targetFileName = fileName + '.' + fileExtension
      // 파일 여러개 가능
      const tmpObj = {
        updateTime: currentTime,
        updater: currentUserId,
        fileName: targetFileName
      }
      uploadTargetObject[controlId].files.extraFile[targetFileName] = tmpObj
    }
  })

  await storageUploadFileListForEvaluation('OE', fileList)

  const result = controlIdList.map(async (controlId) => {
    const targetObj = uploadTargetObject[controlId]
    const newFiles = _.filter(fileList, (file) => file.controlId === controlId)
    await Promise.all(
      _.map(newFiles, async (newFile) => {
        if (!(await hasFile(evalType, evalName, controlId, newFile.file.name))) {
          return
        }

        if (newFile.type === '보고서') {
          targetObj.files.recordFile.checked = true
        } else if (newFile.type === '모집단') {
          targetObj.files.populationFile.checked = true
        } else if (newFile.type === '증빙' && newFile.file.name in targetObj.files.evidenceFile) {
          targetObj.files.evidenceFile[newFile.file.name].checked = true
        } else if (newFile.type === '기타' && newFile.file.name in targetObj.files.extraFile) {
          targetObj.files.extraFile[newFile.file.name].checked = true
        }
      })
    )

    const docRef = dbService.collection('운영평가').doc(evalName).collection('내역').doc(controlId)
    if (targetObj.excelDataValidation) {
      return await docRef
        .update({
          files: targetObj.files,
          evalData: targetObj.excelData
        })
        .then(() =>
          evaluationUpdateResultState(evalType, evalName, controlId, targetObj.resultState)
        )
    } else {
      return await docRef.update({
        files: targetObj.files
      })
    }
  })
  return Promise.all(result)
}

const evaluationUpdateFromExcelDE = async (evalType, evalName, fileList) => {
  // 엑셀파일로부터 설계평가 업데이트

  const currentUserId = authService.currentUser.email
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  const uploadTargetObject = {}

  // 해당 controlIdList
  let controlIdList = fileList.map((fileObject) => fileObject.controlId)
  controlIdList = [...new Set(controlIdList)]

  // "내역" - controlData, riskData, staffData 제외
  const prevEvaluationData = await evaluationGetContentWithControlIdList(
    evalType,
    evalName,
    controlIdList
  )

  prevEvaluationData.forEach((data) => {
    const controlId = data.controlId
    uploadTargetObject[controlId] = data
    uploadTargetObject[controlId].excelDataValidation = false
  })

  // 재구성
  fileList.forEach((fileObj) => {
    const controlId = fileObj.controlId
    const fileName = fileObj.fileName
    const fileExtension = fileObj.fileExtension
    const fileType = fileObj.fileType
    const resultState = fileObj.resultState
    const targetFileName = fileName + '.' + fileExtension
    if (fileType === '보고서') {
      // 파일 무조건 한개
      uploadTargetObject[controlId].files.recordFile = {
        updateTime: currentTime,
        updater: currentUserId,
        fileName: targetFileName
      }
      uploadTargetObject[controlId].excelData = fileObj.excelData
      uploadTargetObject[controlId].excelData.updateTime = currentTime
      if (fileObj.state === 1 || fileObj.state === 102) {
        uploadTargetObject[controlId].excelDataValidation = true
        uploadTargetObject[controlId].resultState = resultState
      }
    } else if (fileType === '기타') {
      const tmpObj = {
        updateTime: currentTime,
        updater: currentUserId,
        fileName: targetFileName
      }
      if (!_.has(uploadTargetObject[controlId].files, 'extraFile')) {
        uploadTargetObject[controlId].files.extraFile = {}
      }
      uploadTargetObject[controlId].files.extraFile[targetFileName] = tmpObj
    }
  })

  await storageUploadFileListForEvaluation('DE', fileList)

  const result = controlIdList.map(async (controlId) => {
    const targetObj = uploadTargetObject[controlId]

    const newFiles = _.filter(fileList, (file) => file.controlId === controlId)
    await Promise.all(
      _.map(newFiles, async (newFile) => {
        if (!(await hasFile(evalType, evalName, controlId, newFile.name))) {
          return
        }

        if (newFile.type === '보고서') {
          targetObj.files.recordFile.checked = true
        } else if (newFile.type === '기타' && newFile.name in targetObj.files.extraFile) {
          targetObj.files.extraFile[newFile.name].checked = true
        }
      })
    )
    const docRef = dbService.collection('설계평가').doc(evalName).collection('내역').doc(controlId)
    if (targetObj.excelDataValidation) {
      return await docRef
        .update({
          files: targetObj.files,
          evalData: targetObj.excelData
        })
        .then(() =>
          evaluationUpdateResultState(evalType, evalName, controlId, targetObj.resultState)
        )
    } else {
      return await docRef.update({
        files: targetObj.files
      })
    }
  })

  return Promise.all(result)
}

export const evaluationUpdateFromExcel = async (evalType, evalName, fileList) => {
  /*
  보고서 내용을 Excel 파일로부터 DB에 업데이트
  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param fileList: evaluationParsingFiles 에서 받은 리턴값 그대로
  */

  // 현재 User 확인
  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(
      new Error('평가 보고서 업데이트(Excel) - 로그인한 유저를 찾을 수 없습니다.')
    )
  }

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  if (evalType === 'OE') {
    return await evaluationUpdateFromExcelOE(evalType, evalName, fileList)
  } else {
    return await evaluationUpdateFromExcelDE(evalType, evalName, fileList)
  }
}

const evaluationUpdateFromWebOE = async (evalType, evalName, newEvaluation) => {
  /*
  운영평가의 보고서 내용을 업데이트한다(Web)

  이전에 보고서 파일이 업로드 된 적이 없다면 createDate 는 비어있게 되는데
  그러므로 파일이 업로드 된 적이 없는지 체크를 한 다음에 그렇다면 createDate 도 채워준다
  체크는 createDate 가 "" 인지 체크하면 될듯

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: newEvaluation: Object{} - 평가의 전체 내용(보고서)
  {
    testResult: "특이사항 없음",
    validCount: "2",
    controlException: "예외사항이 발견되지 아니함.",
    createDate: "Mon Dec 14 2020 23:59:08 GMT+0900 (대한민국 표준시)",
    executor: "Beyul",
    invalidCount: "0",
    name: "기간별로 결산조정이 정확하고 완전하게 이루어지고 결산마감 체크리스트가 담당자에 의해 완전하게 작성되고 검토됨",
    number: "C.FR.02",
    populationCompleteness: "업데이트테스트 - 해당 통제활동은 Monthly로 수행됨에 따라 연간 테스트 모집단은 12건이 존재하는지를 확인하여 완전성을 확보 함",
    populationCount: "12",
    populationDescription: "월별 결산마감 체크리스트",
    populationTableColumnCount: "3",
    samplingAttributeCount: "3",
    samplingCount: "2",
    samplingMethod: "엑셀 Random 함수 사용",
    attributeContents: {
      columnCountIncludeNumbering: 3,
      headerRow: ["Attribute", "테스트 상세내용", "예외사항 정의"],
      rowCountExceptHeader: 3,
      rowObjectList: [
        {
          Attribute: "1",
          예외사항 정의: "결산마감 체크리스트와 계산내역이 불일치 하는 경우",
          테스트 상세내용: "해당 평가대상기간 중 2개 월을 선택하여 결산마감 체크리스트가 각 업무별 담당자에 의해 완전히 작성/서명되었는지 여부를 확인함",
        },
        {
          Attribute: "2",
          예외사항 정의: "결산마감 체크리스트가 승인을 득하지 않은 경우",
          테스트 상세내용: "체크리스트에 대해 재무회계팀 관리자의 승인을 득하였는지 여부",
        },
        {
          Attribute: "3",
          예외사항 정의: "발생한 오류가 수정되지 않은경우",
          테스트 상세내용: "관리자의 검토결과 발견된 오류 등이 적절하게 수정되어 결산마감에 반영되었는지 여부",
        }
      ],
    },
    samplingResult: {
      columnCountIncludeNumbering: 4,
      headerRow: ["No", "결산마감체크리스트", "체크리스트 승인내역", "오류수정내역서"],
      rowCountExceptHeader: 2,
      rowObjectList: [
        {
          No: "1",
          결산마감체크리스트: "3월결산마감체크리스트",
          오류수정내역서: "3월 오류수정내역서",
          체크리스트 승인내역: "3월결산마감체크리스트 승인내역",
        },
        {
          No: "2",
          결산마감체크리스트: "6월결산마감체크리스트",
          오류수정내역서: "6월 오류수정내역서",
          체크리스트 승인내역: "6월결산마감체크리스트 승인내역",
        }
      ],
    },
    testContents: {
      columnCountIncludeNumbering: 9,
      headerRow: ["No", "Attribute_1/결과", "Attribute_1/상세", "Attribute_2/결과", "Attribute_2/상세", "Attribute_3/결과", "Attribute_3/상세", "테스트 결과", "증빙자료 Ref"],
      rowCountExceptHeader: 2,
      rowObjectList: [
        {
          Attribute_1/결과: "유효함",
          Attribute_1/상세: "3월 결산마감 체크리스트가 각 업무별 담당자에 의해 완전히 작성/서명되었는지 여부를 확인함",
          Attribute_2/결과: "유효함",
          Attribute_2/상세: "3월 결산마감 체크리스트에 대해 재무회계팀 관리자의 승인을 득하였는지 여부를 확인함",
          Attribute_3/결과: "유효함",
          Attribute_3/상세: "관리자의 3월 체크리스트 검토결과 발견된 오류 등이 적절하게 수정되어 결산마감에 반영되었는지 여부를 확인함",
          No: "1",
          증빙자료 Ref: "1. 2019.03 결산마감 체크리스트",
          테스트 결과: "유효함",
        },
        {
          Attribute_1/결과: "유효함",
          Attribute_1/상세: "6월 결산마감 체크리스트가 각 업무별 담당자에 의해 완전히 작성/서명되었는지 여부를 확인함",
          Attribute_2/결과: "유효함",
          Attribute_2/상세: "6월 결산마감 체크리스트에 대해 재무회계팀 관리자의 승인을 득하였는지 여부를 확인함",
          Attribute_3/결과: "유효함",
          Attribute_3/상세: "관리자의 6월 체크리스트 검토결과 발견된 오류 등이 적절하게 수정되어 결산마감에 반영되었는지 여부를 확인함",
          No: "2",
          증빙자료 Ref: "1. 2019.06 결산마감 체크리스트",
          테스트 결과: "유효함",
        }
      ],
    },
  }
  */
  const evalObject = {
    number: newEvaluation.number,
    name: newEvaluation.name,
    executor: newEvaluation.executor,
    populationCount: newEvaluation.populationCount,
    validCount: newEvaluation.validCount,
    invalidCount: newEvaluation.invalidCount,
    testResult: newEvaluation.testResult,
    populationDescription: newEvaluation.populationDescription,
    populationCompleteness: newEvaluation.populationCompleteness,
    samplingMethod: newEvaluation.samplingMethod,
    controlException: newEvaluation.controlException,
    samplingCount: newEvaluation.samplingCount,
    populationTableColumnCount: newEvaluation.populationTableColumnCount,
    samplingAttributeCount: newEvaluation.samplingAttributeCount,
    samplingResult: newEvaluation.samplingResult,
    attributeContents: newEvaluation.attributeContents,
    testContents: newEvaluation.testContents
  }
  // NOTE: undefined 값을 empty string으로 변경
  _.forEach(_.pickBy(evalObject, _.isNil), (value, key) => {
    evalObject[key] = ''
  })

  const currentDate = moment().format('YYYY-MM-DD')
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  const controlId = evalObject.number
  let excelCreateDate = await evaluationGetCreateDate(evalType, evalName, controlId)
  if (excelCreateDate === '') {
    excelCreateDate = currentDate
  }

  evalObject.createDate = excelCreateDate
  evalObject.updateTime = currentTime

  let resultState = '1'
  // 평가의 최종결과는 비유효 항목수에 의해 결정된다
  const invalidCount = evalObject.invalidCount
  // 이 경우 invalidCount 는 무조건 string 형태의 숫자 값이다.(예외 없음)
  const numInvalidCount = Number(invalidCount)
  if (numInvalidCount > 0) {
    resultState = '3'
  } else {
    resultState = '2'
  }

  const docRef = dbService.collection('운영평가').doc(evalName).collection('내역').doc(controlId)

  return await docRef
    .update({
      evalData: evalObject
    })
    .then(() => evaluationUpdateResultState(evalType, evalName, controlId, resultState))
}

const evaluationUpdateFromWebDE = async (evalType, evalName, newEvaluation) => {
  /*
  설계평가의 보고서 내용을 업데이트한다(Web)

  이전에 보고서 파일이 업로드 된 적이 없다면 createDate 는 비어있게 되는데
  그러므로 파일이 업로드 된 적이 없는지 체크를 한 다음에 그렇다면 createDate 도 채워준다
  체크는 createDate 가 "" 인지 체크하면 될듯

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: newEvaluation: Object{} - 평가의 전체 내용(보고서)
  {
    considerationOneContent: "미지급연차계산을 위한 기초데이터는 정확하고 완전하게 관리되어 손실을 최소화하도록 설계되어있어 관련 계정과목과 경영진주장의 중요한 왜곡표시위험에 충분히 대처하고있다.",
    considerationOneResult: "Effective",
    considerationThreeContent: "동 통제는 분기별로 수행이 되므로 예외사항을 해결하기 위해 충분히 정교하다 (Threshold를 적용하지 않음). 동 통제는 일관되고 경상적으로 실행되며 해당업무가 발생이 되는 분기별로 수행되는 것이 타당하다고 판단된다.",
    considerationThreeResult: "Effective",
    considerationTwoContent: "오광배팀장은 이베스트증권사에서 14년 6개월간 근무하였고, 동 통제 수행을 5년 3개월간 담당하여 관련 분야의 전문성은 충분하다고 판단됨.",
    considerationTwoResult: "Effective",
    createDate: "2021-06-24",
    executor: "executor 내용",
    judgementLevel: "높은 수준",
    result: "Effective",
    riskAssessmentBasis: "riskAssessmentBasis 내용",
  }
  */

  const evalObject = {
    number: newEvaluation.number,
    considerationOneContent: newEvaluation.considerationOneContent,
    considerationOneResult: newEvaluation.considerationOneResult,
    considerationTwoContent: newEvaluation.considerationTwoContent,
    considerationTwoResult: newEvaluation.considerationTwoResult,
    considerationThreeContent: newEvaluation.considerationThreeContent,
    considerationThreeResult: newEvaluation.considerationThreeResult,
    considerationFourContent: newEvaluation.considerationFourContent,
    considerationFourResult: newEvaluation.considerationFourResult,
    considerationFiveContent: newEvaluation.considerationFiveContent,
    considerationFiveResult: newEvaluation.considerationFiveResult,
    baselineReference: newEvaluation.baselineReference,
    result: newEvaluation.result
  }
  // NOTE: undefined 값을 empty string으로 변경
  _.forEach(_.pickBy(evalObject, _.isNil), (value, key) => {
    evalObject[key] = ''
  })
  const currentDate = moment().format('YYYY-MM-DD')
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
  const controlId = evalObject.number
  let excelCreateDate = await evaluationGetCreateDate(evalType, evalName, controlId)
  if (excelCreateDate === '') {
    excelCreateDate = currentDate
  }

  evalObject.createDate = excelCreateDate
  evalObject.updateTime = currentTime

  let resultState = '1'
  const result = evalObject.result
  if (result === 'Effective') {
    resultState = '2'
  } else if (result === 'Ineffective') {
    resultState = '3'
  }

  const docRef = dbService.collection('설계평가').doc(evalName).collection('내역').doc(controlId)
  return await docRef
    .update({
      evalData: evalObject
    })
    .then(() => evaluationUpdateResultState(evalType, evalName, controlId, resultState))
}

export const evaluationUpdateFromWeb = async (evalType, evalName, evalObject) => {
  /*

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: evalObject: Object{} - 평가의 전체 내용(보고서)
  */

  // 현재 User 확인
  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(
      new Error('운영평가 보고서 업데이트(Web) - 로그인한 유저를 찾을 수 없습니다.')
    )
  }

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  if (evalType === 'OE') {
    return await evaluationUpdateFromWebOE(evalType, evalName, evalObject)
  } else if (evalType === 'DE') {
    return await evaluationUpdateFromWebDE(evalType, evalName, evalObject)
  }
}

const evaluationGetFileInfo = async (evalType, evalName, controlId, fileType) => {
  // 해당 평가의 내역에 존재하는 files 의 알맞은 부분을 가져온다.
  let targetCollectionName
  if (evalType === 'OE') {
    targetCollectionName = '운영평가'
  } else if (evalType === 'DE') {
    targetCollectionName = '설계평가'
  }
  const docRef = dbService
    .collection(targetCollectionName)
    .doc(evalName)
    .collection('내역')
    .doc(controlId)
  const docData = await docRef.get()
  const evalFileData = docData.data().files

  let fileResult
  if (fileType === '보고서') {
    fileResult = evalFileData.recordFile
  } else if (fileType === '모집단') {
    fileResult = evalFileData.populationFile
  } else if (fileType === '증빙') {
    fileResult = evalFileData.evidenceFile
  } else if (fileType === '기타') {
    fileResult = evalFileData.extraFile
  }

  return fileResult
}

const evaluationDeleteFileInfo = async (evalType, evalName, controlId, fileType, fileName) => {
  // DB의 files 에서 삭제할 해당 파일에 대한 정보를 지운다
  // 원하는 것만 삭제가 안되는 관계로 fileType 의 모든걸 가져온 뒤에 해당 부분만 제외하고 업데이트
  let fileData = await evaluationGetFileInfo(evalType, evalName, controlId, fileType)

  let typeToKey = ''
  if (fileType === '보고서') {
    // 보고서는 항상 1개
    typeToKey = 'recordFile'
    fileData = {
      fileName: '',
      updateTime: '',
      updater: ''
    }
  } else if (fileType === '모집단') {
    // 모집단은 항상 1개
    typeToKey = 'populationFile'
    fileData = {
      fileName: '',
      updateTime: '',
      updater: ''
    }
  } else if (fileType === '증빙') {
    typeToKey = 'evidenceFile'
    const fileNameList = Object.keys(fileData)
    const idx = fileNameList.indexOf(fileName)
    if (idx > -1) {
      // fileData 에서 해당 객체 삭제
      delete fileData[fileName]
    }
  } else if (fileType === '기타') {
    typeToKey = 'extraFile'
    const fileNameList = Object.keys(fileData)
    const idx = fileNameList.indexOf(fileName)
    if (idx > -1) {
      // fileData 에서 해당 객체 삭제
      delete fileData[fileName]
    }
  }

  // DB 내용 업데이트
  let targetCollectionName
  if (evalType === 'OE') {
    targetCollectionName = '운영평가'
  } else if (evalType === 'DE') {
    targetCollectionName = '설계평가'
  }
  const docRef = dbService
    .collection(targetCollectionName)
    .doc(evalName)
    .collection('내역')
    .doc(controlId)
  return await docRef.update({
    [`files.${typeToKey}`]: fileData
  })
}

/**
 * 단일 파일 삭제, 여러개의 파일 동시에 삭제 할 때 사용하지 말 것.
 * @param {*} evalType
 * @param {*} evalName
 * @param {*} controlId
 * @param {*} fileType
 * @param {*} fileName
 * @returns
 */
export const evaluationDeleteFile = async (evalType, evalName, controlId, fileType, fileName) => {
  /*
  해당 타입의 평가의 단일 파일을 삭제

  DB 에서 파일의 정보를 삭제
  Storage 에서 파일을 삭제
  */

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  return await evaluationDeleteFileInfo(evalType, evalName, controlId, fileType, fileName).then(
    () => storageDeleteEvaluationFile(evalType, evalName, controlId, fileName)
  )
}

export const evaluationDeleteFiles = async (evalType, fileInfos) => {
  for (let i = 0; i < fileInfos.length; ++i) {
    const { evaluationName, controlId, type, fileName } = fileInfos[i]
    await evaluationDeleteFileInfo(evalType, evaluationName, controlId, type, fileName)
  }

  return _.map(fileInfos, ({ evaluationName, controlId, fileName }) => {
    return storageDeleteEvaluationFile(evalType, evaluationName, controlId, fileName)
  })
}

export const evaluationChangePerformer = async (
  evalType,
  evalName,
  controlIdList,
  finalStaffId
) => {
  /*
  평가-상태의 controlPerformer 를 변경

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlIdList: String[] - CONTROL id list
  @param: finalStaffId: String - 지정될 직원의 아이디
  */

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }
  const stateCollectionRef = dbService.collection(collectionName).doc(evalName).collection('상태')

  const result = controlIdList.map(async (controlId) => {
    const docRef = stateCollectionRef.doc(controlId)
    await docRef.update({
      controlPerformer: finalStaffId
    })
  })

  return Promise.all(result)
}

export const evaluationChangeOwner = async (evalType, evalName, controlIdList, finalStaffId) => {
  /*
  평가-상태의 controlOwner 를 변경

  자연스럽게 변경되어야 하는 정보는(RCM 을 수정하는 것이 아니다. 해당 평가가 생성될 당시 첨부한 내용들을 수정하는 것이다)
  평가-내역의 controlData - owner, department // 변경점(20211027) - 평가 안의 controlData 안의 owner, department 는 수정하지 않는다
  평가-내역의 staffData - 전체

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlIdList: String[] - CONTROL id list
  @param: finalStaffId: String - 지정될 직원의 아이디
  */

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }
  const contentCollectionRef = dbService.collection(collectionName).doc(evalName).collection('내역')
  const stateCollectionRef = dbService.collection(collectionName).doc(evalName).collection('상태')

  // finalStaffId 에 해당하는 staff정보, department 정보가 필요하다
  const staffData = await staffGetWithId(finalStaffId)
  // 불필요 삭제
  delete staffData.controlPathArray
  delete staffData.departmentPathArray

  const departmentId = staffData.departmentName

  return Promise.all(
    controlIdList.map(async (controlId) => {
      const contentDocRef = contentCollectionRef.doc(controlId)
      const stateDocRef = stateCollectionRef.doc(controlId)
      return contentDocRef
        .update({
          staffData
        })
        .then(() =>
          stateDocRef.update({
            controlOwner: finalStaffId,
            departmentName: departmentId
          })
        )
    })
  )
}

export const evaluationUpdateState = async (evalType, evalName, controlIdList, finalState) => {
  /*
  평가-상태 의 state 항목을 수정한다
  "3"으로 바뀔 때는 accomplishTime 업데이트
  "5"로 바뀔 때는 approvalTime, controlApprover 업데이트

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlIdList: String[] - CONTROL id list
  @param: finalState: String - "1", "2", "3", "4", "5"

  FIY. state(상태)
  "1": 미수행(생성된 default)
  "2": 수행중
  "3": 수행완료
  "4": 재작성필요
  "5": 승인완료
  */

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }
  const stateCollectionRef = dbService.collection(collectionName).doc(evalName).collection('상태')

  const stateDataAll = await evaluationGetStateAll(evalType, evalName)

  await Promise.all(
    controlIdList.map(async (controlId) => {
      const docRef = stateCollectionRef.doc(controlId)
      if (finalState === '3') {
        // "3" 수행완료 => accomplishTime 도 업데이트 해야함
        await docRef.update({
          accomplishTime: currentTime,
          state: finalState
        })
      } else if (finalState === '5') {
        // "5" 승인완료 => approvalTime, controlApprover 도 업데이트 해야함
        let currentUserId = ''
        try {
          currentUserId = authService.currentUser.email
          const staffData = await staffGetWithId(currentUserId)
          if (staffData.level !== 'ADMIN') {
            return Promise.reject(new Error('에러. 평가 승인완료 - 권한이 없습니다.'))
          }
        } catch {
          return Promise.reject(
            new Error('에러. 평가 승인완료 - 로그인한 유저를 찾을 수 없습니다.')
          )
        }

        await docRef.update({
          approvalTime: currentTime,
          state: finalState,
          controlApprover: currentUserId
        })
        // 미비점 평가와 관련이 없는 컨트롤들은 resultState를 변경
        const stateData = _.find(stateDataAll, { controlId })
        const resultState = stateData.data.resultState
        if (evalType === 'OE' && resultState === '3') {
          // 운영평가에서만 적용
          // resultState: "3" 이란 것은 평가 보고서의 결과 문제가 있어서 미비점의 평가 대상이란 말이다
          // 그럼 이 경우엔 승인완료 가 될 때 weakState 를 "2"(평가 대기 상태) 로 만들어주자
          await evaluationUpdateWeakState(evalType, evalName, controlId, '2')
        }
      } else if (finalState === '4') {
        // 재작성 필요일 경우, weakState를 다시 결정해야 하기때문에
        // "1"(평가 결론 없음)로 만들자
        if (evalType === 'OE') {
          await docRef.update({ state: finalState, weakState: '1' })
        } else {
          await docRef.update({ state: finalState })
        }
      } else {
        // "2": 수행중
        await docRef.update({ state: finalState })
      }
    })
  )
}

export const evaluationUpdateWeakState = async (evalType, evalName, controlId, finalState) => {
  /*
    평가-상태 의 weakState, weakStateUpdateTime 업데이트

    @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
    @param: evalName: String - 평가 이름
    @param: controlId: String - CONTROL id
    @param: finalState: String - weakState
      운영평가
        "1": default
        "2": 평가 대기 상태
        "3": 단순한 미비점
        "4": 유의한 미비점
        "5": 중요한 취약점
      설계평가
        "1": default
        "2": 평가 대기 상태
        "3": 작성완료(반영할 수정사항 없음)
        "4": 작성완료(반영할 수정사항 있음)
    */

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }

  const docRef = dbService
    .collection(collectionName)
    .doc(evalName)
    .collection('상태')
    .doc(controlId)

  return await docRef.update({
    weakState: finalState,
    weakStateUpdateTime: currentTime
  })
}

export const evaluationUpdateWeakStateData = async (evalType, evalName, controlId, weakObject) => {
  /*
  평가-상태 의 weakStateData 업데이트

  모두 웹에서 업데이트(엑셀 X)
  "상태" 의 weakStateData 를 업데이트
  운영평가 => flowChart
  설계평가 => (구) 설계평가 - 항목명들이 많이 바뀌었음.참고바람

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  @param: weakObject: Object{}
  {
    weakState: "",
    weakStateData: {
    },
  }

  FYI. 운영평가
  weakState
  "1": default(생성시 들어가는 상태)
  "2": 평가 대기 상태
  "3": 단순한 미비점
  "4": 유의한 미비점
  "5": 중요한 취약점

  weakStateData
  {
    "1": {
      // 첫번째 박스
      Answer: "Yes", // Yes/No
      Question: "미비점으로 인한 재무제표 왜곡표시 발생가능성이 낮지 않은 경우인가?",
      Reason: "이유1", // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    },
    "2": {
      // 두번째 박스
      Answer: "No", // Yes/No
      Question: "잠재적인 왜곡표시의 규모가 재무제표에 미치는 영향이 중요한가",
      Reason: "이유2", // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    },
    "3": {
      // 세번째 박스
      Answer: "Yes", // Yes/No
      Question: "잠재적인 왜곡표시의 규모를 중요한 수준 미만으로 줄일 수 있는 보완통제가 있는가?",
      Reason: "이유3", // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    },
    "4": {
      // 네번째 박스
      Answer: "Yes", // Yes/No
      Question: "회계와 내부회계관리제도에 충분한 전문지식을 갖춘 관리자는 이 미비점을 중요한 취약점이라고 결론 내릴것으로 판단되는가?",
      Reason: "이유4", // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    },
    "5": {
      // 다섯번째 박스
      Answer: "Yes", // Yes/No
      Question: "미비점의 수준이 재무보고 관리감독기구(감사위원회 등)가 주목할 만큼 중요한가?",
      Reason: "이유5", // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    },
  }

  FYI. 설계평가
  weakState
  "1": default(생성시 들어가는 상태)
  "2": 평가 대기 상태
  "3": 수정사항 없음 - 아무 내용도 수정할 게 없어서 안쓴거임
  "4": 수정사항 존재 - 아직 미반영
  weakStateData
  {
    control: {
      narrative: {content:"control - narrative - 설계평가 미비점 수정내용", evidence:"control - narrative - 설계평가 미비점 수정근거"},
      policy: {content:"", evidence:""},
      ITDependencySystem: {content:"", evidence:""},
      MRC_YN: {content:"", evidence:""},
      MRC_Number: {content:"", evidence:""},
      MRC_IPE_Name: {content:"", evidence:""},
      IPE_YN: {content:"", evidence:""},
      IPE_Number: {content:"", evidence:""},
      IPE_Name: {content:"", evidence:""},
      department: {content:"", evidence:""},
      owner: {content:"", evidence:""},
      incharge: {content:"", evidence:""},
      teamLeader: {content:"", evidence:""},
      teamMember: {content:"", evidence:""},
      keyControl: {content:"", evidence:""},
      goal_Operation: {content:"", evidence:""},
      goal_Trust: {content:"", evidence:""},
      goal_Asset: {content:"", evidence:""},
      goal_Override: {content:"", evidence:""},
      goal_Law: {content:"", evidence:""},
      accountCode: {content:"", evidence:""},
      accountName: {content:"", evidence:""},
      reportFootnotes: {content:"", evidence:""},
      assertion_EO: {content:"", evidence:""},
      assertion_C: {content:"Yes", evidence:"No 보다는 Yes 가 적절"},
      assertion_RO: {content:"", evidence:""},
      assertion_PD: {content:"", evidence:""},
      assertion_Occur: {content:"", evidence:""},
      assertion_Assessment: {content:"", evidence:""},
      assertion_Measurement: {content:"", evidence:""},
      period: {content:"", evidence:""},
      preventDetective: {content:"", evidence:""},
      autoManual: {content:"", evidence:""},
      residualRiskLevel: {content:"", evidence:""},
      controlRiskLevel: {content:"H", evidence:"L 보다는 H 가 적절"},
      TOC_Procedure: {content:"", evidence:""},
      TOC_Evidence: {content:"", evidence:""},
      TOC_Population: {content:"", evidence:""},
      TOC_PopulationCount: {content:"", evidence:""},
      TOC_TestProperties: {content:"", evidence:""},
      TOC_Exception: {content:"", evidence:""},
      TOC_TestMethod_InquiryInspection: {content:"", evidence:""},
      TOC_TestMethod_Observation: {content:"", evidence:""},
      TOC_TestMethod_Reperformance: {content:"", evidence:""},
    },
    risk: {
      id: "ELC-13-04-01-R01",
      name: {content:"정보시스템을 통해 산출되는 정보의 품질관리가 미비하여 내부회계관리제도를 적절하게 지원하지 못할 위험", evidence:"잘못된 리스크 명 수정"},
      RoMM: {content:"", evidence:""},
      inherentRiskLevel: {content:"", evidence:""},
    },
  }
  */

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  console.log('update weak object', weakObject)

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }
  const docRef = dbService
    .collection(collectionName)
    .doc(evalName)
    .collection('상태')
    .doc(controlId)

  await docRef.update({ weakStateData: weakObject.weakStateData })
  await evaluationUpdateWeakState(evalType, evalName, controlId, weakObject.weakState)
}

const evaluationRollbackResultState = async (evalType, evalName, controlIdList) => {
  //  evaluationResetWeakState 시에 호출.
  // DE. resultState:"4" 인 것은 여기에 못들어옴

  // OE 는 "내역" 의 testResult
  // DE 는 "내역" 의 result

  const result = controlIdList.map(async (controlId) => {
    let finalState
    const contentData = await evaluationGetContentWithControlId(evalType, evalName, controlId)
    if (!('evalData' in contentData)) {
      finalState = '1'
    } else {
      if (evalType === 'OE') {
        const evalResult = contentData.evalData.testResult
        if (evalResult === '') {
          finalState = '1'
        } else if (evalResult === '예외사항 발생') {
          finalState = '3'
        } else {
          finalState = '2'
        }
      } else if (evalType === 'DE') {
        const evalResult = contentData.evalData.result
        if (evalResult === '') {
          finalState = '1'
        } else if (evalResult === 'Effective') {
          finalState = '2'
        } else if (evalResult === 'Ineffective') {
          finalState = '3'
        }
      }
    }
    return await evaluationUpdateResultState(evalType, evalName, controlId, finalState)
  })

  return Promise.all(result)
}

export const evaluationResetWeakState = async (evalType, evalName, controlIdList) => {
  /*
  주의점. 설계평가의 경우 원래 "4" 였다면(RCM 반영이 끝남) reset 이 불가능하게 해야한다.
  여기서 해당 controlId 는 처리가 안되게 막을거지만 UI 에서도 이건 선택이 안되게 막아줘야할듯

  평가-상태 의 weakState 를 초기화
  함께 weakStateData 도 초기화
  그리고 resultState 는 rollback
  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlIdList: String[] - CONTROL id list

  FIY. weakState(상태)
  운영평가
  weakState
  "1": default(생성시 들어가는 상태)
  "2":단순한 미비점
  "3":유의한 미비점
  "4":중요한 취약점

  설계평가
  weakState
  "1": default(생성시 들어가는 상태)
  "2": 수정사항 없음 - 아무 내용도 수정할 게 없어서 안쓴거임
  "3": 수정사항 존재 - 아직 미반영
  "4": "2"와 "3" 에 대해서 수정사항 반영(RCM) 완료
  */

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  let remainControlIdList = []

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
    remainControlIdList = controlIdList
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
    const excludeControlIdList = []
    const stateDataAll = await evaluationGetStateAll(evalType, evalName)
    const filteredStateData = _.filter(stateDataAll, (stateData) => {
      const controlId = stateData.controlId
      const resultState = stateData.data.resultState

      return resultState === '4' && controlIdList.includes(controlId)
    })
    filteredStateData.forEach((obj) => excludeControlIdList.push(obj.controlId))
    controlIdList.forEach((controlId) => {
      if (!excludeControlIdList.includes(controlId)) {
        remainControlIdList.push(controlId)
      }
    })
  }

  const initWeakStateDataForOE = {
    1: {
      // 첫번째 박스
      Answer: '', // Yes/No
      Question: '미비점으로 인한 재무제표 왜곡표시 발생가능성이 낮지 않은 경우인가?',
      Reason: '' // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    },
    2: {
      // 두번째 박스
      Answer: '', // Yes/No
      Question: '잠재적인 왜곡표시의 규모가 재무제표에 미치는 영향이 중요한가',
      Reason: '' // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    },
    3: {
      // 세번째 박스
      Answer: '', // Yes/No
      Question: '잠재적인 왜곡표시의 규모를 중요한 수준 미만으로 줄일 수 있는 보완통제가 있는가?',
      Reason: '' // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    },
    4: {
      // 네번째 박스
      Answer: '', // Yes/No
      Question:
        '회계와 내부회계관리제도에 충분한 전문지식을 갖춘 관리자는 이 미비점을 중요한 취약점이라고 결론 내릴것으로 판단되는가?',
      Reason: '' // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    },
    5: {
      // 다섯번째 박스
      Answer: '', // Yes/No
      Question: '미비점의 수준이 재무보고 관리감독기구(감사위원회 등)가 주목할 만큼 중요한가?',
      Reason: '' // 해당 박스에서 Answer 를 고르며 넣어줄 이유 혹은 근거
    }
  }

  const stateCollectionRef = dbService.collection(collectionName).doc(evalName).collection('상태')

  return Promise.all(
    remainControlIdList.map(async (controlId) => {
      const docRef = stateCollectionRef.doc(controlId)
      return docRef
        .update({
          weakState: '1', // default
          weakStateUpdateTime: '',
          weakStateData: evalType === 'DE' ? {} : initWeakStateDataForOE
        })
        .then(() => evaluationRollbackResultState(evalType, evalName, remainControlIdList))
    })
  )
}

const evaluationClassifyWeakData = async (controlId, weakStateData) => {
  // 해당 control id 의 weakStateData 를 받아서 필요한 업데이트 종류에 맞게 나눈다
  // 3가지의 분류가 생긴다
  // control 자체 value => controlUpdate
  // control-staff 맵핑 => controlMappingWithStaff
  // 연결된 risk의 내용 => riskUpdate

  const result = {
    control: {},
    risk: {},
    staffId: ''
  }

  const controlData = weakStateData.control
  const controlKeyList = Object.keys(controlData)
  for (let i = 0; i < controlKeyList.length; i++) {
    const controlKey = controlKeyList[i]
    if (controlKey === 'id' || controlKey === 'department') {
      continue
    }

    const content = controlData[controlKey].content
    if (content === '') {
      continue
    }

    if (controlKey === 'owner') {
      result.staffId = content
    } else {
      result.control[controlKey] = content
    }
  }

  const riskData = weakStateData.risk
  const riskKeyList = Object.keys(riskData)
  for (let i = 0; i < riskKeyList.length; i++) {
    const riskKey = riskKeyList[i]
    if (riskKey === 'id') {
      continue
    }

    const content = riskData[riskKey].content
    if (content === '') {
      continue
    }
    result.risk[riskKey] = content
  }

  return result
}

const evaluationUpdateFromClassifiedObject = async (classifiedObject) => {
  // 분류된 정보를 바탕으로 필요한 업데이트를 실행한다
  const {
    controlId,
    weakControlData,
    controlData: originalControlData,
    riskId,
    weakRiskData,
    riskData: originalRiskData,
    staffId
  } = classifiedObject

  if (Object.keys(weakControlData).length > 0) {
    Object.assign(originalControlData, weakControlData)
  }
  if (Object.keys(weakRiskData).length > 0) {
    Object.assign(originalRiskData, weakRiskData)
  }

  return await controlUpdate(originalControlData)
    .then(() => riskUpdate(originalRiskData))
    .then(() => {
      if (staffId !== '') {
        controlMappingWithStaff(controlId, staffId)
      } else {
        evaluationUpdateTemplate('OE', controlId, {})
        evaluationUpdateTemplate('DE', controlId, {})
      }
    })
}

export const evaluationApplyWeakDataToRCMFromDE = async (evalType, evalName) => {
  /*
  설계평가의 미비점 작성이 모두 끝나면
  해당 컨트롤별 미비점들을 모두 모아서 RCM 에 반영해야 한다
  사전에 정의된 것은 해당 설계평가의 모든 컨트롤에 대한 미비점이 작성 완료된 상태
  (대전제는 이 모든것들이 보고서관련 해서 다 끝난 승인완료 상태(state="5") 인 컨트롤만 해당한다는 것을 명심하자)

  즉, 컨트롤별로
  resultState 가 "2"(Effective, 문제없음) 은 통과(애초에 설계평가 미비점을 수행할 일이 없는 상태)
  resultState 가 "3"(Ineffective, 문제있음) 은 weakState 가 "3"(작성완료. 수정사항 없음) 혹은 "4"(작성완료. 수정사항 존재) 이어야함 (반대로 말하면 "1"(default) 혹은 "2"(평가 대기 상태) 인 것이 있으면 안됨)

  resultState 가 "3"(Ineffective, 문제있음) 그리고 weakState 가 "3"(작성완료. 수정사항 있음) 을 모두 가져와서 RCM 에 반영하자

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  */

  // evalType 이 DE 가 아니라면 X
  if (evalType !== 'DE') {
    return Promise.reject(new Error('설계평가만 가능합니다.'))
  }

  // 해당 설계평가의 state 를 모두 가져오기
  const evaluationDataAll = await evaluationGetStateAll(evalType, evalName)

  // 일단 대상의 전제는 state="5"(승인완료 == 보고서관련된 것은 마무리가 된 상태) 이다.
  // 그러므로 state="5" 인 애들만 가지고 해야한다
  const stateDataAll = _.filter(evaluationDataAll, (evaluationData) => {
    return evaluationData.data.state === '5'
  })

  // 제한사항 검사
  // resultState 가 "3"(Ineffective) 인데 weakState 가 "1" 혹은 "2" 인 것이 존재하는 경우
  const exceptionControlIdList = []
  const exceptionList = _.filter(stateDataAll, (stateData) => {
    const controlId = stateData.controlId
    const data = stateData.data
    if (
      (data.resultState === '3' && data.weakState === '1') ||
      (data.resultState === '3' && data.weakState === '2')
    ) {
      exceptionControlIdList.push(controlId)
      return true
    }
  })

  if (exceptionControlIdList.length > 0) {
    return Promise.reject(
      new Error(
        `해당 컨트롤들에 문제가 있습니다. 미비점 평가가 완료되지 않았습니다. (${exceptionControlIdList})`
      )
    )
  }

  // 이제 미비점을 RCM에 반영해야할 대상이 되는 것들을 찾자
  // weakState="4"
  const targetStateDataList = _.filter(stateDataAll, (stateData) => {
    return stateData.data.weakState === '4'
  })

  // 처리가 가능한 형태로 가공
  const promiseResult = targetStateDataList.map(async (targetStateData) => {
    const controlId = targetStateData.controlId
    const originalControlData = await controlGetWithId(controlId)

    const weakStateData = targetStateData.data.weakStateData

    const riskId = weakStateData.risk.id
    const originalRiskData = await riskGetWithId(riskId)

    const classifiedData = await evaluationClassifyWeakData(controlId, weakStateData)

    return {
      controlId,
      controlData: originalControlData,
      weakControlData: classifiedData.control,
      riskId,
      riskData: originalRiskData,
      weakRiskData: classifiedData.risk,
      staffId: classifiedData.staffId
    }
  })

  await Promise.all(promiseResult).then(async (classifiedResultList) => {
    const controlIdList = []
    const result = classifiedResultList.map(async (classifiedResult) => {
      const controlId = classifiedResult.controlId
      controlIdList.push(controlId)
      return await evaluationUpdateFromClassifiedObject(classifiedResult)
    })

    await Promise.all(result)
  })
}
export const evaluationGetReference = async (evalType, evalName) => {
  /*
  평가-개요 의 refEvaluationName 를 반환

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @return: String - 참조 평가 이름
  */

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }
  const docRef = dbService.collection(collectionName).doc(evalName).collection('개요').doc('개요')
  const docData = await docRef.get()
  const data = docData.data()

  return data.refEvaluationName
}

export const evaluationSetReference = async (evalType, evalName, refName) => {
  /*
  평가-개요 의 refEvaluationName 를 설정
  참조할 평가인 refName 의 상태가 close(개요-state 가 "2")인지 체크가 필요함

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  */

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }
  const docRef = dbService.collection(collectionName).doc(evalName).collection('개요').doc('개요')

  // 빈 스트링이 들어온건 해제 하겠다는 것이다.
  if (refName === '') {
    return docRef.update({
      refEvaluationName: refName
    })
  }

  const refEvalBaseData = await evaluationGetBase(evalType, refName)
  const refEvalBaseState = refEvalBaseData.state
  if (refEvalBaseState !== '2') {
    // 마감 즉, Close 가 안된 상태.
    // 그러면 Archive 한 내용도 없음.
    return Promise.reject(
      new Error(`참조하려는 평가(${refName}) 은 최종마감이 되지 않은 상태입니다.`)
    )
  }

  return docRef.update({
    refEvaluationName: refName
  })
}

export const evaluationDownloadFile = async (evalType, evalName, controlId, fileName) => {
  /*
  평가안의 파일 하나의 이름을 전달받아서 다운로드

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  @param: fileName: String - 파일 이름
  */

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }

  const fileFullPath = `${collectionName}/${evalName}/${controlId}/${fileName}`

  return await storageDownloadFromFilePath(fileFullPath, fileName)
}

const evaluationMakeFilePathListForDownload = async (
  evalType,
  evalName,
  dataType,
  controlIdList
) => {
  /**
   * controlIdList: 선택한 대상이 되는 control Id 전체 리스트
   * dataType:
   *  '1': 템플릿 다운로드
   *  '2': 업로드 자료 다운로드
   *  '3': 모두 다운로드
   */

  let collectionName
  let templateFileExtension
  if (evalType === 'OE') {
    collectionName = '운영평가'
    templateFileExtension = 'xlsm'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
    templateFileExtension = 'xlsx'
  }

  const result = []
  if (dataType === '1' || dataType === '3') {
    // 템플릿만
    controlIdList.forEach((controlId) => {
      // 보고서 템플릿
      const recordFileFullPath = `Template/${collectionName}/(${collectionName})(${controlId})(수행전Template).${templateFileExtension}`
      result.push({
        controlId,
        fileName: `(${evalName})(${controlId})(보고서)수행전Template.${templateFileExtension}`,
        fileFullPath: recordFileFullPath,
        url: '',
        blob: ''
      })
      // 모집단 템플릿
      const populationFileFullPath = 'Template/(운영평가)(통제번호)(모집단)Template.xlsx'
      result.push({
        controlId,
        fileName: `(${evalName})(${controlId})(모집단)수행전Template.xlsx`,
        fileFullPath: populationFileFullPath,
        url: '',
        blob: ''
      })
    })
  }
  if (dataType === '2' || dataType === '3') {
    const baseFilePath = `${collectionName}/${evalName}`
    // 업로드 자료
    const evalDataAll = await evaluationGetAll(evalType, evalName)
    const targetObjectList = _.filter(evalDataAll, (targetObject) => {
      return _.includes(controlIdList, targetObject.controlId)
    })

    targetObjectList.forEach((targetObject) => {
      const controlId = targetObject.controlId
      if (evalType === 'OE' && targetObject.data.state.state === '5') {
        // 2021.12.31
        // 새로 추가된 운영평가 보고서 요약파일 위한 내용(fileName, evaluation)을 추가해주기
        result.push({
          controlId,
          fileName: `(${evalName})(${controlId})(보고서 readonly) 보고서.xlsx`,
          fileFullPath: '',
          url: '',
          blob: '',
          evaluation: targetObject
        })
      }
      const filesData = targetObject.data.content.files
      Object.keys(filesData).forEach((fileType) => {
        if (fileType === 'recordFile' || fileType === 'populationFile') {
          const target = filesData[fileType]
          if (target.fileName !== '') {
            const fileFullPath = `${baseFilePath}/${controlId}/${target.fileName}`
            result.push({
              controlId,
              fileName: target.fileName,
              fileFullPath,
              url: '',
              blob: ''
            })
          }
        } else if (fileType === 'extraFile' || fileType === 'evidenceFile') {
          const target = filesData[fileType]
          if (Object.keys(target).length > 0) {
            Object.keys(target).forEach((fileName) => {
              const fileFullPath = `${baseFilePath}/${controlId}/${fileName}`
              result.push({
                controlId,
                fileName,
                fileFullPath,
                url: '',
                blob: ''
              })
            })
          }
        }
      })
    })
  }

  return result
}

export const evaluationDownloadTemplateFiles = async (
  evalType,
  evalName,
  treeData,
  dataType,
  controlIdList
) => {
  /*
  평가의 파일들을 구조에 맞게 zip 파일로 다운로드한다

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: treeData: Object{} - RCM Tree data
  @param: dataType: String - "1":보고서템플릿, "2":업로드되어있는자료, "3":모두
  @param: controlIdList: String[] - CONTROL id list

  FIY. dataType
  "1": 보고서 템플릿만
  "2": 업로드 자료만 모두
  "3": 보고서 및 업로드 모두
  */

  let collectionName
  let templateFileExtension
  if (evalType === 'OE') {
    collectionName = '운영평가'
    templateFileExtension = 'xlsm'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
    templateFileExtension = 'xlsx'
  }

  const directoryTreeForDownload = {}

  // 파싱하자 폴더구조로
  const iterateArray = (arr, prevPath) => {
    arr.forEach((element) => {
      if ('keyControl' in element) {
        // leafNode
        directoryTreeForDownload[element.id] = prevPath.slice(1) + '/' + element.id + '/'
        return
      }

      let path = ''
      if ('name' in element) {
        path = prevPath + '/' + element.name
      }
      iterateArray(element.children, path)
    })
  }
  iterateArray(treeData, '')

  const downloadFileObjectList = await evaluationMakeFilePathListForDownload(
    evalType,
    evalName,
    dataType,
    controlIdList
  )
  const staffAll = await LoadStaff()

  const tempResult = downloadFileObjectList.map(async (fileBlobObject) => {
    const controlId = fileBlobObject.controlId
    const fileName = fileBlobObject.fileName
    const fileFullPath = fileBlobObject.fileFullPath
    let blob = null
    if (_.has(fileBlobObject, 'evaluation') && fileName.includes('readonly')) {
      const evaluation = fileBlobObject.evaluation
      const processedEvaluation = postprocessEvaluation('operation', '', evaluation)
      blob = await exportToExcel(staffAll, processedEvaluation)
    } else {
      const url = await storageGetDownloadUrl(fileFullPath)
      blob = await storageDownloadBlobFromUrl(url)
    }

    return {
      controlId,
      zipPath: directoryTreeForDownload[controlId],
      fileName,
      blob
    }
  })

  await Promise.all(tempResult).then((fileBlobObjectList) => {
    const zip = new JSZip()

    let zipFileName
    if (dataType === '1') {
      zipFileName = `(${collectionName})(${evalName})(Template).zip`
    } else if (dataType === '2') {
      zipFileName = `(${collectionName})(${evalName})(업로드파일).zip`
    } else if (dataType === '3') {
      zipFileName = `(${collectionName})(${evalName})(Template,업로드파일).zip`
    }

    fileBlobObjectList.forEach((fileBlobObject) => {
      const fileName = fileBlobObject.fileName
      const filePath = fileBlobObject.zipPath
      zip.file(filePath + fileName, fileBlobObject.blob, { binary: true })
    })

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, zipFileName)
    })
  })
}

const evaluationArchive = async (evalType, evalName) => {
  /*
  해당 평가를 마감할 때(close, 개요 state:"1"=>"2")
  추후에 참조해서 참고자료를 다운로드 받을 수 있는 형태로 Storage 에 저장
  Archive/[평가종류("OE", "DE")]/[평가이름]/[컨트롤아이디].zip ...

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  */

  const tempResult = []
  let controlIdList = []

  let fileBasePath
  let archiveBasePath
  if (evalType === 'OE') {
    fileBasePath = `운영평가/${evalName}`
    archiveBasePath = 'Archive/운영평가'
  } else if (evalType === 'DE') {
    fileBasePath = `설계평가/${evalName}`
    archiveBasePath = 'Archive/설계평가'
  }

  // "내역" 전체
  const contentDataAll = await evaluationGetContentAll(evalType, evalName)
  const staffAll = await LoadStaff()
  const evaluationAll = await evaluationGetAll(evalType, evalName)

  contentDataAll.forEach((contentData) => {
    const controlId = contentData.controlId
    controlIdList.push(controlId)
    const fileData = contentData.data.files
    Object.keys(fileData).forEach((fileType) => {
      const fileTypeData = fileData[fileType]
      if (fileType === 'recordFile' || fileType === 'populationFile') {
        // recordFile(보고서), populationFile(모집단) 이 둘은 무조건 파일이 하나씩만 가능
        const fileName = fileTypeData.fileName
        const fileFullPath = `${fileBasePath}/${controlId}/${fileName}`
        const fileTypeInZip = fileType === 'recordFile' ? '보고서' : '모집단'
        if (fileName !== '') {
          const temp = {
            controlId,
            fileFullPath,
            fileName,
            fileTypeInZip
          }
          tempResult.push(temp)
        } else if (fileName === '' && fileTypeInZip === '보고서') {
          tempResult.push({
            controlId,
            fileFullPath: '',
            fileName: `(${evalName})(${controlId})(보고서 readonly) 보고서.xlsx`,
            fileTypeInZip
          })
        }
      } else {
        // extraFile(기타), evidenceFile(증빙) 이 둘은 여러개가 가능
        Object.keys(fileTypeData).forEach((fileName) => {
          const fileFullPath = `${fileBasePath}/${controlId}/${fileName}`
          const fileTypeInZip = fileType === 'extraFile' ? '기타' : '증빙'
          const temp = {
            controlId,
            fileFullPath,
            fileName,
            fileTypeInZip
          }
          tempResult.push(temp)
        })
      }
    })
  })

  controlIdList = [...new Set(controlIdList)]
  return Promise.all(
    tempResult.map(async (targetObject) => {
      const controlId = targetObject.controlId
      const fileFullPath = targetObject.fileFullPath
      const fileName = targetObject.fileName
      const fileTypeInZip = targetObject.fileTypeInZip
      let fileBlob = ''
      if (evalType === 'OE' && fileTypeInZip === '보고서') {
        // 운영평가-보고서 의 경우 파일의 존재유무와 관계없이 생성해서 Archive 한다.
        const targetEvaluation = _.find(evaluationAll, { controlId })
        const processedEvaluation = postprocessEvaluation(
          evalType === 'OE' ? 'operation' : 'design',
          '',
          targetEvaluation
        )
        fileBlob = await exportToExcel(staffAll, processedEvaluation)
      } else {
        const fileUrl = await storageGetDownloadUrl(fileFullPath)
        fileBlob = await storageDownloadBlobFromUrl(fileUrl)
      }

      return {
        controlId,
        fileBlob,
        fileName,
        fileTypeInZip
      }
    })
  ).then((result) => {
    return Promise.all(
      controlIdList.map(async (controlId) => {
        const objList = _.filter(result, { controlId })
        const archiveFilePath = `${archiveBasePath}/${evalName}/${controlId}.zip`
        return await storageUploadZipFromBlob(archiveFilePath, objList)
      })
    )
  })
}

const evaluationExportToJson = async (evalType, evalName) => {
  /*
  해당 평가의 내용 전체를 JSON 파일로 export 한다

  [평가이름]/
    [개요]/
      {}
    [내역]/
      [컨트롤아이디]/
        controlData
        evalData
        files
        riskData
        staffData
      ...
    [상태]/
      [컨트롤아이디]/
        {}

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  */
  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }

  // 최종 결과의 구조
  const result = {
    [collectionName]: {
      [evalName]: {
        개요: {
          개요: {}
        },
        내역: {},
        상태: {}
      }
    }
  }

  // Object[]
  const evaluationDataAll = await evaluationGetAll(evalType, evalName)

  evaluationDataAll.forEach((evaluationData) => {
    const controlId = evaluationData.controlId
    if (Object.keys(result[collectionName][evalName]['개요']['개요']).length === 0) {
      const baseData = evaluationData.data.base
      result[collectionName][evalName]['개요']['개요'] = baseData
    }
    const contentData = evaluationData.data.content
    const stateData = evaluationData.data.state

    result[collectionName][evalName]['내역'][controlId] = contentData
    result[collectionName][evalName]['상태'][controlId] = stateData
  })

  const resultJson = JSON.stringify(result)
  const resultBlob = new Blob([resultJson], { type: 'text/json' })
  const fileName = `${evalName}.json`
  const filePath = `Archive/${collectionName}/${evalName}/${fileName}`

  return await storageUploadFile(filePath, resultBlob)
}

export const evaluationClose = async (evalType, evalName, finalState) => {
  /*
  평가-개요 의 state 항목을 수정한다
  "1" => "2" 밖에 없을 것 같긴함

  체크해야할 사항
  공통
  state=="5" 인 개수(전체 개수) == [resultState=="2" + resultState=="3"]
  weakState=="2" == 0개

  "OE"(운영평가) 의 경우에는
  resultState=="3" == [weakState=="3" + weakState=="4" + weakState=="5"]

  "DE"(설계평가) 의 경우에는
  resultState=="3" == [weakState=="3" + weakState=="4"]

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: finalState: String - "1", "2"

  FIY. state(개요)
  "1": 미완료(생성된 default)
  "2": 완료 / 평가 및 미비점이 모두 완료된 상태 / 후에 보고서 및 부록 작성 시에 불러올 수 있는 상태
  */

  // 해당 평가의 수정/삭제 가능여부 판단
  const editable = await evaluationCheckEditable(evalType, evalName)
  if (!editable) {
    return Promise.reject(
      new Error(`평가(${evalName})는 이미 마감된 평가이기 떄문에 수정/삭제가 불가능합니다.`)
    )
  }

  // 마감이 가능한지 체크
  const stateDataAll = await evaluationGetStateAll(evalType, evalName)
  const count_result = {
    state_5: 0,
    resultState_2: 0,
    resultState_3: 0,
    weakState_2: 0,
    weakState_3: 0,
    weakState_4: 0,
    weakState_5: 0
  }

  stateDataAll.forEach((stateData) => {
    const state = stateData.data.state
    if (state === '5') {
      count_result.state_5 += 1
    }

    const resultState = stateData.data.resultState
    if (resultState === '2') {
      count_result.resultState_2 += 1
    } else if (resultState === '3') {
      count_result.resultState_3 += 1
    }

    const weakState = stateData.data.weakState
    if (weakState === '2') {
      count_result.weakState_2 += 1
    } else if (weakState === '3') {
      count_result.weakState_3 += 1
    } else if (weakState === '4') {
      count_result.weakState_4 += 1
    } else if (weakState === '5') {
      count_result.weakState_5 += 1
    }
  })

  if (count_result.state_5 !== count_result.resultState_2 + count_result.resultState_3) {
    return Promise.reject(
      new Error(`평가 마감 시 수량 문제: 승인완료 개수 != 특이사항 없음 개수 + 예외사항 발생 개수`)
    )
  }
  if (count_result.weakState_2 !== 0) {
    return Promise.reject(new Error(`평가 마감 시 수량 문제: 미비점 평가대기 개수 != 0`))
  }

  if (evalType === 'OE') {
    if (
      count_result.resultState_3 !==
      count_result.weakState_3 + count_result.weakState_4 + count_result.weakState_5
    ) {
      return Promise.reject(
        new Error(
          `평가 마감 시 수량 문제: 운영평가: 예외사항 발생 개수 != 단순한 미비점 개수  + 유의한 미비점 개수  + 중요한 취약점 개수`
        )
      )
    }
  }

  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  let collectionName
  if (evalType === 'OE') {
    collectionName = '운영평가'
  } else if (evalType === 'DE') {
    collectionName = '설계평가'
  }
  const docRef = dbService.collection(collectionName).doc(evalName).collection('개요').doc('개요')

  return await docRef
    .update({
      state: finalState,
      closeTime: finalState === '2' ? currentTime : ''
    })
    .then(() => {
      if (evalType === 'OE') {
        evaluationArchive(evalType, evalName)
        evaluationExportToJson(evalType, evalName)
      } else if (evalType === 'DE') {
        evaluationApplyWeakDataToRCMFromDE(evalType, evalName).then(() => {
          evaluationArchive(evalType, evalName)
          evaluationExportToJson(evalType, evalName)
        })
      }
    })
}

export const evaluationUpdateTemplate = async (evalType, controlId, updateObject) => {
  /*
  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  @param: updateObject: Object{} - control 의 모든 정보 - controlGetWithId 결과와 같은 항목들
  */

  let baseTemplateFileFullPath
  if (evalType === 'OE') {
    // 기본 운영평가 Template 의 Blob Data 를 가져와야 한다
    baseTemplateFileFullPath = 'Template/(운영평가)(통제번호)(보고서)Template.xlsm'
  } else if (evalType === 'DE') {
    baseTemplateFileFullPath = 'Template/(설계평가)(통제번호)(보고서)Template.xlsx'
  }

  const url = await storageGetDownloadUrl(baseTemplateFileFullPath)
  const blobData = await storageDownloadBlobFromUrl(url)

  return await excelEditEvaluationTemplate(evalType, controlId, updateObject, blobData)
}

export const evaluationUpdateTemplateList = async (controlIdList) => {
  /*
  해당 컨트롤 아이디들의 엑셀 보고서 템플릿을 현재 RCM 기준으로 맞춰준다

  @param: controlIdList: String[] - 대상이 되는 CONTROL id list
  */

  const result = controlIdList.map(async (controlId) => {
    return await evaluationUpdateTemplate('OE', controlId, {}).then(() =>
      evaluationUpdateTemplate('DE', controlId, {})
    )
  })

  return Promise.all(result)
}

export const evaluationDownloadPopulationTemplateExcel = async (evalType, evalName, controlId) => {
  /*
  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id

  모집단 템플릿 엑셀 파일을 다운로드 한다.
  파일명은 (${evalName})(${controlId})(모집단)수행전Template.xlsx => (2021_운영평가)(EX-01-04-01-R03-C04)(모집단)수행전Template.xlsx
  */

  if (evalType !== 'OE') {
    return Promise.reject(new Error('모집단 템플릿 다운로드 - 운영평가만 가능합니다'))
  }

  const fileName = `(${evalName})(${controlId})(모집단)수행전Template.xlsx`
  const fileFullPath = 'Template/(운영평가)(통제번호)(모집단)Template.xlsx'

  const downloadUrl = await storageGetDownloadUrl(fileFullPath)
  const blobData = await storageDownloadBlobFromUrl(downloadUrl)
  storageSaveFromBlob(blobData, fileName)
}

export const evaluationParsingPopulationFromStorage = async (
  evalType,
  evalName,
  controlId,
  fileName,
  readPopulationFile
) => {
  /*
  이미 업로드 된 모집단 파일을 가져와서 파싱한다. MAX_POPULATION_SIZE를 초과한 경우 파싱하지 않는다.

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  @param: fileName: String - 파일 이름(운영평가-상태-controlId-files-populationFile)
  @param: readPopulationFile: useWorker 함수
  */

  if (evalType !== 'OE') {
    return Promise.reject(new Error('모집단 파일 파싱은 운영평가만 가능합니다.'))
  }

  const filePath = `/운영평가/${evalName}/${controlId}/${fileName}`
  const url = await storageGetDownloadUrl(filePath)
  const blob = await storageDownloadBlobFromUrl(url)
  const blobToFile = new File([blob], fileName)

  const newFileObject = {
    file: blobToFile,
    fileName,
    controlId,
    evaluationName: evalName,
    fileType: '모집단',
    state: State.OK,
    checked: false
  }

  if (getFileSizeByMB(newFileObject.file) >= MAX_POPULATION_SIZE) {
    return newFileObject
  }

  return await readPopulationFile(newFileObject)
}

export const evaluationUpdatePopulationFile = async (
  evalType,
  evalName,
  controlId,
  fileName,
  excelData,
  createNewPopulationFile
) => {
  /*
  모집단/샘플링 에서 파싱한 최종결과 모집단 내용을 Excel 형태로 Storage 에 저장하고 DB에 업데이트한다.
  이미 존재하는 모집단 파일이 있다면 덮어씌운다
  만약에 파일의 이름이 다르다면 새로 올리고 삭제한다

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  @param: fileName: String - 저장할 모집단 파일의 이름
  @param: fileName: String - 파일 이름(운영평가-상태-controlId-files-populationFile)
  */

  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(new Error('모집단 파일 업데이트 - 로그인한 유저를 찾을 수 없습니다.'))
  }

  // 기존에 있는 모집단 파일명(삭제하기 위해서)
  const contentData = await evaluationGetContentWithControlId(evalType, evalName, controlId)
  let oldPopulationFileName = ''
  let oldPopulationFilePath = ''
  const populationFileBasePath = `/운영평가/${evalName}/${controlId}`
  oldPopulationFileName = contentData.files.populationFile.fileName
  if (_.isNil(oldPopulationFileName)) {
    oldPopulationFileName = ''
  }
  if (!_.isNil(oldPopulationFileName) && oldPopulationFileName !== '') {
    oldPopulationFilePath = `${populationFileBasePath}/${oldPopulationFileName}`
  }

  // Storage 에 있는 Template 파일 가져오기
  const populationTemplateFilePath = '/Template/(운영평가)(통제번호)(모집단)Template.xlsx'
  const populationTemplateFileUrl = await storageGetDownloadUrl(populationTemplateFilePath)
  const populationTemplateFileBlob = await storageDownloadBlobFromUrl(populationTemplateFileUrl)
  const populationTemplateFileName = fileName
  const populationTemplateFile = new File([populationTemplateFileBlob], populationTemplateFileName)

  const newPopulationFile = await createNewPopulationFile(populationTemplateFile, excelData)

  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  if (oldPopulationFileName !== '' && oldPopulationFileName !== populationTemplateFileName) {
    await storageDeleteFile(oldPopulationFilePath)
  }
  const newPopulationFilePath = `${populationFileBasePath}/${populationTemplateFileName}`
  await storageUploadFile(newPopulationFilePath, newPopulationFile)

  const checked = await hasFile(evalType, evalName, controlId, populationTemplateFileName)
  // DB Update
  return await dbService
    .collection('운영평가')
    .doc(evalName)
    .collection('내역')
    .doc(controlId)
    .update({
      'files.populationFile': {
        fileName: populationTemplateFileName,
        updateTime: currentTime,
        updater: currentUserId,
        checked
      }
    })
}

export const evaluationUploadPopulationFile = async (
  evalType,
  evalName,
  controlId,
  fileName,
  newPopulationFile
) => {
  /*
  3MB 크기 이상의 모집단 파일을 바로 업로드한다.

  @param: evalType: String - 평가 종류 / "OE"(운영평가), "DE"(설계평가)
  @param: evalName: String - 평가 이름
  @param: controlId: String - CONTROL id
  @param: fileName: String - 저장할 모집단 파일의 이름
  @param: fileName: String - 파일 이름(운영평가-상태-controlId-files-populationFile)
  */

  let currentUserId = ''
  try {
    currentUserId = authService.currentUser.email
  } catch {
    return Promise.reject(new Error('모집단 파일 업데이트 - 로그인한 유저를 찾을 수 없습니다.'))
  }
  if (!newPopulationFile) {
    return Promise.reject(new Error('undefined file'))
  }

  // 기존에 있는 모집단 파일명(삭제하기 위해서)
  const contentData = await evaluationGetContentWithControlId(evalType, evalName, controlId)
  let oldPopulationFileName = ''
  let oldPopulationFilePath = ''
  const populationFileBasePath = `/운영평가/${evalName}/${controlId}`
  oldPopulationFileName = contentData.files.populationFile.fileName
  if (_.isNil(oldPopulationFileName)) {
    oldPopulationFileName = ''
  }
  if (!_.isNil(oldPopulationFileName) && oldPopulationFileName !== '') {
    oldPopulationFilePath = `${populationFileBasePath}/${oldPopulationFileName}`
  }

  // Storage Upload & Delete
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

  if (oldPopulationFileName !== '' && oldPopulationFileName !== fileName) {
    await storageDeleteFile(oldPopulationFilePath)
  }
  const newPopulationFilePath = `${populationFileBasePath}/${fileName}`
  await storageUploadFile(newPopulationFilePath, newPopulationFile)

  const checked = await hasFile(evalType, evalName, controlId, fileName)

  // DB Update
  await dbService
    .collection('운영평가')
    .doc(evalName)
    .collection('내역')
    .doc(controlId)
    .update({
      'files.populationFile': {
        fileName,
        updateTime: currentTime,
        updater: currentUserId,
        checked
      }
    })
}
