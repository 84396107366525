import { ColDef, ColGroupDef, GridApi } from '@ag-grid-community/core'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import { Evaluation, EvaluationType } from '../../../base/data/Evaluation'
import { Staff, StaffFormatValue } from '../../../base/data/Staff'
import { getKoName } from '../../../base/data/Translation'
import {
  dateCellRenderer,
  FileNameCellRenderer,
  getFiles,
  getFileViewColumns
} from '../../../base/utils/FileUtils'
import { evaluationDeleteFile } from '../../../dataLoader/Evaluation/evaluation'
import AlertMessage, { AlertMessageHandler } from '../../common/AlertMessage'
import LoadingButton from '../../common/LoadingButton'
import TableViewer, { TableViewerHandler } from '../../common/TableViewer'
import ToolTipTextRenderer from '../../common/ToolTipTextRenderer'
import {
  getShortEvalType,
  isEqualEvaluations,
  weakStateToString,
  evaluationStateToString,
  resultStateToString
} from '../common/EvaluationUtils'

export type TableType = 'upload' | 'template' | 'management' | 'modification' | 'operation_defect'

function defectCellStyle(params: any): any {
  const evaluation = params.data
  if (evaluation.data?.state?.state !== '5' || evaluation.data?.state?.weakState === '1') {
    return { backgroundColor: grey.background }
  }
}

function ControlNameCellRenderer(params: any): HTMLElement {
  const span = document.createElement('span')
  span.innerHTML = params.value.name
  return span
}

function removeFileName(evaluation: Evaluation, fileName: string, key: string): void {
  /* eslint-disable */
  const newFileNames = _(_.get(evaluation, key))
    .split(', ')
    .without(fileName)
    .join(', ')
  /* eslint-enable */
  _.set(evaluation, key, newFileNames)
}

export function unsetFiles(evaluation: Evaluation, file: any): void {
  if (file.type === '보고서') {
    _.unset(evaluation, 'data.content.files.recordFile')
    _.unset(evaluation, 'extra.recordFileName')
  } else if (file.type === '모집단') {
    _.unset(evaluation, 'data.content.files.populationFile')
    _.unset(evaluation, 'extra.populationFileName')
  } else if (file.type === '증빙') {
    removeFileName(evaluation, file.fileName, 'extra.evidenceFileName')
    if (evaluation.data.content.files.evidenceFile) {
      delete evaluation.data.content.files.evidenceFile[file.fileName]
    }
    if (_.isEmpty(evaluation.data.content.files.evidenceFile)) {
      _.unset(evaluation, 'data.content.files.evidenceFile')
    }
  } else if (file.type === '기타') {
    removeFileName(evaluation, file.fileName, 'extra.extraFileName')
    if (evaluation.data.content.files.extraFile) {
      delete evaluation.data.content.files.extraFile[file.fileName]
    }
    if (_.isEmpty(evaluation.data.content.files.extraFile)) {
      _.unset(evaluation, 'data.content.files.extraFile')
    }
  }
}

const deleteCellRender =
  (type: EvaluationType) =>
  (params: any): React.ReactElement => {
    return (
      <LoadingButton
        type="link"
        onClick={() => {
          const file = params.data
          return evaluationDeleteFile(
            getShortEvalType(type),
            file.evaluationName,
            file.controlId,
            file.type,
            file.fileName
          )
            .then(() => {
              const gridApi: GridApi = params.masterApi
              const evaluationDetails: any[] = []
              gridApi.forEachNode((node) => {
                evaluationDetails.push(node.data)
              })

              const deletedData = _.find(evaluationDetails, { controlId: file.controlId })
              if (!_.isEmpty(deletedData)) {
                unsetFiles(deletedData, file)
                gridApi.applyTransaction({ update: [deletedData] })
                params.alertRef.current?.showAlert('success', '파일 삭제 완료')
              }
            })
            .catch((e: any) => {
              // eslint-disable-next-line no-console
              console.log('Failed to delete file', e)
              params.alertRef.current?.showAlert('error', '파일 삭제 실패')
            })
        }}
      >
        삭제
      </LoadingButton>
    )
  }

export function getEvaluationColumns(
  type: TableType,
  evaluationType: EvaluationType,
  staffs: Staff[]
): ColDef[] {
  const columns: ColDef[] = [
    { headerName: getKoName('control', 'id'), field: 'controlId', width: 240, sortable: true },
    // {
    //   headerName: '통제활동명',
    //   field: 'control',
    //   suppressSizeToFit: false,
    //   flex: 2,
    //   cellRenderer: ControlNameCellRenderer
    // },
    {
      headerName: getKoName('common', 'cycleName'),
      field: 'data.content.controlData.cycleName',
      width: 200,
      sortable: true
    },
    {
      headerName: getKoName('control', 'department'),
      field: 'data.state.departmentName',
      width: 200,
      sortable: true
    },
    {
      headerName: getKoName('control', 'evaluationControlOwner'),
      field: 'extra.data.state.controlOwner',
      suppressSizeToFit: false,
      flex: 1,
      cellRenderer: 'ToolTipTextRenderer',
      sortable: true
    },
    {
      headerName: getKoName('control', 'performer'),
      field: 'extra.data.state.controlPerformer',
      suppressSizeToFit: false,
      flex: 1,
      cellRenderer: 'ToolTipTextRenderer',
      sortable: true
    },
    {
      headerName: '상태',
      field: 'extra.data.state.state',
      width: 120,
      sortable: true
    }
  ]

  if (type === 'management' && evaluationType === 'operation') {
    columns.push({
      headerName: '모집단',
      field: 'extra.populationFileName',
      menuTabs: [],
      width: 80,
      cellRenderer: 'FileNameCellRenderer'
    })
    columns.push({
      headerName: '모집단 샘플링',
      field: 'extra.populationSampling',
      menuTabs: [],
      width: 140
    })
    columns.push({
      headerName: '증빙/샘플링 수',
      field: 'extra.evidenceSamplingCount',
      menuTabs: [],
      width: 140
    })
  }

  if (type === 'template' && evaluationType === 'operation') {
    columns.push({
      headerName: '템플릿',
      field: 'extra.template',
      width: 80,
      menuTabs: [],
      cellRenderer: 'FileNameCellRenderer'
    })
  }
  if (_.includes(['template', 'upload'], type)) {
    if (evaluationType === 'operation') {
      columns.push(
        ...[
          {
            headerName: '보고서',
            field: 'extra.recordFileName',
            menuTabs: [],
            width: 80,
            cellRenderer: 'FileNameCellRenderer'
          },
          {
            headerName: '모집단',
            field: 'extra.populationFileName',
            menuTabs: [],
            width: 80,
            cellRenderer: 'FileNameCellRenderer'
          },
          {
            headerName: '증빙',
            field: 'extra.evidenceFileName',
            menuTabs: [],
            width: 80,
            cellRenderer: 'FileNameCellRenderer'
          }
        ]
      )
    }
    columns.push({
      headerName: '기타',
      field: 'extra.extraFileName',
      menuTabs: [],
      width: 80,
      cellRenderer: 'FileNameCellRenderer',
      cellRendererParams: {
        fileType: 'extraFile'
      }
    })
  }
  if (type === 'upload') {
    const controlColumn = columns[0]
    controlColumn.width = 260
    controlColumn.cellRenderer = 'agGroupCellRenderer'
  }
  if (type === 'operation_defect') {
    columns.push({
      headerName: '미비사항',
      field: 'extra.data.state.resultState',
      width: 120,
      sortable: true
    })
    columns.push({
      headerName: '반영 상태',
      field: 'extra.data.state.weakState',
      width: 220,
      sortable: true
    })
  }
  return columns
}

function updateValue(target: Evaluation, key: string, formatter: any) {
  if (_.get(target, key)) {
    _.set(target, `extra.${key}`, formatter(_.get(target, key)))
  }
}

function formatEvaluations(staffs: Staff[], evaluations: Evaluation[]): Evaluation[] {
  return _.map(evaluations, (evaluation) => {
    const newEvaluation = _.cloneDeep(evaluation)
    updateValue(newEvaluation, 'data.state.controlOwner', StaffFormatValue(staffs))
    updateValue(newEvaluation, 'data.state.controlPerformer', StaffFormatValue(staffs))
    updateValue(newEvaluation, 'data.state.state', evaluationStateToString)

    const samplingResult = _.get(newEvaluation, 'data.content.evalData.samplingResult')
    if (samplingResult) {
      const populationSampling =
        !_.isEmpty(samplingResult.headerRow) &&
        _.every(samplingResult.headerRow, (header) => !_.isEmpty(header))
          ? 'O'
          : 'X'
      _.set(newEvaluation, 'extra.populationSampling', populationSampling)
    }

    const sampling = _.get(newEvaluation, 'data.content.evalData.samplingCount', 0)
    const evidenceSize = _.size(_.get(newEvaluation, 'data.content.files.evidenceFile')) || 0
    _.set(newEvaluation, 'extra.evidenceSamplingCount', `${evidenceSize}/${sampling}`)

    updateValue(newEvaluation, 'data.state.resultState', resultStateToString)
    updateValue(newEvaluation, 'data.state.weakState', (v: string) =>
      weakStateToString(evaluation.type, v)
    )

    const files = newEvaluation.data.content.files
    _.set(newEvaluation, 'extra.recordFileName', files.recordFile?.fileName || '')
    _.set(newEvaluation, 'extra.populationFileName', files.populationFile?.fileName || '')
    _.set(newEvaluation, 'extra.evidenceFileName', _.join(_.keys(files.evidenceFile), ', ') || '')
    _.set(newEvaluation, 'extra.extraFileName', _.join(_.keys(files.extraFile), ', ') || '')
    _.set(newEvaluation, 'extra.template', 'template')
    return newEvaluation
  })
}

interface Props {
  noBorder?: boolean
  loading: boolean
  evaluationType: EvaluationType
  type: TableType
  evaluationDetails: Evaluation[]
  staffs: Staff[]
  viewerRef?: React.RefObject<TableViewerHandler>
  height?: number
  onDetailClick?: (detail: Evaluation) => void
  afterDeleteFile?: (newData: Evaluation[]) => void
}

const EvaluationTable: React.FC<Props> = ({
  noBorder,
  loading,
  evaluationType,
  type,
  evaluationDetails,
  staffs,
  viewerRef,
  height,
  onDetailClick,
  afterDeleteFile
}) => {
  const [evaluations, setEvaluations] = React.useState<Evaluation[]>()
  const [formattedEvaluations, setFormattedEvaluations] = React.useState<Evaluation[]>()
  const [columns, setColumns] = React.useState<(ColDef | ColGroupDef)[]>([])
  const alertRef = React.useRef<AlertMessageHandler>(null)

  React.useEffect(() => {
    setColumns(getEvaluationColumns(type, evaluationType, staffs))
  }, [type, evaluationType, staffs])

  React.useEffect(() => {
    if (isEqualEvaluations(evaluations, evaluationDetails)) {
      return
    }
    setEvaluations(evaluationDetails)
    setFormattedEvaluations(formatEvaluations(staffs, evaluationDetails))
  }, [evaluationDetails])

  const getDeleteFileViewColumns = (): (ColDef | ColGroupDef)[] => {
    const fileViewColumns = getFileViewColumns(staffs)
    fileViewColumns.push({
      headerName: '삭제',
      cellRenderer: 'deleteCellRender',
      cellRendererParams: { alertRef }
    })
    return fileViewColumns
  }

  return (
    <>
      <TableViewer
        applyColumnDefOrder
        detailRowAutoHeight
        enableCellChangeFlash
        columnDefs={columns}
        defaultColDef={{
          wrapText: true,
          autoHeight: true,
          resizable: true,
          lockPosition: true,
          filter: 'agSetColumnFilter',
          suppressSizeToFit: true,
          filterParams: {
            excelMode: 'windows' // can be 'windows' or 'mac'
          },
          cellStyle: type === 'operation_defect' ? defectCellStyle : undefined
        }}
        detailCellRendererParams={{
          refreshStrategy: 'rows',
          detailGridOptions: {
            defaultColDef: {
              resizable: true,
              lockPosition: true
            },
            enableCellChangeFlash: true,
            columnDefs: getDeleteFileViewColumns(),
            frameworkComponents: {
              dateCellRenderer,
              deleteCellRender: deleteCellRender(evaluationType)
            }
          },
          getDetailRowData: (params: any) => {
            return params.successCallback(getFiles(evaluationType, params.data))
          }
        }}
        frameworkComponents={{ FileNameCellRenderer, ToolTipTextRenderer }}
        height={height}
        isRowMaster={(dataItem) => !_.isEmpty(getFiles(evaluationType, dataItem))}
        loading={loading}
        masterDetail={type === 'upload'}
        noBorder={noBorder}
        ref={viewerRef}
        rowData={formattedEvaluations}
        onRowClick={onDetailClick ? (row) => onDetailClick(row) : undefined}
        onRowDataUpdated={() => {
          if (!afterDeleteFile) {
            return
          }
          const newData: Evaluation[] = []
          _.forEach(_.zip(evaluations, evaluationDetails), ([row, evaluation]) => {
            if (row && evaluation) {
              const newEvaluation = _.cloneDeep(evaluation)
              newEvaluation.data.content.files = _.cloneDeep(row.data.content.files)
              newData.push(newEvaluation)
            }
          })
          afterDeleteFile(newData)
        }}
      />
      <AlertMessage ref={alertRef} />
    </>
  )
}

export default EvaluationTable
