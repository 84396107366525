/* eslint-disable camelcase */
import { ServerFile } from './BaseFile'
import { Control, Risk } from './PRC'
import { Staff } from './Staff'

export type EvaluationType = 'design' | 'operation'
export function getKoEvaluation(type: EvaluationType): string {
  return type === 'design' ? '설계평가' : '운영평가'
}

export type StateType = 'unperformed' | 'performing' | 'performed' | 'rejected' | 'approved'

export interface TableContent {
  headerRow: string[]
  columnCountIncludeNumbering: number
  rowCountExceptHeader: number
  rowObjectList: Record<string, string>[]
}

export interface EvaluationFiles {
  recordFile: ServerFile
  populationFile?: ServerFile // 모집단
  evidenceFile?: Record<string, ServerFile> // 증빙
  extraFile: Record<string, ServerFile> // 기타
}

export interface EvaluationSummary {
  evalType: 'OE' | 'DE'
  evalName: string
  controlIdList: string[]
  startDate: string
  endDate: string
  closeTime: string
  state: '1' | '2'
  refEvaluationName: string
}

export interface Evaluation {
  type: EvaluationType
  controlId: string
  data: {
    base: {
      id: string // Evaluation Id
      name: string // Evaluation name
      startDate: string
      endDate: string
      period: string // NOTE: EvaluationUtils에서 채워준다.
      controlArray: string[]
      state: '1' | '2' // State "1": 진행중 "2": 마감
      closeTime: string // 전체 마감 시각
      refEvaluationName: string // 참조된 Evaluation
    }
    content: {
      processCode: string // NOTE: EvaluationUtils에서 채워준다.
      riskData: Risk
      controlData: Control
      staffData: Staff
      files: EvaluationFiles
      evalData: OperationEvaluationData | DesignEvaluationData
    }
    state: {
      controlApprover: string
      controlOwner: string
      controlPerformer: string
      departmentName: string
      accomplishTime: string
      approvalTime: string
      // state - 상태
      // "1": 미수행 "2": 수행중 "3": 수행완료 "4": 재작성필요 "5": 승인완료
      state: '1' | '2' | '3' | '4' | '5'
      // resultState
      // - 운영평가
      //   "1": default. 생성 시 상태
      //   "2": 문제 없음. 특이사항 없음
      //   "3": 문제 있음. 예외사항 발생
      // - 설계평가
      //   "1": default. 생성 시 상태
      //   "2": 문제 없음. Effective
      //   "3": 문제 있음. Ineffective
      resultState: '1' | '2' | '3'
      resultStateUpdateTime: string
      // weakState
      // - 위의 resultState에서 문제가 있는 경우에만 발생
      // - 운영평가
      //   "1": default
      //   "2": 평가대기중
      //   "3": 단순한 미비점
      //   "4": 유의한 미비점
      //   "5": 중요한 취약점
      // - 설계평가
      //   "1": default(생성시 들어가는 상태, reset 시)
      //   "2": 평가대기중
      //   "3": 작성완료 (반영할 수정사항 없음)
      //   "4": 작성완료 (반영할 수정사항 있음)
      weakState: '1' | '2' | '3' | '4' | '5'
      weakStateData: any // unused
      weakStateUpdateTIme: string
    }
  }
}

export interface EvaluationData {
  type: EvaluationType
  updateTime: string
  createDate: string
  number: string // control id
}

export const EVIDENCE_KEY = '증빙자료 Ref'

export interface OperationEvaluationData extends EvaluationData {
  executor: string
  populationDescription: string
  populationCount: string
  populationCompleteness: string
  samplingMethod: string
  controlException: string
  samplingCount: number // numberOfSampling
  populationTableColumnCount: number // numberOfSamplingAttributes
  samplingAttributeCount: number // numberOfTestAttributes
  samplingResult: TableContent
  attributeContents: TableContent
  testContents: TableContent
  validCount: string
  invalidCount: string
  testResult: string
}

export enum Result {
  ineffective = 'Ineffective',
  effective = 'Effective'
}

export enum JudgementLevel {
  low = '낮은 수준',
  high = '높은 수준'
}

export interface DesignEvaluationData extends EvaluationData {
  judgementLevel: JudgementLevel
  riskAssessmentBasis: string
  considerationOneContent: string
  considerationOneResult: Result
  considerationTwoContent: string
  considerationTwoResult: Result
  considerationThreeContent: string
  considerationThreeResult: Result
  considerationFourContent: string
  considerationFourResult: Result
  considerationFiveContent: string
  considerationFiveResult: Result
  baselineReference: string
  result: Result
}

export function isOperationEvaluation(value: EvaluationData): value is OperationEvaluationData {
  return value.type === 'operation'
}

export function isDesignEvaluation(value: EvaluationData): value is DesignEvaluationData {
  return value.type === 'design'
}
