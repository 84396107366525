import _ from 'lodash'
import React from 'react'

import { EvaluationFile, ServerFile, State } from '../../../base/data/BaseFile'
import {
  DesignEvaluationData,
  Evaluation,
  EvaluationSummary,
  EvaluationType,
  EVIDENCE_KEY,
  isOperationEvaluation,
  OperationEvaluationData,
  TableContent
} from '../../../base/data/Evaluation'
import { PRCType } from '../../../base/data/PRC'
import { toDateRange } from '../../../base/utils/TimeUtils'
import { getControls } from '../../../dataLoader/DataLoader'
import {
  evaluationDeleteFiles,
  evaluationUpdateFromExcel,
  evaluationUpdateFromWeb,
  evaluationUpdateState,
  evaluationGetAll,
  evaluationGetAllBase
} from '../../../dataLoader/Evaluation/evaluation'
import { BASE_ITEM } from '../../common/DividerSelect'

export interface EvaluationSelectAttribute {
  loading: boolean
  selectedName: string
  names: string[]
  summaries: EvaluationSummary[]
  evaluationDetails?: Evaluation[]
}

export function getShortEvalType(evaluationType: string): 'OE' | 'DE' {
  return evaluationType === 'operation' ? 'OE' : 'DE'
}

export function evaluationStateToString(state: string): string {
  if (state === '2') {
    return '수행중'
  } else if (state === '3') {
    return '수행완료'
  } else if (state === '4') {
    return '재작성 필요'
  } else if (state === '5') {
    return '승인완료'
  }
  return '미수행'
}

export function weakStateToString(type: 'operation' | 'design', state: string): string {
  if (type === 'operation') {
    if (state === '2') {
      return '평가 대기'
    } else if (state === '3') {
      return '단순한 미비점'
    } else if (state === '4') {
      return '유의한 미비점'
    } else if (state === '5') {
      return '중요한 취약점'
    }
    return '평가 대상 아님'
  }

  if (state === '2') {
    return '평가 대기'
  } else if (state === '3') {
    return '작성완료(반영할 수정사항 없음)'
  } else if (state === '4') {
    return '작성완료(반영할 수정사항 있음)'
  }
  return '평가 대상 아님'
}

export function resultStateToString(state: string): string {
  if (state === '2') {
    return '미비사항 없음'
  } else if (state === '3') {
    return '미비사항 존재'
  }
  return '평가 결론 없음'
}

export function getFileNameWithoutPrefix(
  evaluationName: string,
  controlId: string,
  filename: string
): string {
  const prefix = `(${evaluationName})(${controlId})(증빙)`
  if (_.startsWith(filename, prefix)) {
    return _.replace(filename, prefix, '')
  }
  return filename
}

function initTableContent(evaluation: any, key: string): void {
  if (!_.has(evaluation, key)) {
    _.set(evaluation, key, {
      headerRow: [],
      columnCountIncludeNumbering: 0,
      rowCountExceptHeader: 0,
      rowObjectList: []
    })
  }
}

function getPeriod(evaluation: Evaluation): string {
  if (_.isEmpty(evaluation?.data?.base?.startDate) || _.isEmpty(evaluation?.data?.base?.endDate)) {
    return ''
  }
  return `${evaluation.data.base.startDate} ~ ${evaluation.data.base.endDate}`
}

export function postprocessEvaluation(
  type: EvaluationType,
  processCode: string,
  evaluation: Evaluation
): Evaluation {
  const newEvaluation = _.cloneDeep(evaluation)
  newEvaluation.type = type
  newEvaluation.data.content.processCode = processCode
  _.assign(newEvaluation.data.content.controlData, {
    cycleName: _.get(newEvaluation, 'data.content.controlData.processCycleName'),
    cycleNumber: _.get(newEvaluation, 'data.content.controlData.processCycleNumber'),
    categoryName: _.get(newEvaluation, 'data.content.controlData.processCategoryName'),
    categoryNumber: _.get(newEvaluation, 'data.content.controlData.processCategoryNumber'),
    subCategoryName: _.get(newEvaluation, 'data.content.controlData.processSubCategoryName'),
    subCategoryNumber: _.get(newEvaluation, 'data.content.controlData.processSubCategoryNumber')
  })
  newEvaluation.data.base.period = getPeriod(evaluation)
  newEvaluation.data.content.riskData.type = PRCType.risk
  newEvaluation.data.content.controlData.type = PRCType.control

  if (_.isEmpty(newEvaluation.data.content.evalData)) {
    _.set(newEvaluation, 'data.content.evalData', {
      updateTime: '',
      createDate: ''
    })
  }

  // NOTE: state가 승인완료가 아니라면 weakState는 1(평가 대상 아님)로 변경한다.
  if (type === 'operation' && _.get(newEvaluation, 'data.state.state') !== '5') {
    _.set(newEvaluation, 'data.state.weakState', '1')
  }

  const evalData = newEvaluation.data.content.evalData
  evalData.type = type
  evalData.number = evaluation.controlId
  if (isOperationEvaluation(evalData)) {
    initTableContent(evalData, 'samplingResult')
    initTableContent(evalData, 'attributeContents')
    initTableContent(evalData, 'testContents')
    if (!_.has(evalData, 'samplingCount')) {
      evalData.samplingCount = 0
    }
    if (!_.has(evalData, 'populationTableColumnCount')) {
      evalData.populationTableColumnCount = 0
    }
    if (!_.has(evalData, 'samplingAttributeCount')) {
      evalData.samplingAttributeCount = 0
    }

    _.forEach(evalData.testContents.rowObjectList, (rowObject) => {
      rowObject[EVIDENCE_KEY] = getFileNameWithoutPrefix(
        evaluation.data.base.name,
        evaluation.controlId,
        rowObject[EVIDENCE_KEY]
      )
    })

    _.assign(evalData, {
      // NOTE(sangmuk): 모집단 설명이 비어있을 경우 모집단 파일명을 기입한다.
      populationDescription:
        _.isEmpty(evalData.populationDescription) &&
        !_.isEmpty(evaluation.data.content.files.populationFile)
          ? evaluation.data.content.files.populationFile?.fileName
          : evalData.populationDescription
    })
  }
  return newEvaluation
}

export async function loadEvaluationDetailsInner(
  type: EvaluationType,
  evaluationName: string
): Promise<Evaluation[]> {
  const evaluations = await evaluationGetAll(getShortEvalType(type), evaluationName)
  const controls = await getControls()

  const details: Evaluation[] = _.map(evaluations, (evaluation, i) => {
    const processCode = _.get(
      _.find(controls, { id: evaluation.controlId }),
      'processMappingCode[0]'
    )
    return postprocessEvaluation(type, processCode, evaluation as Evaluation)
  })

  return details
}

export function loadEvaluationDetails(
  type: EvaluationType,
  evaluationName: string,
  selectAttributes: EvaluationSelectAttribute,
  setSelectAttributes: React.Dispatch<React.SetStateAction<EvaluationSelectAttribute>>
): void {
  if (evaluationName === BASE_ITEM) {
    setSelectAttributes({
      ...selectAttributes,
      loading: false,
      selectedName: BASE_ITEM,
      evaluationDetails: []
    })
    return
  }

  setSelectAttributes({
    ...selectAttributes,
    loading: true
  })
  loadEvaluationDetailsInner(type, evaluationName)
    .then((evaluations) => {
      setSelectAttributes({
        ...selectAttributes,
        loading: false,
        selectedName: evaluationName,
        evaluationDetails: evaluations
      })
    })
    .catch((e) => {
      console.log('Failed to load evaluation details', e)
      setSelectAttributes({
        ...selectAttributes,
        loading: false
      })
    })
}

export function loadEvaluationNames(
  type: EvaluationType,
  evaluationName: string | undefined,
  selectAttributes: EvaluationSelectAttribute,
  setSelectAttributes: React.Dispatch<React.SetStateAction<any>>
): void {
  setSelectAttributes({
    ...selectAttributes,
    loading: true
  })

  evaluationGetAllBase(getShortEvalType(type))
    .then((summaries) => {
      const names = _.map(summaries, (summary) => summary.evalName)
      if (evaluationName && evaluationName !== BASE_ITEM) {
        setSelectAttributes({
          ...selectAttributes,
          loading: true
        })
        loadEvaluationDetailsInner(type, evaluationName)
          .then((evaluations) => {
            setSelectAttributes({
              ...selectAttributes,
              names,
              summaries,
              loading: false,
              selectedName: evaluationName,
              evaluationDetails: evaluations
            })
          })
          .catch((e) => {
            console.log('Failed to load evaluation details', e)
            setSelectAttributes({
              ...selectAttributes,
              names,
              summaries,
              loading: false
            })
          })
      } else {
        setSelectAttributes({
          ...selectAttributes,
          loading: false,
          selectedName: BASE_ITEM,
          names,
          summaries,
          evaluationDetails: []
        })
      }
    })
    .catch((e) => {
      console.log(`Failed to get ${type} evaluation.`, e)
      setSelectAttributes({
        ...selectAttributes,
        loading: false
      })
    })
}

export function modifyOperationEvaluationForSaving(evaluation: OperationEvaluationData): void {
  const sampling = evaluation.samplingResult as TableContent
  const newHeaders: string[] = ['No']
  _.forEach(_.drop(sampling.headerRow), (header) => {
    newHeaders.push(sampling.rowObjectList[0][header])
  })
  const newRowObject: Record<string, string>[] = []
  _.forEach(_.drop(sampling.rowObjectList), (row: Record<string, string>) => {
    const newRow: Record<string, string> = {}
    _.forEach(_.zip(sampling.headerRow, newHeaders), ([header, newHeader]) => {
      if (header && newHeader) newRow[newHeader] = _.get(row, header)
    })
    newRowObject.push(newRow)
  })
  sampling.headerRow = newHeaders
  sampling.rowCountExceptHeader -= 1
  sampling.rowObjectList = newRowObject

  const cutData = (content: TableContent): void => {
    content.rowObjectList = _.take(content.rowObjectList, content.rowCountExceptHeader)
    content.rowObjectList = _.map(content.rowObjectList, (row) => {
      const newRow: any = {}
      _.forEach(content.headerRow, (header) => {
        newRow[header] = _.get(row, header) || ''
      })
      return newRow
    })
  }

  cutData(evaluation.samplingResult)
  cutData(evaluation.attributeContents)
  cutData(evaluation.testContents)
}

function compareEvaluation(aList?: Evaluation[], bList?: Evaluation[]): boolean {
  if (!aList || !bList || aList.length !== bList.length) return false
  return _.every(_.zip(aList, bList), ([a, b]: Evaluation[]) => {
    return (
      a.type === b.type &&
      a.data.base.name === b.data.base.name &&
      a.controlId === b.controlId &&
      _.isEqual(a.data.content.files, b.data.content.files) &&
      a.data.state.state === b.data.state.state &&
      a.data.state.weakState === b.data.state.weakState &&
      _.isEqual(a.data.content.evalData, b.data.content.evalData)
    )
  })
}

export function isEqualEvaluations(aList?: Evaluation[], bList?: Evaluation[]): boolean {
  return _.isEqualWith(aList, bList, compareEvaluation)
}

export function isAbleToDefect(evaluation: Evaluation): boolean {
  return (
    evaluation.data.state.state === '5' &&
    evaluation.data.state.resultState === '3' &&
    evaluation.data.base.state === '1'
  )
}

export function isAlreadyClose(evaluations: Evaluation[] | undefined): boolean {
  return _.some(evaluations, (evaluation) => evaluation.data.base.state === '2')
}

export function isAbleToClose(evaluations: Evaluation[]): boolean {
  return _.every(evaluations, (evaluation) => {
    if (evaluation.data.base.state === '2') {
      // 이미 마감된 평가일 경우
      return false
    }
    if (evaluation.data.state.state !== '5') {
      // 미승인된 통제일 경우
      return false
    }
    if (evaluation.data.state.resultState === '2') {
      // 통제 평가 완료 후, 문제가 없는 경우
      return true
    }
    if (
      evaluation.data.state.resultState === '3' &&
      !_.includes(['1', '2'], evaluation.data.state.weakState)
    ) {
      // 문제가 있는 평가에 대해 미비점 평가를 완료한 경우
      return true
    }
    return false
  })
}

export async function SaveEvaluationDetail(
  type: 'operation' | 'design',
  evaluationName: string,
  evaluationDetails: Evaluation[],
  selectedDetailId: string,
  evaluationData: OperationEvaluationData | DesignEvaluationData,
  deletedFile: ServerFile[],
  newFiles: EvaluationFile[],
  newState: '2' | '3'
): Promise<void> {
  if (evaluationName === BASE_ITEM) {
    throw new Error('not selected')
  }

  const selectedData = _.find(evaluationDetails, { controlId: selectedDetailId })
  if (!selectedData) {
    throw new Error('not selected')
  }

  const isNotModifiedContent = _.every(evaluationData, (v: any, k: any) => {
    return _.isEqual(v, _.get(selectedData?.data.content.evalData, k))
  })
  if (isNotModifiedContent && _.isEmpty(deletedFile) && _.isEmpty(newFiles)) {
    if (newState === '3') {
      // NOTE: 수행완료 버튼을 눌렀을 경우, 변경사항이 없더라도 저장한다.
      await evaluationUpdateState(
        getShortEvalType(type),
        evaluationName,
        [selectedDetailId],
        newState
      )
      return
    }
    throw new Error('not changed')
  }

  if (
    isOperationEvaluation(evaluationData) &&
    evaluationData.samplingResult.columnCountIncludeNumbering === 2 &&
    evaluationData.samplingResult.rowCountExceptHeader === 1 &&
    _.isEqual(evaluationData.samplingResult.headerRow, ['No', '']) &&
    _.isEqual(evaluationData.samplingResult.rowObjectList[0], { No: '1', '': '' })
  ) {
    evaluationData.samplingResult = {
      headerRow: [],
      columnCountIncludeNumbering: 0,
      rowCountExceptHeader: 0,
      rowObjectList: []
    }
  } else if (
    isOperationEvaluation(evaluationData) &&
    _.some(evaluationData.samplingResult.headerRow, (header) => _.isEmpty(header))
  ) {
    throw new Error('empty header')
  }

  if (!isNotModifiedContent) {
    const newEvaluationData = _.cloneDeep(evaluationData)
    if (isOperationEvaluation(newEvaluationData)) {
      // TODO(sangmuk): Set controlExecutor after set login.
      _.assign(newEvaluationData, {
        samplingCount: newEvaluationData.samplingCount.toString(),
        populationTableColumnCount: newEvaluationData.populationTableColumnCount.toString(),
        samplingAttributeCount: newEvaluationData.samplingAttributeCount.toString()
      })
    }
    await evaluationUpdateFromWeb(getShortEvalType(type), evaluationName, newEvaluationData)
  }

  if (!_.isEmpty(deletedFile)) {
    await evaluationDeleteFiles(getShortEvalType(type), deletedFile)
  }
  if (!_.isEmpty(newFiles)) {
    _.forEach(newFiles, (file: any) => {
      file.state = State.SKIP_RECORD_UPDATE
      file.evaluationName = evaluationName
    })
    await evaluationUpdateFromExcel(getShortEvalType(type), evaluationName, newFiles)
  }

  await evaluationUpdateState(getShortEvalType(type), evaluationName, [selectedDetailId], newState)
}

export function getEvaluationNamesWithDate(summaries: EvaluationSummary[]): string[] {
  return _.map(
    summaries,
    (summary) => `${summary.evalName} (${toDateRange(summary.startDate, summary.endDate)})`
  )
}
