import _ from 'lodash'

import { Control, Process, Risk } from '../../../base/data/PRC'
import { LoadStaffs, Staff } from '../../../base/data/Staff'
import { compareIds, CompareProcess, compareProcessIds } from '../../../base/utils/CompareUtils'
import { getControls, getProcesses, getRisks } from '../../../dataLoader/DataLoader'
import { cycleGetAllTree } from '../../../dataLoader/RCM/cycle'

export interface Cycle {
  id: string
  name: string
  parent?: Cycle
  children: Cycle[]
}

type LoadType = 'cycle' | 'process' | 'risk' | 'control' | 'staff'

export async function LoadStaff(): Promise<{ type: LoadType; data: Staff[] }> {
  return LoadStaffs().then((staffs) => {
    return {
      type: 'staff',
      data: staffs
    }
  })
}

export function convertToCategory(cycleObject: any, parent?: Cycle): Cycle[] {
  const cycles: Cycle[] = []
  _.forEach(cycleObject, (value: any) => {
    const child: any = {
      ..._.omit(value, 'children'),
      parent
    }
    cycles.push({
      ...child,
      children: convertToCategory(value.children, child as Cycle)
    })
  })

  if (_.every(cycles, (element) => !_.isNaN(_.parseInt(element.id)))) {
    cycles.sort((a: any, b: any) => _.parseInt(_.get(a, 'id')) - _.parseInt(_.get(b, 'id')))
  }
  return cycles
}

export async function LoadCycle(): Promise<{ type: LoadType; data: Cycle[] }> {
  return cycleGetAllTree().then((cycleObject) => {
    return {
      type: 'cycle',
      data: convertToCategory(cycleObject)
    }
  })
}

export async function LoadProcess(
  controls: Control[]
): Promise<{ type: LoadType; data: Process[] }> {
  return getProcesses(controls).then((processes) => {
    processes.sort(CompareProcess)
    return {
      type: 'process',
      data: processes
    }
  })
}

export async function LoadRisk(controls: Control[]): Promise<{ type: LoadType; data: Risk[] }> {
  return getRisks(controls).then((risks) => {
    risks.sort(CompareProcess)
    return {
      type: 'risk',
      data: risks
    }
  })
}

export async function LoadControl(): Promise<{ type: LoadType; data: Control[] }> {
  return getControls().then((controls) => {
    controls.sort(CompareProcess)
    return {
      type: 'control',
      data: controls
    }
  })
}

export function getCycleToIdMap(
  type: 'process' | 'risk' | 'control',
  dataSource: any[]
): Record<string, { id: string; name: string; disabled: boolean }[]> {
  const newIds = _.reduce(
    dataSource,
    (result, item: any) => {
      const cycleNumbers = [item.cycleNumber, item.categoryNumber, item.subCategoryNumber]
      if (_.some(cycleNumbers, (id) => _.isEmpty(id))) {
        return result
      }

      const cycleNumberStr = _.join(cycleNumbers, '.')
      if (!_.has(result, cycleNumberStr)) result[cycleNumberStr] = []
      result[cycleNumberStr].push({
        id: item.id,
        name: item.name,
        disabled: type === 'risk' ? _.isEmpty(item.processIds) : false
      })
      return result
    },
    {} as Record<string, { id: string; name: string; disabled: boolean }[]>
  )
  if (type === 'process') {
    _.forEach(newIds, (ids) => ids.sort(compareProcessIds))
  } else {
    _.forEach(newIds, (ids) => ids.sort(compareIds))
  }
  return newIds
}

export function afterUpdateMappings(
  type: 'risk' | 'control',
  dataIndex: string,
  id: string,
  newIds: string[],
  dataSource: { process: any[]; risk: any[]; control: any[]; staff: any[] }
): any {
  const data = type === 'risk' ? dataSource.risk : dataSource.control

  let targetType: 'process' | 'risk' | 'control' | 'staff'
  if (dataIndex === 'processIds') targetType = 'process'
  else if (dataIndex === 'controlIds') targetType = 'control'
  else if (dataIndex === 'riskIds') targetType = 'risk'
  else if (dataIndex === 'staffIds') targetType = 'staff'
  else return {}

  const index = _.findIndex(data, { id })
  const item: any = data[index]

  const addedTargetIds = _.difference(newIds, item[dataIndex])
  const deletedTargetIds = _.difference(item[dataIndex], newIds)
  const newTargetData = _.map(dataSource[targetType], (targetItem: any) => {
    const targetDataIndex = type === 'risk' ? 'riskIds' : 'controlIds'
    if (_.includes(addedTargetIds, targetItem.id)) {
      targetItem[targetDataIndex].push(id)
      targetItem[targetDataIndex].sort()
    } else if (_.includes(deletedTargetIds, targetItem.id)) {
      const targetIndex = targetItem[targetDataIndex].indexOf(id)
      if (targetIndex > -1) {
        targetItem[targetDataIndex].splice(targetIndex, 1)
      }
    }
    return targetItem
  })

  item[dataIndex] = newIds
  const newData = data.slice()
  newData.splice(index, 1, item)
  newData.sort(CompareProcess)
  newTargetData.sort(CompareProcess)
  return {
    [type]: newData,
    [`${type}Ids`]: getCycleToIdMap(type, newData),
    [targetType]: newTargetData,
    [`${targetType}Ids`]: getCycleToIdMap(type, newTargetData)
  }
}
