import _ from 'lodash'

import { PRCType } from '../../base/data/PRC'
import { evaluationUpdateTemplate } from '../Evaluation/evaluation'
import { adminGetOrCreateUser } from '../Utils/admin'
import { backupSetFlag } from '../Utils/backup'
import { updateObjectForm } from '../Utils/updateObjectForm'
import { dbService } from '../fbase'
import { cycleGetAll, cycleGetWithId, cycleListUpdatePathArray } from './cycle'
import { departmentListUpdatePathArray } from './department'
import { processListUpdatePathArray } from './process'
import { riskGetWithId, riskListUpdatePathArray } from './risk'
import { staffListUpdatePathArray } from './staff'

export const controlInsertDataListForInit = async (dataObjectList) => {
  /*
  Init 을 위해서 Excel 에서 넘어온 Control Data List 및 추가 정보들을 Firestore 에 집어넣는다

  @param: dataObjectList: Object[]: 데이터 리스트
  */
  const controlInsertDataForInit = async (dataObject) => {
    // 단일 Data 를 집어넣는다.
    const controlId = dataObject.number

    const targetCollectionName = 'CONTROL'
    const targetDocId = controlId
    const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

    return await docRef.set({
      id: controlId,
      uid: controlId,
      number: controlId,

      name: dataObject.name,
      narrative: dataObject.narrative,

      goal: dataObject.goal,
      goal_Trust: dataObject.goal_Trust,
      goal_Override: dataObject.goal_Override,
      goal_Operation: dataObject.goal_Operation,
      goal_Law: dataObject.goal_Law,
      goal_Asset: dataObject.goal_Asset,

      accountName: dataObject.accountName,
      accountCode: dataObject.accountCode,

      autoManual: dataObject.autoManual,

      assertion_EO: dataObject.assertion_EO,
      assertion_RO: dataObject.assertion_RO,
      assertion_Occur: dataObject.assertion_Occur,
      assertion_C: dataObject.assertion_C,
      assertion_Assessment: dataObject.assertion_Assessment,
      assertion_Measurement: dataObject.assertion_Measurement,
      assertion_PD: dataObject.assertion_PD,

      reportFootnotes: dataObject.reportFootnotes,
      residualRiskLevel: dataObject.residualRiskLevel,
      controlRiskLevel: dataObject.controlRiskLevel,
      preventDetective: dataObject.preventDetective,
      policy: dataObject.policy,
      period: dataObject.period,

      teamLeader: dataObject.teamLeader,
      teamMember: dataObject.teamMember,
      owner: dataObject.owner,
      incharge: dataObject.incharge,
      department: dataObject.department,
      keyControl: dataObject.keyControl,
      ITDependencySystem: dataObject.ITDependencySystem,

      MRC_YN: dataObject.MRC_YN,
      MRC_Number: dataObject.MRC_Number,
      MRC_IPE_Name: dataObject.MRC_IPE_Name,

      IPE_YN: dataObject.IPE_YN,
      IPE_Number: dataObject.IPE_Number,
      IPE_Name: dataObject.IPE_Name,

      TOC_TestProperties: dataObject.TOC_TestProperties,
      TOC_Procedure: dataObject.TOC_Procedure,
      TOC_PopulationCount: dataObject.TOC_PopulationCount,
      TOC_Population: dataObject.TOC_Population,
      TOC_Exception: dataObject.TOC_Exception,
      TOC_Evidence: dataObject.TOC_Evidence,
      TOC_TestMethod_Reperformance: dataObject.TOC_TestMethod_Reperformance,
      TOC_TestMethod_Observation: dataObject.TOC_TestMethod_Observation,
      TOC_TestMethod_InquiryInspection: dataObject.TOC_TestMethod_InquiryInspection,
      TOC_Completeness: dataObject.TOC_Completeness,

      // autoProperty_SGI: dataObject.autoProperty_SGI,
      // autoProperty_RestrictedAccess: dataObject.autoProperty_RestrictedAccess,
      // autoProperty_Interface: dataObject.autoProperty_Interface,
      // autoProperty_Calculation: dataObject.autoProperty_Calculation,
      // autoProperty_AutoControl: dataObject.autoProperty_AutoControl,

      // assertion_VA: dataObject.assertion_VA,
      // assertion_CO: dataObject.assertion_CO,
      // assertion_A: dataObject.assertion_A,

      // ownerCompatibility: dataObject.ownerCompatibility,
      // reviewControl: dataObject.reviewControl,
      // ITDependency: dataObject.ITDependency,
      // MRC_IPE_Number: dataObject.MRC_IPE_Number,
      // TOC_SamplingCount: dataObject.TOC_SamplingCount,
      // TOC_Period: dataObject.TOC_Period,
      // TOC_Owner: dataObject.TOC_Owner,
      // TOC_EvidenceSize: dataObject.TOC_EvidenceSize,
      // TOC_Department: dataObject.TOC_Department,

      // EUC: dataObject.EUC,
      // IPE: "",  // deprecated
      // SOD: "",  // deprecated
      // fraudNumber: "",  // deprecated
      // EOCExecutionDate: "", // deprecated

      processCycleNumber: dataObject.processCycleNumber,
      processCycleName: dataObject.processCycleName,
      processCategoryNumber: dataObject.processCategoryNumber,
      processCategoryName: dataObject.processCategoryName,
      processSubCategoryNumber: dataObject.processSubCategoryNumber,
      processSubCategoryName: dataObject.processSubCategoryName,
      processMappingCode: dataObject.processMappingCode
        .trim()
        .split('\r\n')
        .map((processId) => processId.trim()),

      riskNumber: dataObject.riskNumber,
      riskName: dataObject.riskName,
      riskInherentRiskLevel: dataObject.inherentRiskLevel,

      selfPath: 'CONTROL/' + controlId,
      staffPathArray: ['STAFF/' + dataObject.owner],
      departmentPathArray: ['DEPARTMENT/' + dataObject.department],
      cyclePathArray: [
        'CYCLE/' +
          dataObject.processCycleNumber +
          '-' +
          dataObject.processCategoryNumber +
          '-' +
          dataObject.processSubCategoryNumber
      ],
      processPathArray: dataObject.processMappingCode
        .trim()
        .split('\r\n')
        .map((processId) => 'PROCESS/' + processId.trim()),
      riskPathArray: ['RISK/' + dataObject.riskNumber]
    })
  }

  const result = dataObjectList.map(async (dataObject) => {
    return await controlInsertDataForInit(dataObject)
  })

  console.log('Insert Control Data For Init Done')
  return Promise.all(result)
}

export const controlGetWithId = async (controlId) => {
  // controlId 에 해당하는 항목만 가져온다.
  const targetCollectionName = 'CONTROL'
  const docData = await dbService.collection(targetCollectionName).doc(controlId)
.get()

  return docData.data()
}

export const controlGetAll = async () => {
  // 모든 CONTROL 데이터를 가져온다.
  const targetCollectionName = 'CONTROL'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.data())
}

export const controlGetAllIdList = async () => {
  // 모든 CONTROL 의 Id List 반환
  // @return: String[]
  const targetCollectionName = 'CONTROL'
  const collectionData = await dbService.collection(targetCollectionName).get()

  return collectionData.docs.map((docData) => docData.id)
}

const controlUpdatePathArray = async (controlId, updateObject) => {
  /*
  CONTROL 의 controlId 에 대해서
  updateObject 의 내용들을 수정

  CONTROL 은 processPathArray 일 경우 processMappingCode 도 추가적으로 수정

  TODO: 설계평가에 영향

  @param: controlId: String - CONTROL id
  @param: updateObject{
    "cyclePathArray": {
      "add": [cycleId, ...],
      "del": [cycleId, ...]
    },
    "processPathArray": {
      "add": [processId, ...],
      "del": [processId, ...]
    },
    "riskPathArray": {
      "add": [riskId, ...],
      "del": [riskId, ...]
    },
    "controlPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    },
    "staffPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    },
    "departmentPathArray": {
      "add": [controlId, ...],
      "del": [controlId, ...]
    }
  }
  */

  const finalUpdateObject = {}

  const controlObject = await controlGetWithId(controlId)
  const iterKeyList = [
    'cyclePathArray',
    'processPathArray',
    'riskPathArray',
    'staffPathArray',
    'departmentPathArray'
  ]
  iterKeyList.forEach((targetKey) => {
    let resultIdList = controlObject[targetKey].map((path) => path.split('/')[1])

    let processMappingCode = []
    if (targetKey === 'processPathArray') {
      processMappingCode = controlObject.processMappingCode // list
    }

    let owner = ''
    let department = ''

    const addList = updateObject[targetKey].add
    addList.forEach((addId) => {
      resultIdList.push(addId)

      if (targetKey === 'processPathArray') {
        processMappingCode.push(addId)
      }
      if (targetKey === 'staffPathArray') {
        owner = addId
      }
      if (targetKey === 'departmentPathArray') {
        department = addId
      }
    })

    const delList = updateObject[targetKey].del
    delList.forEach((delId) => {
      const idx = resultIdList.indexOf(delId)
      if (idx > -1) {
        resultIdList.splice(idx, 1)
      }
      if (targetKey === 'processPathArray') {
        const processIdx = processMappingCode.indexOf(delId)
        if (processIdx > -1) {
          processMappingCode.splice(processIdx, 1)
        }
      }
    })

    resultIdList = [...new Set(resultIdList)]

    const resultPathArray = resultIdList.map(
      (id) => targetKey.split('PathArray')[0].toUpperCase() + '/' + id
    )
    finalUpdateObject[targetKey] = resultPathArray

    if (targetKey === 'processPathArray') {
      finalUpdateObject.processMappingCode = processMappingCode
    }
    if (targetKey === 'staffPathArray' && owner !== '') {
      finalUpdateObject.owner = owner
      // 수정 시 Template 과 관련된 내용은 Template 에서도 업데이트를 해줘야한다.
      controlObject.owner = owner
    }
    if (targetKey === 'departmentPathArray' && department !== '') {
      finalUpdateObject.department = department
      // 수정 시 Template 과 관련된 내용은 Template 에서도 업데이트를 해줘야한다.
      controlObject.department = department
    }
  })

  if (finalUpdateObject.riskPathArray.length === 0) {
    // CONTROL 은 하나의 RISK 만 가질 수 있기 때문에
    // CONTROL 에 연결된 RISK 가 없다면 연결된 PROCESS 도 존재하지 않아야 하는 것이 맞다.
    finalUpdateObject.riskName = ''
    finalUpdateObject.riskNumber = ''
    finalUpdateObject.riskInherentRiskLevel = ''
    finalUpdateObject.processMappingCode = []
    finalUpdateObject.processPathArray = []
  } else if (finalUpdateObject.riskPathArray.length === 1) {
    // CONTROL 은 하나의 RISK 만 가질 수 있기 때문에 다음 것들이 확정
    const riskId = finalUpdateObject.riskPathArray[0].split('/')[1]
    const riskData = await riskGetWithId(riskId)
    finalUpdateObject.riskName = riskData.name
    finalUpdateObject.riskNumber = riskId
    finalUpdateObject.riskInherentRiskLevel = riskData.inherentRiskLevel
    finalUpdateObject.processMappingCode = riskData.processPathArray.map(
      (riskPath) => riskPath.split('/')[1]
    )
    finalUpdateObject.processPathArray = riskData.processPathArray
  }

  // 수정 시 Template 과 관련된 내용은 Template 에서도 업데이트를 해줘야한다.
  controlObject.riskName = finalUpdateObject.riskName
  controlObject.riskNumber = finalUpdateObject.riskNumber
  controlObject.riskInherentRiskLevel = finalUpdateObject.riskInherentRiskLevel
  controlObject.processMappingCode = finalUpdateObject.processMappingCode

  return await dbService
    .collection('CONTROL')
    .doc(controlId)
    .update(finalUpdateObject)
    .then(() => {
      // evaluationUpdateTemplate('OE', controlId, controlObject)
      evaluationUpdateTemplate('DE', controlId, controlObject)
    })
}

export const controlListUpdatePathArray = async (updateObject) => {
  /*
  CONTROL 의 controlIdList 에 속하는 controlId 들에 대해서
  controlUpdatePathArray 를 호출

  @param: controlId: String - CONTROL id
  @param: updateObject{
    "controlId": {},
    ...
  }
  */

  const result = Object.keys(updateObject).map(async (controlId) => {
    return await controlUpdatePathArray(controlId, updateObject[controlId])
  })

  return Promise.all(result)
}

export const controlGetAllContainCycle = async () => {
  /*
  CONTROL 은 자체적으로 CYCLE 정보를 모두 담고 있다.
  기본적으로 CONTROL 은 생성될 때 분류정보(cycleNumber, categoryNumber, subCategoryNumber) 는 모두 들어가서 생성된다.
  그렇기 때문에 cyclePathArray 가 비어있을 수 없다.

  UserLevel이 NORMAL, UPLOADER일 경우, 해당되는 Control만 반환한다.

  @return: Object[
    {

    },
    ...
  ]
  */

  const cycleDataAll = await cycleGetAll()
  const controlDataAll = await controlGetAll()
  const user = await adminGetOrCreateUser()

  return _(controlDataAll)
    .map((controlObject) => {
      const cycleId = controlObject.cyclePathArray[0].split('/')[1]
      const relateCycleObject = _.find(cycleDataAll, { id: cycleId })

      if (
        (user.level === 'UPLOADER' || user.level === 'NORMAL') &&
        user.id !== _.get(controlObject, 'owner') &&
        user.id !== _.get(controlObject, 'incharge')
      ) {
        return undefined
      }

      return {
        ...controlObject,
        type: PRCType.control,
        cycleNumber: relateCycleObject.cycleNumber,
        cycleName: relateCycleObject.cycleName,
        categoryNumber: relateCycleObject.categoryNumber,
        categoryName: relateCycleObject.categoryName,
        subCategoryNumber: relateCycleObject.subCategoryNumber,
        subCategoryName: relateCycleObject.subCategoryName,
        processIds: _.map(controlObject.processPathArray, (path) => _.last(_.split(path, '/'))),
        riskIds: _.map(controlObject.riskPathArray, (path) => _.last(_.split(path, '/'))),
        staffIds: _.map(controlObject.staffPathArray, (path) => _.last(_.split(path, '/')))
      }
    })
    .compact()
    .value()
}

export const controlUpdate = async (controlObject) => {
  /*
  CONTROL 을 업데이트 한다.
  아래 항목들만 업데이트 가능

  TODO: 운영평가에 영향
  TODO: 설계평가에 영향

  @param: Object{
    id: "ELC-01-01-01-R01-C01",

    name: "대금지급 검토 및 승인",
    narrative: "일반 지출결제 있을시 지급 리스트는 재무회계팀장, 재무기획실장, 재무관리실장이 집행 전 검토 및 승인함.",

    goal: "새로운 goal",
    goal_Trust: "No",
    goal_Override: "No",
    goal_Operation: "No",
    goal_Law: "No",
    goal_Asset: "No",

    accountName: "N/A",
    accountCode: "N/A",

    autoManual: "Manual",

    assertion_EO: "No",
    assertion_RO: "No",
    assertion_Occur: "No",
    assertion_C: "No",
    assertion_Assessment: "No",
    assertion_Measurement: "No",
    assertion_PD: "No",

    reportFootnotes: "매출채권 및 기타채권",
    residualRiskLevel: "L",
    controlRiskLevel: "L",
    preventDetective: "예방",
    policy: "윤리강령(또는 윤리규정)",
    period: "연별",

    incharge: "어쩌고@저쩌고.com",
    teamLeader: "윤리경영책임자",
    teamMember: "윤리경영멤버",
    keyControl: "Yes",
    ITDependencySystem: "N/A",

    MRC_YN: "No",
    MRC_Number: "N/A",
    MRC_IPE_Name: "N/A",

    IPE_YN: "No",
    IPE_Number: "N/A",
    IPE_Name: "N/A",

    TOC_TestProperties: "TOC_TestProperties 스트링",
    TOC_Procedure: "TOC_Procedure 스트링",
    TOC_PopulationCount: "조회된 개설 계좌 및 해지 계좌 수",
    TOC_Population: "",
    TOC_Exception: "TOC_Exception 스트링",
    TOC_Evidence: "TOC_Evidence 스트링",
    TOC_TestMethod_Reperformance: "No",
    TOC_TestMethod_Observation: "No",
    TOC_TestMethod_InquiryInspection: "Yes",
    TOC_Completeness: "",
  }
  */

  const targetCollectionName = 'CONTROL'
  const targetDocId = controlObject.id
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const controlId = controlObject.id
  const controlData = await controlGetWithId(controlId)

  return await docRef
    .update({
      name: controlObject.name,
      narrative: controlObject.narrative,

      goal: controlObject.goal,
      goal_Trust: controlObject.goal_Trust,
      goal_Override: controlObject.goal_Override,
      goal_Operation: controlObject.goal_Operation,
      goal_Law: controlObject.goal_Law,
      goal_Asset: controlObject.goal_Asset,

      accountName: controlObject.accountName,
      accountCode: controlObject.accountCode,

      autoManual: controlObject.autoManual,

      assertion_EO: controlObject.assertion_EO,
      assertion_RO: controlObject.assertion_RO,
      assertion_Occur: controlObject.assertion_Occur,
      assertion_C: controlObject.assertion_C,
      assertion_Assessment: controlObject.assertion_Assessment,
      assertion_Measurement: controlObject.assertion_Measurement,
      assertion_PD: controlObject.assertion_PD,

      reportFootnotes: controlObject.reportFootnotes,
      residualRiskLevel: controlObject.residualRiskLevel,
      controlRiskLevel: controlObject.controlRiskLevel,
      preventDetective: controlObject.preventDetective,
      policy: controlObject.policy,
      period: controlObject.period,

      incharge: controlObject.incharge, // 변경점(20211026) - 통제평가자
      teamLeader: controlObject.teamLeader,
      teamMember: controlObject.teamMember,
      keyControl: controlObject.keyControl,
      ITDependencySystem: controlObject.ITDependencySystem,

      MRC_YN: controlObject.MRC_YN,
      MRC_Number: controlObject.MRC_Number,
      MRC_IPE_Name: controlObject.MRC_IPE_Name,

      IPE_YN: controlObject.IPE_YN,
      IPE_Number: controlObject.IPE_Number,
      IPE_Name: controlObject.IPE_Name,

      TOC_TestProperties: controlObject.TOC_TestProperties,
      TOC_Procedure: controlObject.TOC_Procedure,
      TOC_PopulationCount: controlObject.TOC_PopulationCount,
      TOC_Population: controlObject.TOC_Population,
      TOC_Exception: controlObject.TOC_Exception,
      TOC_Evidence: controlObject.TOC_Evidence,
      TOC_TestMethod_Reperformance: controlObject.TOC_TestMethod_Reperformance,
      TOC_TestMethod_Observation: controlObject.TOC_TestMethod_Observation,
      TOC_TestMethod_InquiryInspection: controlObject.TOC_TestMethod_InquiryInspection,
      TOC_Completeness: controlObject.TOC_Completeness
    })
    .then(() => {
      // TODO: 평가 보고서
      // CONTROL 의 내용을 바꾸면 해당 Template 을 업데이트 해야한다
      // Template 과 관련된 모든 정보
      controlData.name = controlObject.name // OE
      controlData.narrative = controlObject.narrative // OE, DE
      controlData.goal = controlObject.goal // OE
      controlData.keyControl = controlObject.keyControl // OE, DE
      controlData.period = controlObject.period // OE, DE
      controlData.TOC_Population = controlObject.TOC_Population // OE
      controlData.TOC_Completeness = controlObject.TOC_Completeness // OE
      controlData.TOC_TestProperties = controlObject.TOC_TestProperties // OE
      controlData.TOC_Exception = controlObject.TOC_Exception // OE
      controlData.TOC_Evidence = controlObject.TOC_Evidence // OE, DE
      controlData.TOC_Procedure = controlObject.TOC_Procedure // OE, DE
      controlData.TOC_TestMethod_InquiryInspection = controlObject.TOC_TestMethod_InquiryInspection // DE
      controlData.TOC_TestMethod_Observation = controlObject.TOC_TestMethod_Observation // DE
      controlData.TOC_TestMethod_Reperformance = controlObject.TOC_TestMethod_Reperformance // DE
      controlData.autoManual = controlObject.autoManual // OE, DE
      controlData.preventDetective = controlObject.preventDetective // DE
      controlData.residualRiskLevel = controlObject.residualRiskLevel // DE
      controlData.controlRiskLevel = controlObject.controlRiskLevel // DE
      controlData.MRC_Number = controlObject.MRC_Number // DE
      controlData.IPE_Number = controlObject.IPE_Number // DE

      evaluationUpdateTemplate('OE', controlId, controlData)
      evaluationUpdateTemplate('DE', controlId, controlData)
    })
}

export const controlFindNewNumberToCreate = async (riskId) => {
  /*
  새로운 CONTROL 을 만들기 위해서 필요한 새로운 number 를 리턴.

  아이디의 체계가 바뀌어서 필요한 정보가 바뀜
  기존에는 cycleName 을 기준으로 CONTROL 을 생성했지만
  이제는 riskId 를 기준으로 CONTROL 을 생성하게 바뀜
  RISK id 가 필요하다 - ELC-01-01-01-R01
  리턴하는 값도 예전에는 "01" 이런식이었지만
  이제는 "C01" 이런식으로 바뀜

  @param: riskId: String - "ELC-01-01-01-R01"
  @return: String: "C01" - new CONTROL number
  */

  const controlDataAll = await controlGetAll()

  // 같은 cycleName 에 속하는 CONTROL 목록
  const controlTargetList = _.filter(controlDataAll, (controlObject) => {
    const controlId = controlObject.id
    // TODO: Id 체계에 따라 수정할 부분
    const splitControlId = controlId.split('-')
    const riskIdFromControl = _.join(_.slice(splitControlId, 0, 5), '-')

    return riskIdFromControl === riskId
  })

  let maxNumber = 0
  controlTargetList.forEach((controlObject) => {
    // TODO: Id 체계에 따라 수정할 부분
    const controlId = controlObject.id
    const splitControlId = controlId.split('-')
    const controlPartFromControlId = _.last(splitControlId)
    const stringNumber = controlPartFromControlId.slice(1)
    const intNumber = Number(stringNumber)
    if (intNumber > maxNumber) {
      maxNumber = intNumber
    }
  })
  const newIntNumber = maxNumber + 1
  const newStringNumber = newIntNumber < 10 ? '0' + String(newIntNumber) : String(newIntNumber)
  const newControlPart = 'C' + newStringNumber

  return newControlPart
}

const controlFindChangePathFromCreate = async (controlId, cycleId, processId, riskId) => {
  /*
  CONTROL 을 생성할 때 생기는 연결된 path 들의 변경사항을 모두 찾는다.
  CYCLE
  PROCESS
  RISK

  @param: controlId: String - CONTROL id to be created
  @param: cycleId: String - CYCLE id
  @return: Object{
    cycleUpdateObject, processUpdateObject, riskUpdateObject
    생긴건 updateObjectForm 참조
  }
  */

  // CYCLE 업데이트 객체
  const cycleUpdateObject = {}
  cycleUpdateObject[cycleId] = _.cloneDeep(updateObjectForm)
  cycleUpdateObject[cycleId].controlPathArray.add = [controlId]

  // PROCESS 업데이트 객체
  const processUpdateObject = {}
  processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
  processUpdateObject[processId].controlPathArray.add = [controlId]

  // RISK 업데이트 객체
  const riskUpdateObject = {}
  riskUpdateObject[riskId] = _.cloneDeep(updateObjectForm)
  riskUpdateObject[riskId].controlPathArray.add = [controlId]

  return { cycleUpdateObject, processUpdateObject, riskUpdateObject }
}

export const controlCreate = async (controlObject) => {
  /*
  새로운 CONTROL 을 만든다.

  기존에 CONTROL 을 생성할 때 CONTROL 은 CYCLE 에 연결되어져서 만들어졌다.
  하지만 이제는 생성되는 CONTROL 은 RISK 에 귀속되기 때문에
  CYCLE-PROCESS-RISK 까지 연결되어져서 만들어진다.
  이제 RISK id 가 추가로 필요하다

  아래 항목들은 최초 생성 시에는 비어있다.
  추후 맵핑에서 해결
  담당자(owner, staffPathArray)
  담당부서(department, departmentPathArray)

  CONTROL 생성 시에 연결되는 CYCLE 정보를 모두 넣는다.(cycle, category, subCategory)

  속하는 CYCLE 의 controlPathArray 에 CONTROL 을 추가한다.
  속하는 PROCESS 의 controlPathArray 에 CONTROL 을 추가한다.
  속하는 RISK 의 controlPathArray 에 CONTROL 을 추가한다.

  @param: Object{
    riskId: String - "ELC-01-01-01-R01",
    controlNumber: String - "C02",

    name: "대금지급 검토 및 승인",
    narrative: "일반 지출결제 있을시 지급 리스트는 재무회계팀장, 재무기획실장, 재무관리실장이 집행 전 검토 및 승인함.",

    goal: "대금지급은 결제 전에 독립된 자로부터 검토되어야 한다.",
    goal_Trust: "No",
    goal_Override: "No",
    goal_Operation: "No",
    goal_Law: "No",
    goal_Asset: "Yes",

    accountName: "체선료",
    accountCode: "421007",

    autoManual: "Manual",

    assertion_EO: "No",
    assertion_RO: "Yes",
    assertion_Occur: "No",
    assertion_C: "No",
    assertion_Assessment: "No",
    assertion_Measurement: "No",
    assertion_PD: "No",

    reportFootnotes: "매출채권 및 기타채권",
    residualRiskLevel: "L",
    controlRiskLevel: "L",
    preventDetective: "예방",
    policy: "전산자산관리규정",
    period: "Daily",

    incharge: "어쩌고@저쩌고.com", - 통제평가자
    teamLeader: "새로운 팀리더", - UI 에서 입력을 받아야할듯
    teamMember: "새로운 팀멤버", - UI 에서 입력을 받아야할듯
    keyControl: "No",
    ITDependencySystem: "N/A",

    MRC_YN: "Yes",
    MRC_Number: "MRC_Number 스트링",
    MRC_IPE_Name: "MRC_IPE_Name 스트링",

    IPE_YN: "No",
    IPE_Number: "IPE_Number 스트링",
    IPE_Name: "IPE_Name 스트링",

    TOC_TestProperties: "윤리규정, 윤리강령에 고객에 대한 직무윤리, 임직원의 직무윤리, 정보와 자산에 대한 보호 및 관리, 거래업체 / 경쟁사 /주주에 대한 직무윤리, 재무보고 윤리 등의 포함 여부",
    TOC_Procedure: "1. 회사의 윤리규정, 윤리강령을 입수한다.2. 윤리규정, 윤리강령에 고객에 대한 직무윤리, 임직원의 직무윤리, 정보와 자산에 대한 보호 및 관리, 거래업체 / 경쟁사 /주주에 대한 직무윤리, 재무보고 윤리 등이 포함되어있는지 확인한다.",
    TOC_PopulationCount: "1",
    TOC_Population: "N/A",
    TOC_Exception: "윤리규정, 윤리강령이 존재하지 않거나, 테스트절차에서 명시한 주요 내용 등이 누락된 경우",
    TOC_Evidence: "윤리강령(또는 윤리규정)",
    TOC_TestMethod_Reperformance: "No",
    TOC_TestMethod_Observation: "No",
    TOC_TestMethod_InquiryInspection: "Yes",
    TOC_Completeness: "새로운 TOC_Completeness",
  }
  */

  const riskId = controlObject.riskId
  // TODO: Id 체계에 따라 수정할 부분
  const splitRiskId = riskId.split('-')
  const controlNumber = controlObject.controlNumber
  const controlId = riskId + '-' + controlNumber
  const cycleId = _.join(_.slice(splitRiskId, 0, 3), '-')
  const processId = _.join(_.slice(splitRiskId, 0, 4), '-')

  const cycleData = await cycleGetWithId(cycleId)
  const riskData = await riskGetWithId(riskId)

  const targetCollectionName = 'CONTROL'
  const targetDocId = controlId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const updateResult = await controlFindChangePathFromCreate(controlId, cycleId, processId, riskId)

  return await docRef
    .set({
      id: controlId,
      uid: controlId,
      number: controlId,

      name: controlObject.name,
      narrative: controlObject.narrative,

      goal: controlObject.goal,
      goal_Trust: controlObject.goal_Trust,
      goal_Override: controlObject.goal_Override,
      goal_Operation: controlObject.goal_Operation,
      goal_Law: controlObject.goal_Law,
      goal_Asset: controlObject.goal_Asset,

      accountName: controlObject.accountName,
      accountCode: controlObject.accountCode,

      autoManual: controlObject.autoManual,

      assertion_EO: controlObject.assertion_EO,
      assertion_RO: controlObject.assertion_RO,
      assertion_Occur: controlObject.assertion_Occur,
      assertion_C: controlObject.assertion_C,
      assertion_Assessment: controlObject.assertion_Assessment,
      assertion_Measurement: controlObject.assertion_Measurement,
      assertion_PD: controlObject.assertion_PD,

      reportFootnotes: controlObject.reportFootnotes,
      residualRiskLevel: controlObject.residualRiskLevel,
      controlRiskLevel: controlObject.controlRiskLevel,
      preventDetective: controlObject.preventDetective,
      policy: controlObject.policy,
      period: controlObject.period,

      incharge: controlObject.incharge, // 변경점(20211026) - 통제평가자
      teamLeader: controlObject.teamLeader,
      teamMember: controlObject.teamMember,
      owner: '',
      department: '',
      keyControl: controlObject.keyControl,
      ITDependencySystem: controlObject.ITDependencySystem,

      MRC_YN: controlObject.MRC_YN,
      MRC_Number: controlObject.MRC_Number,
      MRC_IPE_Name: controlObject.MRC_IPE_Name,

      IPE_YN: controlObject.IPE_YN,
      IPE_Number: controlObject.IPE_Number,
      IPE_Name: controlObject.IPE_Name,

      TOC_TestProperties: controlObject.TOC_TestProperties,
      TOC_Procedure: controlObject.TOC_Procedure,
      TOC_PopulationCount: controlObject.TOC_PopulationCount,
      TOC_Population: controlObject.TOC_Population,
      TOC_Exception: controlObject.TOC_Exception,
      TOC_Evidence: controlObject.TOC_Evidence,
      TOC_TestMethod_Reperformance: controlObject.TOC_TestMethod_Reperformance,
      TOC_TestMethod_Observation: controlObject.TOC_TestMethod_Observation,
      TOC_TestMethod_InquiryInspection: controlObject.TOC_TestMethod_InquiryInspection,
      TOC_Completeness: controlObject.TOC_Completeness,

      processCycleNumber: cycleData.cycleNumber,
      processCycleName: cycleData.cycleName,
      processCategoryNumber: cycleData.categoryNumber,
      processCategoryName: cycleData.categoryName,
      processSubCategoryNumber: cycleData.subCategoryNumber,
      processSubCategoryName: cycleData.subCategoryName,

      riskNumber: riskId,
      riskName: riskData.name,
      riskInherentRiskLevel: riskData.inherentRiskLevel,

      selfPath: 'CONTROL/' + controlId,
      staffPathArray: [],
      departmentPathArray: [],
      cyclePathArray: ['CYCLE/' + cycleId],
      processPathArray: ['PROCESS/' + processId],
      processMappingCode: [processId],
      riskPathArray: ['RISK/' + riskId]
    })
    .then(() => {
      cycleListUpdatePathArray(updateResult.cycleUpdateObject)
      processListUpdatePathArray(updateResult.processUpdateObject)
      riskListUpdatePathArray(updateResult.riskUpdateObject)
    })
    .then(() => backupSetFlag())
}

const controlFindChangePathFromDelete = async (controlId) => {
  /*
  CONTROL 를 삭제할 때 생기는 연결된 path 들의 변경사항을 모두 찾는다.
  CYCLE, PROCESS, RISK, STAFF, DEPARTMENT

  @param: controlId: String - CONTROL id to be deleted
  @return: Object{
    cycleUpdateObject, processUpdateObject, riskUpdateObject, staffUpdateObject, departmentUpdateObject
    생긴건 updateObjectForm 참조
  }
  */

  const controlData = await controlGetWithId(controlId)
  const cycleId = controlData.cyclePathArray[0].split('/')[1] // 연결된 cycle 은 무조건 한개다
  const processIdList = controlData.processPathArray.map((processPath) => processPath.split('/')[1])
  const riskIdList = controlData.riskPathArray.map((riskPath) => riskPath.split('/')[1])
  const staffId = controlData.owner
  const departmentId = controlData.department

  // CYCLE 업데이트 객체
  const cycleUpdateObject = {}
  cycleUpdateObject[cycleId] = _.cloneDeep(updateObjectForm)
  cycleUpdateObject[cycleId].controlPathArray.del = [controlId]

  // PROCESS 업데이트 객체
  const processUpdateObject = {}
  processIdList.forEach((processId) => {
    processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
    processUpdateObject[processId].controlPathArray.del = [controlId]
  })

  // RISK 업데이트 객체
  const riskUpdateObject = {}
  riskIdList.forEach((riskId) => {
    riskUpdateObject[riskId] = _.cloneDeep(updateObjectForm)
    riskUpdateObject[riskId].controlPathArray.del = [controlId]
  })

  // 해당 CONTROL 이 STAFF, DEPARTMENT 가 연결이 안 된 상태일 수도 있다.
  // STAFF 업데이트 객체
  const staffUpdateObject = {}
  if (staffId !== '') {
    staffUpdateObject[staffId] = _.cloneDeep(updateObjectForm)
    staffUpdateObject[staffId].controlPathArray.del = [controlId]
  } else {
    staffUpdateObject[staffId] = _.cloneDeep(updateObjectForm)
  }

  // DEPARTMENT 업데이트 객체
  const departmentUpdateObject = {}
  if (departmentId !== '') {
    departmentUpdateObject[departmentId] = _.cloneDeep(updateObjectForm)
    departmentUpdateObject[departmentId].controlPathArray.del = [controlId]
  } else {
    departmentUpdateObject[departmentId] = _.cloneDeep(updateObjectForm)
  }

  return {
    cycleUpdateObject,
    processUpdateObject,
    riskUpdateObject,
    staffUpdateObject,
    departmentUpdateObject
  }
}

export const controlDelete = async (controlId) => {
  /*
  CONTROL 을 삭제한다
  연결된 CYCLE 의 controlPathArray 에서 삭제한다
  연결된 PROCESS 의 controlPathArray 에서 삭제한다
  연결된 RISK 의 controlPathArray 에서 삭제한다
  연결된 STAFF 의 controlPathArray 에서 삭제한다
  연결된 DEPARTMENT 의 controlPathArray 에서 삭제한다

  @param: controlId: String - "ELC-01-02-01-R01-C01"
  */

  const targetCollectionName = 'CONTROL'
  const targetDocId = controlId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const updateResult = await controlFindChangePathFromDelete(controlId)

  return await docRef
    .delete()
    .then(() => {
      cycleListUpdatePathArray(updateResult.cycleUpdateObject)
      processListUpdatePathArray(updateResult.processUpdateObject)
      riskListUpdatePathArray(updateResult.riskUpdateObject)
      staffListUpdatePathArray(updateResult.staffUpdateObject)
      departmentListUpdatePathArray(updateResult.departmentUpdateObject)
    })
    .then(() => backupSetFlag())
}
