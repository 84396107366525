import _ from 'lodash'

import { evaluationUpdateTemplate } from '../Evaluation/evaluation'
import { backupSetFlag } from '../Utils/backup'
import { updateObjectForm } from '../Utils/updateObjectForm'
import { dbService } from '../fbase'
import { controlGetAll, controlGetWithId, controlListUpdatePathArray } from './control'
import { departmentListUpdatePathArray } from './department'
import { processListUpdatePathArray } from './process'
import { riskGetWithId, riskListUpdatePathArray } from './risk'
import { staffGetWithId, staffListUpdatePathArray } from './staff'

const riskFindChangePathFromMappigWithProcessList = async (riskId, processIdList) => {
  /*
  RISK 에 PROCESS List 를 맵핑한다.

  추가되는 PROCESS
  현재 RISK 의 processPathArray => 여기선 처리 X
  현재 RISK 와 연결된 CONTROL 의 processPathArray, processMappingCode
  PROCESS 의 riskPathArray, controlPathArray

  제거되는 PROCESS
  현재 RISK 의 processPathArray => 여기선 처리 X
  현재 RISK 와 연결된 CONTROL 의 processPathArray, processMappingCode
  PROCESS 의 riskPathArray, controlPathArray

  @param: riskId: String - 연결할 RISK id
  @param: processIdList: String[] - 해당 RISK 와 연결될 최종 PROCESS id List
  */

  const riskData = await riskGetWithId(riskId)
  const originalProcessIdList = riskData.processPathArray.map(
    (processPath) => processPath.split('/')[1]
  )
  const controlIdList = riskData.controlPathArray.map((controlPath) => controlPath.split('/')[1])

  // PROCESS 업데이트 객체
  const processUpdateObject = {}
  // CONTROL 업데이트 객체
  const controlUpdateObject = {}

  // 제거될, 추가될 PROCESS id list 를 구한다
  const addProcessIdList = _.difference(processIdList, originalProcessIdList)
  const delProcessIdList = _.difference(originalProcessIdList, processIdList)

  // 추가될 PROCESS list
  addProcessIdList.forEach((processId) => {
    controlIdList.forEach((controlId) => {
      // 현재 RISK 에 연결되어 있는 CONTROL 의 processPathArray
      if (controlId in controlUpdateObject) {
        controlUpdateObject[controlId].processPathArray.add.push(processId)
      } else {
        controlUpdateObject[controlId] = _.cloneDeep(updateObjectForm)
        controlUpdateObject[controlId].processPathArray.add.push(processId)
      }

      // 추가될 PROCESS 의 controlPathArray
      if (processId in processUpdateObject) {
        processUpdateObject[processId].controlPathArray.add.push(controlId)
      } else {
        processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
        processUpdateObject[processId].controlPathArray.add.push(controlId)
      }
    })

    // 추가될 PROCESS 의 riskPathArray
    if (processId in processUpdateObject) {
      processUpdateObject[processId].riskPathArray.add.push(riskId)
    } else {
      processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
      processUpdateObject[processId].riskPathArray.add.push(riskId)
    }
  })

  // 제거될 PROCESS list
  delProcessIdList.forEach((processId) => {
    controlIdList.forEach((controlId) => {
      // 현재 RISK 에 연결되어 있는 CONTROL 의 processPathArray
      if (controlId in controlUpdateObject) {
        controlUpdateObject[controlId].processPathArray.del.push(processId)
      } else {
        controlUpdateObject[controlId] = _.cloneDeep(updateObjectForm)
        controlUpdateObject[controlId].processPathArray.del.push(processId)
      }

      // 제거될 PROCESS 의 controlPathArray
      if (processId in processUpdateObject) {
        processUpdateObject[processId].controlPathArray.del.push(controlId)
      } else {
        processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
        processUpdateObject[processId].controlPathArray.del.push(controlId)
      }
    })

    // 제거될 PROCESS 의 riskPathArray
    if (processId in processUpdateObject) {
      processUpdateObject[processId].riskPathArray.del.push(riskId)
    } else {
      processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
      processUpdateObject[processId].riskPathArray.del.push(riskId)
    }
  })

  return { processUpdateObject, controlUpdateObject }
}

export const riskMappingWithProcessList = async (riskId, processIdList) => {
  /*
  RISK 에 PROCESS List 를 맵핑한다.

  추가되는 PROCESS
  현재 RISK 의 processPathArray
  현재 RISK 와 연결된 CONTROL 의 processPathArray, processMappingCode
  PROCESS 의 controlPathArray

  제거되는 PROCESS
  현재 RISK 의 processPathArray
  현재 RISK 와 연결된 CONTROL 의 processPathArray, processMappingCode
  PROCESS 의 controlPathArray

  @param: riskId: String - 연결할 RISK id
  @param: processIdList: String[] - 해당 RISK 와 연결될 최종 PROCESS id List
  */

  const updateResult = await riskFindChangePathFromMappigWithProcessList(riskId, processIdList)

  const targetCollectionName = 'RISK'
  const targetDocId = riskId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef
    .update({
      processPathArray: processIdList.map((processId) => 'PROCESS/' + processId)
    })
    .then(() => processListUpdatePathArray(updateResult.processUpdateObject))
    .then(() => controlListUpdatePathArray(updateResult.controlUpdateObject))
    .then(() => backupSetFlag())
}

const riskFindChangePathFromMappingWithControlList = async (riskId, controlIdList) => {
  /*
  RISK 에 CONTROL List 를 맵핑한다.
  controlMappingWithRisk 와 다른 점은 하나의 RISK 는 여러개의 CONTROL 을 가질 수 있다. List

  추가되는 CONTROL
  CONTROL 의 riskPathArray, riskNumber, riskName, processPathArray, processMappingCode
  RISK 의 controlPathArray 변경 => 여기선 처리 X
  RISK 와 연결되어 있는 PROCESS 들이 controlPathArray 변경
  CONTROL 이 기존에 연결되어 있던 RISK 가 있었다면 해당 CONTROL 의 riskPathArray, 해당 RISK 의 controlPathArray 그리고 그 RISK 와 연결되어 있는 PROCESS 들의 controlPathArray

  제거되는 CONTROL
  CONTROL 의 riskPathArray, riskNumber, riskName, processPathArray, processMappingCode
  RISK 의 controlPathArray 변경 => 여기선 처리 X
  RISK 와 연결되어 있던 PROCESS 들의 controlPathArray

  @param: riskId: String - 연결할 RISK id
  @param: controlIdList: String[] - 해당 RISK 에 최종적으로 연결될 CONTROL id list
  */

  const riskData = await riskGetWithId(riskId)
  const originalControlIdList = riskData.controlPathArray.map(
    (controlPath) => controlPath.split('/')[1]
  )

  // PROCESS 업데이트 객체
  const processUpdateObject = {}
  // RISK 업데이트 객체
  const riskUpdateObject = {}
  // CONTROL 업데이트 객체
  const controlUpdateObject = {}

  // 제거될, 추가될 CONTROL id list 를 구한다
  const addControlIdList = _.difference(controlIdList, originalControlIdList)
  const delControlIdList = _.difference(originalControlIdList, controlIdList)

  const controlDataAll = await controlGetAll()

  // 추가될 CONTROL list
  addControlIdList.forEach((controlId) => {
    const controlData = _.find(controlDataAll, { id: controlId })
    // 연결되어 있던 RISK 가 있는지 체크
    const prevRiskId = controlData.riskNumber
    if (prevRiskId !== '') {
      // 연결되어 있던 RISK 가 있음
      if (controlId in controlUpdateObject) {
        controlUpdateObject[controlId].riskPathArray.del.push(prevRiskId)
      } else {
        controlUpdateObject[controlId] = _.cloneDeep(updateObjectForm)
        controlUpdateObject[controlId].riskPathArray.del.push(prevRiskId)
      }

      if (prevRiskId in riskUpdateObject) {
        riskUpdateObject[prevRiskId].controlPathArray.del.push(controlId)
      } else {
        riskUpdateObject[prevRiskId] = _.cloneDeep(updateObjectForm)
        riskUpdateObject[prevRiskId].controlPathArray.del.push(controlId)
      }

      const prevProcessIdList = controlData.processMappingCode
      prevProcessIdList.forEach((processId) => {
        if (processId in processUpdateObject) {
          processUpdateObject[processId].controlPathArray.del.push(controlId)
        } else {
          processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
          processUpdateObject[processId].controlPathArray.del.push(controlId)
        }
      })
    }

    // 새로 연결될 CONTROL
    if (controlId in controlUpdateObject) {
      controlUpdateObject[controlId].riskPathArray.add.push(riskId)
    } else {
      controlUpdateObject[controlId] = _.cloneDeep(updateObjectForm)
      controlUpdateObject[controlId].riskPathArray.add.push(riskId)
    }

    // 현재 연결될 RISK 는 필요없음. Main 에서 처리

    // 새로 연결될 PROCESS
    const processIdList = riskData.processPathArray.map((processPath) => processPath.split('/')[1])
    processIdList.forEach((processId) => {
      if (processId in processUpdateObject) {
        processUpdateObject[processId].controlPathArray.add.push(controlId)
      } else {
        processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
        processUpdateObject[processId].controlPathArray.add.push(controlId)
      }
    })
  })

  // 제거될 CONTROL list
  delControlIdList.forEach((controlId) => {
    const controlData = _.find(controlDataAll, { id: controlId })

    // 제거될 CONTROL
    if (controlId in controlUpdateObject) {
      controlUpdateObject[controlId].riskPathArray.del.push(riskId)
    } else {
      controlUpdateObject[controlId] = _.cloneDeep(updateObjectForm)
      controlUpdateObject[controlId].riskPathArray.del.push(riskId)
    }

    // PROCESS
    const processIdList = riskData.processPathArray.map((processPath) => processPath.split('/')[1])
    processIdList.forEach((processId) => {
      if (processId in processUpdateObject) {
        processUpdateObject[processId].controlPathArray.del.push(controlId)
      } else {
        processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
        processUpdateObject[processId].controlPathArray.del.push(controlId)
      }
    })
  })

  return { processUpdateObject, riskUpdateObject, controlUpdateObject }
}

export const riskMappingWithControlList = async (riskId, controlIdList) => {
  /*
  RISK 에 CONTROL List 를 맵핑한다.
  controlMappingWithRisk 와 다른 점은 하나의 RISK 는 여러개의 CONTROL 을 가질 수 있다. List

  추가되는 CONTROL
  controlMappingWithRisk 로 생각
  RISK 의 controlPathArray 변경

  제거되는 CONTROL
  controlMappingWithRisk 에서 빈 riskId 로 생각
  RISK 의 controlPathArray 변경

  @param: riskId: String - 연결할 RISK id
  @param: controlIdList: String[] - 해당 RISK 에 최종적으로 연결될 CONTROL id list
  */

  const updateResult = await riskFindChangePathFromMappingWithControlList(riskId, controlIdList)

  const targetCollectionName = 'RISK'
  const targetDocId = riskId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef
    .update({
      controlPathArray: controlIdList.map((controlId) => 'CONTROL/' + controlId)
    })
    .then(() => processListUpdatePathArray(updateResult.processUpdateObject))
    .then(() => riskListUpdatePathArray(updateResult.riskUpdateObject))
    .then(() => controlListUpdatePathArray(updateResult.controlUpdateObject))
    .then(() => backupSetFlag())
}

const controlFindChangePathFromMappingWithRisk = async (controlId, riskId) => {
  /*
  CONTROL - RISK 맵핑을 변경할 때 생기는 path 변경사항을 모두 찾는다.

  CONTROL 은 단 하나의 RISK 만 가질 수 있다.(이건 사전에 가정을 해놓은거임)
  연결된 하나의 RISK 에 대해서

  CONTROL 의 riskPathArray, riskNumber, riskName 변경
  새로 연결될 RISK 에 연결된 PROCESS 정보를 CONTROL 의 processPathArray, processMappingCode 변경

  새로 연결될 RISK 에 대해서
  controlPathArray 변경
  RISK 에 연결된 PROCESS 들에 대해서 controlPathArray 변경

  기존에 연결된 RISK 가 있었다면
  이전 RISK 의 controlPathArray 변경
  이전 RISK 와 연결된 PROCESS 의 controlPathArray 변경
  CONTROL 에서 이전 RISK 와 연결된 PROCESS 에 대한 정보를 processPathArray, processMappingCode 변경
  */

  const controlData = await controlGetWithId(controlId)
  let riskData
  let newProcessIdList
  if (riskId !== '') {
    riskData = await riskGetWithId(riskId)
    newProcessIdList = riskData.processPathArray.map((processPath) => processPath.split('/')[1])
  }
  const prevRiskId = controlData.riskNumber
  const prevProcessIdList = controlData.processMappingCode

  // RISK 업데이트 객체
  const riskUpdateObject = {}
  if (riskId !== '') {
    riskUpdateObject[riskId] = _.cloneDeep(updateObjectForm)
    riskUpdateObject[riskId].controlPathArray.add = [controlId]
  }
  if (prevRiskId !== '') {
    // 이전에 연결된 RISK 가 있다면
    riskUpdateObject[prevRiskId] = _.cloneDeep(updateObjectForm)
    riskUpdateObject[prevRiskId].controlPathArray.del = [controlId]
  }

  // PROCESS 업데이트 객체
  const processUpdateObject = {}
  if (riskId !== '') {
    newProcessIdList.forEach((processId) => {
      processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
      processUpdateObject[processId].controlPathArray.add = [controlId]
    })
  }
  if (prevProcessIdList.length > 0) {
    // 이전에 연결된 RISK 가 있다면
    prevProcessIdList.forEach((processId) => {
      if (processId in processUpdateObject) {
        // if(!(processUpdateObject[processId]["controlPathArray"]["add"].includes(controlId))) {
        processUpdateObject[processId].controlPathArray.del.push(controlId)
        // }
      } else {
        processUpdateObject[processId] = _.cloneDeep(updateObjectForm)
        processUpdateObject[processId].controlPathArray.del = [controlId]
      }
    })
  }

  return { processUpdateObject, riskUpdateObject }
}

export const controlMappingWithRisk = async (controlId, riskId) => {
  /*
  CONTROL 과 RISK 를 맵핑한다.

  CONTROL 은 단 하나의 RISK 만 가질 수 있다.(이건 사전에 가정을 해놓은거임)
  연결된 하나의 RISK 에 대해서

  CONTROL 의 riskPathArray, riskNumber, riskName 변경
  새로 연결될 RISK 에 연결된 PROCESS 정보를 CONTROL 의 processPathArray, processMappingCode 변경

  기존에 연결된 RISK 가 있었다면
  이전 RISK 의 controlPathArray 변경
  이전 RISK 와 연결된 PROCESS 의 controlPathArray 변경
  CONTROL 에서 이전 RISK 와 연결된 PROCESS 에 대한 정보를 processPathArray, processMappingCode 변경

  @param: controlId: String - 연결할 CONTROL id
  @param: riskId: String - 해당 CONTROL id 와 연결된 RISK id(빈 스트링일 수도 있음)
  */

  let riskNumber = ''
  let riskName = ''
  let riskPathArray = []
  let processPathArray = [] // CONTROL 은 PROCESS 와 연결될 수 있는 이유는 연결된 RISK 때문이다.
  let processMappingCode = [] // CONTROL 은 PROCESS 와 연결될 수 있는 이유는 연결된 RISK 때문이다.

  // riskId 가 빈 스트링이면 단순 연결 해제

  if (riskId !== '') {
    const riskData = await riskGetWithId(riskId)
    riskNumber = riskData.id
    riskName = riskData.name
    riskPathArray = ['RISK/' + riskData.id]
    processPathArray = riskData.processPathArray
    processMappingCode = processPathArray.map((processPath) => processPath.split('/')[1])
  }

  const updateResult = await controlFindChangePathFromMappingWithRisk(controlId, riskId)

  const targetCollectionName = 'CONTROL'
  const targetDocId = controlId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  return await docRef
    .update({
      riskNumber,
      riskName,
      riskPathArray,
      processPathArray,
      processMappingCode
    })
    .then(() => processListUpdatePathArray(updateResult.processUpdateObject))
    .then(() => riskListUpdatePathArray(updateResult.riskUpdateObject))
    .then(() => backupSetFlag())
}

const controlFindChangePathFromMappingWithStaff = async (controlId, staffId) => {
  /*
  CONTROL - STAFF 맵핑을 변경할 때 생기는 path 변경사항을 모두 찾는다.

  기존 연결 STAFF 가 있는지 체크가 필요
  STAFF 의 controlPathArray 변경
  DEPARTMENT 의 controlPathArray 변경
  */

  const controlData = await controlGetWithId(controlId)
  const staffData = await staffGetWithId(staffId)
  const departmentId = staffData.departmentName

  const prevStaffId = controlData.owner
  const prevDepartmentId = controlData.department

  // STAFF 업데이트 객체
  const staffUpdateObject = {}
  staffUpdateObject[staffId] = _.cloneDeep(updateObjectForm)
  staffUpdateObject[staffId].controlPathArray.add = [controlId]
  if (prevStaffId !== '') {
    staffUpdateObject[prevStaffId] = _.cloneDeep(updateObjectForm)
    staffUpdateObject[prevStaffId].controlPathArray.del = [controlId]
  }

  // DEPARTMENT 업데이트 객체
  const departmentUpdateObject = {}
  departmentUpdateObject[departmentId] = _.cloneDeep(updateObjectForm)
  departmentUpdateObject[departmentId].controlPathArray.add = [controlId]
  if (prevDepartmentId !== '' && prevDepartmentId !== departmentId) {
    departmentUpdateObject[prevDepartmentId] = _.cloneDeep(updateObjectForm)
    departmentUpdateObject[prevDepartmentId].controlPathArray.del = [controlId]
  }

  return { staffUpdateObject, departmentUpdateObject }
}

export const controlMappingWithStaff = async (controlId, staffId) => {
  /*
  CONTROL 과 STAFF 를 맵핑한다.

  기존의 각종 pathArray 와 이제 아무 상관 없음
  무조건 CONTROL 안의 owner(staffPathArray), department(departmentPathArray) 로 연결됨

  기존 연결 STAFF 가 있는지 체크가 필요
  CONTROL 의 owner, staffPathArray, department, departmentPathArray 변경
  STAFF 의 controlPathArray 변경
  DEPARTMENT 의 controlPathArray 변경

  TODO: 설계평가에 영향

  @param: controlId: String - 연결할 CONTROL id
  @param: staffId: String - 해당 CONTROL id 와 연결된 STAFF id
  */

  const staffData = await staffGetWithId(staffId)
  const departmentId = staffData.departmentName

  const targetCollectionName = 'CONTROL'
  const targetDocId = controlId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const controlData = await controlGetWithId(controlId)

  const updateResult = await controlFindChangePathFromMappingWithStaff(controlId, staffId)

  return await docRef
    .update({
      owner: staffId,
      staffPathArray: ['STAFF/' + staffId],
      department: departmentId,
      departmentPathArray: ['DEPARTMENT/' + departmentId]
    })
    .then(() => {
      // TODO: 평가보고서
      // CONTROL - STAFF 맵핑을 하면 해당 내용을 바탕으로 Template 을 업데이트 해야한다
      controlData.owner = staffId
      controlData.department = departmentId

      evaluationUpdateTemplate('OE', controlId, controlData)
      evaluationUpdateTemplate('DE', controlId, controlData)
      staffListUpdatePathArray(updateResult.staffUpdateObject)
      departmentListUpdatePathArray(updateResult.departmentUpdateObject)
    })
    .then(() => backupSetFlag())
}

const staffFindChangePathFromMappingWithDepartment = async (staffId, departmentId) => {
  /*
  STAFF - DEPARTMENT 맵핑을 변경할 때 생기는 path 변경사항을 모두 찾는다.

  새로운 DEPARTMENT 의 staffPathArray, controlPathArray 변경
  이전 DEPARTMENT 의 staffPathArray, controlPathArray 변경
  CONTROL 의 department(departmentPathArray) 변경
  */

  const staffData = await staffGetWithId(staffId)
  const controlIdList = staffData.controlPathArray.map((controlPath) => controlPath.split('/')[1])
  const prevDepartmentId = staffData.departmentName

  // DEPARTMENT 업데이트 객체
  const departmentUpdateObject = {}
  departmentUpdateObject[departmentId] = _.cloneDeep(updateObjectForm)
  departmentUpdateObject[departmentId].staffPathArray.add = [staffId]
  departmentUpdateObject[departmentId].controlPathArray.add = controlIdList

  departmentUpdateObject[prevDepartmentId] = _.cloneDeep(updateObjectForm)
  departmentUpdateObject[prevDepartmentId].staffPathArray.del = [staffId]
  departmentUpdateObject[prevDepartmentId].controlPathArray.del = controlIdList

  // CONTROL 업데이트 객체
  const controlUpdateObject = {}
  controlIdList.forEach((controlId) => {
    controlUpdateObject[controlId] = _.cloneDeep(updateObjectForm)
    controlUpdateObject[controlId].departmentPathArray.add = [departmentId]
    controlUpdateObject[controlId].departmentPathArray.del = [prevDepartmentId]
  })

  return { departmentUpdateObject, controlUpdateObject }
}

export const staffMappingWithDepartment = async (staffId, departmentId) => {
  /*
  STAFF 와 DEPARTMENT 를 맵핑한다.
  STAFF 의 소속 DEPARTMENT 를 변경한다고 생각하면됨

  STAFF 의 departmentName, departmentPathArray 변경
  DEPARTMENT 의 staffPathArray, controlPathArray 변경
  CONTROL 의 department, departmentPathArray 변경

  @param: controlId: String - 연결할 CONTROL id
  @param: staffId: String - 해당 CONTROL id 와 연결된 STAFF id
  */

  const targetCollectionName = 'STAFF'
  const targetDocId = staffId
  const docRef = dbService.collection(targetCollectionName).doc(targetDocId)

  const updateResult = await staffFindChangePathFromMappingWithDepartment(staffId, departmentId)

  return await docRef
    .update({
      departmentName: departmentId,
      departmentPathArray: ['DEPARTMENT/' + departmentId]
    })
    .then(() => {
      departmentListUpdatePathArray(updateResult.departmentUpdateObject)
      controlListUpdatePathArray(updateResult.controlUpdateObject)
    })
    .then(() => backupSetFlag())
}

export const controlListMappingWithAdmin = async (staffId, adminId) => {
  /*
  staffId 에 속한 컨트롤 목록들을 관리자(ADMIN) 에게 일괄 이관
  adminId 은 ADMIN 인 STAFF 의 id

  이전 STAFF 의 controlPathArray
  ADMIN 의 controlPathArray
  이전 DEPARTMENT 의 controlPathArray
  각 CONTROL 의 owner, staffPathArray, department, departmentPathArray

  @param: staffId: string - CONTROL 을 넘겨줄 id
  @param: adminId: string - CONTROL 을 넘겨 받을 ADMIN id
  */

  const adminData = await staffGetWithId(adminId)
  const adminDepartmentId = adminData.departmentName

  const staffData = await staffGetWithId(staffId)
  const departmentId = staffData.departmentName
  const controlPathArray = staffData.controlPathArray
  const controlIdList = controlPathArray.map((controlPath) => controlPath.split('/')[1])

  return Promise.all(
    controlIdList.map(async (controlId) => {
      return await dbService
        .collection('CONTROL')
        .doc(controlId)
        .update({
          owner: adminId,
          staffPathArray: ['STAFF/' + adminId],
          department: adminDepartmentId,
          departmentPathArray: ['DEPARTMENT/' + adminDepartmentId]
        })
    })
  )
    .then(() => {
      dbService.collection('STAFF').doc(staffId)
.update({
        controlPathArray: []
      })
      dbService.collection('STAFF').doc(adminId)
.update({
        controlPathArray
      })

      const departmentUpdateObject = {}
      departmentUpdateObject[departmentId] = _.cloneDeep(updateObjectForm)
      departmentUpdateObject[departmentId].controlPathArray.del = controlIdList
      departmentUpdateObject[adminDepartmentId] = _.cloneDeep(updateObjectForm)
      departmentUpdateObject[adminDepartmentId].controlPathArray.add = controlIdList
      departmentListUpdatePathArray(departmentUpdateObject)
    })
    .then(() => backupSetFlag())
}
