/* eslint-disable camelcase */
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import { Col, Row } from 'antd/lib/grid'
import List from 'antd/lib/list'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { lightGrey } from '../../../base/color'
import { parseDateStr } from '../../../base/utils/TimeUtils'
import {
  createRCM,
  downloadRCM_Excel,
  getRCM_VersionList
} from '../../../dataLoader/RCM/RCM_Manage'
import LoadingButton from '../../common/LoadingButton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: 'white',
    border: lightGrey.border
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  list: {
    marginTop: theme.spacing(1)
  }
}))

const RCMVersionView: React.FC = () => {
  const classes = useStyles()
  const [versions, setVersions] = React.useState<string[]>([])

  React.useEffect(() => {
    getRCM_VersionList().then((result) => setVersions(_.reverse(result)))
  }, [])

  // const download = () => {}

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography.Title level={3}>RCM 버전 관리</Typography.Title>
        <LoadingButton
          type="primary"
          onClick={() =>
            createRCM().then(() =>
              getRCM_VersionList().then((result) => setVersions(_.reverse(result)))
            )
          }
        >
          현재 RCM으로 새로운 버전 생성
        </LoadingButton>
      </div>
      <List
        header={
          <Row style={{ alignItems: 'center' }}>
            <Col span={18}>
              <Typography.Title level={5}>이름</Typography.Title>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>날짜</Typography.Title>
            </Col>
            <Col span={2}>
              <Typography.Title level={5} style={{ paddingLeft: 15 }}>
                다운로드
              </Typography.Title>
            </Col>
          </Row>
        }
        bordered
        className={classes.list}
        dataSource={versions}
        renderItem={(item: string) => {
          const dateStr = _.first(_.split(item, '-')) || ''
          return (
            <List.Item>
              <Row style={{ flex: 1, alignItems: 'center' }}>
                <Col span={18}>
                  <Typography.Text>{item}</Typography.Text>
                </Col>
                <Col span={4}>
                  <Typography.Text>{parseDateStr('YYYYMMDD', dateStr)}</Typography.Text>
                </Col>
                <Col span={2}>
                  <Typography.Text>
                    <Button type="link" key="download" onClick={() => downloadRCM_Excel(item)}>
                      다운로드
                    </Button>
                  </Typography.Text>
                </Col>
              </Row>
            </List.Item>
          )
        }}
      />
    </div>
  )
}

export default RCMVersionView
