import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import Form, { Rule } from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import { Control, RiskLevel, YesNo } from '../../../base/data/PRC'
import { Staff } from '../../../base/data/Staff'
import { getKoName } from '../../../base/data/Translation'
import {
  controlUpdate,
  controlFindNewNumberToCreate,
  controlCreate
} from '../../../dataLoader/RCM/control'
import { controlMappingWithStaff } from '../../../dataLoader/RCM/mapping'
import { processFindNewNumberToCreate, processCreate } from '../../../dataLoader/RCM/process'
import { riskCreate, riskFindNewNumberToCreate } from '../../../dataLoader/RCM/risk'
import TableItem from '../../common/TableItem'
import ControlEditView from './ControlEditView'
import { Cycle } from './RCMEditorUtils'

const useStyle = makeStyles((theme: Theme) => ({
  form: {
    border: grey.border,
    height: 'inherit',
    overflowY: 'auto'
  },
  select: {
    maxWidth: 330
  },
  formText: {
    marginBottom: 0,
    width: '100%',
    '& .ant-form-item-control-input': {
      minHeight: 0
    }
  },
  formSelect: {
    maxWidth: 400,
    flexGrow: 1,
    marginBottom: 0
  }
}))

function GetTri(type: 'process' | 'risk' | 'control', process: any, risk: any, control: any): any {
  if (type === 'process') return process
  if (type === 'risk') return risk
  return control
}

function renderOptions(data: Record<string, string>): JSX.Element[] {
  // console.log('render options', data)
  const dataList: { id: string; number: string }[] = []
  _.forEach(data, (id, number) => {
    dataList.push({ id, number })
  })
  if (_.every(dataList, (element) => !_.isNaN(_.parseInt(element.id)))) {
    dataList.sort((a, b) => _.parseInt(a.id) - _.parseInt(b.id))
  }

  return _.map(dataList, ({ id, number }) => {
    return (
      <Select.Option key={id} value={number}>
        {id}
      </Select.Option>
    )
  })
}

function getRequiredMessage(type: string, nameStr?: string): string {
  let typeStr = type
  if (type === 'name' && nameStr) typeStr = nameStr
  if (type === 'narrative') typeStr = '설명'
  if (type === 'RoMM') typeStr = getKoName('risk', type)

  if (_.includes(['cycle', 'category', 'subCategory'], type)) {
    return `${typeStr}를 선택해주세요.`
  }
  return `${typeStr}을 입력해주세요.`
}

interface SelectItemProps {
  disabled?: boolean
  name: string
  dataKey: string
  required?: boolean
  options: Record<string, string>
  rules?: Rule[]
  noDivider?: boolean
}

const SelectItem: React.FC<SelectItemProps> = ({
  disabled,
  name,
  dataKey,
  required,
  options,
  rules,
  noDivider
}) => {
  const classes = useStyle()
  return (
    <TableItem name={name} required={required} noDivider={noDivider}>
      <Form.Item className={classes.formSelect} name={dataKey} rules={rules}>
        <Select className={classes.select} disabled={disabled} size="small">
          {renderOptions(options)}
        </Select>
      </Form.Item>
    </TableItem>
  )
}

interface TextItemProps {
  name: string
  dataKey: string
  rules?: Rule[]
  noDivider?: boolean
  required?: boolean
  multiline?: boolean
}

const TextItem: React.FC<TextItemProps> = ({
  name,
  dataKey,
  rules,
  noDivider,
  required,
  multiline
}) => {
  const classes = useStyle()
  return (
    <TableItem name={name} noDivider={noDivider} required={required}>
      <Form.Item className={classes.formText} name={dataKey} rules={rules}>
        {multiline ? <Input.TextArea autoSize /> : <Input />}
      </Form.Item>
    </TableItem>
  )
}

async function createProcess(process: any): Promise<void> {
  const processNumber = await processFindNewNumberToCreate(
    process.cycleNumber,
    process.categoryNumber,
    process.subCategoryNumber
  )
  return processCreate({ ...process, processNumber })
}

async function createRisk(risk: any): Promise<void> {
  const riskNumber = await riskFindNewNumberToCreate(risk.processId)
  return riskCreate({ ...risk, riskNumber })
}

function setEmptyStringIfUndefined(data: any, key: string): void {
  if (!_.has(data, key) || data[key] === undefined) {
    data[key] = ''
  }
}

function convertCheckData(data: any, key: string, subKeys: string[]): void {
  const newKey = `${key}_`
  _.forEach(subKeys, (subKey) => {
    _.set(data, `${newKey}${subKey}`, _.indexOf(data[newKey], subKey) !== -1 ? 'Yes' : 'No')
  })
  return data
}

async function createOrUpdateControl(control: any, update: boolean): Promise<void | any> {
  // NOTE: name, goal, TOC_Completeness은 미래컴퍼니에서 사용하지 않지만 서버에는 존재
  setEmptyStringIfUndefined(control, 'name')
  setEmptyStringIfUndefined(control, 'goal')
  setEmptyStringIfUndefined(control, 'TOC_Completeness')

  setEmptyStringIfUndefined(control, 'residualRiskLevel')
  setEmptyStringIfUndefined(control, 'controlRiskLevel')
  setEmptyStringIfUndefined(control, 'policy')
  setEmptyStringIfUndefined(control, 'ITDependencySystem')
  setEmptyStringIfUndefined(control, 'MRC_YN')
  setEmptyStringIfUndefined(control, 'MRC_Number')
  setEmptyStringIfUndefined(control, 'MRC_IPE_Name')
  setEmptyStringIfUndefined(control, 'IPE_YN')
  setEmptyStringIfUndefined(control, 'IPE_Number')
  setEmptyStringIfUndefined(control, 'IPE_Name')
  setEmptyStringIfUndefined(control, 'department')
  setEmptyStringIfUndefined(control, 'owner')
  setEmptyStringIfUndefined(control, 'teamLeader')
  setEmptyStringIfUndefined(control, 'teamMember')
  setEmptyStringIfUndefined(control, 'keyControl')
  convertCheckData(control, 'goal', ['Operation', 'Trust', 'Asset', 'Override', 'Law'])
  setEmptyStringIfUndefined(control, 'accountCode')
  setEmptyStringIfUndefined(control, 'accountName')
  setEmptyStringIfUndefined(control, 'reportFootnotes')
  convertCheckData(control, 'assertion', [
    'EO',
    'C',
    'RO',
    'PD',
    'Occur',
    'Assessment',
    'Measurement'
  ])
  setEmptyStringIfUndefined(control, 'period')
  setEmptyStringIfUndefined(control, 'preventDetective')
  setEmptyStringIfUndefined(control, 'autoManual')
  setEmptyStringIfUndefined(control, 'TOC_Procedure')
  setEmptyStringIfUndefined(control, 'TOC_Evidence')
  setEmptyStringIfUndefined(control, 'TOC_Population')
  setEmptyStringIfUndefined(control, 'TOC_PopulationCount')
  setEmptyStringIfUndefined(control, 'TOC_TestProperties')
  setEmptyStringIfUndefined(control, 'TOC_Exception')
  convertCheckData(control, 'TOC_TestMethod', ['InquiryInspection', 'Observation', 'Reperformance'])

  if (!update) {
    const itemNumber = await controlFindNewNumberToCreate(control.riskId)
    control.controlNumber = itemNumber
    await controlCreate(control)
    const controlId = `${control.riskId}-${itemNumber}`
    return controlMappingWithStaff(controlId, control.owner)
  } else {
    await controlUpdate(control)
    return controlMappingWithStaff(control.id, control.owner)
  }
}

function generateCycleOptions(options: Record<string, string>): Record<string, string> {
  return _.reduce(
    options,
    (result, name, number) => {
      _.set(result, number, `${number}. ${name}`)
      return result
    },
    {} as Record<string, string>
  )
}

function generateOptions(options: string[]): Record<string, string> {
  return _.reduce(
    options,
    (result, value) => {
      _.set(result, value, value)
      return result
    },
    {} as Record<string, string>
  )
}

function prepareCheckTypes(control: any, key: string, subKeys: string[]): void {
  const checked: string[] = []
  _.forEach(subKeys, (subKey) => {
    const value = _.get(control, `${key}_${subKey}`)
    if (value === YesNo.yes) {
      checked.push(subKey)
    }
  })
  _.set(control, `${key}_`, checked)
}

interface Props {
  type: 'process' | 'risk' | 'control'
  koType: string
  cycles: Cycle[]
  dataSource: any[]
  visible: boolean
  sizes: { width: number | null; height: number | null }
  editingControl?: Control
  staff?: Staff[]
  onCancel: (update: boolean) => void
  getReferenceIds?: (cycle: string, category: string, subCategory: string) => Record<string, string>
  getReferenceNames?: (
    cycle: string,
    category: string,
    subCategory: string
  ) => Record<string, string>
}

const PRCCreationForm: React.FC<Props> = ({
  type,
  koType,
  cycles,
  dataSource,
  visible,
  sizes,
  editingControl,
  staff,
  onCancel,
  getReferenceIds,
  getReferenceNames
}) => {
  const classes = useStyle()
  const [form] = Form.useForm()
  const [states, setStates] = React.useState({
    loading: false,
    cycles: {} as Record<string, string>,
    categories: {} as Record<string, Record<string, string>>,
    subCategories: {} as Record<string, Record<string, string>>
  })
  const [data, setData] = React.useState({
    cycle: '',
    category: '',
    subCategory: ''
  })
  const [itemId, setItemId] = React.useState('')
  const [riskName, setRiskName] = React.useState('')
  const [selectedStaff, setSelectedStaff] = React.useState({} as Staff)

  React.useEffect(() => {
    const newCycles: Record<string, string> = {}
    const newCategories: Record<string, Record<string, string>> = {}
    const newSubCategories: Record<string, Record<string, string>> = {}
    _.forEach(cycles, (cycle) => {
      newCycles[cycle.id] = cycle.name
      newCategories[cycle.id] = {}
      _.forEach(cycle.children, (category) => {
        newCategories[cycle.id][category.id] = category.name
        const subCategoryPath = `${cycle.id}-${category.id}`
        newSubCategories[subCategoryPath] = {}
        _.forEach(category.children, (subCategory) => {
          newSubCategories[subCategoryPath][subCategory.id] = subCategory.name
        })
      })
    })

    setStates({
      ...states,
      cycles: newCycles,
      categories: newCategories,
      subCategories: newSubCategories
    })
  }, [cycles])

  React.useEffect(() => {
    if (editingControl) {
      const newValues: Record<string, any> = _.cloneDeep(editingControl)
      _.forEach(editingControl, (value, key) => {
        if (typeof value === 'object') {
          const newSubValues: string[] = []
          _.forEach(value, (subValue, subKey) => {
            if (subValue === 'Yes' || (typeof subValue === 'boolean' && subValue)) {
              newSubValues.push(subKey)
            }
          })
          newValues[key] = newSubValues
        }
      })
      setData({
        ...data,
        cycle: newValues.cycleNumber,
        category: newValues.categoryNumber,
        subCategory: newValues.subCategoryNumber
      })

      const foundStaff = _.find(staff, { id: editingControl.owner })
      if (foundStaff) {
        setSelectedStaff(foundStaff)
      } else {
        setSelectedStaff({} as Staff)
      }

      const {
        cycleNumber: cycle,
        categoryNumber: category,
        subCategoryNumber: subCategory,
        ...rest
      } = newValues

      prepareCheckTypes(rest, 'goal', ['Operation', 'Trust', 'Asset', 'Override', 'Law'])
      prepareCheckTypes(rest, 'assertion', [
        'EO',
        'C',
        'RO',
        'PD',
        'Occur',
        'Assessment',
        'Measurement'
      ])
      prepareCheckTypes(rest, 'TOC_TestMethod', [
        'InquiryInspection',
        'Observation',
        'Reperformance'
      ])

      setRiskName(rest.riskName)
      form.setFieldsValue({
        cycle,
        category,
        subCategory,
        riskId: _.first(editingControl.riskIds) || '',
        ...rest
      })
    }
  }, [editingControl])

  const initForm = React.useCallback((): void => {
    setData({
      ...data,
      cycle: '',
      category: '',
      subCategory: ''
    })
    setItemId('')
    setSelectedStaff({} as Staff)
  }, [data])

  const handleCancel = React.useCallback((): void => {
    onCancel(false)
    form.resetFields()
    initForm()
  }, [form, data])

  const handleOk = React.useCallback((): void => {
    setStates({ ...states, loading: true })
    form
      .validateFields()
      .then(() => {
        const newData = form.getFieldsValue()
        const newItem = {
          cycleNumber: newData.cycle,
          cycleName: states.cycles[newData.cycle],
          categoryNumber: newData.category,
          categoryName: states.categories[newData.cycle][newData.category],
          subCategoryNumber: newData.subCategory,
          subCategoryName:
            states.subCategories[`${newData.cycle}-${newData.category}`][newData.subCategory]
        }
        if (type === 'process') {
          return createProcess({
            ...newItem,
            processName: newData.name || '',
            processNarrative: newData.narrative
          })
        } else if (type === 'risk') {
          return createRisk({
            ...newItem,
            ...newData
          })
        } else {
          const { cycle, ...rest } = newData
          return createOrUpdateControl(
            {
              ...newItem,
              ...rest
            },
            !_.isEmpty(editingControl)
          )
        }
      })
      .then(() => {
        onCancel(true)
        form.resetFields()
        initForm()
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log('Failed to create', e)
      })
      .finally(() => setStates({ ...states, loading: false }))
  }, [states, data, form])

  const names = React.useMemo(() => _.map(dataSource, (item) => item.name.trim()), [dataSource])

  const selectValidator = React.useCallback(
    (key: string): ((rule: any, value: any) => Promise<void>) => {
      return (rule: any, value: any): Promise<void> => {
        if (_.isEmpty(value)) {
          return Promise.reject(new Error(`${key} 선택해주세요.`))
        }
        return Promise.resolve()
      }
    },
    []
  )

  const onFormChange = React.useCallback(
    (formName, info) => {
      let name = info.changedFields?.[0]?.name
      if (_.isArray(name) && !_.isEmpty(name)) {
        name = name[0] as string
      } else {
        name = name as string
      }
      const value = form.getFieldValue(name)
      if (name === 'cycle' && data.cycle !== value) {
        form.resetFields(['category', 'subCategory', 'processId', 'riskId'])
        setData({
          ...data,
          cycle: value,
          category: '',
          subCategory: ''
        })
        setItemId('')
      } else if (name === 'category' && data.category !== value) {
        form.resetFields(['subCategory', 'processId', 'riskId'])
        setData({
          ...data,
          category: value,
          subCategory: ''
        })
        setItemId('')
      } else if (name === 'subCategory' && data.subCategory !== value) {
        form.resetFields(['processId', 'riskId'])
        setData({
          ...data,
          subCategory: value
        })
        if (type === 'process') {
          processFindNewNumberToCreate(data.cycle, data.category, value).then((number) => {
            setItemId(`${data.cycle}-${data.category}-${value}-${number}`)
          })
        } else {
          setItemId('')
        }
      } else if (name === 'processId') {
        // for risk
        const processId = form.getFieldValue('processId')
        riskFindNewNumberToCreate(processId).then((number) => setItemId(`${processId}-${number}`))
      } else if (name === 'riskId') {
        // for control
        const riskId = form.getFieldValue('riskId')
        if (getReferenceNames) {
          const ids = getReferenceNames(data.cycle, data.category, data.subCategory)
          setRiskName(_.get(ids, riskId) || '')
        }
        controlFindNewNumberToCreate(riskId).then((number) => setItemId(`${riskId}-${number}`))
      } else if (name === 'owner') {
        const staffId = form.getFieldValue('owner')
        const foundStaff = _.find(staff, { id: staffId })
        if (foundStaff) {
          setSelectedStaff(foundStaff)
        }
      }
    },
    [form, data]
  )

  return (
    <Modal
      centered
      bodyStyle={{
        height: type === 'control' && sizes.height ? sizes.height * 0.7 : undefined,
        padding: 0,
        margin: 12
      }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          취소
        </Button>,
        <Button key="submit" loading={states.loading} type="primary" onClick={handleOk}>
          저장
        </Button>
      ]}
      title={editingControl ? `${koType} 수정` : `${koType} 추가`}
      visible={visible}
      width={type === 'control' && sizes.width ? sizes.width * 0.8 : undefined}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form.Provider onFormChange={onFormChange}>
        <Form className={classes.form} form={form} layout="horizontal" name="PRCCreation">
          <SelectItem
            required
            dataKey="cycle"
            disabled={editingControl !== undefined}
            name="대분류"
            options={generateCycleOptions(states.cycles)}
            rules={[() => ({ validator: selectValidator('대분류를') })]}
          />
          <SelectItem
            required
            dataKey="category"
            disabled={_.isEmpty(data.cycle) || editingControl !== undefined}
            name="중분류"
            options={generateCycleOptions(states.categories?.[data.cycle])}
            rules={[() => ({ validator: selectValidator('중분류를') })]}
          />
          <SelectItem
            required
            dataKey="subCategory"
            disabled={
              _.isEmpty(data.cycle) || _.isEmpty(data.category) || editingControl !== undefined
            }
            name="소분류"
            options={generateCycleOptions(states.subCategories?.[data.cycle + '-' + data.category])}
            rules={[() => ({ validator: selectValidator('소분류를') })]}
          />
          {type === 'risk' && (
            <SelectItem
              required
              dataKey="processId"
              disabled={
                _.isEmpty(data.cycle) || _.isEmpty(data.category) || _.isEmpty(data.subCategory)
              }
              name="프로세스 아이디"
              options={
                (getReferenceIds && getReferenceIds(data.cycle, data.category, data.subCategory)) ||
                {}
              }
              rules={[() => ({ validator: selectValidator('프로세스 아이디를') })]}
            />
          )}
          {type === 'control' && (
            <>
              <SelectItem
                required
                dataKey="riskId"
                disabled={
                  _.isEmpty(data.cycle) ||
                  _.isEmpty(data.category) ||
                  _.isEmpty(data.subCategory) ||
                  editingControl !== undefined
                }
                name={getKoName('risk', 'id')}
                options={
                  (getReferenceIds &&
                    getReferenceIds(data.cycle, data.category, data.subCategory)) ||
                  {}
                }
                rules={[() => ({ validator: selectValidator(`${getKoName('risk', 'id')}를`) })]}
              />
              <TableItem name={getKoName('risk', 'name')}>{riskName}</TableItem>
            </>
          )}
          <TableItem
            minHeight={40}
            name={GetTri(type, '코드', getKoName('risk', 'id'), getKoName('control', 'id'))}
          >
            <Form.Item
              className={classes.formText}
              dependencies={['cycle', 'category', 'subCategory', 'processId', 'riskId']}
              name="id"
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule: any, value: any): Promise<void | any> | void {
                    if (_.isEmpty(getFieldValue('cycle'))) {
                      return Promise.reject(new Error('대분류를 선택해주세요.'))
                    } else if (_.isEmpty(getFieldValue('category'))) {
                      return Promise.reject(new Error('중분류를 선택해주세요.'))
                    } else if (_.isEmpty(getFieldValue('subCategory'))) {
                      return Promise.reject(new Error('소분류를 선택해주세요.'))
                    } else if (type === 'risk' && _.isEmpty(getFieldValue('processId'))) {
                      return Promise.reject(new Error('프로세스 아이디를 선택해주세요.'))
                    } else if (type === 'control' && _.isEmpty(getFieldValue('riskId'))) {
                      return Promise.reject(new Error(`${getKoName('risk', 'id')}를 선택해주세요.`))
                    }
                    return Promise.resolve()
                  }
                })
              ]}
            >
              <Typography>{editingControl ? editingControl.id : itemId}</Typography>
            </Form.Item>
          </TableItem>
          {
            // NOTE: 미래컴퍼니에서는 risk만 이름 존재
            type === 'risk' && (
              <TextItem
                required
                dataKey="name"
                name={GetTri(type, '이름', '이름', '통제활동명')}
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('name', GetTri(type, '이름', '이름', '통제활동명'))
                  },
                  () => ({
                    validator(rule: any, value: any): Promise<void> {
                      if (editingControl?.name.trim() === value?.trim()) {
                        return Promise.resolve()
                      }
                      if (!_.isEmpty(value) && names) {
                        if (_.includes(names, value.trim())) {
                          return Promise.reject(
                            new Error(
                              `이미 존재하는 ${GetTri(type, '이름', '이름', '통제활동명')}입니다.`
                            )
                          )
                        }
                      }
                      return Promise.resolve()
                    }
                  })
                ]}
              />
            )
          }
          {type === 'process' && (
            <TextItem
              multiline
              noDivider
              required
              dataKey="narrative"
              name="설명"
              rules={[{ required: true, message: getRequiredMessage('narrative') }]}
            />
          )}
          {type === 'risk' && (
            <>
              <TextItem
                required
                dataKey="RoMM"
                name={getKoName('risk', 'RoMM')}
                rules={[{ required: true, message: getRequiredMessage('RoMM') }]}
              />
              <SelectItem
                noDivider
                required
                dataKey="inherentRiskLevel"
                name={getKoName('risk', 'inherentRiskLevel')}
                options={generateOptions([RiskLevel.low, RiskLevel.moderate, RiskLevel.high])}
                rules={[
                  () => ({
                    validator: selectValidator(`${getKoName('risk', 'inherentRiskLevel')}을`)
                  })
                ]}
              />
            </>
          )}
          {type === 'control' && <ControlEditView selectedStaff={selectedStaff} staffs={staff} />}
        </Form>
      </Form.Provider>
    </Modal>
  )
}

export default PRCCreationForm
