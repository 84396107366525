import { AppMenu, MenuName } from './AppMenu'

export enum UserLevel {
  ADMIN = 'ADMIN',
  NORMAL = 'NORMAL',
  UPLOADER = 'UPLOADER',
  WATCHER = 'WATCHER',
  BLOCKED = 'BLOCKED'
}

const watcherMenus: AppMenu[] = [
  {
    name: MenuName.Dashboard
  },
  {
    name: MenuName.RCM,
    children: [{ name: MenuName.RCMViewer }]
  },
  {
    name: MenuName.OperationEvaluation,
    children: [
      { name: MenuName.OperationEvaluationPopulationSampling },
      { name: MenuName.OperationEvaluationManagement },
      { name: MenuName.OperationEvaluationDefect }
    ]
  },
  {
    name: MenuName.DesignEvaluation,
    children: [
      { name: MenuName.DesignEvaluationManagement },
      { name: MenuName.DesignEvaluationModification }
    ]
  },
  {
    name: MenuName.ResultReport
  }
]

const uploaderMenus: AppMenu[] = [
  {
    name: MenuName.Dashboard
  },
  {
    name: MenuName.RCM,
    children: [{ name: MenuName.RCMViewer }]
  },
  {
    name: MenuName.OperationEvaluation,
    children: [
      { name: MenuName.OperationEvaluationFileManagement },
      { name: MenuName.OperationEvaluationPopulationSampling },
      { name: MenuName.OperationEvaluationEvidence }
    ]
  },
  {
    name: MenuName.DesignEvaluation,
    children: [
      { name: MenuName.DesignEvaluationFileManagement },
      { name: MenuName.DesignEvaluationWritingReport }
    ]
  }
]

const normalMenus: AppMenu[] = [
  {
    name: MenuName.Dashboard
  },
  {
    name: MenuName.RCM,
    children: [{ name: MenuName.RCMViewer }]
  },
  {
    name: MenuName.OperationEvaluation,
    children: [
      { name: MenuName.OperationEvaluationFileManagement },
      { name: MenuName.OperationEvaluationPopulationSampling },
      { name: MenuName.OperationEvaluationEvidence },
      { name: MenuName.OperationEvaluationWritingReport }
    ]
  },
  {
    name: MenuName.DesignEvaluation,
    children: [
      { name: MenuName.DesignEvaluationFileManagement },
      { name: MenuName.DesignEvaluationWritingReport }
    ]
  }
]

const adminMenus: AppMenu[] = [
  {
    name: MenuName.Dashboard
  },
  {
    name: MenuName.RCM,
    children: [
      { name: MenuName.RCMViewer },
      { name: MenuName.RCMEditor },
      { name: MenuName.RCMVersion }
    ]
  },
  {
    name: MenuName.OperationEvaluation,
    children: [
      { name: MenuName.OperationEvaluationCreation },
      { name: MenuName.OperationEvaluationFileManagement },
      { name: MenuName.OperationEvaluationPopulationSampling },
      { name: MenuName.OperationEvaluationEvidence },
      { name: MenuName.OperationEvaluationWritingReport },
      { name: MenuName.OperationEvaluationManagement },
      { name: MenuName.OperationEvaluationDefect }
    ]
  },
  {
    name: MenuName.DesignEvaluation,
    children: [
      { name: MenuName.DesignEvaluationCreation },
      { name: MenuName.DesignEvaluationFileManagement },
      { name: MenuName.DesignEvaluationWritingReport },
      { name: MenuName.DesignEvaluationManagement },
      { name: MenuName.DesignEvaluationModification }
    ]
  },
  {
    name: MenuName.ResultReport
  },
  {
    name: MenuName.AdminPage
  },
  {
    name: MenuName.FileBox
  }
]

export function getUserMenu(userLevel: UserLevel): AppMenu[] {
  if (userLevel === UserLevel.ADMIN) {
    return adminMenus
  } else if (userLevel === UserLevel.NORMAL) {
    return normalMenus
  } else if (userLevel === UserLevel.UPLOADER) {
    return uploaderMenus
  } else if (userLevel === UserLevel.WATCHER) {
    return watcherMenus
  }
  return []
}
