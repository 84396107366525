import { blue } from '@ant-design/colors'
import { UploadOutlined } from '@ant-design/icons'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from 'antd/lib/typography'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: 36,
    marginBottom: theme.spacing(2)
  },
  emptyContainer: {
    margin: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dragActiveIcon: {
    color: blue.primary
  }
}))

interface Props {
  isDragActive: boolean
}

const UploadIconAndText: React.FC<Props> = ({ isDragActive }) => {
  const classes = useStyles()
  return (
    <div className={classes.emptyContainer}>
      <UploadOutlined className={clsx(classes.icon, { [classes.dragActiveIcon]: isDragActive })} />
      {isDragActive ? (
        <Typography.Text>파일 끌어 놓기</Typography.Text>
      ) : (
        <Typography.Text>업로드할 파일 선택 또는 파일 끌어 놓기</Typography.Text>
      )}
    </div>
  )
}

export default UploadIconAndText
