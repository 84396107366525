import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'

import { grey } from '../../../base/color'
import {
  AutoManual,
  Control,
  YesNo,
  Period,
  PreventDetective,
  RiskLevel
} from '../../../base/data/PRC'
import { renderStaff, renderStaffDepartment, Staff } from '../../../base/data/Staff'
import { getKoName } from '../../../base/data/Translation'
import TableHeader from '../../common/TableHeader'
import TableItem, { ComplexTableItem } from '../../common/TableItem'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: grey.border
  },
  margin: {
    margin: theme.spacing(2)
  }
}))

interface Props {
  control: Control
  noMargin?: boolean
  staffs: Staff[]
}

const ControlView: React.FC<Props> = ({ control, noMargin, staffs }) => {
  const classes = useStyles()
  const incharge = React.useMemo(() => _.find(staffs, { id: control?.incharge }), [control, staffs])
  const staff = React.useMemo(() => _.find(staffs, { id: control?.owner }), [control, staffs])

  // TODO(sangmuk): 회사별로 서로 다른 Control 속성을 적용할 수 있도록 변경
  return (
    <div className={clsx(classes.root, { [classes.margin]: !noMargin })}>
      <ComplexTableItem name={getKoName('control', 'id')} value={control.id} />
      <ComplexTableItem name={getKoName('control', 'narrative')} value={control.narrative} />
      <TableHeader value="규정 및 자원" />
      <ComplexTableItem name={getKoName('control', 'policy')} value={control.policy} />
      <ComplexTableItem
        name={getKoName('control', 'ITDependencySystem')}
        value={control.ITDependencySystem}
      />
      <TableHeader value="MRC" />
      <ComplexTableItem name={getKoName('control', 'MRC_YN')} value={control.MRC_YN} />
      <ComplexTableItem name={getKoName('control', 'MRC_Number')} value={control.MRC_Number} />
      <ComplexTableItem name={getKoName('control', 'MRC_IPE_Name')} value={control.MRC_IPE_Name} />
      <TableHeader value="IPE 통제" />
      <ComplexTableItem name={getKoName('control', 'IPE_YN')} value={control.IPE_YN} />
      <ComplexTableItem name={getKoName('control', 'IPE_Number')} value={control.IPE_Number} />
      <ComplexTableItem name={getKoName('control', 'IPE_Name')} value={control.IPE_Name} />
      <TableHeader value="통제 조직" />
      <ComplexTableItem name={getKoName('control', 'department')} value={control.department} />
      <ComplexTableItem name={getKoName('control', 'teamLeader')} value={control.teamLeader} />
      <ComplexTableItem name={getKoName('control', 'teamMember')} value={control.teamMember} />
      {incharge ? (
        <TableItem name={getKoName('control', 'incharge')}>{renderStaff(incharge)}</TableItem>
      ) : (
        <ComplexTableItem name={getKoName('control', 'incharge')} value={control.incharge} />
      )}
      {staff ? (
        <TableItem name={getKoName('control', 'owner')}>{renderStaff(staff)}</TableItem>
      ) : (
        <ComplexTableItem name={getKoName('control', 'owner')} value={control.owner} />
      )}
      <TableHeader value="기타 통제 속성" />
      <ComplexTableItem
        name={getKoName('control', 'keyControl')}
        value={control.keyControl}
        radioTypes={YesNo}
      />
      <ComplexTableItem
        checkTypes
        name={getKoName('control', 'goal')}
        value={{
          goal_Operation: control.goal_Operation,
          goal_Trust: control.goal_Trust,
          goal_Asset: control.goal_Asset,
          goal_Override: control.goal_Override,
          goal_Law: control.goal_Law
        }}
      />
      <ComplexTableItem name={getKoName('control', 'accountCode')} value={control.accountCode} />
      <ComplexTableItem name={getKoName('control', 'accountName')} value={control.accountName} />
      <ComplexTableItem
        name={getKoName('control', 'reportFootnotes')}
        value={control.reportFootnotes}
      />
      <ComplexTableItem
        checkTypes
        name={getKoName('control', 'assertion')}
        value={{
          assertion_EO: control.assertion_EO,
          assertion_C: control.assertion_C,
          assertion_RO: control.assertion_RO,
          assertion_PD: control.assertion_PD,
          assertion_Occur: control.assertion_Occur,
          assertion_Assessment: control.assertion_Assessment,
          assertion_Measurement: control.assertion_Measurement
        }}
      />
      <ComplexTableItem
        name={getKoName('control', 'period')}
        value={control.period}
        radioTypes={Period}
      />
      <ComplexTableItem
        name={getKoName('control', 'preventDetective')}
        value={control.preventDetective}
        radioTypes={PreventDetective}
      />
      <ComplexTableItem
        name={getKoName('control', 'autoManual')}
        value={control.autoManual}
        radioTypes={AutoManual}
      />
      <ComplexTableItem
        name={getKoName('control', 'residualRiskLevel')}
        value={control.residualRiskLevel}
        radioTypes={RiskLevel}
      />
      <TableHeader value="통제 운영 속성" />
      <ComplexTableItem
        name={getKoName('control', 'controlRiskLevel')}
        value={control.controlRiskLevel}
        radioTypes={RiskLevel}
      />
      <ComplexTableItem
        name={getKoName('control', 'TOC_Procedure')}
        value={control.TOC_Procedure}
      />
      <ComplexTableItem name={getKoName('control', 'TOC_Evidence')} value={control.TOC_Evidence} />
      <ComplexTableItem
        name={getKoName('control', 'TOC_Population')}
        value={control.TOC_Population}
      />
      <ComplexTableItem
        name={getKoName('control', 'TOC_PopulationCount')}
        value={control.TOC_PopulationCount}
      />
      <ComplexTableItem
        name={getKoName('control', 'TOC_TestProperties')}
        value={control.TOC_TestProperties}
      />
      <ComplexTableItem
        name={getKoName('control', 'TOC_Exception')}
        value={control.TOC_Exception}
      />
      <ComplexTableItem
        checkTypes
        name={getKoName('control', 'TOC_TestMethod')}
        value={{
          TOC_TestMethod_InquiryInspection: control.TOC_TestMethod_InquiryInspection,
          TOC_TestMethod_Observation: control.TOC_TestMethod_Observation,
          TOC_TestMethod_Reperformance: control.TOC_TestMethod_Reperformance
        }}
      />
    </div>
  )
}

export default ControlView
