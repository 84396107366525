import { AgGridEvent, ColumnApi, GridApi } from '@ag-grid-community/core'
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'

import { lightGrey } from '../../base/color'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    minHeight: 0
  },
  noBorder: {
    '& .ag-root-wrapper': {
      border: 0
    }
  },
  loading: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: lightGrey.backgroundOpacity
  }
}))

function InitializeGrid(
  gridApi: GridApi,
  gridColumnApi: ColumnApi,
  data: any,
  autoSizeColumns?: boolean
): void {
  gridApi.setRowData(data)
  gridApi.resetRowHeights()
  if (autoSizeColumns) {
    const allColumnIds: string[] = []
    _.forEach(gridColumnApi.getAllColumns(), (column) => {
      allColumnIds.push(column.getColId())
    })
    gridColumnApi.autoSizeColumns(allColumnIds)
  } else {
    gridApi.sizeColumnsToFit()
  }
}

export interface TableViewerHandler {
  forceRefresh: () => void
}

interface Props extends AgGridReactProps {
  autoSizeColumns?: boolean
  loading?: boolean
  noBorder?: boolean
  height?: number
  onRowClick?: (row: any) => void
}

const TableViewer = React.forwardRef<TableViewerHandler, Props>((props, viewerRef) => {
  const {
    autoSizeColumns,
    loading,
    noBorder,
    height,
    onRowClick,
    rowData,
    detailCellRendererParams,
    ...restProps
  } = props
  const classes = useStyles()
  const [gridApi, setGridApi] = React.useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = React.useState<ColumnApi>()
  const onGridReady = (params: AgGridEvent): void => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  React.useEffect(() => {
    if (gridApi && gridColumnApi) {
      InitializeGrid(gridApi, gridColumnApi, rowData, autoSizeColumns)
    }
  }, [rowData, gridApi, gridColumnApi])

  React.useImperativeHandle(viewerRef, () => ({
    forceRefresh() {
      if (gridApi) {
        gridApi.refreshCells({ force: true })
      }
    }
  }))

  const getDetailCellRendererParams = (): any => {
    if (detailCellRendererParams) {
      const newDetailParams = _.cloneDeep(detailCellRendererParams)
      _.forEach(newDetailParams?.detailGridOptions?.columnDefs, (column) => {
        if (column.cellRenderer) {
          column.cellRendererParams = {
            ...column.cellRendererParams,
            masterApi: gridApi
          }
        }
      })
      return newDetailParams
    }
    return undefined
  }

  return (
    <div className={clsx(classes.root, { [classes.noBorder]: noBorder })}>
      <div className="ag-theme-cck" style={{ height: height || '100%', position: 'relative' }}>
        <AgGridReact
          enableCellChangeFlash
          defaultColDef={{
            wrapText: true,
            autoHeight: true,
            resizable: true,
            lockPosition: true,
            filter: 'agSetColumnFilter',
            suppressSizeToFit: true,
            filterParams: {
              excelMode: 'windows' // can be 'windows' or 'mac'
            }
          }}
          detailCellRendererParams={getDetailCellRendererParams()}
          modules={AllModules}
          rowSelection={onRowClick && 'single'}
          onColumnResized={(params: any) => params.api.resetRowHeights()}
          onGridReady={onGridReady}
          onSelectionChanged={(event) => {
            const row = _.first(event.api.getSelectedRows())
            if (row && onRowClick) {
              onRowClick(row)
            }
          }}
          {...restProps}
        />
        <div className={classes.loading} style={{ visibility: loading ? 'visible' : 'hidden' }}>
          <CircularProgress />
        </div>
      </div>
    </div>
  )
})

TableViewer.displayName = 'TableViewer'
export default TableViewer
