import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/firestore'

const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID

const firebaseConfig = {
  projectId,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  storageBucket: `${projectId}.appspot.com`,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
}

firebase.initializeApp(firebaseConfig)

export default firebase

export const authService = firebase.auth()
export const dbService = firebase.firestore()
export const storageService = firebase.storage()
