import { AgGridEvent, ColDef, ColumnApi, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Modal } from 'antd'
import Button from 'antd/lib/button'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import React from 'react'

import { departmentGetAllIdList } from '../../dataLoader/RCM/department'
import {
  guestGetAll,
  adminRegisterStaffAsGuestFromExcel,
  guestDelete,
  guestUpgrade
} from '../../dataLoader/Utils/admin'
import AlertMessage, { AlertMessageHandler } from '../common/AlertMessage'
import CircleBackdrop from '../common/CircleBackdrop'
import LoadingButton from '../common/LoadingButton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  excelUploadButtons: {
    margin: theme.spacing(1),
    '& button': {
      marginRight: theme.spacing(1)
    }
  },
  buttons: {
    display: 'flex',
    flex: 1,
    margin: theme.spacing(1),
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(1)
    }
  },
  modal: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

interface GridProps {
  gridApi?: GridApi
  columnApi?: ColumnApi
}

interface Guest {
  id: string
  name: string
  department: string
  upgrade: boolean
}

function getColumns(departments: string[]): ColDef[] {
  return [
    {
      headerName: 'ID',
      field: 'id',
      checkboxSelection: true,
      flex: 1,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true
    },
    { headerName: '이름', field: 'name', flex: 1 },
    {
      headerName: '부서',
      field: 'department',
      flex: 1,
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: departments
      }
    }
  ]
}

const GuestManagement: React.FC<{ active: boolean }> = ({ active }) => {
  const classes = useStyles()
  const [gridApi, setGridApi] = React.useState<GridProps>()
  const [guests, setGuests] = React.useState([] as Guest[])
  const [states, setStates] = React.useState({
    type: 'approve' as 'approve' | 'delete',
    visible: false,
    loading: false
  })
  const [departments, setDepartments] = React.useState([] as string[])
  const alertRef = React.useRef<AlertMessageHandler>(null)
  const inputFile = React.useRef<HTMLInputElement>(null)

  const setNewGuests = (newGuests: Guest[], departmentNames: string[]): void => {
    _.forEach(newGuests, (guest) => {
      if (!_.isEmpty(guest.department) && !_.includes(departmentNames, guest.department)) {
        guest.department = ''
      }
    })
    setGuests(_.filter(newGuests, { upgrade: false }))
  }

  React.useEffect(() => {
    if (active) {
      Promise.all([guestGetAll(), departmentGetAllIdList()]).then((values) => {
        setNewGuests(values[0] as Guest[], values[1])
        setDepartments(values[1])
      })
    }
  }, [active])

  const getSelectedData = (): Guest[] => {
    const selectedData: Guest[] = []
    gridApi?.gridApi?.forEachNodeAfterFilter((row) => {
      if (row.isSelected()) {
        selectedData.push(row.data)
      }
    })
    return selectedData
  }

  const openApproveModal = (): void => {
    const selectedData = getSelectedData()
    if (_.isEmpty(selectedData)) {
      alertRef.current?.showAlert('error', '승인 대상을 먼저 선택해주세요.')
      return
    } else if (_.some(selectedData, (item) => _.isEmpty(item.department))) {
      alertRef.current?.showAlert('error', '부서를 먼저 지정해주세요.')
      return
    }

    setStates({
      ...states,
      type: 'approve',
      visible: true
    })
  }

  const openDeleteModal = (): void => {
    const selectedData = getSelectedData()
    if (_.isEmpty(selectedData)) {
      alertRef.current?.showAlert('error', '삭제 대상을 먼저 선택해주세요.')
      return
    }
    setStates({
      ...states,
      type: 'delete',
      visible: true
    })
  }

  const approveGuests = React.useCallback((): Promise<any> => {
    const upgradePromise: Promise<void>[] = []
    _.forEach(getSelectedData(), (item) => {
      upgradePromise.push(guestUpgrade(item))
    })
    return Promise.all(upgradePromise)
      .then(() => {
        alertRef.current?.showAlert('success', '사용자 승인이 완료되었습니다.')
      })
      .catch((e) => {
        console.log('Failed to upgrade guest', e)
        alertRef.current?.showAlert('error', '사용자 승인이 실패했습니다. 다시 시도해주세요.')
      })
      .finally(() => {
        return guestGetAll()
          .then((newGuests) => {
            setNewGuests(newGuests as Guest[], departments)
          })
          .finally(() => {
            setStates({
              ...states,
              visible: false
            })
          })
      })
  }, [departments])

  const deleteGuests = (): Promise<any> => {
    const deletePromise: Promise<void>[] = []
    _.forEach(getSelectedData(), (item) => {
      deletePromise.push(guestDelete(item.id))
    })
    return Promise.all(deletePromise)
      .then(() => {
        alertRef.current?.showAlert('success', '사용자 삭제가 완료되었습니다.')
      })
      .catch((e) => {
        console.log('Failed to delete guest', e)
        alertRef.current?.showAlert('error', '사용자 삭제가 실패했습니다. 다시 시도해주세요.')
      })
      .finally(() => {
        return guestGetAll()
          .then((newGuests) => {
            setNewGuests(newGuests as Guest[], departments)
          })
          .finally(() => {
            setStates({
              ...states,
              visible: false
            })
          })
      })
  }

  const clickUploadFile = React.useCallback((): void => {
    if (inputFile.current) {
      inputFile.current.click()
    }
  }, [inputFile])

  const setFile = (event: React.ChangeEvent): void => {
    const files = _.get(event.target, 'files')
    if (_.isEmpty(files)) {
      alertRef.current?.showAlert('error', '업로드할 파일을 선택해주세요.')
      return
    }

    setStates({
      ...states,
      loading: true
    })

    adminRegisterStaffAsGuestFromExcel(files[0])
      .then(() => {
        guestGetAll()
          .then((newGuests) => {
            setNewGuests(newGuests as Guest[], departments)
            alertRef.current?.showAlert('success', '게스트로 등록되었습니다.')
          })
          .finally(() => {
            setStates({ ...states, loading: false })
          })
      })
      .catch((e) => {
        setStates({ ...states, loading: false })
        alertRef.current?.showAlert('error', e.message)
      })
      .finally(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputFile.current.value = null
      })
  }

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <div className={classes.excelUploadButtons}>
          <input
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            type="file"
            onChange={setFile}
          />
          <Button onClick={clickUploadFile}>사용자 목록 업로드</Button>
          <Button>
            <a download="sample_guests.xlsx" href="/samples/sample_guests.xlsx">
              템플릿 파일 다운
            </a>
          </Button>
        </div>
        <div className={classes.buttons}>
          <Button ghost type="primary" onClick={openApproveModal}>
            승인
          </Button>
          <Button danger onClick={openDeleteModal}>
            삭제
          </Button>
        </div>
      </div>
      <div className="ag-theme-cck" style={{ height: '100%', position: 'relative' }}>
        <AgGridReact
          enableCellChangeFlash
          rowMultiSelectWithClick
          columnDefs={getColumns(departments)}
          defaultColDef={{
            wrapText: true,
            autoHeight: true,
            resizable: true,
            lockPosition: true,
            filter: 'agSetColumnFilter',
            suppressSizeToFit: true,
            filterParams: {
              excelMode: 'windows' // can be 'windows' or 'mac'
            }
          }}
          modules={AllModules}
          rowData={guests}
          rowSelection="multiple"
          onColumnResized={(params: any) => params.api.resetRowHeights()}
          onGridReady={(event: AgGridEvent): void => {
            setGridApi({
              gridApi: event.api,
              columnApi: event.columnApi
            })
          }}
        />
      </div>
      <Modal
        footer={[
          <Button key="back" onClick={() => setStates({ ...states, visible: false })}>
            취소
          </Button>,
          <LoadingButton
            key="submit"
            type="primary"
            onClick={states.type === 'approve' ? approveGuests : deleteGuests}
          >
            {states.type === 'approve' ? '승인' : '삭제'}
          </LoadingButton>
        ]}
        onCancel={() => setStates({ ...states, visible: false })}
        title={states.type === 'approve' ? '사용자 승인' : '사용자 삭제'}
        visible={states.visible}
      >
        <div className={classes.modal}>
          {_.map(getSelectedData(), (row) => {
            return (
              <Typography.Text key={row.id}>
                {row.name} ({row.id})
              </Typography.Text>
            )
          })}
        </div>
      </Modal>
      <CircleBackdrop open={states.loading} />
      <AlertMessage ref={alertRef} />
    </div>
  )
}

export default GuestManagement
