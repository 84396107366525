/* eslint-disable camelcase */
import _ from 'lodash'

import { Staff } from './Staff'

// eslint-disable-next-line no-shadow
export enum PRCType {
  process = 'process',
  risk = 'risk',
  control = 'control'
}

export interface BasePRC {
  type: PRCType
  id: string
  name: string
  cycleName: string
  categoryName: string
  subCategoryName: string
  cycleNumber: string
  categoryNumber: string
  subCategoryNumber: string
}

export interface Process extends BasePRC {
  narrative: string
  riskIds: string[]
  controlIds: string[]
}

export enum RiskLevel {
  low = 'L',
  moderate = 'M',
  high = 'H'
}

export interface Risk extends BasePRC {
  RoMM: string
  inherentRiskLevel: RiskLevel
  processIds: string[]
  controlIds: string[]
}

// period : 건별,일별수시,일별,주별,월별,분기별,반기별,연별,상시
export enum Period {
  byCase = '건별',
  dailyAnyTime = '일별수시',
  daily = '일별',
  weekly = '주별',
  monthly = '월별',
  quarterly = '분기별',
  halfBiAnnual = '반기별',
  annual = '연별',
  always = '상시'
}

export enum PreventDetective {
  prevent = '예방',
  detective = '적발'
}

export enum AutoManual {
  auto = '자동통제',
  manual = '수동통제',
  ITAC = 'ITAC'
}

export enum YesNo {
  yes = 'Yes',
  no = 'No'
}

export interface Control extends BasePRC {
  goal: string
  goal_Asset: YesNo
  goal_Law: YesNo
  goal_Operation: YesNo
  goal_Override: YesNo
  goal_Trust: YesNo
  narrative: string
  keyControl?: YesNo
  department: string
  incharge: string
  owner: string
  riskName: string
  reportFootnotes: string

  controlRiskLevel: string
  residualRiskLevel: RiskLevel
  policy: string
  teamLeader: string
  teamMember: string

  MRC_IPE_Name: string
  MRC_IPE_Number: string
  MRC_Number: string
  MRC_YN: YesNo
  IPE_Name: string
  IPE_Number: string
  IPE_YN: YesNo
  TOC_TestProperties: string
  TOC_TestMethod_InquiryInspection: YesNo
  TOC_TestMethod_Observation: YesNo
  TOC_TestMethod_Reperformance: YesNo
  TOC_SamplingCount?: string
  TOC_Procedure: string
  TOC_Population: string
  TOC_Exception: string
  TOC_EvidenceSize: string
  TOC_Evidence: string
  // assertion_A: YesNo
  assertion_C: YesNo
  // assertion_CO: YesNo
  assertion_EO: YesNo
  assertion_PD: YesNo
  assertion_RO: YesNo
  // assertion_VA: YesNo
  assertion_Occur: YesNo
  assertion_Assessment: YesNo
  assertion_Measurement: YesNo

  reviewControl: string
  period: Period
  preventDetective: PreventDetective
  autoManual: AutoManual
  accountName: string
  accountCode: string
  ITDependencySystem: string
  processIds: string[]
  riskIds: string[]
  staffIds: string[]
  staffs?: Staff[]
  riskData?: Risk[]

  // Below is deprecated
  TOC_PopulationCount?: string
  TOC_Period?: string
  TOC_Owner?: string
  TOC_Department?: string
  TOC_Completeness?: string
  ITDependency?: YesNo
  autoProperty?: {
    interface: boolean
    calculation: boolean
    autoControl: boolean
    restrictedAccess: boolean
    SGI: boolean
  }
  EUC?: string
}

export function isProcess(value: BasePRC): value is Process {
  return value.type === PRCType.process
}

export function isRisk(value: BasePRC): value is Risk {
  return value.type === PRCType.risk
}

export function isControl(value: BasePRC): value is Control {
  return value.type === PRCType.control
}

export function isProcessArray(value: BasePRC[]): value is Process[] {
  return _.every(value, (element) => element.type === PRCType.process)
}

export function isRiskArray(value: BasePRC[]): value is Risk[] {
  return _.every(value, (element) => element.type === PRCType.risk)
}

export function isControlArray(value: BasePRC[]): value is Control[] {
  return _.every(value, (element) => element.type === PRCType.control)
}
