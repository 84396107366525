import _ from 'lodash'

import {
  Evaluation,
  EvaluationSummary,
  OperationEvaluationData,
  TableContent
} from '../../base/data/Evaluation'
import { getKoName } from '../../base/data/Translation'
import { writeExcel } from '../../base/utils/ExcelUtils'
import { toDateRange } from '../../base/utils/TimeUtils'
import { getDefectSummary } from '../evaluation/common/DesignDefectUtils'
import { getResultStatus, getResultStatusColumns } from './ResultStatusUtils'

function convertTableContent(content: TableContent, split?: boolean): string[][] {
  const data: string[][] = []
  if (!split) {
    data.push(content.headerRow)
  } else {
    const firstHeaders: string[] = []
    const secondHeaders: string[] = []
    _.forEach(content.headerRow, (header) => {
      const splitHeader = _.split(header, '/')
      if (splitHeader.length > 1) {
        firstHeaders.push(splitHeader[0])
        secondHeaders.push(splitHeader[1])
      } else {
        firstHeaders.push(' ')
        secondHeaders.push(splitHeader[0])
      }
    })
    data.push(firstHeaders)
    data.push(secondHeaders)
  }

  _.forEach(content.rowObjectList, (row) => {
    data.push(_.map(content.headerRow, (header) => _.get(row, header, '')))
  })
  return data
}

function downloadOperationSummary(evaluations: Evaluation[], data: (string | number)[][]): void {
  const defectEvaluations: Evaluation[] = _.filter(evaluations, (evaluation) => {
    // 미비점이 존재할 경우
    return (
      evaluation.data.state.weakState === '3' ||
      evaluation.data.state.weakState === '4' ||
      evaluation.data.state.weakState === '5'
    )
  })

  _.forEach(defectEvaluations, (evaluation) => {
    const evalData = evaluation.data.content.evalData as OperationEvaluationData
    const weakStateTable = _(evaluation.data.state.weakStateData)
      .filter((weakState) => !_.isEmpty(_.get(weakState, 'Answer')))
      .map((weakState, key) => {
        return [
          key,
          _.get(weakState, 'Question', ''),
          _.get(weakState, 'Answer', ''),
          _.get(weakState, 'Reason', '')
        ]
      })
      .value()

    data.push(
      ...[
        [],
        [],
        [getKoName('control', 'id'), evaluation.controlId],
        [getKoName('control', 'narrative'), evaluation.data.content.controlData.narrative],
        [getKoName('control', 'populationDescription'), evalData.populationDescription],
        [getKoName('control', 'populationCompleteness'), evalData.populationDescription],
        [getKoName('control', 'samplingMethod'), evalData.samplingMethod],
        [getKoName('control', 'samplingCount'), evalData.samplingCount],
        [getKoName('control', 'samplingAttributeCount'), evalData.samplingAttributeCount],
        [],
        ['테스트 수행 기준'],
        ...convertTableContent(evalData.attributeContents),
        [],
        ['테스트 수행 내역'],
        ...convertTableContent(evalData.testContents, true),
        [],
        [getKoName('control', 'populationCount'), evalData.populationCount],
        [getKoName('control', 'samplingCount'), evalData.samplingCount],
        [getKoName('control', 'validCount'), evalData.validCount],
        [getKoName('control', 'invalidCount'), evalData.invalidCount],
        [getKoName('control', 'testResult'), evalData.testResult],
        [],
        ['미비점평가', '질의', '답변', '근거'],
        ...weakStateTable
      ]
    )
  })

  writeExcel('결과 요약', { 운영평가: data })
}

function downloadDesignSummary(evaluations: Evaluation[], data: (string | number)[][]) {
  const defectEvaluations: Evaluation[] = _.filter(evaluations, (evaluation) => {
    // 수정사항이 있는 사항
    return evaluation.data.state.weakState === '4'
  })

  _.forEach(defectEvaluations, (evaluation) => {
    const defectSummary = getDefectSummary(evaluation)

    data.push(
      ...[
        [],
        [],
        [getKoName('control', 'id'), evaluation.controlId],
        [getKoName('control', 'narrative'), evaluation.data.content.controlData.narrative],
        ['구분', '기존 통제 정보', '수정대상', '수정근거'],
        ..._.map(defectSummary, (row) => [row.category, row.key, row.content, row.evidence])
      ]
    )
  })
  writeExcel('결과 요약', { 설계평가: data })
}

export function downloadSummary(
  type: 'operation' | 'design',
  summary: EvaluationSummary,
  evaluations: Evaluation[]
): void {
  const resultStatusColumns = getResultStatusColumns(type)
  const data: (string | number)[][] = []
  data.push(
    ...[
      ['평가명', summary.evalName],
      ['평가대상기간', toDateRange(summary.startDate, summary.endDate)],
      [],
      _.map(resultStatusColumns, (column) => column.headerName || '')
    ]
  )

  const resultStatusList = getResultStatus(type, evaluations)
  _.forEach(resultStatusList, (status) => {
    data.push(_.map(resultStatusColumns, (column) => _(status).get(column.field || '', '')))
  })

  if (type === 'operation') {
    downloadOperationSummary(evaluations, data)
  } else {
    downloadDesignSummary(evaluations, data)
  }
}
