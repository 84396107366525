import firebase from '../../dataLoader/fbase'
import * as firebaseui from './esm__ko'

// This is our firebaseui configuration object
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.
  // signInSuccess function.
  signInSuccessUrl: '/web/dashboard',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
}

// This sets up firebaseui
const ui = new firebaseui.auth.AuthUI(firebase.auth())

// This adds firebaseui to the page
// It does everything else on its own
export const FirebaseLogin = function (elementId) {
  ui.start(elementId, uiConfig)
}
