import _ from 'lodash'
import React from 'react'

import { Evaluation } from '../../../base/data/Evaluation'
import MultipleTableItem from '../../common/MultipleTableItem'
import {
  DesignEvaluationColumnKeyDef,
  DesignEvaluationColumnValueDef,
  isDesignEvaluationKeyDef,
  isDesignEvaluationValueDef
} from '../common/DesignEvaluationUtils'

interface Props {
  columns: (DesignEvaluationColumnKeyDef | DesignEvaluationColumnValueDef)[][]
  evaluation: Evaluation
}

const DesignEvaluationTable: React.FC<Props> = ({ columns, evaluation }) => {
  return (
    <>
      {_.map(columns, (row, idx) => {
        return (
          <MultipleTableItem
            key={idx}
            values={_.map(row, (column, idx2) => {
              let key = `${idx}_${idx2}`
              let value = ''
              if (isDesignEvaluationKeyDef(column)) {
                key = column.key
                value = _.get(evaluation, column.key)
              } else if (isDesignEvaluationValueDef(column)) {
                value = column.value
              }

              if (column.valueFormatter) {
                value = column.valueFormatter(value)
              }

              return {
                key,
                value,
                span: column.span,
                style: column.style,
                type: _.get(column, 'type'),
                editable: _.get(column, 'editable', false),
                options: _.get(column, 'options', [])
              }
            })}
          />
        )
      })}
    </>
  )
}

export default DesignEvaluationTable
