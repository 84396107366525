import { CircularProgress, makeStyles, Theme } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const CircleBackdrop: React.FC<{ open: boolean }> = ({ open }) => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default CircleBackdrop
