import _ from 'lodash'

import { Control, PRCType, Process, Risk } from '../base/data/PRC'
import { Staff } from '../base/data/Staff'
import { controlGetAllContainCycle } from './RCM/control'
import { cycleGetAll } from './RCM/cycle'
import { departmentGetAll } from './RCM/department'
import { processGetAllContainCycle } from './RCM/process'
import { riskGetAllContainCycle } from './RCM/risk'
import { staffGetAll } from './RCM/staff'

function parseIdPathArrays(idPathArray: string[]): string[] {
  return _(idPathArray)
    .map((path) => _.last(path.split('/')))
    .filter(_.isString)
    .value()
}

export async function getProcesses(controls: Control[]): Promise<Process[]> {
  return (await processGetAllContainCycle(controls)) as Process[]
}

export async function getRisks(controls: Control[]): Promise<Risk[]> {
  return (await riskGetAllContainCycle(controls)) as Risk[]
}

export async function getControls(): Promise<Control[]> {
  return (await controlGetAllContainCycle()) as Control[]
}

export async function GetStaffs(): Promise<Staff[]> {
  return staffGetAll().then((staffList) => {
    const newStaff: any = []
    _.forEach(staffList, ({ id, staffName: name, controlPathArray, ...rest }) => {
      if (!_.find(newStaff, id)) {
        newStaff.push({
          id,
          name,
          controlIds: parseIdPathArrays(controlPathArray),
          ...rest
        })
      }
    })
    return newStaff
  })
}

export async function GetCycles(): Promise<any[]> {
  return cycleGetAll()
}

export async function GetDepartments(): Promise<any[]> {
  return departmentGetAll()
}
