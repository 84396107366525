import { toLetters } from '@cck/common/dist/utils/StringUtils'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import _ from 'lodash'

import { Evaluation, TableContent } from '../../base/data/Evaluation'
import { Staff, StaffFormatValue } from '../../base/data/Staff'
import { getKoName } from '../../base/data/Translation'
import { parseDateStr, parseTimeStr } from '../../base/utils/TimeUtils'
import { storageDownloadBlobFromUrl, storageGetDownloadUrl } from '../Utils/storage'

enum FileType {
  REPORT = '보고서',
  POPULATION = '모집단',
  EVIDENCE = '증빙',
  EXTRA = '기타'
}

export function checkEvaluationName(fileName: string):
  | undefined
  | {
      evaluationName: string
      controlId: string
      fileType: FileType
      name: string
    } {
  const nameCheckRegex = [
    /^\((.*)\)\(([^()]*)\)\((보고서|증빙|기타|모집단)\)(.*)$/,
    /^\((.*)\)\((보고서|증빙|기타|모집단)\)\(([^()]*)\)(.*)$/
  ]

  let matched = nameCheckRegex[0].exec(fileName.normalize('NFC'))
  if (matched) {
    return {
      evaluationName: _.trim(matched[1]),
      controlId: _.trim(matched[2]),
      fileType: matched[3] as FileType,
      name: _.trim(matched[4])
    }
  }

  matched = nameCheckRegex[1].exec(fileName.normalize('NFC'))
  if (matched) {
    return {
      evaluationName: _.trim(matched[1]),
      controlId: _.trim(matched[3]),
      fileType: matched[2] as FileType,
      name: _.trim(matched[4])
    }
  }
  return undefined
}

function getCellId(cellIndex: number, rowIndex: number): string {
  return `${toLetters(cellIndex + 1)}${rowIndex + 1}`
}

const MENU_STYLE = {
  style: { font: { bold: true } },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFF2F2F2' // RGB(242, 242, 242)
    }
  },
  alignment: { horizontal: 'left', vertical: 'middle' }
}

const CENTER_MENU_STYLE = {
  ...MENU_STYLE,
  alignment: { horizontal: 'center', vertical: 'middle' }
}

const BORDER: any = {
  top: { style: 'thin', color: { argb: 'FF808080' } },
  left: { style: 'thin', color: { argb: 'FF808080' } },
  bottom: { style: 'thin', color: { argb: 'FF808080' } },
  right: { style: 'thin', color: { argb: 'FF808080' } }
}

function getOperationEvaluationDefs(staffs: Staff[]) {
  return {
    TestInfo: {
      columnSize: [19], // almost 150px
      mergedCells: ['A1:E1', 'B2:E2', 'B3:E3', 'B4:E4', 'B5:E5', 'B6:E6', 'B7:E7'],
      rows: [
        [
          {
            value: 'Test Info',
            ...CENTER_MENU_STYLE
          }
        ],
        [{ value: getKoName('control', 'number'), ...MENU_STYLE }, { key: 'controlId' }],
        [{ value: getKoName('control', 'period'), ...MENU_STYLE }, { key: 'data.base.period' }],
        [
          { value: getKoName('control', 'inchargeInRCM'), ...MENU_STYLE },
          { key: 'data.content.controlData.incharge', formatter: StaffFormatValue(staffs) }
        ],
        [
          { value: getKoName('control', 'owner'), ...MENU_STYLE },
          { key: 'data.content.controlData.owner', formatter: StaffFormatValue(staffs) }
        ],
        [
          { value: getKoName('control', 'createDate'), ...MENU_STYLE },
          {
            key: 'data.content.evalData.createDate',
            formatter: (dateStr: string) => parseDateStr('YYYY-MM-DD', dateStr)
          }
        ],
        [
          { value: getKoName('control', 'updateTime'), ...MENU_STYLE },
          { key: 'data.content.evalData.updateTime', formatter: parseTimeStr }
        ]
      ]
    },
    FileInfo: {
      columnSize: [85, 28, 28, 28],
      mergedCells: [],
      rows: [
        [
          {
            value: getKoName('common', 'fileName'),
            ...CENTER_MENU_STYLE
          },
          {
            value: getKoName('common', 'type'),
            ...CENTER_MENU_STYLE
          },
          {
            value: getKoName('common', 'updater'),
            ...CENTER_MENU_STYLE
          },
          {
            value: getKoName('common', 'updateTime'),
            ...CENTER_MENU_STYLE
          }
        ]
      ]
    },
    PopulationInfo: {
      defaultColWidth: 13,
      columnSize: [21],
      rowHeight: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8],
      mergedCells: ['A1:J1', 'B2:J2', 'B3:J3', 'B4:J4', 'B5:J5', 'B6:J6', 'B7:J7', 'A10:J10'],
      rows: [
        [
          {
            value: '모집단 정보',
            ...CENTER_MENU_STYLE
          }
        ],
        [
          { value: getKoName('control', 'populationDescription'), ...MENU_STYLE },
          { key: 'data.content.evalData.populationDescription' }
        ],
        [
          { value: getKoName('control', 'populationCount'), ...MENU_STYLE },
          { key: 'data.content.evalData.populationCount' }
        ],
        [
          { value: getKoName('control', 'populationCompleteness'), ...MENU_STYLE },
          { key: 'data.content.evalData.populationCompleteness' }
        ],
        [
          { value: getKoName('control', 'samplingMethod'), ...MENU_STYLE },
          { key: 'data.content.evalData.samplingMethod' }
        ],
        [
          { value: `${getKoName('control', 'samplingCount')}(행)`, ...MENU_STYLE },
          { key: 'data.content.evalData.samplingCount' }
        ],
        [
          { value: `${getKoName('control', 'samplingAttributeCount')}(열)`, ...MENU_STYLE },
          { key: 'data.content.evalData.populationTableColumnCount' }
        ],
        [],
        [],
        [
          {
            value: '샘플링 결과',
            ...CENTER_MENU_STYLE
          }
        ],
        []
      ]
    },
    TestContentsInfo: {
      defaultColWidth: 11,
      columnSize: [21],
      mergedCells: ['A1:J1', 'B2:J2'],
      rows: [
        [
          {
            value: '테스트 수행 기준',
            ...CENTER_MENU_STYLE
          }
        ],
        [
          {
            value: getKoName('control', 'samplingAttributeCount'),
            ...MENU_STYLE
          },
          {
            key: 'data.content.evalData.samplingAttributeCount'
          }
        ],
        [],
        [],
        [],
        [
          {
            value: '테스트 수행 내역',
            ...CENTER_MENU_STYLE
          }
        ],
        [],
        [],
        [],
        [
          {
            value: '결과 정리',
            ...CENTER_MENU_STYLE
          }
        ],
        [
          { value: getKoName('control', 'populationCount'), ...MENU_STYLE },
          { key: 'data.content.evalData.populationCount' }
        ],
        [
          { value: getKoName('control', 'samplingCount'), ...MENU_STYLE },
          { key: 'data.content.evalData.samplingCount' }
        ],
        [
          { value: getKoName('control', 'validCount'), ...MENU_STYLE },
          { key: 'data.content.evalData.validCount' }
        ],
        [
          { value: getKoName('control', 'invalidCount'), ...MENU_STYLE },
          { key: 'data.content.evalData.invalidCount' }
        ],
        [
          { value: getKoName('control', 'testResult'), ...MENU_STYLE },
          { key: 'data.content.evalData.testResult' }
        ]
      ]
    }
  }
}

function addFileInfoRow(staffs: Staff[], fileInfoDefs: any, type: string, fileInfo: any): void {
  if (fileInfo && !_.isEmpty(fileInfo.fileName)) {
    fileInfoDefs.rows.push([
      { value: fileInfo.fileName },
      { value: type },
      { value: StaffFormatValue(staffs)(fileInfo.updater) },
      { value: parseTimeStr(fileInfo.updateTime) }
    ])
  }
}

export function updateFileInfoRows(
  staffs: Staff[],
  fileInfoDefs: any,
  evaluation: Evaluation
): any {
  const newFileInfoDefs = _.cloneDeep(fileInfoDefs)
  addFileInfoRow(
    staffs,
    newFileInfoDefs,
    '보고서',
    _.get(evaluation, 'data.content.files.recordFile')
  )
  addFileInfoRow(
    staffs,
    newFileInfoDefs,
    '모집단',
    _.get(evaluation, 'data.content.files.populationFile')
  )
  _.forEach(_.get(evaluation, 'data.content.files.evidenceFile'), (evidenceFile) => {
    addFileInfoRow(staffs, newFileInfoDefs, '증빙', evidenceFile)
  })
  _.forEach(_.get(evaluation, 'data.content.files.extraFile'), (extraFile) => {
    addFileInfoRow(staffs, newFileInfoDefs, '기타', extraFile)
  })
  return newFileInfoDefs
}

function convertTable(tableContent: TableContent): any {
  return [
    _.map(tableContent.headerRow, (headerRow) => {
      return {
        value: headerRow,
        ...CENTER_MENU_STYLE
      }
    }),
    ..._.map(tableContent.rowObjectList, (rowObject) => {
      return _.map(tableContent.headerRow, (headerRow, index) => ({
        value: _.get(rowObject, headerRow, ''),
        ...(index === 0 && CENTER_MENU_STYLE)
      }))
    })
  ]
}

function updateSamplingResults(populationInfoDefs: any, evaluation: Evaluation): any {
  const samplingResult = _.get(evaluation, 'data.content.evalData.samplingResult')
  if (!samplingResult) {
    return populationInfoDefs
  }

  const newPopulationInfoDefs = _.cloneDeep(populationInfoDefs)
  newPopulationInfoDefs.rows.push(...convertTable(samplingResult))
  return newPopulationInfoDefs
}

function updateTestContents(testContentInfoDefs: any, evaluation: Evaluation): any {
  const newTestContentInfoDefs = _.cloneDeep(testContentInfoDefs)

  let rowIndex = 3
  const attributeContentRows = convertTable(
    _.get(evaluation, 'data.content.evalData.attributeContents')
  )

  if (attributeContentRows && !_.isEmpty(_.head(attributeContentRows))) {
    if (_.get(_.head(_.head(attributeContentRows)), 'value') === 'Attribute') {
      attributeContentRows[0][0].value = '속성 번호'
    }
    for (let i = 0; i < attributeContentRows.length; ++i) {
      newTestContentInfoDefs.mergedCells.push(
        `${getCellId(1, rowIndex + i)}:${getCellId(4, rowIndex + i)}`
      )
      newTestContentInfoDefs.mergedCells.push(
        `${getCellId(5, rowIndex + i)}:${getCellId(8, rowIndex + i)}`
      )

      attributeContentRows[i].splice(3, 0, {}, {}, {})
      attributeContentRows[i].splice(2, 0, {}, {}, {})
    }

    newTestContentInfoDefs.rows.splice(rowIndex, 0, ...attributeContentRows)

    rowIndex += attributeContentRows.length
  }

  rowIndex += 4
  newTestContentInfoDefs.mergedCells.push(
    `${getCellId(0, rowIndex - 2)}:${getCellId(9, rowIndex - 2)}`
  )

  const testContentRows = convertTable(_.get(evaluation, 'data.content.evalData.testContents'))
  const subHeaders: any = []
  if (testContentRows && _.head(testContentRows)) {
    const headers: any = _.head(testContentRows)
    const resultRe = /^Attribute_(\d+)\/결과$/
    const detailRe = /^Attribute_(\d+)\/상세$/
    for (let index = headers.length - 1; index >= 0; --index) {
      const header = headers[index]
      if (_.get(header, 'value') === 'No') {
        header.value = '항목 번호'
      }

      const matched = resultRe.exec(header.value)
      if (matched && matched.length > 1) {
        header.value = `속성 번호 ${matched[1]}`
        subHeaders.push({ value: '결과', ...CENTER_MENU_STYLE })
      } else if (detailRe.exec(header.value)) {
        headers.splice(index, 1, {}, {}, {})
        subHeaders.push(...[{}, {}, { value: '상세', ...CENTER_MENU_STYLE }])
        for (let j = 1; j < testContentRows.length; ++j) {
          testContentRows[j].splice(index + 1, 0, {}, {})
        }
      } else {
        subHeaders.push({ value: '' })
      }
    }
    testContentRows.splice(1, 0, _.reverse(subHeaders))

    // For 항목번호/테스트 결과/증빙자료 Ref
    _.forEach([0, headers.length - 2, headers.length - 1], (colIdx) => {
      if (colIdx >= 0) {
        newTestContentInfoDefs.mergedCells.push(
          `${getCellId(colIdx, rowIndex)}:${getCellId(colIdx, rowIndex + 1)}`
        )
      }
    })

    // For 속성 상세
    for (let i = 0; i < testContentRows.length; ++i) {
      let prevColumnIndex = 0
      for (let j = 0; j < testContentRows[i].length; ++j) {
        if (_.has(testContentRows[i][j], 'value')) {
          if (j - prevColumnIndex > 1) {
            newTestContentInfoDefs.mergedCells.push(
              `${getCellId(prevColumnIndex, rowIndex + i)}:${getCellId(j - 1, rowIndex + i)}`
            )
          }
          prevColumnIndex = j
        }
      }
    }
  }

  newTestContentInfoDefs.rows.splice(rowIndex, 0, ...testContentRows)

  rowIndex += testContentRows.length + 2
  newTestContentInfoDefs.mergedCells.push(`${getCellId(0, rowIndex)}:${getCellId(9, rowIndex)}`)
  for (let i = 1; i <= 5; ++i) {
    newTestContentInfoDefs.mergedCells.push(
      `${getCellId(1, rowIndex + i)}:${getCellId(9, rowIndex + i)}`
    )
  }

  return newTestContentInfoDefs
}

export function writeWorksheet(
  sheetDef: any,
  worksheet: ExcelJS.Worksheet,
  evaluation: Evaluation
) {
  if (_.has(sheetDef, 'defaultColWidth')) {
    worksheet.properties.defaultColWidth = sheetDef.defaultColWidth
  }

  _.forEach(sheetDef.rows, (rowDef, rowIndex: number) => {
    _.forEach(rowDef, (cellDef, cellIndex: number) => {
      const cell = worksheet.getCell(getCellId(cellIndex, rowIndex))
      if (cellDef.value) {
        cell.value = cellDef.value
      }
      if (cellDef.key) {
        if (cellDef.formatter) {
          cell.value = cellDef.formatter(_.get(evaluation, cellDef.key))
        } else {
          cell.value = _.get(evaluation, cellDef.key, '').toString()
        }
      }
      if (cellDef.style) {
        cell.style = _.cloneDeep(cellDef.style)
      }
      if (cellDef.fill) {
        cell.fill = _.cloneDeep(cellDef.fill)
      }
      if (cellDef.alignment) {
        cell.alignment = _.cloneDeep(cellDef.alignment)
        cell.alignment.wrapText = true
      } else {
        cell.alignment = { wrapText: true }
      }

      if (cellIndex === 0 && _.isEmpty(cell.value)) {
        return
      }

      cell.border = BORDER
    })
  })

  if (_.has(sheetDef, 'mergedCells')) {
    _.forEach(sheetDef.mergedCells, (mergedCell) => {
      worksheet.mergeCells(mergedCell)
    })
  }
  if (_.has(sheetDef, 'columnSize')) {
    _.forEach(sheetDef.columnSize, (columnSize, index) => {
      worksheet.getColumn(index + 1).width = columnSize
    })
  }
  if (_.has(sheetDef, 'rowHeight')) {
    _.forEach(sheetDef.rowHeight, (rowHeight, index: number) => {
      if (rowHeight <= 0) {
        return
      }
      worksheet.getRow(index + 1).height = rowHeight
    })
  }
  if (_.has(sheetDef, 'rowHeightMap')) {
    _.map(sheetDef.rowHeightMap, (newRowHeight, rowIndex) => {
      worksheet.getRow(_.isNumber(rowIndex) ? rowIndex : _.toNumber(rowIndex)).height = newRowHeight
    })
  }
}

export async function exportToExcel(staffs: Staff[], evaluation: Evaluation): Promise<any> {
  /**
   * 운영평가를 엑셀파일로 변경한다.
   */
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'CCKSolution'

  const evaluationDefs = getOperationEvaluationDefs(staffs)
  const sheetOptions = { views: [{ showGridLines: false, zoomScale: 85 }] }

  const testInfoSheet = workbook.addWorksheet('1_테스트개요', sheetOptions)
  writeWorksheet(evaluationDefs.TestInfo, testInfoSheet, evaluation)

  const fileInfoSheet = workbook.addWorksheet('2_파일정보', sheetOptions)
  writeWorksheet(
    updateFileInfoRows(staffs, evaluationDefs.FileInfo, evaluation),
    fileInfoSheet,
    evaluation
  )

  const populationInfoSheet = workbook.addWorksheet('3_모집단정보 및 샘플링내역', sheetOptions)
  writeWorksheet(
    updateSamplingResults(evaluationDefs.PopulationInfo, evaluation),
    populationInfoSheet,
    evaluation
  )

  const testContentsInfoSheet = workbook.addWorksheet('4_테스트수행 및 결과', sheetOptions)
  writeWorksheet(
    updateTestContents(evaluationDefs.TestContentsInfo, evaluation),
    testContentsInfoSheet,
    evaluation
  )

  const buffer = await workbook.xlsx.writeBuffer()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  // eslint-disable-next-line no-undef
  return new Blob([buffer], { type: fileType })
}

async function downloadFilesWithoutRecord(zip: JSZip, evaluation: Evaluation): Promise<any> {
  const baseFilePath = `운영평가/${evaluation.data.base.name}/${evaluation.controlId}`
  const files = evaluation.data.content.files

  if (files.populationFile && !_.isEmpty(files.populationFile.fileName)) {
    const filePath = `${baseFilePath}/${files.populationFile.fileName}`
    const fileBlob = await storageDownloadBlobFromUrl(await storageGetDownloadUrl(filePath))
    zip.file(files.populationFile.fileName, fileBlob, { binary: true })
  }

  await Promise.all(
    _.map(_.concat(_.values(files.evidenceFile), _.values(files.extraFile)), async (file) => {
      const filePath = `${baseFilePath}/${file?.fileName}`
      const fileBlob = await storageDownloadBlobFromUrl(await storageGetDownloadUrl(filePath))
      zip.file(file?.fileName, fileBlob, { binary: true })
    })
  )
}

export async function exportToExcelZip(staffs: Staff[], evaluations: Evaluation[]): Promise<void> {
  const zip = new JSZip()
  await Promise.all(
    _.map(evaluations, async (evaluation) => {
      const subZip = zip.folder(evaluation.controlId)
      if (subZip) {
        subZip.file(
          `(${evaluation.data.base.name})(${evaluation.controlId})(보고서 readonly) 보고서.xlsx`,
          exportToExcel(staffs, evaluation),
          {
            binary: true
          }
        )
        await downloadFilesWithoutRecord(subZip, evaluation)
      }
    })
  )

  zip.generateAsync({ type: 'blob' }).then((content) => {
    const name = _.get(_.head(evaluations), 'data.base.name', '운영평가')
    saveAs(content, `${name}_보고서.zip`)
  })
}
