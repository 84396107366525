import Button from 'antd/lib/button'
import React from 'react'

import AlertMessage, { AlertMessageHandler } from './AlertMessage'

type ButtonProps = Parameters<typeof Button>[0]

interface Props extends ButtonProps {
  onClick: (event?: unknown) => Promise<unknown> | undefined
}

const LoadingButton: React.FC<Props> = ({ onClick, ...rest }) => {
  const [loading, setLoading] = React.useState(false)
  const alertRef = React.useRef<AlertMessageHandler>(null)

  return (
    <>
      <Button
        loading={loading}
        onClick={(e) => {
          setLoading(true)
          const result = onClick(e)
          if (result) {
            result
              .catch((error) => {
                alertRef.current?.showAlert('error', error.message)
              })
              .finally(() => setLoading(false))
          }
        }}
        {...rest}
      />
      {/* TODO(sangmuk): Modal 외에 다른곳에서 사용한다면 다시 설정 고려. */}
      <AlertMessage ref={alertRef} top={100} />
    </>
  )
}

export default LoadingButton
