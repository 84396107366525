import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Theme, makeStyles } from '@material-ui/core/styles'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import MenuIcon from '@material-ui/icons/Menu'
import RemoveIcon from '@material-ui/icons/Remove'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { lightBlue } from '../../base/color'
import { AppMenu, convertToMenuStr } from '../../base/data/AppMenu'
import firebase from '../../dataLoader/fbase'
import LogoutButton from './LogoutButton'

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  drawer: {
    width: 320
  },
  title: {
    flexGrow: 1,
    color: '#ffffff'
  },
  listButton: {
    '&:hover .MuiListItemIcon-root': {
      color: lightBlue.color
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  selected: {
    color: lightBlue.color
  },
  text: {
    wordBreak: 'keep-all'
  }
}))

interface Props {
  current: string
  menus: AppMenu[]
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header: React.FC<Props> = ({ current, menus, open, setOpen }) => {
  const classes = useStyles()

  const toggleDrawer = (newOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setOpen(newOpen)
  }

  const renderButtonItem = (menu: AppMenu, isTopLevel?: boolean): React.ReactElement => {
    return (
      <ListItem
        button
        className={clsx(classes.listButton, {
          [classes.selected]: current === menu.name,
          [classes.nested]: !isTopLevel
        })}
        component={(props) => <Link to={`/web/${convertToMenuStr(menu.name)}`} {...props} />}
        key={menu.name}
      >
        <ListItemIcon className={clsx({ [classes.selected]: current === menu.name })}>
          {menu.icon || <KeyboardArrowRightIcon />}
        </ListItemIcon>
        <ListItemText className={classes.text} primary={menu.name} />
      </ListItem>
    )
  }

  const renderItem = (menu: AppMenu, noDivider?: boolean): React.ReactElement => {
    return (
      <div key={menu.name}>
        {_.isEmpty(menu?.children) && renderButtonItem(menu, true)}
        {menu.children && (
          <>
            <ListItem key={menu.name}>
              <ListItemIcon>{menu.icon || <RemoveIcon />}</ListItemIcon>
              <ListItemText className={classes.text} primary={menu.name} />
            </ListItem>
            <List disablePadding component="div" key={`${menu.name}_child`}>
              {_.map(menu.children, (child) => renderButtonItem(child))}
            </List>
          </>
        )}
        {!noDivider && <Divider />}
      </div>
    )
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            aria-label="menu"
            className={classes.menuButton}
            color="inherit"
            edge="start"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6">
            {current}
          </Typography>
          <LogoutButton logout={() => firebase.auth().signOut()} />
        </Toolbar>
      </AppBar>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <div className={classes.drawer}>
          <List>
            {_.map(menus, (menu) => {
              return renderItem(menu)
            })}
          </List>
        </div>
      </Drawer>
    </>
  )
}

export default Header
