import { PlayCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import Divider from '@material-ui/core/Divider'
import { Theme, makeStyles } from '@material-ui/core/styles'
import Timeline from 'antd/lib/timeline'
import Typography from 'antd/lib/typography'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { grey } from '../../base/color'
import { getKoEvaluation } from '../../base/data/Evaluation'
import { evaluationGetAllBase } from '../../dataLoader/Evaluation/evaluation'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
    height: '100%',
    backgroundColor: 'white',
    border: grey.border
  },
  title: {
    padding: theme.spacing(1.5, 3, 1.5, 3)
  },
  timeline: {
    margin: theme.spacing(2, 2, 0, 2)
  },
  content: {
    overflowY: 'auto',
    flex: 1
  }
}))

interface Milestone {
  evaluationType: string
  name: string
  date: moment.Moment
  type: 1 | 2 | 3
}

const TimelineView: React.FC = () => {
  const classes = useStyles()
  const [milestones, setMilestones] = React.useState<Milestone[]>([])

  React.useEffect(() => {
    Promise.all([evaluationGetAllBase('OE'), evaluationGetAllBase('DE')]).then(
      ([operations, designs]) => {
        const evaluations = _.concat(operations, designs)
        const today = moment()

        const newMilestones: Milestone[] = []
        _.forEach(evaluations, (evaluation) => {
          const evaluationType = getKoEvaluation(
            evaluation.evalType === 'OE' ? 'operation' : 'design'
          )

          const startDate = moment(evaluation.startDate, 'YYYY-MM-DD')
          const endDate = moment(evaluation.endDate, 'YYYY-MM-DD')
          if (endDate < today) {
            return
          }

          newMilestones.push({
            evaluationType,
            name: evaluation.evalName,
            date: startDate,
            type: 2
          })
          newMilestones.push({
            evaluationType,
            name: evaluation.evalName,
            date: endDate,
            type: 3
          })
        })
        newMilestones.push({
          evaluationType: '',
          name: '오늘',
          date: today,
          type: 1
        })
        setMilestones(_.sortBy(newMilestones, ['date', 'type']))
      }
    )
  }, [])

  const getIconByType = (type: 1 | 2 | 3) => {
    if (type === 2) {
      return <PlayCircleOutlined />
    } else if (type === 3) {
      return <CloseCircleOutlined />
    }
    return undefined
  }

  const getColorByType = (type: string) => {
    if (type === '운영평가') {
      return 'blue'
    } else if (type === '설계평가') {
      return 'red'
    }
    return 'green'
  }

  return (
    <div className={classes.root}>
      <Typography.Text strong className={classes.title}>
        일정
      </Typography.Text>
      <Divider />
      <div className={classes.content}>
        <Timeline className={classes.timeline} mode="left">
          {_.map(milestones, (milestone) => {
            return (
              <Timeline.Item
                dot={getIconByType(milestone.type)}
                color={getColorByType(milestone.evaluationType)}
                label={
                  milestone.evaluationType
                    ? `[${milestone.evaluationType}] ${milestone.date.format('YYYY.MM.DD')}`
                    : milestone.date.format('YYYY.MM.DD')
                }
              >
                {milestone.name}
              </Timeline.Item>
            )
          })}
        </Timeline>
      </div>
    </div>
  )
}

export default TimelineView
