import { makeStyles, Theme } from '@material-ui/core'
import Tabs from 'antd/lib/tabs'
import React from 'react'

import { grey } from '../../base/color'

const useStyles = makeStyles((theme: Theme) => ({
  rootFull: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: 0,
    padding: theme.spacing(1),
    '& .ant-tabs-top': {
      '& .ant-tabs-nav::before': {
        borderColor: grey.borderColor
      }
    },
    '& .ant-tabs-card': {
      '& .ant-tabs-tab': {
        borderColor: grey.borderColor
      }
    },
    '& .ant-tabs': {
      '& .ant-tabs-nav': {
        margin: 0
      }
    },
    '& .ant-tabs-content-holder': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      minHeight: 0
    },
    '& .ant-tabs-content': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      minHeight: 0,
      height: 400
    },
    '& .ant-tabs-tabpane': {
      background: '#ffffff',
      borderLeft: grey.border,
      borderRight: grey.border,
      borderBottom: grey.border,
      display: 'flex',
      flexGrow: 1,
      flexBasis: 0,
      flexDirection: 'column',
      minHeight: 0,
      padding: theme.spacing(1)
    }
  },
  tabFull: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: 0
  },
  rootOverflow: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    padding: theme.spacing(1),
    '& .ant-tabs-top': {
      '& .ant-tabs-nav::before': {
        borderColor: grey.borderColor
      }
    },
    '& .ant-tabs-card': {
      '& .ant-tabs-tab': {
        borderColor: grey.borderColor
      }
    },
    '& .ant-tabs': {
      '& .ant-tabs-nav': {
        margin: 0
      }
    },
    '& .ant-tabs-content-holder': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0
    },
    '& .ant-tabs-content': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& .ant-tabs-tabpane': {
      background: '#ffffff',
      borderLeft: grey.border,
      borderRight: grey.border,
      borderBottom: grey.border,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0,
      padding: theme.spacing(1)
    }
  },
  tabOverflow: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0
  }
}))

type TabsProps = Parameters<typeof Tabs>[0]

interface Props extends TabsProps {
  defaultActiveKey: string
  children: any
  overflow?: boolean
}

const CardTabs: React.FC<Props> = ({ defaultActiveKey, children, overflow, ...restProps }) => {
  const classes = useStyles()
  return (
    <div className={overflow ? classes.rootOverflow : classes.rootFull}>
      <Tabs
        className={overflow ? classes.tabOverflow : classes.tabFull}
        defaultActiveKey={defaultActiveKey}
        type="card"
        {...restProps}
      >
        {children}
      </Tabs>
    </div>
  )
}

export default CardTabs
