import Typography from 'antd/lib/typography'
import React from 'react'

function ToolTipTextRenderer(params: any): React.ReactElement {
  return (
    <Typography.Text ellipsis={{ tooltip: params.value }} style={{ width: '100%' }}>
      {params.value}
    </Typography.Text>
  )
}
export default ToolTipTextRenderer
