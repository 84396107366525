import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from 'antd/lib/button'
import _ from 'lodash'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  rightButton: {
    direction: 'rtl',
    display: 'flex',
    justifyContent: 'flex-end',
    '&.ant-btn > .anticon + span': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0)
    },
    '&.ant-btn > .anticon': {
      lineHeight: 1.9
    }
  }
}))

interface Props {
  id?: string
  onChange: (id: string) => void
}

export const PreviousButton: React.FC<Props> = ({ id, onChange }) => {
  return (
    <Button
      disabled={_.isEmpty(id)}
      icon={<LeftOutlined />}
      onClick={() => {
        if (id) onChange(id)
      }}
    >
      이전
    </Button>
  )
}

export const NextButton: React.FC<Props> = ({ id, onChange }) => {
  const classes = useStyles()
  return (
    <Button
      className={classes.rightButton}
      disabled={_.isEmpty(id)}
      icon={<RightOutlined />}
      onClick={() => {
        if (id) onChange(id)
      }}
    >
      다음
    </Button>
  )
}
