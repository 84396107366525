/* eslint-disable no-undef */
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import _ from 'lodash'

import { grey, lightBlue, lightGreen } from '../../base/color'
import { Evaluation } from '../../base/data/Evaluation'
import { Staff } from '../../base/data/Staff'
import { getKoName } from '../../base/data/Translation'
import { getConsiderations } from '../../component/evaluation/common/DesignEvaluationUtils'
import { storageDownloadBlobFromUrl, storageGetDownloadUrl } from '../Utils/storage'
import { updateFileInfoRows, writeWorksheet } from './evaluationUtils'

enum CellStyle {
  category = 'category',
  subCategory1 = 'subCategory1',
  subCategory2 = 'subCategory2',
  subCategory3 = 'subCategory3',
  subCategory4 = 'subCategory4'
}

const lightGreyColor = _.replace(grey.background, '#', 'ff')
const lightBlueColor = _.replace(lightBlue.background, '#', 'ff')
const lightGreenColor = _.replace(lightGreen.background, '#', 'ff')
const lightYellowColor = 'fffff8f0'

const getStyle = (cellStyleName: CellStyle) => {
  const baseStyle = {
    style: { font: { bold: true } },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'FFF2F2F2' // RGB(242, 242, 242)
      }
    },
    alignment: { horizontal: 'left', vertical: 'middle' }
  }
  if (cellStyleName === CellStyle.category) {
    baseStyle.fill.fgColor.argb = lightGreyColor // rgb(224, 224, 224) 베이지색
    baseStyle.alignment.horizontal = 'center'
  } else if (cellStyleName === CellStyle.subCategory1) {
    baseStyle.fill.fgColor.argb = lightBlueColor // rgb(255, 153, 255) 핑크색
    baseStyle.alignment.horizontal = 'center'
  } else if (cellStyleName === CellStyle.subCategory2) {
    baseStyle.fill.fgColor.argb = lightGreenColor // rgb(204, 204, 0) 누런색
    baseStyle.alignment.horizontal = 'center'
  } else if (cellStyleName === CellStyle.subCategory3) {
    baseStyle.fill.fgColor.argb = '3399FF' // rgb(51, 153, 255) 파랑색
    baseStyle.alignment.horizontal = 'center'
  } else if (cellStyleName === CellStyle.subCategory4) {
    baseStyle.fill.fgColor.argb = lightYellowColor // rgb(153, 76, 0) 갈색
    baseStyle.style.font.bold = false
  }

  return baseStyle
}

function getDefectRow(type: 'control' | 'risk', key: string) {
  return [
    {},
    { value: getKoName(type, key), ...getStyle(CellStyle.category) },
    { key: `data.content.${type}Data.${key}` },
    { key: `data.state.weakStateData.${type}.${key}.content`, ...getStyle(CellStyle.subCategory4) },
    { key: `data.state.weakStateData.${type}.${key}.evidence`, ...getStyle(CellStyle.subCategory4) }
  ]
}

const makeRowHeightMap = (
  lastRowNumber: number,
  defaultRowHeight: number,
  exceptionRowHeightMap: Record<string, number>
) => {
  const result = {} as Record<string, number>
  _.forEach(_.range(lastRowNumber), (rowNumber) => {
    if (rowNumber) {
      result[rowNumber] = exceptionRowHeightMap[rowNumber]
    } else {
      result[rowNumber] = defaultRowHeight
    }
  })
  return result
}

const makeConsiderationResults = (considerations: { title: string; content: string }[]) => {
  const result: {
    first: { value?: string; key?: string }[]
    second: { value?: string; key?: string }[]
  }[] = []

  const numberStr = ['One', 'Two', 'Three', 'Four', 'Five']
  _.forEach(considerations, (consideration, index) => {
    const first = [{}]
    const second = [{}]
    const num = numberStr[index]

    if (index === 0) {
      first.push({ value: '통제활동\n설계평가', ...getStyle(CellStyle.category) })
    } else {
      first.push({ value: '', ...getStyle(CellStyle.category) })
    }
    first.push({ value: considerations[index].title, ...getStyle(CellStyle.subCategory1) })
    _.range(0, 5).forEach((v, i, a) =>
      first.push({ value: '', ...getStyle(CellStyle.subCategory1) })
    )
    first.push({ value: '평가 결론', ...getStyle(CellStyle.category) })
    first.push({ key: `data.content.evalData.consideration${num}Result` })

    second.push({ value: '', ...getStyle(CellStyle.category) })
    second.push({ value: considerations[index].content, ...getStyle(CellStyle.subCategory2) })
    _.range(0, 2).forEach((v, i, a) =>
      second.push({ value: '', ...getStyle(CellStyle.subCategory2) })
    )
    second.push({ key: `data.content.evalData.consideration${num}Content` })
    result.push({ first, second })
  })
  return result
}

const getDesignEvaluationDefs = (staffs: Staff[]) => {
  const considerationResults = makeConsiderationResults(getConsiderations())
  return {
    DesignEvaluation: {
      columnSize: [2, 24, 24, 24, 15, 15, 15, 15, 15, 15],
      mergedCells: [
        'B3:J3',
        'C5:F5',
        'H5:J5',
        'C6:F6',
        'H6:J6',
        'C7:F7',
        'H7:J7',
        'C8:F8',
        'H8:J8',
        'E10:J10',
        'E11:J11',
        'B15:B24',
        'C15:H15',
        'C16:E16',
        'F16:J16',
        'C17:H17',
        'C18:E18',
        'F18:J18',
        'C19:H19',
        'C20:E20',
        'F20:J20',
        'C21:H21',
        'C22:E22',
        'F22:J22',
        'C23:H23',
        'C24:E24',
        'F24:J24',
        'B26:B28',
        'D27:H27'
      ],
      rowHeightMap: makeRowHeightMap(38, 30, {
        3: 100,
        16: 120,
        18: 120,
        20: 120,
        22: 120,
        24: 120
      }),
      rows: [
        [],
        [],
        [
          {},
          {
            value: '내부회계관리제도 설계의 효과성 평가(일반_PLC,ITGC)',
            ...getStyle(CellStyle.category)
          }
        ],
        [],
        [
          {},
          { value: getKoName('common', 'cycleName'), ...getStyle(CellStyle.category) },
          { key: 'data.content.controlData.cycleName' },
          {},
          {},
          {},
          { value: getKoName('common', 'processCode'), ...getStyle(CellStyle.category) },
          { key: 'data.content.processCode' },
          {},
          {}
        ],
        [
          {},
          { value: getKoName('common', 'categoryName'), ...getStyle(CellStyle.category) },
          { key: 'data.content.controlData.categoryName' },
          {},
          {},
          {},
          { value: getKoName('common', 'updateTime'), ...getStyle(CellStyle.category) },
          { key: 'data.content.evalData.updateTime' },
          {},
          {}
        ],
        [
          {},
          { value: getKoName('common', 'subCategoryName'), ...getStyle(CellStyle.category) },
          { key: 'data.content.controlData.subCategoryName' },
          {},
          {},
          {},
          { value: getKoName('control', 'performer'), ...getStyle(CellStyle.category) },
          { key: 'data.content.controlData.incharge' },
          {},
          {}
        ],
        [
          {},
          { value: getKoName('control', 'department'), ...getStyle(CellStyle.category) },
          { key: 'data.content.controlData.department' },
          {},
          {},
          {},
          {
            value: getKoName('control', 'owner'),
            ...getStyle(CellStyle.category)
          },
          { key: 'data.content.controlData.owner' },
          {},
          {}
        ],
        [],
        [
          {},
          { value: getKoName('risk', 'id'), ...getStyle(CellStyle.category) },
          { key: 'data.content.riskData.id' },
          {
            value: getKoName('risk', 'name'),
            ...getStyle(CellStyle.category)
          },
          { key: 'data.content.riskData.name' },
          {},
          {},
          {},
          {},
          {}
        ],
        [
          {},
          { value: getKoName('control', 'id'), ...getStyle(CellStyle.category) },
          { key: 'data.content.controlData.id' },
          {
            value: getKoName('control', 'narrative'),
            ...getStyle(CellStyle.category)
          },
          { key: 'data.content.controlData.narrative' },
          {},
          {},
          {},
          {},
          {}
        ],
        [
          {},
          { value: '통제유형', ...getStyle(CellStyle.category) },
          { value: getKoName('control', 'keyControl'), ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.controlData.keyControl' },
          { value: getKoName('control', 'autoManual'), ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.controlData.autoManual' },
          { value: getKoName('control', 'preventDetective'), ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.controlData.preventDetective' },
          { value: getKoName('control', 'period'), ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.controlData.period' }
        ],
        [
          {},
          { value: '통제위험평가', ...getStyle(CellStyle.category) },
          { value: getKoName('risk', 'inherentRiskLevel'), ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.riskData.inherentRiskLevel' },
          { value: getKoName('control', 'residualRiskLevel'), ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.controlData.residualRiskLevel' },
          { value: getKoName('control', 'controlRiskLevel'), ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.controlData.controlRiskLevel' }
        ],
        [],
        considerationResults[0].first, // []
        considerationResults[0].second,
        considerationResults[1].first,
        considerationResults[1].second,
        considerationResults[2].first,
        considerationResults[2].second,
        considerationResults[3].first,
        considerationResults[3].second,
        considerationResults[4].first,
        considerationResults[4].second,
        [],
        [
          {},
          { value: '통제평가절차', ...getStyle(CellStyle.category) },
          {
            value: getKoName('control', 'TOC_TestMethod_InquiryInspection'),
            ...getStyle(CellStyle.subCategory1)
          },
          { key: 'data.content.controlData.TOC_TestMethod_InquiryInspection' },
          {
            value: getKoName('control', 'TOC_TestMethod_Observation'),
            ...getStyle(CellStyle.subCategory1)
          },
          { key: 'data.content.controlData.TOC_TestMethod_Observation' },
          {
            value: getKoName('control', 'TOC_TestMethod_Reperformance'),
            ...getStyle(CellStyle.subCategory1)
          },
          { key: 'data.content.controlData.TOC_TestMethod_Reperformance' }
        ],
        [
          {},
          { value: '', ...getStyle(CellStyle.category) },
          {
            value: getKoName('control', 'TOC_Procedure'),
            ...getStyle(CellStyle.subCategory1)
          },
          { key: 'data.content.controlData.TOC_Procedure' },
          {},
          {},
          {},
          {},
          {
            value: getKoName('control', 'TOC_Evidence'),
            ...getStyle(CellStyle.subCategory1)
          },
          { key: 'data.content.controlData.TOC_Evidence' }
        ],
        [
          {},
          { value: '', ...getStyle(CellStyle.category) },
          { value: '설계일자 (Baseline) Reference', ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.evalData.baselineReference' },
          { value: getKoName('control', 'MRC_number'), ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.controlData.MRC_Number' },
          { value: getKoName('control', 'IPE_Number'), ...getStyle(CellStyle.subCategory1) },
          { key: 'data.content.controlData.IPE_Number' },
          { value: '검토증빙 문서', ...getStyle(CellStyle.subCategory1) },
          { value: '증빙문서 시트 참고' }
        ],
        [],
        [
          {},
          { value: '평가 결론', ...getStyle(CellStyle.category) },
          {
            key: 'data.state.resultState',
            formatter: (numStr: string) => {
              if (numStr === '2') {
                return 'Effective'
              } else {
                return 'Ineffective'
              }
            }
          }
        ]
      ]
    },
    FileInfo: {
      columnSize: [85, 28, 28, 28],
      mergedCells: [],
      rows: [
        [
          {
            value: getKoName('common', 'fileName'),
            ...getStyle(CellStyle.category)
          },
          {
            value: getKoName('common', 'type'),
            ...getStyle(CellStyle.category)
          },
          {
            value: getKoName('common', 'updater'),
            ...getStyle(CellStyle.category)
          },
          {
            value: getKoName('common', 'updateTime'),
            ...getStyle(CellStyle.category)
          }
        ]
      ]
    },
    RcmModification: {
      columnSize: [2, 25, 40, 50, 50],
      mergedCells: [
        'B2:C3',
        'B5:C5',
        'B6:C6',
        'B7:E7',
        'D8:E8',
        'B10:E10',
        'B13:E13',
        'B17:E17',
        'B21:E21',
        'B27:E27',
        'B33:E33',
        'B41:E41',
        'B50:E50',
        'B55:C56',
        'B58:C58',
        'B59:C59',
        'B60:E60',
        'B68:E68'
      ],
      rowHeightMap: makeRowHeightMap(62, 30, {
        5: 35,
        6: 20,
        7: 35,
        10: 35,
        13: 35,
        17: 35,
        21: 35,
        27: 35,
        33: 35,
        41: 35,
        50: 35,
        58: 35,
        59: 20,
        60: 35,
        68: 35
      }),
      rows: [
        [],
        [{}, { value: '통제 정보 업데이트', ...getStyle(CellStyle.category) }],
        [{}, { value: '', ...getStyle(CellStyle.category) }],
        [],
        [
          {},
          { value: '기존 통제 정보', ...getStyle(CellStyle.subCategory1) },
          { value: '', ...getStyle(CellStyle.subCategory1) },
          { value: '수정된 통제 내용', ...getStyle(CellStyle.subCategory1) },
          { value: '수정 근거', ...getStyle(CellStyle.subCategory1) }
        ],
        [
          {},
          { value: '기존 RCM 상 존재하였던 통제 정보', ...getStyle(CellStyle.category) },
          { value: '', ...getStyle(CellStyle.category) },
          { value: '당 설계평가에서 수정된 RCM 정보', ...getStyle(CellStyle.category) },
          { value: '수정된 RCM 정보의 근거', ...getStyle(CellStyle.category) }
        ],
        [{}, { value: '통제 기본 정보', ...getStyle(CellStyle.category) }],
        [
          {},
          { value: getKoName('control', 'number'), ...getStyle(CellStyle.category) },
          { key: 'data.content.controlData.id' },
          { value: '수정불가', ...getStyle(CellStyle.category) }
        ],
        getDefectRow('control', 'narrative'),
        [{}, { value: '규정 및 자원', ...getStyle(CellStyle.category) }],
        getDefectRow('control', 'policy'),
        getDefectRow('control', 'ITDependencySystem'),
        [{}, { value: 'MRC', ...getStyle(CellStyle.category) }],
        getDefectRow('control', 'MRC_YN'),
        getDefectRow('control', 'MRC_Number'),
        getDefectRow('control', 'MRC_IPE_Name'),
        [{}, { value: 'IPE 통제', ...getStyle(CellStyle.category) }],
        getDefectRow('control', 'IPE_YN'),
        getDefectRow('control', 'IPE_Number'),
        getDefectRow('control', 'IPE_Name'),
        [{}, { value: '통제 조직', ...getStyle(CellStyle.category) }],
        getDefectRow('control', 'department'),
        getDefectRow('control', 'incharge'),
        getDefectRow('control', 'owner'),
        getDefectRow('control', 'teamLeader'),
        getDefectRow('control', 'teamMember'),
        [{}, { value: getKoName('control', 'goal'), ...getStyle(CellStyle.category) }],
        getDefectRow('control', 'goal_Trust'),
        getDefectRow('control', 'goal_Asset'),
        getDefectRow('control', 'goal_Override'),
        getDefectRow('control', 'goal_Operation'),
        getDefectRow('control', 'goal_Law'),
        [{}, { value: getKoName('control', 'assertion'), ...getStyle(CellStyle.category) }],
        getDefectRow('control', 'assertion_EO'),
        getDefectRow('control', 'assertion_C'),
        getDefectRow('control', 'assertion_RO'),
        getDefectRow('control', 'assertion_PD'),
        getDefectRow('control', 'assertion_Occur'),
        getDefectRow('control', 'assertion_Assessment'),
        getDefectRow('control', 'assertion_Measurement'),
        [{}, { value: '기타 통제 속성', ...getStyle(CellStyle.category) }],
        getDefectRow('control', 'keyControl'),
        getDefectRow('control', 'accountCode'),
        getDefectRow('control', 'accountName'),
        getDefectRow('control', 'reportFootnotes'),
        getDefectRow('control', 'period'),
        getDefectRow('control', 'preventDetective'),
        getDefectRow('control', 'autoManual'),
        getDefectRow('control', 'residualRiskLevel'),
        [{}, { value: '관련 위험', ...getStyle(CellStyle.category) }],
        getDefectRow('risk', 'name'),
        getDefectRow('risk', 'RoMM'),
        getDefectRow('risk', 'inherentRiskLevel'),
        [],
        [{}, { value: '통제 평가 방법 업데이트', ...getStyle(CellStyle.category) }],
        [{}, { value: '', ...getStyle(CellStyle.category) }],
        [],
        [
          {},
          { value: '기존 통제 정보', ...getStyle(CellStyle.subCategory1) },
          { value: '', ...getStyle(CellStyle.subCategory1) },
          { value: '수정된 통제 내용', ...getStyle(CellStyle.subCategory1) },
          { value: '수정 근거', ...getStyle(CellStyle.subCategory1) }
        ],
        [
          {},
          { value: '기존 RCM 상 존재하였던 통제 정보', ...getStyle(CellStyle.category) },
          { value: '', ...getStyle(CellStyle.category) },
          { value: '당 설계평가에서 수정된 RCM 정보', ...getStyle(CellStyle.category) },
          { value: '수정된 RCM 정보의 근거', ...getStyle(CellStyle.category) }
        ],
        [{}, { value: '통제 운영 속성', ...getStyle(CellStyle.category) }],
        getDefectRow('control', 'controlRiskLevel'),
        getDefectRow('control', 'TOC_Procedure'),
        getDefectRow('control', 'TOC_Evidence'),
        getDefectRow('control', 'TOC_Population'),
        getDefectRow('control', 'TOC_PopulationCount'),
        getDefectRow('control', 'TOC_TestProperties'),
        getDefectRow('control', 'TOC_Exception'),
        getDefectRow('control', 'TOC_TestMethod'),
        getDefectRow('control', 'TOC_TestMethod_InquiryInspection'),
        getDefectRow('control', 'TOC_TestMethod_Observation'),
        getDefectRow('control', 'TOC_TestMethod_Reperformance')
      ]
    }
  }
}

export const exportToExcel = async (staffs: Staff[], evaluation: Evaluation): Promise<Blob> => {
  /**
   * 설계평가를 엑셀파일로 변경한다
   */

  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'CCKSolution'

  const evaluationDefs = getDesignEvaluationDefs(staffs)
  const sheetOptions = { views: [{ showGridLines: false, zoomScale: 85 }] }

  const testInfoSheet = workbook.addWorksheet('설계평가', sheetOptions)
  // testInfoSheet.properties.defaultRowHeight = 25
  writeWorksheet(evaluationDefs.DesignEvaluation, testInfoSheet, evaluation)

  const fileInfoSheet = workbook.addWorksheet('증빙문서', sheetOptions)
  writeWorksheet(
    updateFileInfoRows(staffs, evaluationDefs.FileInfo, evaluation),
    fileInfoSheet,
    evaluation
  )

  // RcmModification
  const rcmModificationSheet = workbook.addWorksheet('RCM수정사항', sheetOptions)
  rcmModificationSheet.properties.defaultRowHeight = 40
  writeWorksheet(evaluationDefs.RcmModification, rcmModificationSheet, evaluation)

  const buffer = await workbook.xlsx.writeBuffer()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  return new Blob([buffer], { type: fileType })
}

async function downloadFilesWithoutRecord(zip: JSZip, evaluation: Evaluation): Promise<void> {
  const baseFilePath = `설계평가/${evaluation.data.base.name}/${evaluation.controlId}`
  const files = evaluation.data.content.files

  if (files.populationFile && !_.isEmpty(files.populationFile.fileName)) {
    const filePath = `${baseFilePath}/${files.populationFile.fileName}`
    const url = await storageGetDownloadUrl(filePath)
    const blob = await storageDownloadBlobFromUrl(url)
    zip.file(files.populationFile.fileName, blob, { binary: true })
  }

  await Promise.all(
    _.map(_.concat(_.values(files.evidenceFile), _.values(files.extraFile)), async (file) => {
      const filePath = `${baseFilePath}/${file?.fileName}`
      const url = await storageGetDownloadUrl(filePath)
      const fileBlob = await storageDownloadBlobFromUrl(url)
      zip.file(file?.fileName, fileBlob, { binary: true })
    })
  )
}

export async function exportToExcelZipDesign(
  staffs: Staff[],
  evaluations: Evaluation[]
): Promise<void> {
  const zip = new JSZip()
  await Promise.all(
    _.map(evaluations, async (evaluation) => {
      const subZip = zip.folder(evaluation.controlId)
      if (subZip) {
        subZip.file(
          `(${evaluation.data.base.name})(${evaluation.controlId})(보고서 readonly) 보고서.xlsx`,
          exportToExcel(staffs, evaluation),
          {
            binary: true
          }
        )
        await downloadFilesWithoutRecord(subZip, evaluation)
      }
    })
  )

  zip.generateAsync({ type: 'blob' }).then((content) => {
    const name = _.get(_.head(evaluations), 'base.name', '설계평가')
    saveAs(content, `${name}_보고서.zip`)
  })
}
