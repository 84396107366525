export function deleteCellRenderer(params: any): HTMLElement {
  const deleteElement = document.createElement('a')
  deleteElement.innerHTML = '삭제'
  deleteElement.onclick = () => params.deleteItem(params.data)
  return deleteElement
}

export function modifyAndDeleteCellRenderer(params: any): HTMLElement {
  const modifyButton = document.createElement('a')
  modifyButton.style.marginRight = '8px'
  modifyButton.innerHTML = '수정'
  modifyButton.onclick = () => params.openItemEditor(params.data)

  const deleteElement = document.createElement('a')
  deleteElement.innerHTML = '삭제'
  deleteElement.onclick = () => params.deleteItem(params.data)

  const div = document.createElement('div')
  div.appendChild(modifyButton)
  div.appendChild(deleteElement)
  return div
}
